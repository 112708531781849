import { findIndex } from 'lodash'
const defaultStudent = {
  id: null,
  name: '',
  last_login: '',
  email: '',
  email_secundario: '',
  age: '',
  birthdate: '',
  is_active: true,
  cellphone: '',
  macrocycles: [],
  personal_anamnesis: { days_of_week: [] },
  physiological_anamnesis: {}
}

function MesocycleToBeEvaluated () {
  this.evaluated_items = {
    perimeter: { bodyPerimeter: {}, skinFold: {} },
    posture: {},
    somatotype: {},
    caloric_expenditure: {},
    heart: {},
    student: {},
    biochemical: {}
  }
}

function MicrocycleToBeEvaluated () {
  this.evaluated_items = {
    perimeter: { bodyPerimeter: {}, skinFold: {} },
    posture: {},
    somatotype: {},
    caloric_expenditure: {},
    heart: {},
    student: {},
    biochemical: {}
  }
}

export default {
  namespaced: true,
  state: {
    students: [],
    currentStudent: { ...defaultStudent },
    currentMacroCycle: {},
    mesocycleToBeEvaluated: new MesocycleToBeEvaluated(),
    microcycleToBeEvaluated: new MicrocycleToBeEvaluated(),
    studentEvaluationDialog: false,
    studentEvaluationDialogMicro: false,
    studentTrainingDialog: false,
    studentTrainingDialogMicro: false,
    studentFinalResultDialog: false,
    createOrUpdateExerciseDialog: false,
    trainingIndex: -1,
    createOrUpdateExercise: {},
    createOrUpdateExerciseIndex: -1,
    cycleType: 'meso'
  },
  mutations: {
    SET_CYCLE_TYPE (state, cycleType) {
      state.cycleType = cycleType
    },
    SET_STUDENTS (state, payload) {
      Object.assign(state, { students: payload })
    },
    SET_STUDENT (state, payload) {
      Object.assign(state, { currentStudent: payload })
    },
    ADD_STUDENTS (state, payload) {
      state.students.push(...payload)
    },
    RESET_STUDENT (state) {
      Object.assign(state, { currentStudent: { ...defaultStudent } })
      Object.assign(state.currentStudent, { personal_anamnesis: { days_of_week: [] }, physiological_anamnesis: {} })
      state.mesocycleToBeEvaluated = new MesocycleToBeEvaluated()
      state.microcycleToBeEvaluated = new MicrocycleToBeEvaluated()
    },
    CREATE_MACROCYCLE (state, payload) {
      state.currentStudent.macrocycles.unshift(payload)
    },
    UPDATE_MACROCYCLE (state, payload) {
      const macrocycles = Object.assign([], state.currentStudent.macrocycles)
      let idx = macrocycles.findIndex(macrocycle => macrocycle.id === payload.id)
      macrocycles[idx] = payload
      state.currentStudent.macrocycles = [...macrocycles]
    },
    DESTROY_MACROCYCLE (state, index) {
      state.currentStudent.macrocycles.splice(index, 1)
    },
    SET_MESOCYCLES (state, { macrocycleId, mesocycles }) {
      const index = findIndex(state.currentStudent.macrocycles, { id: macrocycleId })
      state.currentStudent.macrocycles[index].mesocycles = mesocycles

      if (state.currentStudent.macrocycles[index].mesocycles.length > 0) {
        state.currentStudent.macrocycles[index].start_date = state.currentStudent.macrocycles[index].mesocycles.reduce(
          function (result, current) {
            var currentDateTime = Date.parse(current.start_date.replace(/-0/g, '-'))
            var resultDateTime = Date.parse(result.start_date.replace(/-0/g, '-'))

            return currentDateTime < resultDateTime ? current : result
          }
        ).start_date

        state.currentStudent.macrocycles[index].end_date = state.currentStudent.macrocycles[index].mesocycles.reduce(
          function (result, current) {
            var currentDateTime = Date.parse(current.end_date.replace(/-0/g, '-'))
            var resultDateTime = Date.parse(result.end_date.replace(/-0/g, '-'))

            return currentDateTime > resultDateTime ? current : result
          }
        ).end_date
      }
    },
    SET_MICROCYCLES (state, { macrocycleId, microcycles }) {
      const index = findIndex(state.currentStudent.macrocycles, { id: macrocycleId })
      state.currentStudent.macrocycles[index].microcycles = microcycles

      if (state.currentStudent.macrocycles[index].microcycles.length > 0) {
        state.currentStudent.macrocycles[index].start_date = state.currentStudent.macrocycles[index].microcycles.reduce(
          function (result, current) {
            var currentDateTime = Date.parse(current.start_date.replace(/-0/g, '-'))
            var resultDateTime = Date.parse(result.start_date.replace(/-0/g, '-'))

            return currentDateTime < resultDateTime ? current : result
          }
        ).start_date

        state.currentStudent.macrocycles[index].end_date = state.currentStudent.macrocycles[index].microcycles.reduce(
          function (result, current) {
            var currentDateTime = Date.parse(current.end_date.replace(/-0/g, '-'))
            var resultDateTime = Date.parse(result.end_date.replace(/-0/g, '-'))

            return currentDateTime > resultDateTime ? current : result
          }
        ).end_date
      }
    },
    SET_MESOCYCLE_EVALUATION (state, evaluation) {
      state.mesocycleToBeEvaluated.evaluated_items = evaluation
    },
    SET_MESOCYCLE_TRAINING (state, trainings) {
      state.mesocycleToBeEvaluated.trainings = trainings
    },
    SET_MESOCYCLE_TO_BE_EVALUATED (state, mesocycle) {
      state.mesocycleToBeEvaluated = Object.assign({}, mesocycle)
    },
    SET_MICROCYCLE_EVALUATION (state, evaluation) {
      state.microcycleToBeEvaluated.evaluated_items = evaluation
    },
    SET_MICROCYCLE_TRAINING (state, trainings) {
      state.microcycleToBeEvaluated.trainings = trainings
    },
    SET_MICROCYCLE_TO_BE_EVALUATED (state, microcycle) {
      state.microcycleToBeEvaluated = Object.assign({}, microcycle)
    },
    SET_STUDENT_EVALUATION_DIALOG (state, dialogState) {
      state.studentEvaluationDialog = dialogState
    },
    SET_STUDENT_EVALUATION_DIALOG_MICRO (state, dialogState) {
      state.studentEvaluationDialogMicro = dialogState
    },
    SET_TRAINING_DIALOG (state, dialogState) {
      state.studentTrainingDialog = dialogState
    },
    SET_TRAINING_DIALOG_MICRO (state, dialogState) {
      state.studentTrainingDialogMicro = dialogState
    },
    SET_FINAL_RESULT_DIALOG (state, dialogState) {
      state.studentFinalResultDialog = dialogState
    },

    SET_CREATE_OR_UPDATE_EXERCISE_DIALOG (state, dialogState) {
      state.createOrUpdateExerciseDialog = dialogState
    },
    SET_CREATE_OR_UPDATE_EXERCISE (state, exercise) {
      state.createOrUpdateExercise = Object.assign({}, exercise)
    },
    SET_CREATE_OR_UPDATE_EXERCISE_INDEX (state, exerciseIndex) {
      state.createOrUpdateExerciseIndex = exerciseIndex
    },
    DESTROY_EXERCISE (state, { trainingIndex, exerciseIndex, cycle }) {
      if (cycle === 'micro') { state.microcycleToBeEvaluated.trainings[trainingIndex].exercises.splice(exerciseIndex, 1) } else { state.mesocycleToBeEvaluated.trainings[trainingIndex].exercises.splice(exerciseIndex, 1) }
    },

    SET_TRAINING_INDEX (state, trainingIndex) {
      state.trainingIndex = trainingIndex
    },
    DESTROY_TRAINING (state, { trainingIndex, cycle }) {
      console.log(cycle)
      if (cycle === 'micro') { state.microcycleToBeEvaluated.trainings.splice(trainingIndex, 1) } else { state.mesocycleToBeEvaluated.trainings.splice(trainingIndex, 1) }
    },
    UPDATE_MESOCYCLE_TO_BE_EVALUATED (state, newMesocycle) {
      var macrocycleIndex = findIndex(state.currentStudent.macrocycles, { id: newMesocycle.macrocycle.id })
      const macro = state.currentStudent.macrocycles[macrocycleIndex]
      const mesocycleIndex = findIndex(macro.mesocycles, { id: newMesocycle.id })
      var mesos = macro.mesocycles
      mesos[mesocycleIndex] = newMesocycle
      state.currentStudent.macrocycles[macrocycleIndex].mesocycles = mesos
    },
    UPDATE_MICROCYCLE_TO_BE_EVALUATED (state, newMicrocycle) {
      var macrocycleIndex = findIndex(state.currentStudent.macrocycles, { id: newMicrocycle.macrocycle.id })
      const macro = state.currentStudent.macrocycles[macrocycleIndex]
      const microcycleIndex = findIndex(macro.microcycles, { id: newMicrocycle.id })
      var micros = macro.microcycles
      micros[microcycleIndex] = newMicrocycle
      state.currentStudent.macrocycles[macrocycleIndex].microcycles = micros
    },
    SET_CURRENT_MACROCYCLE (state, macrocycleId) {
      let macrocycleIndex = findIndex(state.currentStudent.macrocycles, { id: macrocycleId })
      state.currentMacroCycle = Object.assign({}, state.currentStudent.macrocycles[macrocycleIndex])
    },
    RESET_CURRENT_MACROCYCLE (state) {
      state.currentMacroCycle = Object.assign({}, null)
    },
    RESET_DIALOGS (state) {
      state.studentEvaluationDialog = false
      state.studentEvaluationDialogMicro = false
      state.studentTrainingDialog = false
      state.studentTrainingDialogMicro = false
      state.studentFinalResultDialog = false
      state.createOrUpdateExerciseDialog = false
    }

  },
  actions: {
    setCycleType ({ commit }, cycleType) {
      commit('SET_CYCLE_TYPE', cycleType)
    },
    setStudents ({ commit }, payload) {
      commit('SET_STUDENTS', payload)
    },
    setStudent ({ commit }, payload) {
      commit('SET_STUDENT', payload)
    },
    addStudents ({ commit }, payload) {
      commit('ADD_STUDENTS', payload)
    },
    resetStudent ({ commit }) {
      commit('RESET_STUDENT')
    },
    createMacrocycle ({ commit }, payload) {
      commit('CREATE_MACROCYCLE', payload)
    },
    updateMacrocycle ({ commit }, payload) {
      commit('UPDATE_MACROCYCLE', payload)
    },
    destroyMacrocycle ({ commit }, index) {
      commit('DESTROY_MACROCYCLE', index)
    },
    setMesocycles ({ commit }, { macrocycleId, mesocycles }) {
      commit('SET_MESOCYCLES', { macrocycleId, mesocycles })
    },
    setMicrocycles ({ commit }, { macrocycleId, microcycles }) {
      commit('SET_MICROCYCLES', { macrocycleId, microcycles })
    },
    setMesocycleEvaluation ({ commit }, evaluation) {
      commit('SET_MESOCYCLE_EVALUATION', evaluation)
    },
    setMicrocycleEvaluation ({ commit }, evaluation) {
      commit('SET_MICROCYCLE_EVALUATION', evaluation)
    },
    setMesocycleTraining ({ commit }, trainings) {
      commit('SET_MESOCYCLE_TRAINING', trainings)
    },
    setMicrocycleTraining ({ commit }, trainings) {
      commit('SET_MICROCYCLE_TRAINING', trainings)
    },
    setMesocycleToBeEvaluated ({ commit }, mesocyle) {
      commit('SET_MESOCYCLE_TO_BE_EVALUATED', mesocyle)
    },
    setMicrocycleToBeEvaluated ({ commit }, microcycle) {
      commit('SET_MICROCYCLE_TO_BE_EVALUATED', microcycle)
    },
    setStudentEvaluationDialog ({ commit }, dialogState) {
      commit('SET_STUDENT_EVALUATION_DIALOG', dialogState)
    },
    setStudentEvaluationDialogMicro ({ commit }, dialogState) {
      commit('SET_STUDENT_EVALUATION_DIALOG_MICRO', dialogState)
    },
    setTrainingDialog ({ commit }, dialogState) {
      commit('SET_TRAINING_DIALOG', dialogState)
    },
    setTrainingDialogMicro ({ commit }, dialogState) {
      commit('SET_TRAINING_DIALOG_MICRO', dialogState)
    },
    setFinalResultDialog ({ commit }, dialogState) {
      commit('SET_FINAL_RESULT_DIALOG', dialogState)
    },
    setCreateOrUpdateExerciseDialog ({ commit }, dialogState) {
      commit('SET_CREATE_OR_UPDATE_EXERCISE_DIALOG', dialogState)
    },
    setCreateOrUpdateExercise ({ commit }, exercise) {
      commit('SET_CREATE_OR_UPDATE_EXERCISE', exercise)
    },
    setCreateOrUpdateExerciseIndex ({ commit }, exerciseIndex) {
      commit('SET_CREATE_OR_UPDATE_EXERCISE_INDEX', exerciseIndex)
    },
    destroyExercise ({ commit }, { trainingIndex, exerciseIndex, cycle = 'meso' }) {
      commit('DESTROY_EXERCISE', { trainingIndex, exerciseIndex, cycle })
    },

    setTrainingIndex ({ commit }, trainingIndex) {
      commit('SET_TRAINING_INDEX', trainingIndex)
    },
    destroyTraining ({ commit }, { trainingIndex, cycle }) {
      commit('DESTROY_TRAINING', { trainingIndex, cycle })
    },
    updateMesocycleToBeEvaluated ({ commit }, mesocycle) {
      commit('UPDATE_MESOCYCLE_TO_BE_EVALUATED', mesocycle)
      commit('SET_MESOCYCLE_TO_BE_EVALUATED', mesocycle)
    },
    updateMicrocycleToBeEvaluated ({ commit }, microcycle) {
      commit('UPDATE_MICROCYCLE_TO_BE_EVALUATED', microcycle)
      commit('SET_MICROCYCLE_TO_BE_EVALUATED', microcycle)
    },
    setCurrentMacroCycle ({ commit }, macrocycleId) {
      commit('SET_CURRENT_MACROCYCLE', macrocycleId)
    },
    resetCurrentMacroCycle ({ commit }) {
      commit('RESET_CURRENT_MACROCYCLE')
    },
    resetDialogs ({ commit }) {
      commit('RESET_DIALOGS')
    }
  },
  getters: {
    cycleType: state => state.cycleType,
    students: state => state.students,
    student: state => state.currentStudent,
    macrocycles: state => state.currentStudent.macrocycles,
    currentMacroCycle: state => state.currentMacroCycle,
    mesocycleToBeEvaluated: state => state.mesocycleToBeEvaluated,
    microcycleToBeEvaluated: state => state.microcycleToBeEvaluated,
    studentEvaluationDialog: state => state.studentEvaluationDialog,
    studentEvaluationDialogMicro: state => state.studentEvaluationDialogMicro,
    studentTrainingDialog: state => state.studentTrainingDialog,
    studentTrainingDialogMicro: state => state.studentTrainingDialogMicro,
    studentFinalResultDialog: state => state.studentFinalResultDialog,

    createOrUpdateExerciseDialog: state => state.createOrUpdateExerciseDialog,
    createOrUpdateExercise: state => state.createOrUpdateExercise,
    createOrUpdateExerciseIndex: state => state.createOrUpdateExerciseIndex,

    trainingIndex: state => state.trainingIndex
  }
}
