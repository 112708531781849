import axios from './axios-instance'

export default {
  createOrUpdate (studentId, payload) {
    if (payload.id === null) {
      return axios.post(`v1/students/${studentId}/macrocycles`, payload)
    } else {
      return axios.put(`v1/students/${studentId}/macrocycles/${payload.id}`, payload)
    }
  },
  destroy (studentId, id) {
    return axios.delete(`v1/students/${studentId}/macrocycles/${id}`)
  }
}
