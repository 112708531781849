import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL
// const baseURL = 'https://fisic.mestresdaweb.io/'

const axiosInstance = axios.create({
  baseURL: `${baseURL}/api/`
})

axiosInstance.defaults.headers.common.Accept = 'application/json'

export default axiosInstance
