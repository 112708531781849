/*eslint-disable*/
const parseDateFromString = {
  methods: {
    parseDateFromString (stringDate) {
      if (stringDate === undefined)
        return ''
      const [year, month, day] = stringDate.split('-')
      return new Date(year, month - 1, day)
    }
  }
}

export default parseDateFromString
