<template>
  <v-form
    ref="form"
  >
    <v-row class="pa-3">
      <v-col cols="4" class="secondary">
        <v-row class="white--text display-1 align-center justify-center fill-height">
          R${{getPlanValue}}
        </v-row>

      </v-col>
      <v-col style="min-height: 160px;" cols=8>
        <v-row class="pl-6 pa-0">
          <v-select class="font-weight-black headline"
            :loading="loadingPlans"
            item-color="secondary"
            color="accent"
            :items="plans"
            v-model="plan"
            return-object
            hide-details
            item-text="name"
          ></v-select>
        </v-row>
        <v-row>
          <v-col class="ml-2 pb-0 mb-n3">
            <p class="text--secondary caption ma-0" v-if="!yearPlan"> *Este plano é um plano mensal e será renovado automaticamente ao termino do periodo de validade.</p>
            <p class="text--secondary caption ma-0" v-else> **Este plano é um plano anual e será renovado automaticamente ao termino do periodo de validade.</p>
          </v-col>
        </v-row>
        <v-row class="align-center pl-6 mb-n4" v-if="hasPlanYealy">
          <v-switch color="primary"
            v-model="yearPlan"
          />
          Cobrança anual ( {{getPlanDiscount}} % de desconto )
        </v-row>
      </v-col>
    </v-row>
    <!-- disable installments -->
    <!-- <v-expand-transition>
      <v-row dense v-show="registerForm.paymentInformation.yearPlan">
        <v-col>
          <v-select
            color="primary"
            v-model="registerForm.paymentInformation.installments"
            :items="installments"
            item-text="text"
            item-value="value"
            filled
          />
        </v-col>
      </v-row>
    </v-expand-transition> -->
    <v-row dense>
      <v-col cols="8">
        <v-text-field
        v-model="registerForm.paymentInformation.creditCard.number"
          color="secondary"
          label="Numero do cartão"
          v-mask="'#### #### #### ####'"
          :rules="[validationRules.creditCard]"
          filled
          dense
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="registerForm.paymentInformation.creditCard.cvv"
          :rules="[validationRules.required]"
          color="secondary"
          label="CVC"
          filled
          dense
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          v-model="registerForm.paymentInformation.creditCard.expiration_date"
          :rules="[validationRules.required]"
          v-mask="'##/####'"
          color="secondary"
          label="Validade"
          filled
          dense
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="registerForm.cpf"
          color="secondary"
          label="CPF do Titular"
          v-mask="'###.###.###-##'"
          :rules="[validationRules.cpf]"
          filled
          dense
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
          class="uppercase"
          v-model="registerForm.paymentInformation.creditCard.holder_name"
          color="secondary"
          label="Nome impresso no cartão"
          :rules="[validationRules.required]"
          filled
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="registerForm.phone"
          color="secondary"
          label="Celular"
          v-mask="(registerForm.phone || '').length < 15 ? '(##) ####-#####' : '(##) # ####-####'"
          :rules="[validationRules.required]"
          filled
          dense
          @keyup.enter="next"
        />
      </v-col>
    </v-row>
    <slot name="actions" :next="validate">
      <v-row class="pa-3 justify-between">
        <v-col cols="3">
          <v-btn @click="back">
            <span class="secondary--text">Retornar</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-btn color="secondary" @click="validate(next)">
            Proximo
          </v-btn>
        </v-col>
      </v-row>
    </slot>

  </v-form>
</template>

<script>
import validationRules from '@/mixins/validation-rules'
import AuthenticationService from '@/services/authentication-service'
import pagarme from 'pagarme'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'payment-information-step',
  data: () => ({
    validationRules,
    plans: [],
    loadingPlans: true,
    plan: null,
    yearPlan: false
  }),
  methods: {
    ...mapActions('base', ['setSnackbar']),
    validate (next) {
      if (this.$refs.form.validate()) {
        var card = {
          card_holder_name: this.registerForm.paymentInformation.creditCard.holder_name,
          card_expiration_date: this.registerForm.paymentInformation.creditCard.expiration_date,
          card_number: this.registerForm.paymentInformation.creditCard.number,
          card_cvv: this.registerForm.paymentInformation.creditCard.cvv
        }
        let valid = true
        Object.entries(pagarme.validate({ card: card }).card).reduce((obj, [key, value]) => {
          valid = valid && value
        })
        if (valid) {
          pagarme.client.connect({ encryption_key: process.env.VUE_APP_PAGARME_ENCRYPTION_KEY })
            .then(client => client.security.encrypt(card))
            .then(cardHash => {
              const payload = {
                cardHash: cardHash,
                plan: this.yearPlan ? this.plan.yearly : this.plan.monthly,
                yearPlan: this.yearPlan,
                holderCpf: this.registerForm.cpf,
                holderPhone: this.registerForm.phone
              }
              next(payload)
            })
        } else {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Dados do Cartão inválido!'
          })
        }
      }
    },
    next (payload) {
      this.registerForm.paymentInformation.creditCard.card_hash = payload.cardHash

      let plan = this.yearPlan ? this.plan.yearly : this.plan.monthly
      this.registerForm.paymentInformation.plan = { id: plan.id, installments: plan.max_installments }
      this.$emit('next')
    },
    back () {
      this.$emit('back')
    },
    getPlans () {
      AuthenticationService.plans()
        .then(({ data }) => {
          this.plans = data.plans
          this.plan = this.plans[0]
          this.loadingPlans = false
        }).catch(() => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Não foi possivel recuperar os dados dos planos!'
          })
        })
    }
  },
  computed: {
    ...mapGetters('auth', ['registerForm']),
    installments () {
      let first = { text: `À Vista (R$ ${this.value.toFixed(2).replace(/[.]/g, ',')})`, value: 1 }
      let all = []
      let formatedInstallments = ''
      for (var x = 2; x <= 12; x++) {
        formatedInstallments = parseFloat(this.value / x).toFixed(2).replace(/[.]/g, ',')
        all.push({ text: `${x}x de R$ ${formatedInstallments} (R$ ${this.value.toFixed(2).replace(/[.]/g, ',')})`, value: x })
      }
      return [first, ...all]
    },
    getPlanDiscount () {
      try {
        return this.plan.yearly.discount_in_percent
      } catch (error) {
        return 0
      }
    },
    getPlanValue () {
      try {
        return (this.yearPlan ? this.plan.yearly.value : this.plan.monthly.value).toFixed(2).replace(/[.]/g, ',')
      } catch (error) {
        return '0,00'
      }
    },
    hasPlanYealy () {
      return !!this.plan && !!this.plan.yearly
    }
  },
  created () {
    this.getPlans()
    // this.plans = [{ 'name': 'Plano Básico LR-FIT', 'monthly': { 'id': 10, 'value': 34.9, 'max_installments': 1 }, 'yearly': { 'id': 192, 'value': 349.90, 'max_installments': 12, 'discount_in_percent': 10.0 } }, { 'name': 'Plano starter - CLENISSON CALAÇA CAVALCANTE GOMES', 'monthly': { 'id': 11, 'value': 400.0, 'max_installments': 1 } }, { 'name': 'Plano starter - Clenisson Calaça ', 'monthly': { 'id': 8, 'value': 1200.0, 'max_installments': 1, 'discount_in_percent': 91.7 } }, { 'name': 'Plano starter - Clenisson Calaça', 'monthly': { 'id': 3, 'value': 1600.0, 'max_installments': 1, 'discount_in_percent': 91.7 } }]
  }
}
</script>
<style lang="scss">
  .v-select__selection.v-select__selection--comma {
    color: var(--v-secondary-base) !important;
  }

</style>
