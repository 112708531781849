export async function fetchAddressByCEP (cep) {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
    if (!response.ok) {
      throw new Error('Erro ao buscar o endereço.')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erro ao buscar o endereço:', error)
    return null
  }
}
