<template>

  <div>
    <v-chip v-if="modifiedStudent" class="modified-student secondary">
      Modificou cadastro
    </v-chip>
    <div
      :class="[
        { 'elevation-3': student.is_active },
        { 'inactive-card': !student.is_active },
        'student-card'
      ]"

    >
      <v-row>
        <v-col cols="3" @click="onStudentClick(student.id)">
          <v-avatar>
            <v-img
              :src="
                student.photo_url ||
                  require('@/assets/images/user-placeholder.png')
              "
              :alt="student.name"
              :lazy-src="require('@/assets/images/user-placeholder.png')"
              @error="onImageError"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </v-col>
        <v-col @click="onStudentClick(student.id)">
          <div>
            <span class="subtitle-1 font-weight-bold">
              {{ student.id }} -
              {{
               student.name | reduceName
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="justify-center align-center">
        <v-col cols="10"   >
          <v-hover v-slot:default="{ hover }">
            <v-chip
              @click.stop="copyToClipboard($event)"
              small
              class="my-2"
              :class="student.is_active ? 'primary lighten-3' : 'inactive-chip'"
            >
              {{ student.email }}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-show="hover" x-small class="pl-1" v-on="on">
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copiar</span>
              </v-tooltip>
            </v-chip>
          </v-hover>
          <v-chip
            small
            class="mr-2"
            :class="student.is_active ? 'primary lighten-3' : 'inactive-chip'"
          >
            {{ this.formatAge(student.birthdate) }} anos
          </v-chip>
          <v-chip
            small
            :class="student.is_active ? 'primary lighten-3' : 'inactive-chip'"
          >
            {{ student.cellphone }}
          </v-chip>
        </v-col>
        <v-col cols="10" v-if="!student.is_active">
             <v-btn
                  color="primary"
                  value="student.id"
                  @click="deleteStudent()"
                >
               Excluir
            </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'student-card',

  props: {
    student: {
      type: Object,
      default: () => ({})
    }
  },
  filters: {
    reduceName: function (value) {
      if (value) {
        var names = value.split(' ')
        if (names.length > 1) {
          return names[0] + ' ' + names[names.length - 1]
        } else {
          return names[0]
        }
      }
      return ''
    }
  },
  methods: {
    ...mapActions('base', ['setLoader']),
    ...mapActions('students', ['setStudent', 'resetDialogs', 'resetStudent']),
    editStudent () {
      this.setStudent(this.student)
      this.$emit('editStudent')
    },
    deleteStudent () {
      this.setStudent(this.student)
      this.$emit('deleteStudent')
    },
    onStudentClick (id) {
      this.resetDialogs()
      this.resetStudent()
      this.$router.push({ name: 'StudentShow', params: { id: id } })
    },
    onImageError () {
      this.student.photo_url = null
    },
    formatAge (birthDateISO) {
      const [year, month, day] = birthDateISO.split('T')[0].split('-')
      const birthDate = new Date(year, month - 1, day)
      const today = new Date()
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }

      return age
    }
  },
  computed: {
    ...mapGetters('base', ['loader']),
    modifiedStudent () {
      return (
        this.student.is_profile_changed && !this.student.is_changes_notified
      )
    }

  }
}
</script>

<style scoped>
.student-card {
  width: 275px;
  height: 185px;
  padding: 15px 20px;
  margin: 15px 12px;
  cursor: pointer;
}

.background-list-student:hover {
  background: #dfd7ff;
}

.inactive-chip {
  background: #e5e5e9 !important;
}

.inactive-card {
  background: #f9f9ff !important;
  border: solid #f4f4f4 1px; height: 240px;
}

.modified-student {
  position: absolute;
  margin-left: 160px;
}
</style>
