<template>
  <div>
    <calendar
      canMoveNext="false"
      canMovePrev="false"
      :rows="rows"
      :columns="columns"
      :min-page="getPage(macrocycle.start_date)"
      title-position="left"
      :attributes="attributes"
      locale="pt-br"
      :first-day-of-week="1"
      :from-date="new Date(macrocycle.start_date)"
      v-if="macrocycle.mesocycles.length > 0 && macrocycle.start_date != null && macrocycle.end_date != null"
    >
      <template
        v-slot:header="{ title }"
      >
        <div
          class="vc-header"
        >
          <span
            class="vc-title"
          >
            {{title}}
          </span>
        </div>
      </template>

      <template
        v-slot:day-content="value"
      >
          <v-btn
            icon
            height="32"
            width="32"
            class="vc-rounded-full vc-day-content calendar-day-btn mt-0"
            :class="[ borderDay( value ), startOrEnd( value ) ]"
            @click="click(value, $event)"
          >
            {{value.day.label}}
          </v-btn>

          <div
            class="highlight-background"
            :class="highlightColor( value )"
          />

          <div class="selector hidden"/>
      </template>

    </calendar>
    <p
      class="elevation-3 pa-5 text-center"
      v-else
    >
      <span
      class="caption">
        Não possui nenhum Mesociclo cadastrado
      </span>

    </p>

    <v-menu
      offset-x
      v-model="showMenu"
      :left="alignLeft"
      :nudge-right="alignLeft ? 0 : 40"
      absolute
      :position-x.sync="posMenu.x"
      :position-y.sync="posMenu.y"
      :close-on-content-click="true"
      :max-width="387"
      :nudge-bottom="16"
    >
      <student-meso-cycle-card
        @close="closeCard()"
        v-if="currentIndex != -1"
        :cols="['12', '6', '6', '12']"
        :mesocycle="currentMesocycle"
        :index="currentIndex"
        :macrocycle="macrocycle"
      />
      <v-card
        class="pa-3"
        v-else-if="canCreate"
      >
        <student-meso-cycle-btn-new-meso
          @close="closeCard()"
          :newMesocycle.sync="mesocycleOnClickedDate"
          :macrocycleId="macrocycle.id"
          :macrocycle="macrocycle"
        />
      </v-card>
    </v-menu>
    <v-row v-if="macrocycle.mesocycles.length <= 0 && canCreate" class="d-flex justify-end">
      <v-col cols="auto">
        <student-meso-cycle-btn-new-meso
          :macrocycleId="macrocycle.id"
          :macrocycle="macrocycle"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import parseDateFromString from '@/mixins/parse-date-from-string'
import StudentMesoCycleCard from '@/components/student/show/StudentMesoCycleCard'
import StudentMesoCycleBtnNewMeso from '@/components/student/show/StudentMesoCycleBtnNewMeso'

export default {
  name: 'student-macro-calendar',
  props: ['macrocycle', 'canCreate'],
  mixins: [parseDateFromString],
  components: { Calendar, StudentMesoCycleCard, StudentMesoCycleBtnNewMeso },
  data: () => ({
    mesocycleOnClickedDate: null,
    currentIndex: -1,
    output: '',
    posMenu: {
      x: 0,
      y: 0
    },
    alignLeft: false,
    currentBtn: null,
    mesocycleDetail: false,
    showMenu: false
  }),
  watch: {
    showMenu (newValue, oldValue) {
      if (!oldValue && newValue) {
        // opening
        if (this.currentBtn) {
          this.currentBtn.classList.remove('hidden')
        }
      } else if (oldValue && !newValue) {
        // closing
        this.currentBtn.classList.add('hidden')
        this.currentBtn = null
      }
    }
  },
  computed: {
    currentMesocycle () {
      return this.currentIndex === -1 ? null : this.macrocycle.mesocycles[this.currentIndex]
    },
    months () {
      var startMonth = Number(this.macrocycle.start_date.slice(5, 7)) + (Number(this.macrocycle.start_date.slice(0, 4)) * 12)
      var endMonth = Number(this.macrocycle.end_date.slice(5, 7)) + (Number(this.macrocycle.end_date.slice(0, 4)) * 12)
      return endMonth - startMonth + 1
    },
    columns () {
      return 3
    },
    rows () {
      return Math.ceil(this.months / 3)
    },
    attributes () {
      const self = this
      return this.macrocycle.mesocycles.length <= 0 ? {} : this.macrocycle.mesocycles.map(function (mesocycle, index) {
        const today = new Date()
        var color = today > self.parseDateFromString(mesocycle.start_date) &&
                      today < self.parseDateFromString(mesocycle.end_date)
          ? 'primary'
          : 'grey'
        return {
          highlight: true,
          customData: {
            start: {
              class: color,
              highlightClass: 'highlight-start'
            },
            base: {
              class: color + '-base',
              highlightClass: 'highlight-base'
            },
            end: {
              class: color,
              highlightClass: 'highlight-end'
            },
            index: index
          },
          dates: {
            start: mesocycle.start_date,
            end: mesocycle.end_date
          }
        }
      })
    }
  },
  methods: {
    closeCard () {
      this.showMenu = false
    },
    getPage (date) {
      var month, year
      month = Number(date.slice(5, 7))
      year = Number(date.slice(0, 4))
      return {
        month,
        year
      }
    },
    highlightColor ({ attributes, day }) {
      if (!attributes || !attributes[0]) { return '' }
      var _class = attributes[0].customData.base.class

      if (attributes[0].dates[0].startTime === day.dateTime) {
        _class += ' ' + attributes[0].customData.start.highlightClass
      }

      if (attributes[0].dates[0].endTime === day.dateTime) {
        _class += ' ' + attributes[0].customData.end.highlightClass
      }

      return _class
    },
    startOrEnd ({ attributes, day }) {
      if (!attributes || !attributes[0]) { return '' }

      if (attributes[0].dates[0].startTime === day.dateTime) {
        return attributes[0].customData.start.class + ' vc-text-white'
      }

      if (attributes[0].dates[0].endTime === day.dateTime) {
        return attributes[0].customData.end.class + ' vc-text-white'
      }
    },
    borderDay ({ attributes, day }) {
      if (!attributes || !attributes[0] || attributes[0].dates[0].startTime === day.dateTime || attributes[0].dates[0].endTime === day.dateTime) { return '' }
      if (day.isFirstDay || day.isLastDay || day.onLeft || day.onRight) {
        return attributes[0].customData.base.class
      }
    },
    click ({ attributes, day }, event) {
      // try hidden selector
      if (this.currentBtn) {
        this.currentBtn.classList.add('hidden')
      }

      // send date
      this.mesocycleOnClickedDate = { start_date: `${day.date.toISOString().substr(0, 10)}` }

      // set currentBtn
      if (!!event.target && !!event.target.parentElement && !!event.target.parentElement.nextElementSibling) { this.currentBtn = event.target.parentElement.nextElementSibling.nextSibling }

      if (!attributes || !attributes[0]) {
        this.currentIndex = -1
        this.mesocycleDetail = false
      } else {
        this.currentIndex = attributes[0].customData.index
        this.mesocycleDetail = true
      }
      this.posMenu = {
        x: event.x - event.offsetX,
        y: event.y - event.offsetY
      }
      if ((window.innerWidth - this.posMenu.x) > 387) {
        this.alignLeft = false
      } else {
        this.alignLeft = true
      }
      this.showMenu = !this.showMenu
    }
  }

}
</script>

<style lang="sass">
.v-menu__content
  overflow: visible !important
  contain: unset !important
  .student-meso-cycle-card
    margin-top: -40%

.menu-bkg
  width: 32px
  height: 32px
  display: contents
  overflow: visible !important
  .v-card
    margin-left: 32px

.vc-container
  --highlight-height: 32px
  margin: auto
  font-family: Roboto !important

.vc-grid-cell
  min-width: 48px

.vc-title
  text-transform: uppercase
  font-size: 16px
  margin-top: 32px
  margin-bottom: 16px
  font-weight: bold
  line-height: 1.5

.calendar-day-btn.vc-text-white
  span.v-btn__content
    color: white !important
    font-weight: 500 !important

.selector
  position: absolute
  top: 0px
  left: 8px
  width: 32px
  height: 32px
  border-radius: 100%
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24) !important
  border: solid 2px #ffffff !important

.calendar-day-btn
  margin-left: 8px
  font-weight: 500 !important
  font-size: 13px !important
  color: #7c86a2 !important

.vc-weekday.vc-text-sm
  font-weight: bold
  color: rgba(52, 72, 94, 0.54)
  font-size: 14px

.vc-highlights .vc-day-layer
  display: none

.vc-day
  margin-top: 5px
  height: 32px !important

.vc-pane
  padding: 0px 16px

.primary-base
  background-color: var(--v-primary-lighten3) !important

.grey-base
  background-color: lightgray !important

.secondary-base
  background-color: var(--v-secondary-lighten3) !important

.highlight-background
  width: 48px
  height: 32px !important
  position: absolute
  top: 0px
  left: 0px
  z-index: -1

.vc-opacity-0
 .highlight-background
  display: none !important

.on-left, .is-first-day
  *
    .highlight-end
      display: none !important

    .highlight-background
      width: 50%
      margin-left: 50%

.on-left.is-last-day
  *
    .highlight-background
      display: none !important

.on-right, .is-last-day
  *
    .highlight-start
      display: none !important

    .highlight-background
      width: 50%
      margin-right: 50%

.on-right.is-first-day
  *
    .highlight-background
      display: none !important

.hidden
  display: none

.highlight-start
  width: 50%
  margin-left: 50%

.highlight-end
  width: 50%
  margin-right: 50%

.vc-arrows-container.title-left
  display: none

.vc-border
  border: 0px !important

.vc-bg-blue-200
  background-color: transparent !important
</style>
