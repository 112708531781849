<template>
  <v-dialog
    v-model="createOrUpdateExerciseDialog"
    persistent
    width="750"
  >
    <v-card>
      <v-form
        v-model="valid"
      >
      <v-card-title
        class="secondary white--text"
        primary-title
      >
        {{ isNewItem ? 'Novo' : 'Editar' }} Exercício
        <v-spacer></v-spacer>
        <v-btn
          fab
          x-small
          text
          @click.stop="closeDialog()"
        >
          <v-icon
            class="white--text"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model="exerciseSelected"
                :search-input.sync="searchText"
                :items="exercisesList"
                :loading="loadingSearch"
                :disabled="exerciseDeleted"
                no-data-text="Nenhum resultado encontrado"
                :menu-props="{maxHeight: '280px'}"
                :filled="exerciseDeleted"
                solo
                dense
                item-text="name"
                item-value="id"
                return-object
                label="Nome do Exercício"
                :rules="[(v) => (v && Object.keys(v).length > 0) || 'Campo obrigatório']"
                @change="logExerciseSelected"
              >
              <template v-slot:item="{item}">
                <v-layout style="width: 440px">
                  <v-row>
                    <v-col>
                    {{item.name}}
                    </v-col>

                    <v-col cols="auto" v-if="item.suggested" class="primary--text">
                      (sugerido)
                    </v-col>
                  </v-row>
                </v-layout>
              </template>
              </v-autocomplete>

            </v-col>

          </v-row>

          <v-row v-if="exerciseSelected && exerciseSelected.link">
            <v-col cols="12">
              <iframe width="100%" height="350" :src="`https://www.youtube.com/embed/${youtubeId}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </v-col>
          </v-row>

          <v-row v-if="isAerobic" class="my-n6">
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="exerciseInfo.tempo"
                  class="dense-padding-input-slot"
                  label="Tempo"
                  v-mask="['####']"
                  :rules="[validationRules.required]"
                  filled
                  dense
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="exerciseInfo.freqCardiaca"
                  class="dense-padding-input-slot"
                  label="Freq Cardíaca"
                  v-mask="['###']"
                  suffix="bpm"
                  :rules="[validationRules.required]"
                  filled
                  dense
                />
              </v-col>
          </v-row>

          <v-row v-if="isResistence">
            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.min_reps"
                class="dense-padding-input-slot"
                label="Repetições Mín"
                v-mask="['####']"
                filled hide-details="auto"
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.max_reps"
                class="dense-padding-input-slot"
                label="Repetições Máx"
                :rules="[validationRules.required, v => parseInt(v) > parseInt(exerciseInfo.min_reps || '0') || 'Deve ser maior que o mínimo']"
                v-mask="['####']"
                filled hide-details="auto"
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.series"
                class="dense-padding-input-slot"
                label="Séries"
                v-mask="['#####']"
                :rules="[validationRules.required]"
                filled hide-details="auto"
                dense
              />
            </v-col>
            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.descanco"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Descanso"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled hide-details="auto"
                dense
              />
            </v-col>
          </v-row>

          <v-row v-if="isFunctional">
            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.min_reps"
                class="dense-padding-input-slot"
                label="Repetições Mín"
                v-mask="['####']"
                filled hide-details="auto"
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.max_reps"
                class="dense-padding-input-slot"
                label="Repetições Máx"
                :rules="[validationRules.required, v => parseInt(v) > parseInt(exerciseInfo.min_reps || '0') || 'Deve ser maior que o mínimo']"
                v-mask="['####']"
                filled hide-details="auto"
                dense
              />
            </v-col>
          </v-row>

          <v-row v-if="isMobility">
            <v-col cols=12>
              <v-text-field
                v-model="exerciseInfo.series"
                class="dense-padding-input-slot"
                label="Séries"
                v-mask="['########']"
                :rules="[validationRules.required]"
                filled hide-details="auto"
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.min_reps"
                class="dense-padding-input-slot"
                label="Repetições "
                v-mask="['####']"
                filled hide-details="auto"
                :rules="[validationRules.required]"
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.descanco"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Descanso"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled hide-details="auto"
                dense
              />
            </v-col>
          </v-row>
          <v-row v-if="isStretching">
            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.series"
                class="dense-padding-input-slot"
                label="Séries"
                v-mask="['####']"
                :rules="[validationRules.required]"
                filled hide-details="auto"
                dense
              />
            </v-col>
            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.quantidade_tiros"
                v-mask="timeMask('hh:mm:ss')"
                class="dense-padding-input-slot"
                label="Tempo"
                suffix="hh:mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled hide-details="auto"
                dense
              />
            </v-col>

            <v-col cols=12>
              <v-text-field
                v-model="exerciseInfo.descanco"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Descanso"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled hide-details="auto"
                dense
              />
            </v-col>

          </v-row>
          <v-row v-if="isMyofascialRelease">
            <v-col cols=12>
              <v-text-field
                v-model="exerciseInfo.series"
                class="dense-padding-input-slot"
                label="Séries"
                v-mask="['########']"
                :rules="[validationRules.required]"
                filled hide-details="auto"
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.tempo"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Tempo Liberação"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.descanco"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Tempo de Intervalo"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled hide-details="auto"
                dense
              />
            </v-col>
          </v-row>

          <v-row v-if="isHiit">
            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.quantidade_tiros"
                class="dense-padding-input-slot"
                label="Número de Sprints"
                v-mask="['########']"
                :rules="[validationRules.required]"
                filled hide-details="auto"
                dense
              />
            </v-col>

             <v-col cols=6>
                <v-text-field
                  v-model="exerciseInfo.freqCardiaca"
                  class="dense-padding-input-slot"
                  label="Freq Cardíaca Alvo"
                  v-mask="['###']"
                  suffix="bpm"
                  :rules="[validationRules.required]"
                  filled
                  dense
                />
              </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.intervalo_tiros"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Intervalo entre Sprints"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled
                dense
              />
            </v-col>

            <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.tempo_tiros"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Duração das Sprints"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled hide-details="auto"
                dense
              />
            </v-col>
          </v-row>

          <v-row v-if="needsCadence">
            <v-col cols="6">
              <v-text-field
                v-model="exerciseInfo.cadencia"
                class="dense-padding-input-slot"
                label="Cadência"
                v-mask="['#-#-#-#']"
                :rules="[cadenceRule]"
                filled hide-details="auto"
                dense
              />
            </v-col>

            <v-col cols="6"  v-if="peso > 0">
              <v-text-field
                v-model="peso"
                class="dense-padding-input-slot"
                label="Peso"
                filled hide-details="auto"
                dense
                disabled
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="exerciseInfo.exerciseTechnic"
                :search-input.sync="searchExerciseTechnics"
                :items="exerciseTechnics"
                :loading="loadingSearchExerciseTechnics"
                no-data-text="Nenhum resultado encontrado"
                dense
                filled
                item-text="name"
                item-value="id"
                return-object
                label="Técnica de Intensificação"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="isExAerobico">

             <v-col cols=6>
              <v-checkbox
                      v-model="exerciseInfo.hiit"
                      label='Hiit'
                      filled
                      required
                      @change="selectHiit()"
                  />
             </v-col>
             <v-col cols=6>
              <v-checkbox
                      v-model="exerciseInfo.aerobicocont"
                      label='Aerobico Contínuo'
                      filled
                      @change="selectAerobicoContinuo()"
                  />
             </v-col>

             <v-col cols=6>
              <v-text-field
                  v-model="exerciseInfo.tempo"
                  class="dense-padding-input-slot"
                  label="Tempo"
                  v-mask="['###']"
                  :rules="[validationRules.required]"
                  filled
                  dense
              />

            </v-col>

            <v-col cols=6>
            <v-text-field
                  v-model="exerciseInfo.freqCardiaca"
                  class="dense-padding-input-slot"
                  label="Freq Cardíaca"
                  v-mask="['###']"
                  suffix="bpm"
                  :rules="[validationRules.required]"
                  filled
                  dense
             />
            </v-col>
            <v-col cols=6 v-if="exerciseInfo.hiit">
              <v-text-field
                v-model="exerciseInfo.quantidade_tiros"
                class="dense-padding-input-slot"
                label="Número de Sprints"
                v-mask="['########']"
                :rules="[validationRules.required]"
                filled hide-details="auto"
                dense
              />
             </v-col>
             <v-col cols=6  v-if="exerciseInfo.hiit">
              <v-text-field
                v-model="exerciseInfo.intervalo_tiros"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Intervalo entre Sprints"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled
                dense

              />
            </v-col>

           </v-row>
<!--
           <v-row v-if="exerciseInfo.aerobicocont">
             <v-col cols=12>
                  Dias Da Semana Aerobio Continuo
             </v-col>
             <v-col cols=1>
              <v-checkbox
                      v-model="exerciseInfo.segaer"
                      label='seg'
                      filled
                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.teraer"
                      label='ter'
                      filled

                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.quaaer"
                      label='qua'
                      filled
                       />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.quiaer"
                      label='qui'
                      filled

                  />
             </v-col>
              <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.sexaer"
                      label='sex'
                      filled

                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.sabaer"
                      label='sab'
                      filled

                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.domaer"
                      label='dom'
                      filled

                  />
             </v-col>
          </v-row> -->
          <v-row v-if="exerciseInfo.hiit">
             <v-col cols=12>
                  Dias Da Semana Hiit
             </v-col>
             <v-col cols=1>
              <v-checkbox
                      v-model="exerciseInfo.seghiit"
                      label='seg'
                      filled
                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.terhiit"
                      label='ter'
                      filled

                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.quahiit"
                      label='qua'
                      filled
                       />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.quihiit"
                      label='qui'
                      filled

                  />
             </v-col>
              <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.sexhiit"
                      label='sex'
                      filled

                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.sabhiit"
                      label='sab'
                      filled

                  />
             </v-col>
             <v-col cols=1>
                <v-checkbox
                      v-model="exerciseInfo.domhiit"
                      label='dom'
                      filled

                  />
             </v-col>
          </v-row>

          <v-row v-if="isresp">
              <v-col cols=6>
                <v-text-field
                  v-model="exerciseInfo.quantidade_tiros"
                  class="dense-padding-input-slot"
                  label="Repetições em isometria"
                  v-mask="['########']"
                  :rules="[validationRules.required]"
                  filled hide-details="auto"
                  dense
                />
             </v-col>
             <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.tempo"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Tempo de Cada Repetição"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled
                dense
              />
            </v-col>
             <v-col cols=6>
              <v-text-field
                v-model="exerciseInfo.intervalo_tiros"
                v-mask="timeMask('mm:ss')"
                class="dense-padding-input-slot"
                label="Intervalo entre cada Repetição"
                suffix="mm:ss"
                :rules="[validationRules.time, validationRules.timeRequired]"
                filled
                dense
              />
            </v-col>

           </v-row>
          <v-row v-if="exerciseSelected">
            <v-col>
              <v-textarea
                v-model="exerciseInfo.observacao"
                class="dense-padding-input-slot"
                label="Observações (opcional)"
                filled
                hide-details="auto"
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click.stop="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="save()"
          :disabled="!valid"
        >
          {{ isNewItem ? 'Adicionar' : 'Atualizar' }} Exercício
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>
/*eslint-disable*/
import timeMask from '@/mixins/timeMask'
import validationRules from '@/mixins/validation-rules'
import ExerciseTechnicsService from '@/services/user-exercise-technics-service'
import ExercisesService from '@/services/user-exercises-service'
import MesocycleService from '@/services/mesocycle-service.js'
import { mapActions, mapGetters } from 'vuex'
import axios from '@/services/axios-instance'
export default {
  name: 'create-or-update-exercise-dialog',
  components: {
  },
  mixins: [timeMask],
  data () {
    return {
      searchExerciseTechnics: '',
      exerciseTechnics: [],
      loadingSearchExerciseTechnics: false,
      exerciseSelected: null,
      searchText: '',
      valid: false,
      exibeHiit: true,
      exibeaerobico: true,
      validationRules,
      exercises: [],
      loadingSearch: false,
      exerciseInfo: {
        tempo: '00:00',
        freqCardiaca: '',
        series: '',
        min_reps: '',
        max_reps: '',
        descanco: '',
        cadencia: '',
        observacao: '',
        aerobicocont: false,
        hiit: false,
        seghiit:'',
        teriit:'',
        quahiit:'',
        quihiit:'',
        sexhiit:'',
        sabhiit:'',
        domhiit:'',
        segaer:'',
        teraer:'',
        quaaer:'',
        quiaer:'',
        sexaer:'',
        sabaer:'',
        domaer:'',
        exerciseTechnic: {}
      },
      strengthTests: [],
      cycleToBeEvaluated: {
        trainings: []
      }
    }
  },
  methods: {
    ...mapActions('students', ['setCreateOrUpdateExerciseDialog', 'setCycleType']),
    save () {
      this.setCycle()
      this.loadingSearch = true
      const payload = {
        exercise: this.exerciseSelected,
        tempo: this.exerciseInfo.tempo,
        freqCardiaca: this.exerciseInfo.freqCardiaca,
        series: this.exerciseInfo.series,
        intervalo_tiros: this.exerciseInfo.intervalo_tiros,
        tempo_tiros: this.exerciseInfo.tempo_tiros,
        quantidade_tiros: this.exerciseInfo.quantidade_tiros,
        min_reps: this.exerciseInfo.min_reps,
        max_reps: this.exerciseInfo.max_reps,
        descanco: this.exerciseInfo.descanco,
        cadencia: this.exerciseInfo.cadencia,
        observacao: this.exerciseInfo.observacao,
        aerobicocont: this.exerciseInfo.aerobicocont,
        hiit: this.exerciseInfo.hiit,
        exercise_technic: this.exerciseInfo.exerciseTechnic,
        seghiit:this.exerciseInfo.seghiit,
        teriit:this.exerciseInfo.teriit,
        quahiit:this.exerciseInfo.quahiit,
        quihiit:this.exerciseInfo.quihiit,
        sexhiit:this.exerciseInfo.sexhiit,
        sabhiit:this.exerciseInfo.sabhiit,
        domhiit:this.exerciseInfo.domhiit,
        segaer:this.exerciseInfo.segaer,
        teraer:this.exerciseInfo.teraer,
        quaaer:this.exerciseInfo.quaaer,
        quiaer:this.exerciseInfo.quiaer,
        sexaer:this.exerciseInfo.sexaer,
        sabaer:this.exerciseInfo.sabaer,
        domaer:this.exerciseInfo.domaer
      }
      if (this.isNewItem){
        this.cycleToBeEvaluated.trainings[this.trainingIndex].exercises.push(payload)
      } else {
        this.cycleToBeEvaluated.trainings[this.trainingIndex].exercises.splice(this.createOrUpdateExerciseIndex, 1, payload)
      }
      console.log(this.cycleType, ';asd')

      this.$emit('update', { successMessage: 'Exercício salvo com sucesso', failMessage: 'Erro ao salvar exercício', cycle: this.cycleType })
      console.log(this.cycleType, ';asdadsad')

      this.loadingSearch = false
      this.closeDialog()
    },

    closeDialog () {
      this.setCreateOrUpdateExerciseDialog(false)
      this.exerciseSelected = null
      this.exerciseInfo = {exerciseTechnic: {}}
      this.exercises = []
      this.exerciseTechnics = []
    },
    selectAerobicoContinuo(){
        if(this.exerciseInfo.aerobicocont){
          this.exerciseInfo.hiit=false;

        }else{
          this.exerciseInfo.hiit=true;
          this.exerciseInfo.aerobicocont=false;
        }
    },
    selectHiit(){
      if(this.exerciseInfo.hiit){
        this.exerciseInfo.aerobicocont=false;
        this.exerciseInfo.hiit=true;
      }else{
        this.exerciseInfo.aerobicocont=true;
        this.exerciseInfo.hiit=false;
      }
    },
    fetchSuggestedExercises(query) {
      this.loadingSearch = true
      let payload = {
        q: query,
        trainingIndex: this.trainingIndex,
        mesocycleId: this.mesocycleToBeEvaluated.id
      };

      if (this.cycleType === 'micro') {
        payload.microcycleId = this.microcycleToBeEvaluated.id
      }

      ExercisesService.suggested(payload)
        .then(({ data }) => {
          const { exercises } = data
          exercises.sort((exercise) => exercise.suggested ? -1 : 1)
          this.exercises = exercises
        })
        .catch((error) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: error
          })
        })
        .finally(() => {
          this.loadingSearch = false
        })
    },
    fetchEcerciseTechnics(query) {
      this.loadingSearchExerciseTechnics = true
      ExerciseTechnicsService.all({ q: query })
        .then(({ data }) => {
          this.exerciseTechnics = data.exercise_technics
        })
        .catch((error) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: error
          })
        })
        .finally(() => {
          this.loadingSearchExerciseTechnics = false
        })
    },
    cadenceRule(cadence) {
      return (!cadence || cadence.length == 7) || "Campo com formato inválido"
    },
    getStrengthTests() {
      const studentId = this.student.id
      axios.get(`v1/strength_tests/student/${studentId}`).then(response => {
        const strengthTests = response.data.strength_tests
        const strengthTestForMuscle = strengthTests.find(strengthTest => strengthTest && strengthTest.muscle_group && strengthTest.muscle_group.id === this.exerciseSelected.muscle_group_id)
        if (!strengthTestForMuscle) {
          return this.peso = 0;
        }
        const weightWithPercentage = strengthTestForMuscle.weight * (strengthTestForMuscle.percentage / 100)
        this.peso = Math.round(weightWithPercentage)
      })
    },
    logExerciseSelected() {
      console.log(this.exerciseSelected);
    },
    setCycle() {
      this.cycleToBeEvaluated = this.cycleType === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    }
  },
  computed: {
    ...mapGetters('students', ['cycleType', 'createOrUpdateExerciseDialog', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated', 'trainingIndex', 'createOrUpdateExercise', 'createOrUpdateExerciseIndex', 'student']),
    isNewItem () {
      return this.createOrUpdateExerciseIndex === -1
    },
    isAerobic(){
      return this.exerciseSelected && ['aerobic'].includes(this.exerciseSelected.exercise_type)
    },
    isMobility(){
      return this.exerciseSelected && ['mobility'].includes(this.exerciseSelected.exercise_type)
    },
    isStretching(){
      return this.exerciseSelected && ['stretching'].includes(this.exerciseSelected.exercise_type)
    },
    isResistence(){
      return this.exerciseSelected && ['resistence'].includes(this.exerciseSelected.exercise_type)
    },
    isHiit(){
      return this.exerciseSelected && ['hiit'].includes(this.exerciseSelected.exercise_type)
    },
    isFunctional() {
      return this.exerciseSelected && ['functional'].includes(this.exerciseSelected.exercise_type)
    },
    isMyofascialRelease() {
      return this.exerciseSelected && ['myofascial_release'].includes(this.exerciseSelected.exercise_type)
    },
    isExAerobico(){
      return this.exerciseSelected && ['exerc_aerobicos'].includes(this.exerciseSelected.exercise_type)
    },
    isresp(){
      return this.exerciseSelected && ['manobra_resp'].includes(this.exerciseSelected.exercise_type)
    },
    needsCadence() {
      if (this.isResistence) {
        this.fetchEcerciseTechnics('')
        return true
      }
      return false
    },
    exercisesList() {
      this.setCycle();
      if (this.exerciseDeleted && this.exerciseSelected) return [this.exerciseSelected]
      if (!this.cycleToBeEvaluated.trainings || !this.cycleToBeEvaluated.trainings[this.trainingIndex]) return []
      const trainingExercises = this.cycleToBeEvaluated.trainings[this.trainingIndex].exercises.map(item => item.exercise)
      return this.isNewItem ? this.exercises.filter(exercise => !trainingExercises.find(item => item.id === exercise.id)) : this.exercises
    },
    exerciseDeleted() {
      return !this.isNewItem && !this.exercises.find(exercise => this.exerciseSelected.id == exercise.id);
    },
    youtubeId(){
      if (this.exerciseSelected && this.exerciseSelected.link) {
        const link = this.exerciseSelected.link
        const youtubeId = link.split('/').pop()
        return youtubeId
      }
      return ''
    }
  },
  watch: {
    createOrUpdateExerciseDialog (newVal) {
      if (newVal) {
        this.fetchSuggestedExercises('')
      }
    },
    createOrUpdateExercise: {
      handler(newVal){
        this.searchText = newVal.exercise ? newVal.exercise.name : ''
        this.searchExerciseTechnics = newVal.exercise_technic ? newVal.exercise_technic.name : ''
        this.exerciseSelected = newVal.exercise
        this.exerciseInfo = {
          tempo: newVal.tempo,
          freqCardiaca: newVal.freqCardiaca,
          series: newVal.series,
          intervalo_tiros: newVal.intervalo_tiros,
          tempo_tiros: newVal.tempo_tiros,
          quantidade_tiros: newVal.quantidade_tiros,
          min_reps: newVal.min_reps,
          max_reps: newVal.max_reps,
          descanco: newVal.descanco,
          cadencia: newVal.cadencia,
          observacao: newVal.observacao,
          aerobicocont: newVal.aerobicocont,
          hiit: newVal.hiit,
          seghiit:newVal.seghiit,
          teriit:newVal.teriit,
          quahiit:newVal.quahiit,
          quihiit:newVal.quihiit,
          sexhiit:newVal.sexhiit,
          sabhiit:newVal.sabhiit,
          domhiit:newVal.domhiit,
          segaer:newVal.segaer,
          teraer:newVal.teraer,
          quaaer:newVal.quaaer,
          quiaer:newVal.quiaer,
          sexaer:newVal.sexaer,
          sabaer:newVal.sabaer,
          domaer:newVal.domaer,
          exerciseTechnic: newVal.exercise_technic
        }
      }
    },
    exerciseSelected: {
      handler(newVal){
        this.getStrengthTests()
      }
    },
    cycleType (newVal) {
      if (newVal) {
        this.setCycle()
      }
    }
  }
}
</script>

<style lang="scss">
</style>
