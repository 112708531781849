<template>
  <v-row class="mx-12 mt-6" style=" height: 75vh;">
    <v-tabs v-model="tab" slider-size="3" style="border-bottom: 1px solid #a1a1a1;">
      <v-tab key="dados">Dados</v-tab>
      <v-tab key="pessoal">Anamnese Pessoal</v-tab>
      <v-tab key="nutricional">Anamnese Fisiológica e Nutricional</v-tab>
    </v-tabs>

    <v-form ref="form" lazy-validation style="height: 90%; width: 100%;">
      <v-tabs-items v-model="tab" class="fill-height">

        <v-tab-item key="dados" class="fill-height">
          <v-row class="pt-6">
            <v-col cols="3">
              <v-card elevation="2" height="100%" class="pa-4">
                <v-row justify="center" no-gutters>
                  <v-avatar size="190">
                    <v-img :src="photoUrl || require('@/assets/images/user-placeholder.png')" :alt="student.name"
                      :lazy-src="require('@/assets/images/user-placeholder.png')" @error="onImageError">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-row>
                <v-row justify="center" no-gutters>
                  <v-btn color="secondary" @click="$refs.inputFile.click()" absolute bottom>
                    <v-icon class="mr-2">
                      mdi-image-plus
                    </v-icon>
                    carregar foto
                  </v-btn>
                  <input type="file" @change="onFileChange" ref="inputFile" style="display: none">
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="9" class="pl-8">
              <v-row>
                <v-col cols="3" class="pb-0">
                  <v-select color="secondary" label="Tipo" v-model="student.student_type"
                    :items="[{ text: 'Consultoria', value: 'consulting' }, { text: 'Presencial', value: 'in_person' }]"
                    :rules="[validationRules.required]" :disabled="isStudent" filled dense />
                </v-col>

                <v-col class="pb-0">
                  <v-text-field color="secondary" label="Nome" v-model="student.name"
                    :rules="[validationRules.required]" filled dense />
                </v-col>

                <v-col cols="2" class="pb-0">
                  <v-select v-model="student.email_secundario" label='Pais Residencia'
                    :rules="[validationRules.required]" :items="[
                      'Brasil (+55)',
                      'Portugal (+351)',
                      'Argentina (Região Metropolitana) (+54)',
                      'Argentina (Area II) (+54)',
                      'Argentina (Area III) (+54)',
                      'EUA (+1)',
                      'Chile Metropolitana (+56)',
                      'Chile Outra Regiões(+56)',
                      'Moçambique Area I (+258)',
                      'Moçambique Area II (+56)',
                      'Alemanha (+39) Area I',
                      'Alemanha (+39) Area II',
                      'Alemanha (+39) Area III',
                      'França (+33)',
                      'Japão (+81)'
                    ]" no-data-text="Nenhum valor encontrado" filled required dense />
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-text-field color="secondary" label="Celular" v-model="student.cellphone" v-mask="returnMask()"
                    :rules="[validationRules.required]" filled dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="pb-0">
                  <v-text-field color="secondary" label="Email" v-model="student.email"
                    :rules="[validationRules.required, validationRules.email]" filled dense />
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-text-field color="secondary" label="Nascimento" v-model="student.birthdate"
                    :rules="[validationRules.required, validationRules.date, validationRules.age]" v-mask="'##/##/####'"
                    filled dense />
                </v-col>
                <v-col cols="2" class="pb-0">
                  <v-select color="secondary" label="Gênero" v-model="student.gender" :items="genders"
                    :rules="[validationRules.required]" item-text="name" item-value="type" filled dense />
                </v-col>

                <v-col class="pb-0">
                  <v-row class="align-center justify-center">
                    <v-switch color="secondary" v-model="student.is_active" :disabled="isStudent" />
                    <span>Ativo</span>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="pl-3">
                <span class="text-uppercase font-weight-bold subtitle-2 mb-3"> Contato de Emergência</span>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="8" class="pb-0">
                  <v-text-field color="secondary" label="Nome do parente mais próximo" v-model="student.contact_name"
                    :disabled="isStudent" filled dense />
                </v-col>

                <v-col class="pb-0">
                  <v-text-field color="secondary" label="Celular do parente" v-model="student.contact_cellphone"
                    :rules="[validationRules.cellphoneSize]" v-mask="'(##) # ####-####'" :disabled="isStudent" filled
                    dense />
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row class="d-flex justify-end px-0 pb-5" style="position: absolute; bottom: 0; width: 100%;">
            <v-btn color="secondary" text class="mr-2" @click="close">
              cancelar
            </v-btn>
            <v-btn color="primary" class="ml-2" @click="tab = 1">
              próximo
            </v-btn>
          </v-row>
          <v-row class="align-center justify-end">
            <v-col cols="3" class="pb-0">
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item key="pessoal" class="fill-height">
          <v-row class="pt-6">
            <v-col cols="7">
              <v-row>
                <v-col cols="3" class="pb-0">
                  <v-text-field color="secondary" label="Ocupação" v-model="student.personal_anamnesis.ocupation" filled
                    dense />
                </v-col>
                <v-col cols="5" class="pb-0">
                  <v-text-field color="secondary" label="Horas de trabalho diárias" suffix="horas/dia"
                    v-model="student.personal_anamnesis.daily_journey" filled dense />
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-select color="secondary" label="Melhor turno de treino"
                    v-model="student.personal_anamnesis.training_shift" :items="trainingShift" item-text="name"
                    item-value="type" filled dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="pb-0">
                  <v-text-field color="secondary" label="Horas disp. p/ treino" suffix="horas"
                    v-model="student.personal_anamnesis.training_hours" filled dense />
                </v-col>
                <v-col class="pb-0">
                  <v-select color="secondary" label="Dias disponíveis para o treino" v-model="selectedDays"
                    :disabled="isStudent" :items="daysOfWeek"
                    :rules="[v => v.length !== 0 || 'Este campo não pode ficar em branco']" item-text="name"
                    return-object chips small-chips multiple filled dense @input="sortDays" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-text-field color="secondary" label="Atividades físicas favoritas"
                    v-model="student.personal_anamnesis.favorite_activities" filled dense />
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select color="secondary" label="Objetivos do treino"
                    :items="['Performance', 'Reabilitação postural']"
                    v-model="student.personal_anamnesis.training_goals" :disabled="isStudent" filled dense />
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="4">
                  <v-row class="align-center justify-end">
                    <span style="width: calc(100% - 58px); text-align: end;" class="mr-2">
                      Já teve alguma lesão ostemioarticular?
                    </span>
                    <v-switch color="secondary" v-model="student.personal_anamnesis.had_lesions" />
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="7">
                  <v-row>
                    <v-text-field color="secondary" label="Onde?" v-model="student.personal_anamnesis.lesions" filled
                      dense />
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-row class="align-center justify-end">
                    <span style="width: calc(100% - 58px); text-align: end;" class="mr-2">
                      Sente dores ao realizar esforço?
                    </span>
                    <v-switch color="secondary" v-model="student.personal_anamnesis.had_pains" />
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="7">
                  <v-row>
                    <v-text-field color="secondary" label="Onde?" v-model="student.personal_anamnesis.pains" filled
                      dense />
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-row class="align-center justify-end">
                    <span style="width: calc(100% - 58px); text-align: end;" class="mr-2">
                      Consome bebidas alcóolicas com frequência?
                    </span>
                    <v-switch color="secondary" v-model="student.personal_anamnesis.use_alcohol" />
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="7">
                  <v-row>
                    <v-text-field color="secondary" label="Quanto?" v-model="student.personal_anamnesis.alcohol" filled
                      dense />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end px-0 pb-5" style="position: absolute; bottom: 0; width: 100%;">

            <v-btn color="secondary" text class="mr-2" @click="close">
              cancelar
            </v-btn>
            <v-btn color="primary" class="ml-2" @click="tab = 2">
              próximo
            </v-btn>
          </v-row>
          <v-row class="align-center justify-end">
            <v-col cols="3" class="pb-0">
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item key="nutricional" class="fill-height">
          <v-row class="pt-6" no-gutters>
            <v-col cols="6">
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-text-field color="secondary" label="Horas dormidas por noite" suffix="horas"
                    v-model="student.physiological_anamnesis.hours_slep_night" filled dense />
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field color="secondary" label="Horas dormidas por dia" suffix="horas"
                    v-model="student.physiological_anamnesis.hours_slep_day" filled dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-text-field color="secondary" label="Faz exames com que frequencia?"
                    v-model="student.physiological_anamnesis.exam_frequency" filled dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-text-field color="secondary" label="Faz quantas refeições diárias?" suffix="refeições/dia"
                    v-model="student.physiological_anamnesis.how_many_meals" filled dense />
                </v-col>
                <v-col cols="6" class="pb-0">

                  <v-text-field color="secondary" label=" Quantidade Agua Ingerida por Dia??"
                    v-model="student.physiological_anamnesis.water" filled dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="px-0">
                  <v-row class="align-center justify-end ma-0">
                    <span style="width: calc(100% - 58px); text-align: end;" class="mr-2">
                      Planejamento alimentar elaborado por nutricionista?
                    </span>
                    <v-switch color="secondary" v-model="student.physiological_anamnesis.nutritionist" />
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-text-field color="secondary" label="Nome do Nutricionista"
                    v-model="student.physiological_anamnesis.nutricionist_name" filled dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="px-0">
                  <v-row class="align-center justify-end ma-0">
                    <span style="width: calc(100% - 58px); text-align: end;" class="mr-2">
                      Usa alguma suplementação?
                    </span>
                    <v-switch color="secondary" v-model="student.physiological_anamnesis.supplements" />
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-text-field color="secondary" label="Nome do suplemento"
                    v-model="student.physiological_anamnesis.supplements_name" filled dense />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pl-4">
              <v-row class="align-center ma-0 mr-2">
                <v-col cols="4" class="pb-0">
                  <v-row align="center" justify="end">
                    <span class="mr-4">Diabetes?</span>
                    <v-switch color="secondary" v-model="student.physiological_anamnesis.diabetes" />
                  </v-row>
                </v-col>
                <v-col cols=5 class="pb-0">
                  <v-select color="secondary" label="Qual tipo?" v-model="student.physiological_anamnesis.diabets_type"
                    :items="diabetesTypes" item-text="name" item-value="type" clearable filled dense />
                </v-col>
              </v-row>

              <v-row class="align-center ma-0 mr-2">
                <v-col cols="4" class="pb-0">
                  <v-row align="center" justify="end">
                    <span class="mr-4">
                      Fuma?
                    </span>
                    <v-switch color="secondary" v-model="student.physiological_anamnesis.smoke" />
                  </v-row>
                </v-col>
                <v-col cols=5 class="pb-0">
                  <v-text-field color="secondary" label="Quantos?"
                    v-model="student.physiological_anamnesis.how_many_cigarettes" filled dense />
                </v-col>
              </v-row>

              <v-row class="align-center ma-0 mr-2">
                <v-col cols="4" class="pb-0">
                  <v-row align="center" justify="end">
                    <span style="width: calc(100% - 58px); text-align: end;" class="mr-2">
                      Tem problemas cardíacos?
                    </span>
                    <v-switch color="secondary" v-model="student.physiological_anamnesis.cardiac_problems" />
                  </v-row>
                </v-col>
                <v-col cols=8 class="pb-0">
                  <v-text-field color="secondary" label="Quais?"
                    v-model="student.physiological_anamnesis.cardiac_problems_type" filled dense />
                </v-col>
              </v-row>

              <v-row class="align-center ma-0 mr-2">
                <v-col cols="4" class="pb-0">
                  <v-row align="center" justify="end">
                    <span style="width: calc(100% - 58px); text-align: end;" class="mr-2">
                      Já se submeteu a alguma cirurgia?
                    </span>
                    <v-switch color="secondary" v-model="student.physiological_anamnesis.surgeries" />
                  </v-row>
                </v-col>
                <v-col cols=8 class="pb-0">
                  <v-text-field color="secondary" label="Qual?" v-model="student.physiological_anamnesis.whichSurgeries"
                    filled dense />
                </v-col>
                <v-col cols="4" class="pb-0">
                </v-col>

              </v-row>
            </v-col>
            <v-row class="align-center ma-0 mr-2">

              <v-col cols=8 class="pb-0">

              </v-col>
            </v-row>

          </v-row>
          <v-row class="d-flex justify-end px-0 pb-5"
            style="position: absolute; bottom: 0; right: 0; margin-right: 20px;">
            <v-btn color="secondary" text class="mr-2" @click="close">
              cancelar
            </v-btn>
            <v-btn color="primary" class="ml-2" @click="save">
              salvar
            </v-btn>
          </v-row>
          <v-row class="align-center justify-end">
            <v-col cols="3" class="pb-0">
            </v-col>
          </v-row>
        </v-tab-item>

      </v-tabs-items>
    </v-form>

  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import studentService from '@/services/student-service'
import validationRules from '@/mixins/validation-rules'
import jsonToFormData from '@/mixins/jsonToFormData'
import { cloneDeep } from 'lodash'

export default {
  name: 'student-form',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [jsonToFormData],
  data: () => ({
    photoUrl: '',
    validationRules,
    isDisabledEmailSecondary: true,
    tab: 0,
    studentCache: null,
    ethnicities: [
      { name: 'Indígena', type: 'indigenous' },
      { name: 'Negro', type: 'black' },
      { name: 'Branco', type: 'white' }
    ],
    genders: [
      { name: 'Masculino', type: 'male' },
      { name: 'Feminino', type: 'female' }
    ],
    trainingShift: [
      { name: 'Matutino', type: 'morning' },
      { name: 'Verpertino', type: 'evening' },
      { name: 'Noturno', type: 'nocturnal' }
    ],
    daysOfWeek: [
      { name: 'Seg', type: 'monday', value: 1 },
      { name: 'Ter', type: 'tuesday', value: 2 },
      { name: 'Qua', type: 'wednesday', value: 3 },
      { name: 'Qui', type: 'thursday', value: 4 },
      { name: 'Sex', type: 'friday', value: 5 },
      { name: 'Sab', type: 'saturday', value: 6 },
      { name: 'Dom', type: 'sunday', value: 7 }
    ],
    selectedDays: [],
    diabetesTypes: [
      { name: 'Tipo 1', type: 'type1' },
      { name: 'Tipo 2', type: 'type2' },
      { name: 'Gestacional', type: 'gestational' }
    ]
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['setStudent']),
    sortDays () {
      this.selectedDays.sort((a, b) => {
        return a.value - b.value
      })
    },
    save () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setLoader({ show: true })
      this.student.personal_anamnesis.days_of_week = this.selectedDays.map(item => item.type)
      studentService.createOrUpdate(this.jsonToFormData({ student: this.student }), this.student.id)
        .then(({ data }) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Aluno salvo com sucesso'
          })
          this.$emit('updateList')
          this.close()
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    close () {
      this.setLoader({ show: false })
      this.$refs.form.resetValidation()
      this.setStudent(this.studentCache)
      this.$emit('update:dialog', false)
    },
    returnMask () {
      /*
        'Brasil (+55)',
        'Portugal (+351)',
        'Argentina (Região Metropolitana) (+54)' ,
        'Argentina (Area II) (+54)',
        'Argentina (Area III) (+54)'
        'EUA (+1)'
        'Italia (+39)'
        Italia (+39) Area I',
                      'Italia (+39) Area II',
                      'Italia (+39) Area III',
                      179 XXXXXXXXXX
      */
      if (this.student.email_secundario === 'Brasil (+55)') {
        return '(##) #####-####'
      }
      if (this.student.email_secundario === 'Portugal (+351)') {
        return '## ### ###'
      }
      if (this.student.email_secundario === 'Argentina (Região Metropolitana) (+54)') {
        return '## #### ####'
      }
      if (this.student.email_secundario === 'Argentina (Area II) (+54)') {
        return '## ### ####'
      }
      if (this.student.email_secundario === 'Argentina (Area III) (+54)') {
        return '### ## ####'
      }
      if (this.student.email_secundario === 'Chile Metropolitana (+56)') {
        return '# #### ####'
      }
      if (this.student.email_secundario === 'Chile Outra Regiões(+56)') {
        return '## #### ####'
      }
      if (this.student.email_secundario === 'Moçambique Area I (+258)') {
        return '## #######'
      }
      if (this.student.email_secundario === 'Moçambique Area II (+258)') {
        return '### #######'
      }
      if (this.student.email_secundario === 'Italia (+39)') {
        return '###.#######'
      }
      if (this.student.email_secundario === 'Alemanha (+39) Area I') {
        return '### ##########'
      }
      if (this.student.email_secundario === 'Alemanha (+39) Area II') {
        return '#### ##########'
      }
      if (this.student.email_secundario === 'Alemanha (+39) Area III') {
        return '##### ##########'
      }
      if (this.student.email_secundario === 'EUA (+1)') {
        return '(###) ###-####'
      }
      if (this.student.email_secundario === 'França (+33)') {
        return '## ## ## ## ##'
      }
      if (this.student.email_secundario === 'Japão (+81)') {
        return '##-##-####-####'
      }
      return '(##) #####-####'
    },
    fillSelects () {
      if (this.student.personal_anamnesis.days_of_week) {
        this.selectedDays = Object.values(this.student.personal_anamnesis.days_of_week).map(day => {
          return this.daysOfWeek.find(item => item.type === day)
        }).filter(item => item !== undefined)
      }
      this.photoUrl = this.student.photo_url
    },
    onFileChange (event) {
      this.student.photo = event.target.files[0]
      this.$refs.inputFile.value = ''
      var reader = new FileReader()
      reader.onload = (e) => {
        this.photoUrl = e.target.result
      }
      reader.readAsDataURL(this.student.photo)
    },
    onImageError () {
      this.photoUrl = null
    }
  },
  computed: {
    ...mapGetters('students', ['student']),
    ...mapGetters('auth', ['user']),
    isStudent () {
      return this.user.isStudent()
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.studentCache = cloneDeep(this.student)
        this.fillSelects()
        if (
          !this.isStudent &&
          this.student.id &&
          this.student.is_profile_changed &&
          !this.student.is_changes_notified
        ) {
          studentService.studentChangeNotification(this.student.id)
        }
      } else {
        this.tab = 0
      }
    }
  }
}
</script>

<style scoped></style>
