<template>
  <v-row class="mainContainer">
    <v-col class="login-background pa-0" lg="5" md="5" sm="5" xs="12">
      <v-row class="align-center justify-end" style="height: 100%">
        <Logo imgColor="white" admin />
      </v-row>
    </v-col>
    <v-col class="mainContent">
      <v-row class="responsive-line" style="height: 100%">
        <v-card elevation="0" class="responsive-card">
          <v-card-title class="justify-center titleCardLogin">
            Administrador Resetar Senha
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="newPassword" color="secondary" label="Nova senha" type="password" filled dense />
            <v-text-field v-model="newPasswordConfirmation" color="secondary" label="Confirme a nova senha"
              type="password" filled dense />
            <v-btn block color="primary" class="mb-8" @click="resetPassword">
              Resetar senha
            </v-btn>
          </v-card-text>

          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import adminAuthenticationService from '@/services/admin-authentication-service'
import { mapActions } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'admin-reset-password',
  components: {
    Logo
  },
  data: () => ({
    newPassword: '',
    newPasswordConfirmation: ''
  }),
  methods: {
    ...mapActions('base', ['setLoader']),
    ...mapActions('base', ['setSnackbar']),
    resetPassword () {
      this.setLoader({ show: true })
      adminAuthenticationService.resetPassword(this.$router.currentRoute.query.token, { new_password: this.newPassword, new_password_confirmation: this.newPasswordConfirmation })
        .then(({ data }) => {
          this.setSnackbar({
            show: true,
            message: 'Sua senha foi alterada com sucesso!'
          })
        })
        .catch((request) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: request.response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.$router.push({ name: 'AdminLogin' })
        })
    }
  }
}
</script>

<style scoped>
.login-background {
  height: 100vh;
  background-image: url('../../../assets/images/login-admin-background.png');
  background-size: cover;
}

.mainContainer {
  height: 100vh;
  display: flex;
}

.responsive-card {
  width: 40%;
  margin-left: 12%;
  margin-top: 48px;
}

.responsive-line {
  align-items: center;
  justify-content: start;
}

.titleCardLogin {
  word-break: break-word;
}

@media (min-width: 1100px) and (max-width: 1400px) {

  .responsive-line {
    justify-content: center;
  }

  .responsive-card {
    width: 70%;
    margin: 0 5%;
    margin-left: 6%;
    margin-top: 48px;

  }
}

@media (max-width: 1099px) {

  .responsive-line {
    align-items: center;
    justify-content: center;
  }

  .responsive-card {
    width: 100%;
    margin: 0 5%;
    margin-top: 48px;

  }
}

@media (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .login-background {
    width: 100%;
    flex-grow: 1;
  }

  .login-background .row {
    margin: 0;
  }

  .login-background img {
    width: 100%;
  }

  .mainContent {
    flex-grow: 3;
  }

  .responsive-card {
    width: 90%;
    margin: 0;
    margin-top: 24px;
    padding: 0;
  }
}
</style>
