<template>
  <v-list-item
    class="flex align-center py-1"
    @click="onStudentClick(student.id)"
  >
      <v-col cols="1">{{ student.id }}</v-col>
      <v-col cols="3">{{ student.name | reduceName }}</v-col>
      <v-col cols="1">{{ this.formatAge(student.birthdate) }} anos</v-col>
      <v-col cols="2">{{ student.email }}</v-col>
      <v-col cols="2">{{ student.cellphone }}</v-col>
      <v-col cols="2">{{ formatPlan }}</v-col>
      <v-col cols="1" class="flex">
        <v-row class="ml-1" >
          <v-switch
            class="custom-switch"
            :input-value="student.is_active"
            :color="activeToggle ? 'var(--v-primary-base)' : 'grey'"
            @click.stop.prevent="toggleActive"
          ></v-switch>
        </v-row>

      </v-col>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'student-list-item',
  data: () => ({
    activeToggle: true
  }),
  props: {
    student: {
      type: Object,
      default: () => ({})
    }
  },
  filters: {
    reduceName: function (value) {
      var names = value.split(' ')
      if (names.length > 1) {
        return names[0] + ' ' + names[names.length - 1]
      } else {
        return names[0]
      }
    }
  },
  methods: {
    ...mapActions('base', ['setLoader']),
    ...mapActions('students', ['setStudent', 'resetDialogs', 'resetStudent']),
    editStudent () {
      this.setStudent(this.student)
      this.$emit('editStudent')
    },
    deleteStudent () {
      this.setStudent(this.student)
      this.$emit('deleteStudent')
    },
    onStudentClick (id) {
      this.resetDialogs()
      this.resetStudent()
      this.$router.push({ name: 'StudentShow', params: { id: id } })
    },
    toggleActive () {
      const updatedStatus = !this.student.is_active
      this.$emit('update-status', updatedStatus, this.student.id)
    },
    formatAge (birthDateISO) {
      const [year, month, day] = birthDateISO.split('T')[0].split('-')
      const birthDate = new Date(year, month - 1, day)
      const today = new Date()
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }

      return age
    }
  },
  computed: {
    ...mapGetters('base', ['loader']),
    modifiedStudent () {
      return (
        this.student.is_profile_changed && !this.student.is_changes_notified
      )
    },
    formatPlan () {
      return this.student.subscription ? this.student.subscription.subscription_plan.name : 'Sem plano'
    }

  }
}
</script>

<style scoped>

  ::v-deep .item-list:focus, .item-list:active {
    background-color: none;
    outline: none;
  }

  .custom-switch {
    margin: 0 !important;
    padding: 0 !important;
  }

  ::v-deep .custom-switch .v-messages {
    display: none !important;
  }

  ::v-deep .custom-switch .v-input--selection-controls__ripple {
    display: none !important;
  }

  ::v-deep .custom-switch .v-input__control {
    margin: 0;
  }

  ::v-deep .custom-switch .v-input__slot {
    margin: 0;
  }

</style>
