<template>
  <v-col>
    <v-row class="flex-d px-12">
      <v-col cols="12">
        <v-btn text @click="back" class="px-0">
          <v-icon>mdi mdi-arrow-left</v-icon>
          <span>Voltar</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="px-12 justify-space-between">
      <v-col
        :xs="6"
        :md="5"
        :lg="4"
        :xl="3"
      >
        <v-container>
          <v-row class=" elevation-3">
            <v-col
              class="py-10 px-0 d-flex flex-column align-center"
              cols="12">
              <v-avatar class="mb-3" size="128">
                <v-img
                  :src="require('@/assets/images/user-placeholder.png')"
                  contain
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

              </v-avatar>
              <span class="subtitle-1 font-weight-bold">{{user.fullname}}</span>
              <span class="caption">{{user.email}}</span>
              <div>
                <span class="caption font-weight-medium">Telefone: </span><span
                class="caption">{{user.phone}}</span>
              </div>
              <!-- <div>
                <span class="caption font-weight-medium">Nascimento: </span><span class="caption">{{user.birthday | formatBrazilianDate}}</span>
              </div> -->
              <div>
                <span class="caption font-weight-medium">{{ plan }}</span>
              </div>
              <div>
                <span class="caption font-weight-medium">Alunos:{{ user.students_count }} </span>
                <span class="caption font-weight-medium ml-2">Último Login: {{lastLogin}}</span>
              </div>

            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <template>
      <v-row
        class="mt-3 mx-5">
        <v-col
          cols="12"
          class="pa-0"
        >
        <personal-payment-history
          :key="user.id"
          :showBtn="false"
          :payments="user.payments"
        />
        </v-col>
      </v-row>
    </template>
    </v-row>

  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatBrazilianDate } from '@/filters/format-date-to-br'
import PersonalPaymentHistory from '@/components/personal/PersonalPaymentHistory.vue'
import PersonalNodeService from '@/services/personal-node-service.js'

export default {
  components: {
    PersonalPaymentHistory
  },
  name: 'personal-show',
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('users', ['setUser']),

    getPersonal () {
      this.setLoader({ show: true })
      this.loadingSearch = true
      PersonalNodeService.getPersonal(this.$router.currentRoute.params.id)
        .then(({ data }) => {
          this.setUser(data)
        })
        .catch(
          (error) => {
            console.log(error.resolve)
            this.setSnackbar({
              show: true,
              message: 'Erro ao buscar dados do usuário',
              color: 'error'
            })
          }
        )
        .finally(() => {
          this.setLoader({ show: false })
          this.loadingSearch = false
        })
    },
    back () {
      this.$router.go(-1)
    }

  },
  mounted () {
    this.getPersonal()
  },
  computed: {
    ...mapGetters('users', ['user']),
    lastLogin () {
      return formatBrazilianDate(this.user.last_sign_in_at) || 'Nunca'
    },
    plan () {
      if (this.user && this.user.subscriptions && this.user.subscriptions[0] && this.user.subscriptions[0].subscription_plans) {
        return this.user.subscriptions[0].subscription_plans.name
      }
      if (this.user && this.user.current_subscription && this.user.current_subscription.status === 'Em dia') {
        return 'Plano em dia'
      }
      return 'Sem plano'
    }
  }
}
</script>
