<template>
  <div class="text-center">
    <v-dialog
      v-model="loader.show"
      persistent
      width="300"
    >
      <v-card
        class="pa-2"
        color="secondary"
        dark
      >
        <v-card-text>
          {{ loader.message }}
          <v-progress-linear
            indeterminate
            :color="loader.color"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'loader',
  computed: {
    ...mapGetters('base', ['loader'])
  },
  methods: {
    ...mapActions('base', ['setLoader'])
  }
}
</script>

<style scoped>

</style>
