<template>
  <v-container
    class="py-5 px-0"  v-if="!isStudent"
  >
    <div
      class="d-flex flex-column elevation-3 pa-5"
    >
      <v-row>
        <v-col>
          <span class="text-uppercase font-weight-medium">Diâmetro Ósseo</span>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="somatotype.umero"
            class="dense-padding-input-slot"
            label="Úmero"
            :readonly="disableInput"
            filled
            dense
          />
          <v-text-field
            v-model="somatotype.femur"
            class="dense-padding-input-slot"
            label="Fêmur"
            :readonly="disableInput"
            filled
            dense
          />
        </v-col>
        <v-spacer/>
        <v-col
          cols="7">
          <!-- grafico de somatotipo -->
          <v-chart ref="chart" width="600" height="435" type="scatter" :options="options" :series="series"/>
        </v-col>
      </v-row>

    </div>

    <v-row v-if="!readonly">
      <v-col class="d-flex justify-end">
        <v-btn
          text
          color="secondary"
          @click="$emit('closeDialog')"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="ml-10"
          color="primary"
          @click="$emit('updateMesocycleEvaluation')"
        >
          Salvar Avaliação
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'perimetria',
  components: {
    'v-chart': VueApexCharts
  },
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    isStudent: {
      default: true,
      type: Boolean
    }
  },
  mounted () {
    this.mesocycleToBeEvaluated.evaluated_items.somatotype.midPoint = this.series.data

    this.$refs.chart.dataURI().then(({ imgURI }) => {
      this.mesocycleToBeEvaluated.evaluated_items.somatotype.chart = imgURI
    })
  },
  data () {
    return {
      options: {
        chart: {
          parentHeightOffset: 0,
          type: 'scatter',
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          width: 10,
          curve: 'smooth'
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        xaxis: {
          type: 'numeric',
          tickAmount: 72,
          min: -9.25,
          max: 8.75,
          labels: {
            rotate: 0,
            formatter: function (val) {
              const validValues = [-9.25, -7.25, -5.25, -3.25, -1.25, 0.75, 2.75, 4.75, 6.75, 8.75]
              let parsedVal = parseFloat(val).toFixed(2)
              return validValues.indexOf(val) !== -1 ? parsedVal : ''
            },
            align: 'center'
          },
          axisBorder: {
            show: true,
            offsetY: -147
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          type: 'numeric',
          tickAmount: 120,
          min: -11.25,
          max: 18.75,
          labels: {
            formatter: function (val) {
              const validValues = [18.75, 13.75, 8.75, 3.75, -11.25, -7.25, -3.25]
              let parsedVal = parseFloat(val).toFixed(2)

              return validValues.indexOf(val) !== -1 ? parsedVal : ''
            },
            align: 'right',
            offsetX: 288
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          }
        }

      },
      createOrUpdateMesoDialog: false,
      mesoCycleDetailsDialog: true
    }
  },
  methods: {
    convertToFloat (object) {
      Object.entries(object).forEach(([key, value]) => {
        // se for um objeto ou array chama a função de novo
        var aux
        if (typeof value === 'object') {
          this.convertToFloat(value)
        } else if (typeof value === 'string') {
          aux = parseFloat(value.replace(',', '.'))
          if (!isNaN(aux) && isFinite(aux)) {
            object[key] = aux
          }
        }
      })
    },
    // ALUNO N8 - ESTATURA, L8 - MASSA
    // DOBRAS CUTANEAS J53 - TRICEPS , J51 - SUBESCAPULAR ,B60 - SUBESPINHAL, B62 COXA MEDIA
    // PERIMETROS CORPORAIS B26 - BRAÇO CONT D, B42 - PANTURRILHA D
    // DIAMETRO OSSEO D82 - UMERO, D88 - FEMUR
    endomorfia () {
      this.convertToFloat(this.perimeter.skinFold)

      var endomorfia = -0.7182
      endomorfia += 0.1451 * ((this.perimeter.skinFold.triceps + this.perimeter.skinFold.subescapular + this.perimeter.skinFold.supraespinhal) * (170.18 / (this.studentStature * 100)))
      endomorfia -= 0.00068 * ((this.perimeter.skinFold.triceps + this.perimeter.skinFold.subescapular + this.perimeter.skinFold.supraespinhal) * (170.18 / (this.studentStature * 100))) * 2
      endomorfia += 0.0000014 * Math.pow((this.perimeter.skinFold.triceps + this.perimeter.skinFold.subescapular + this.perimeter.skinFold.supraespinhal) * (170.18 / (this.studentStature * 100)), 3)

      return [
        endomorfia * Math.cos(Math.PI * 30 / 180),
        -endomorfia * Math.sin(Math.PI * 30 / 180)
      ]
    },
    mesomorfia () {
      this.convertToFloat(this.perimeter)
      const umero = parseFloat(this.somatotype.umero.replace(',', '.'))
      const femur = parseFloat(this.somatotype.femur.replace(',', '.'))

      var mesomorfia = 0.858 * (umero) +
                      0.601 * (femur) +
                      0.188 * (this.perimeter.bodyPerimeter.braco_direito_contraido - this.perimeter.skinFold.triceps) +
                      0.161 * (this.perimeter.bodyPerimeter.panturrilha_direita - this.perimeter.skinFold.coxa_media) -
                      0.131 * ((this.studentStature * 100)) +
                      4.5

      return [
        mesomorfia * Math.cos(Math.PI * 30 / 180),
        -mesomorfia * Math.sin(Math.PI * 30 / 180)
      ]
    },
    ectomorfia () {
      var ectomorfia
      const weight = parseFloat(this.student.weight.replace(',', '.'))

      var massaRc = Math.pow(weight, 1.0 / 3.0) // ((L8)^(1/3))
      var statureMassaRc = (this.studentStature * 100) / massaRc // (N8*100)/((L8)^(1/3))

      // SE((N8)/((L8)^(1/3))>=40,75;
      if (this.studentStature / massaRc >= 40.75) {
        // 0,732*(N8*100)/((L8)^(1/3))-28,58
        ectomorfia = 0.732 * statureMassaRc - 28.58
      } else if (statureMassaRc < 40.75 && statureMassaRc > 38.25) { // SE(E((N8*100)/((L8)^(1/3))<40,75;(N8*100)/((L8)^(1/3))>38,25);
        // 0,463*(N8*100)/((L8)^(1/3))-17,63;
        ectomorfia = 0.463 * statureMassaRc - 17.63
      } else if (statureMassaRc <= 38.25) { // SE((N8*100)/((L8)^(1/3))<=38,25;
        // 0,1
        ectomorfia = 0.1
      } else {
        // ERRO
        ectomorfia = 0
      }

      return [
        ectomorfia * Math.cos(Math.PI * 30 / 180),
        -ectomorfia * Math.sin(Math.PI * 30 / 180)
      ]
    }
  },
  computed: {
    series () {
      if (
        !this.perimeter.skinFold ||
        !this.student ||
        !this.somatotype ||
        !this.student.stature ||
        !this.student.weight ||
        !this.perimeter.skinFold.triceps ||
        !this.perimeter.skinFold.subescapular ||
        !this.perimeter.skinFold.supraespinhal ||
        !this.somatotype.umero ||
        !this.somatotype.femur
      ) {
        return [
          {
            data: [ [0, 0] ]
          }
        ]
      }
      // ponto médio
      var pontoM = [0, 0]
      pontoM[0] = (this.endomorfia()[0] + this.mesomorfia()[0] + this.ectomorfia()[0]) / 3 // x-axis
      pontoM[1] = (this.endomorfia()[1] + this.mesomorfia()[1] + this.ectomorfia()[1]) / 3 // y-axis

      return [
        {
          name: 'Ponto Médio',
          data: [ pontoM ]
        }
      ]
    },
    perimeter () {
      return this.mesocycleToBeEvaluated.evaluated_items.perimeter
    },
    student () {
      return this.mesocycleToBeEvaluated.evaluated_items.student
    },
    studentStature () {
      return parseFloat(this.student.stature.replace(',', '.'))
    },
    somatotype () {
      return this.mesocycleToBeEvaluated.evaluated_items.somatotype
    },
    disableInput () {
      return this.readonly || this.isStudent
    },
    ...mapGetters('students', ['mesocycleToBeEvaluated'])
  },
  watch: {
    series ([ val ]) {
      this.mesocycleToBeEvaluated.evaluated_items.somatotype.midPoint = val.data

      this.$refs.chart.dataURI().then(({ imgURI }) => {
        this.mesocycleToBeEvaluated.evaluated_items.somatotype.chart = imgURI
      })
    }
  }
}
</script>

<style lang="sass">
svg.apexcharts-svg
  [class*='axis']
    line
      stroke-width: 3 !important
      stroke: #c5c5c5
.apexcharts-series-scatter
  circle
    r: 12
    stroke: #efefef
    stroke-width: 3
    fill: var(--v-primary-darken1)

</style>
