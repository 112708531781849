<template >
  <v-row >
    <v-col>
      <v-card class="card elevation-3">

        <v-row v-if="showBtn">
          <v-col cols="12">
            <v-btn
              text
              @click="$emit('toggle')"
            >
              <v-icon>mdi mdi-arrow-left</v-icon>
              <span>Voltar</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-card-title class="justify-space-between">
          <div class="d-flex justify-center align-center">
            <v-icon>mdi mdi-history</v-icon>
            <span class="ml-1">Histórico de Pagamentos</span>
          </div>
          <span class="body-1 font-weight-regular">Pagamentos sucedidos: {{ payments.filter((item) => item.status === 'paid').length }}</span>
        </v-card-title>

        <v-card-text>
          <v-data-table
            v-if="payments.length !== 0"
            class="custom-data-table"
            :headers="headers"
            :items="formattedPayments"
            :items-per-page="10"
            :footer-props="footerProps"
            >
            <template v-slot:item.date="{ item }">
              <span>{{ item.date }}</span>
            </template>
            <template v-slot:item.value="{ item }">
              <span>{{ item.value }}</span>
            </template>
            <template v-slot:item.payment_method="{ item }">
              <span>{{ item.payment_method }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                class="custom-chip"
                :color="item.status === 'Pago' ? 'success' : item.status === 'Atraso' ? 'warning' : 'error'"
                text-color="white"
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
          <span v-if="payments.length === 0" class="text-center">Não há pagamentos.</span>
        </v-card-text>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatBrazilianDate } from '@/filters/format-date-to-br'
export default {
  name: 'personal-payment-history',
  props: {
    payments: {
      type: Array,
      default: () => []
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    headers: [
      { text: 'Data', value: 'date', width: '20%' },
      { text: 'Valor', value: 'value', sortable: false, width: '20%' },
      { text: 'Forma de Pagamento', value: 'payment_method', width: '20%' },
      { text: 'Status', value: 'status', width: '20%' }
    ],
    footerProps: {
      itemsPerPageText: 'Itens por página',
      itemsPerPageAllText: 'Todos',
      nextPageText: 'Próxima página',
      prevPageText: 'Página anterior',
      pageText: '{0}-{1} de {2}'
    }

  }),
  methods: {
    firstLetterUppercase (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },
  computed: {
    formattedPayments () {
      return this.payments.map((payment) => {
        return {
          ...payment,
          date: formatBrazilianDate(payment.created_at),
          value: `R$ ${Number(payment.value).toFixed(2).replace('.', ',')}`,
          payment_method: this.firstLetterUppercase(payment.card_brand),
          status: this.firstLetterUppercase(payment.status === 'paid' ? 'Pago' : payment.status === 'pending' ? 'Atraso' : 'Cancelado')
        }
      })
    }
  }
}
</script>

<style scoped>
  .card {
    border-radius: 0 !important;
  }

  .custom-chip {
    height: 0px !important;
    padding: 12px 4px !important;
    border-radius: 4px !important;
    margin-top: 0;
  }

  .custom-data-table {
    border-radius: 0 !important;
  }

  ::v-deep .custom-data-table .v-data-table__wrapper table .v-data-table-header tr th, ::v-deep .custom-data-table td {
    padding-left: 30px
  }

  ::v-deep .custom-data-table .v-data-table__wrapper table .v-data-table-header tr th {
    font-size: 16px;
  }

</style>
