<template>
  <v-col
    cols="5"
    class="pl-2"
    v-if="!isStudent"
  >
    <v-row
    class="d-flex flex-column elevation-3 pa-3"
      no-gutters
    >

    <v-row>
      <v-col>
        <span class="text-uppercase">
            Dados Bioquímicos
        </span>
        <v-divider class="mt-1 mb-2"></v-divider>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      class="d-flex flex-row"
    >
      <v-col
        class="py-0 pl-0 pr-1"
        cols="2"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.biochemical.glicose"
          :readonly="readonly"
          :disabled="readonly"
          class="dense-padding-input-slot"
          filled
          label="Glicose"
          v-mask="['###']"
        />
      </v-col>
      <v-col
        cols="3"
        class="py-0 px-1"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.biochemical.triglicerides"
          :readonly="readonly"
          :disabled="readonly"
          class="dense-padding-input-slot"
          filled
          label="Triglicerídeos"
          v-mask="['###']"
        />
      </v-col>
      <v-col
        cols="2"
        class="py-0 px-1"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.biochemical.ldl"
          :readonly="readonly"
          :disabled="readonly"
          class="dense-padding-input-slot py-0"
          filled
          label="LDL-C"
          v-mask="['###']"
        />
      </v-col>
      <v-col
        cols="2"
        class="py-0 px-1"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.biochemical.hdl"
          :readonly="readonly"
          :disabled="readonly"
          class="dense-padding-input-slot"
          filled
          label="HDL-C"
          v-mask="['###']"
        />
      </v-col>
      <v-col
        cols="3"
        class="py-0 pl-1"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.biochemical.creatina"
          :readonly="readonly"
          :disabled="readonly"
          class="dense-padding-input-slot"
          filled
          label="Creatinina"
          v-mask="['#,#']"
        />
      </v-col>
    </v-row>
  </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'biochimical-info',
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    isStudent: {
      default: false,
      type: Boolean
    },
    cycle: {
      default: '',
      type: String
    }
  },
  data: () => ({
    cycleToBeEvaluated: {
      evaluated_items: {
        biochemical: {
          glicose: '',
          triglicerides: '',
          ldl: '',
          hdl: '',
          creatina: ''
        }
      }
    }
  }),
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated']),
    userIsStudent () {
      return this.user.isStudent()
    }
  },
  methods: {
    setCycle () {
      this.cycleToBeEvaluated = this.cycle === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    }
  },
  mounted () {
    this.setCycle()
  }
}
</script>

<style scoped>
</style>
