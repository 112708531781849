<template>
  <div>
    <v-btn color="primary" block @click="createMicrocycle({ id: null })">
      <v-icon class="mr-2">
        mdi-file-table-box-multiple
      </v-icon>
      ADICIONAR MICRO
    </v-btn>

    <create-or-update-micro-dialog :dialog.sync="createOrUpdateMicroDialog" :editedItem="microcycle" :editedIndex="-1"
      :macrocycleId="macrocycleId" @update="updateItem(payload, index)" />
  </div>
</template>

<script>
import CreateOrUpdateMicroDialog from '@/components/student/show/CreateOrUpdateMicroDialog'
export default {
  name: 'student-micro-cycle-btn-new-micro',
  props: ['macrocycleId', 'macrocycle', 'newMicrocycle'],
  components: {
    CreateOrUpdateMicroDialog
  },
  data: () => ({ createOrUpdateMicroDialog: false, microcycle: {} }),
  methods: {
    createMicrocycle (item) {
      const startDate = new Date().toISOString().substring(0, 10)
      this.microcycle = Object.assign({}, { ...item, name: `Microciclo ${this.macrocycle.microcycles.length + 1}`, start_date: `${startDate}` })
      const vm = this
      this.$nextTick(() => {
        if (vm.newMicrocycle) {
          vm.microcycle.start_date = vm.newMicrocycle.start_date
        }
      })
      this.createOrUpdateMicroDialog = true
    }
  },
  watch: {
    createOrUpdateMicroDialog () {
      this.$emit('close')
    }
  }
}
</script>
