<template>
  <div class="d-flex mr-3">
    <v-row class="align-center justify-end">
    <span class="mr-2 caption">
      <strong>{{ label }}:</strong>
    </span>
      <v-select
        :items="filters"
        v-model="currentFilter"
        @input="updateFilter($event)"
        item-text="name"
        item-color="secondary"
        dense
        solo
        hide-details
        return-object
        color="secondary"
        :style="`max-width: ${maxWidth}`"
      />
    </v-row>

  </div>
</template>

<script>
export default {
  name: 'filter-select',
  props: {
    maxWidth: {
      type: String,
      default: '150px'
    },
    label: {
      type: String,
      default: 'Filtro'
    },
    filters: {
      type: Array,
      default: () => (
        [
          {
            name: 'Todos',
            prop: 'all'
          },
          {
            name: 'Ativos',
            prop: 'true'
          },
          {
            name: 'Inativos',
            prop: 'false'
          }
        ]
      )
    },
    selectedFilter: {
      type: Object,
      default: () => ({
        name: 'Ativos',
        prop: 'true'
      })
    }
  },
  data: () => ({
  }),
  methods: {
    updateFilter (filter) {
      this.$emit('update:selectedFilter', filter)
    }
  },
  computed: {
    currentFilter: {
      set (val) {
        this.updateFilter(val)
      },
      get () {
        return this.selectedFilter
      }
    }
  }
}
</script>

<style scoped>

</style>
