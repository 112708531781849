import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axiosInstance from './services/axios-instance'
import axiosNodeInstance from './services/axios-node-instance'
import reduceName from './filters/reduce-name'
import { formatDateToBr, formatBrazilianDate } from './filters/format-date-to-br'
import fullAddressFilter from './filters/full-address'
import copyToClipboard from '@/mixins/copyToClipboard'
import VueTheMask from 'vue-the-mask'
import VTiptap from '@peepi/vuetify-tiptap'
import "@/sass/custom.scss"

const token = localStorage.getItem('access_token')
if (token) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  axiosNodeInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  store.dispatch('auth/fetchCurrentUser')
} else if (!localStorage.getItem('access_token')) {
  localStorage.clear()
}

axiosInstance.interceptors.response.use(response => response, (error) => {
  const isTryLogin = error.config.url.includes('auth')
  const isUnauthorized = error.request.status === 401
  const isInsideOfApplication = !router.history.current.fullPath.includes('autenticacao')

  if (isUnauthorized && isInsideOfApplication && !isTryLogin) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')

    return store.dispatch('base/setLoginDialog', { show: true })
      .then(() => {
        const newRequest = error.config
        const token = localStorage.getItem('access_token')
        newRequest.headers.Authorization = `Bearer ${token}`
        return axiosInstance.request(newRequest)
      })
  }
  return Promise.reject(error)
})

Vue.config.productionTip = false
Vue.filter('reduceName', reduceName)
Vue.filter('formatDateToBr', formatDateToBr)
Vue.filter('formatBrazilianDate', formatBrazilianDate)
Vue.filter('fullAddress', fullAddressFilter)
Vue.mixin(copyToClipboard)
Vue.use(VTiptap)
Vue.use(VueTheMask)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
