<template>
  <v-img
    :src="getImg"
    :height="height"
    :width="width"
    @click="onClick"
    contain
  />
</template>

<script>
export default {
  name: 'Logo',
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false
    },
    imgColor: {
      type: String,
      required: false,
      default: 'colorful' // values can be colorful, white, black
    },
    height: {
      type: Number,
      required: false,
      default: 64
    },
    width: {
      type: Number,
      required: false,
      default: 120
    }
  },
  computed: {
    getImg () {
      if (this.admin) {
        return require(`@/assets/images/logo-${this.imgColor}.png`)
      }
      return require(`@/assets/images/logo-${this.imgColor}.png`)
    }
  },
  methods: {
    onClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>
