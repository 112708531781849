<template>
  <dialog-full-screen
    :dialog="studentFinalResultDialog"
    titleName="Resultado final"
    @close="closeDialog()"
  >
    <v-container>
      <student-header-info>
        <template v-slot:actions>
        </template>

        <template v-slot:cycle-info>
           {{currentMacroCycle.name}} ({{currentMacroCycle.start_date | formatDateToBr}} - {{currentMacroCycle.end_date | formatDateToBr}})
        </template>
      </student-header-info>

      <v-tabs class="final-result-tabs"
        v-model="tab"
      >
        <v-tab
          v-for="(current, idx) in tabs"
          :key="idx"
        >
          {{current.title}}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item
          v-for="(current, idx) in tabs"
          :key="idx"
        >
          <component :is="current.component"/>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </dialog-full-screen>
</template>

<script>
import ResultTab from '@/components/student/show/finalResultTabs/ResultTab'
import PostureResultTab from '@/components/student/show/finalResultTabs/PostureResultTab'

import DialogFullScreen from '@/components/base/DialogFullScreen'
import StudentHeaderInfo from '@/components/student/show/studentEvaluation/StudentHeaderInfo'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'student-meso-cycle-final-result',
  components: {
    DialogFullScreen,
    StudentHeaderInfo
  },
  data: () => ({
    tabs: [],
    tab: 1
  }),
  computed: {
    ...mapGetters('students', ['studentFinalResultDialog', 'currentMacroCycle'])
  },
  methods: {
    ...mapActions('students', ['setFinalResultDialog', 'resetCurrentMacroCycle']),
    closeDialog () {
      this.setFinalResultDialog(false)
      this.resetCurrentMacroCycle()
    },
    downloadPdf () {
      // TODO
    }
  },
  mounted () {
    this.tabs = [{ title: 'RESULTADOS', component: ResultTab },
      { title: 'RESULTADOS POSTURAIS', component: PostureResultTab }]
  }
}
</script>

<style lang="scss">
  .final-result-tabs{
    border-bottom: solid grey 1px;
  }
</style>
