import axios from './axios-node-instance'

export default {
  getTerms () {
    return axios.get('terms')
  },
  createAndUpdateTerm (payload) {
    return axios.post('terms', payload)
  }
}
