import axios from './axios-node-instance'

export default {
  addPlan (payload) {
    return axios.post('subscriptionPlan/', payload)
  },
  getPlansPagination (page, limit, ownerBy = '', ownerById = '', search = '') {
    return axios.get(`subscriptionPlan/?page=${page}&limit=${limit}&owner_by=${ownerBy}&owner_by_id=${ownerById}&search=${search}`)
  },
  getPlanById (id) {
    return axios.get(`subscriptionPlan/${id}`)
  },
  updatePlan (id, payload) {
    return axios.put(`subscriptionPlan/${id}`, payload)
  },
  destroyPlan (id) {
    return axios.delete(`subscriptionPlan/${id}`)
  }
}
