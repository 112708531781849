<template>
    <v-container>

      <v-row
        class="align-center"
        mt-5
      >
        <v-col
          cols="4"
        >
          <search-field
            label="Busque por nome do Tutorial"
            :loading="loadingSearch"
            @searchText="searchText = $event"
          />
        </v-col>
         <v-spacer></v-spacer>
        <v-col cols="1"
               class="text-right">
          <span class="mr-2 caption">
            <strong>Filtros:</strong>
          </span>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="tutorialType"
            :items="tutorialTypes"
            solo
            dense
            item-text="name"
            item-value="value"
            required
            hide-details
            return-object
          />
        </v-col>
        <v-spacer></v-spacer>

      </v-row>
      <v-row
          mt-5
        >
          <v-col xs12>
            <v-data-table
              :headers="headers"
              :items="tutoriais"
              disable-pagination
              disable-sort
              hide-default-footer
              no-data-text=""
              loading-text="Carregando... Aguarde"
              class="elevation-1"
            >
              <template v-slot:item.tutorial_type="{ item }">
                {{ translatetutorialTypes(item.tutorial_type) }}
              </template>
              <template v-slot:item.link="{ item }">
                <a :href="item.link"
                   v-if="item.link"
                   target="_blank">Visualizar</a>
              </template>

              <template v-slot:footer>
                <div class="full-width">
                  <infinite-loading
                    @infinite="infiniteHandler">
                    <template slot="no-more">Não há mais itens cadastrados</template>
                    <template slot="no-results">Não há itens cadastrados</template>
                  </infinite-loading>
                </div>

              </template>
            </v-data-table>
          </v-col>
        </v-row>
    </v-container>

</template>

<script>
import AdminTutorialService from '@/services/user-tutorial-service'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import SearchField from '@/components/base/SearchField'

export default {
  name: 'TutorialList',
  components: {
    InfiniteLoading,
    SearchField
  },
  mounted () {
    this.setLoader({ show: true })
  },
  data () {
    return {
      ...mapActions('base', ['setLoader', 'setSnackbar']),
      nextPage: 1,
      totalPages: 0,
      searchText: '',
      state: null,
      resetList: false,
      tutorialTypes: [
        { name: 'Guia de fotos posturais', value: 2 }
      ],
      tutorialType: { name: 'Todos', value: '' },
      tutoriais: [],
      headers: [
        { text: 'Nome Tutorial', sortable: false, value: 'name' },
        { text: 'Descrição', sortable: false, value: 'name' },
        { text: 'Tipo de Tutorial', value: 'tutorial_type', width: 140, align: 'center' },
        { text: 'Video/Documento', value: 'link', width: 140, align: 'center' },
        { text: '', value: 'actions', width: 140, align: 'center' }
      ],
      loadingSearch: true,
      editedItem: {},
      itemToBeDeleted: {},
      createOrUpdateDialog: false,
      destroyDialog: false
    }
  },
  methods: {
    infiniteHandler ($state) {
      this.state = $state
      this.resetList = false
      if (this.nextPage === null) {
        $state.complete()
      } else {
        this.fetchTutorial()
      }
    },
    fetchTutorial () {
      AdminTutorialService
        .all({ q: this.searchText, page: this.nextPage, tutorialType: 2 })
        .then(({ data }) => {
          if (data.tutorials.length > 0) {
            if (this.resetList) {
              this.tutoriais = []
            }
            this.tutoriais.push(...data.tutorials)
            this.nextPage = data.meta.next_page
            this.totalPages = data.meta.total_count
            this.state.loaded()
          } else {
            this.tutoriais = []
            this.state.complete()
          }
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.loadingSearch = false
          this.setLoader({ show: false })
        })
    },
    resetInfinityLoadAndPagination () {
      this.tutoriais = []
      this.state.reset()
      this.nextPage = 1
      this.resetList = true
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.createOrUpdateDialog = true
    },
    deleteItem (item) {
      this.itemToBeDeleted = item
      this.destroyDialog = true
    },
    closeCreateOrUpdateDialog (state) {
      this.createOrUpdateDialog = false
      this.editedItem = {}
      if (state) this.resetInfinityLoadAndPagination()
    },
    closeDestroyDialog () {
      this.destroyDialog = false
      this.resetInfinityLoadAndPagination()
    },
    translatetutorialTypes (tutorialTypes) {
      try {
        const type = this.tutorialTypes.find(type => type.value === tutorialTypes)
        return type.name
      } catch (error) {
        return 'Sem classificação'
      }
    }

  },
  watch: {
    tutorialType () {
      this.resetInfinityLoadAndPagination()
    },
    searchText () {
      const qLength = this.searchText.length
      if (qLength > 0 && qLength < 3) return
      this.loadingSearch = true
      this.resetInfinityLoadAndPagination()
    }
  }
}
</script>

<style scoped>

</style>
