<template>
    <v-form ref="form" lazy-validation class="mx-md-12 mt-md-6 px-md-12">
      <v-row class="pt-6 px-4 px-md-0">
        <v-col cols="12" md="4">
          <v-card elevation="2" height="100%" class="pa-4">
            <v-row justify="center" no-gutters>
              <v-img contain
                :src="photoUrl || require('@/assets/images/logo-placeholder.jpg')"
                :alt="personal.name"
                :lazy-src="require('@/assets/images/logo-placeholder.jpg')"
              >
                <v-btn v-if="photoUrl" absolute top right icon @click="clearImage()"> <v-icon>mdi-delete</v-icon> </v-btn>
              </v-img>
            </v-row>
            <v-row justify="center" no-gutters class="mt-12">
              <v-btn color="secondary" @click="$refs.inputFile.click()" absolute bottom>
                <v-icon class="mr-2">
                  mdi-image-plus
                </v-icon>
                carregar imagem
              </v-btn>
              <input type="file" @change="onFileChange" ref="inputFile" style="display: none">
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" class="pl-8">

          <v-row class="pl-3">
            <span class="text-uppercase font-weight-bold subtitle-2"> Dados Pessoais</span>
          </v-row>

          <v-divider></v-divider>

          <v-row>
            <v-col cols="12" md="7" class="pb-0">
              <v-text-field
                color="secondary"
                label="Nome completo"
                v-model="personal.fullname"
                :rules="[validationRules.required]"
                filled
                dense
                hide-details="auto"
              />
            </v-col>

            <v-col class="pb-0" cols="12" md="5">
              <v-text-field
                color="secondary"
                label="Celular"
                v-model="personal.phone"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
                :rules="[validationRules.required]"
                filled
                dense
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row class="pl-3 mt-6">
            <span class="text-uppercase font-weight-bold subtitle-2"> Dados de Endereço</span>
          </v-row>

          <v-divider></v-divider>

          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="personal.address.zipcode"
                v-mask="'#####-###'"
                :rules="[validationRules.cep]"
                color="secondary"
                label="CEP"
                filled
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col cols="8" md="4">
              <v-text-field
                v-model="personal.address.city"
                :rules="[validationRules.required]"
                color="secondary"
                label="Cidade"
                filled
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col cols="4" md="3">
              <v-text-field
                v-model="personal.address.state"
                :rules="[validationRules.required]"
                color="secondary"
                label="Estado"
                filled
                dense
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="7">
              <v-text-field
                v-model="personal.address.street"
                :rules="[validationRules.required]"
                color="secondary"
                label="Rua"
                filled
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col cols="8" md="5">
              <v-text-field
                v-model="personal.address.neighborhood"
                :rules="[validationRules.required]"
                color="secondary"
                label="Bairro"
                filled
                dense
                hide-details="auto"
              />
            </v-col>

            <v-col cols="4" md="3">
              <v-text-field
                v-model="personal.address.number"
                :rules="[validationRules.required]"
                color="secondary"
                label="Número"
                filled
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="personal.address.complement"
                color="secondary"
                label="Complemento"
                @keyup.enter="next"
                filled
                dense
                hide-details="auto"
              />
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <v-row class="align-center justify-end">
        <v-btn color="secondary" text class="mr-2" @click="close">
          cancelar
        </v-btn>
        <v-btn color="primary" class="ml-2" @click="save">
          Salvar alterações
        </v-btn>
      </v-row>
    </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserPersonalService from '@/services/user-personal-service.js'
import CEP from 'cep-promise'
import validationRules from '@/mixins/validation-rules'
import jsonToFormData from '@/mixins/jsonToFormData'

export default {
  mixins: [jsonToFormData],
  data: () => ({
    photoUrl: '',
    photoChanged: false,
    validationRules,
    personal: { address: {} }
  }),
  mounted () {
    this.fetchCurrentUser()
      .then(() => {
        this.personal = { ...this.user }
        this.photoUrl = this.user.photo_url
      })
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('auth', ['fetchCurrentUser']),
    save () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setLoader({ show: true })

      const payload = this.jsonToFormData({
        user: {
          fullname: this.personal.fullname,
          phone: this.personal.phone,
          photo: this.personal.photo,
          photo_changed: this.photoChanged,
          address: this.personal.address
        }
      })

      UserPersonalService.updateInfo(payload)
        .then(({ data }) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Personal salvo com sucesso'
          })
          this.close()
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    close () {
      this.$router.replace({ name: 'PersonalProfile' })
    },
    onFileChange (event) {
      this.personal.photo = event.target.files[0]
      this.$refs.inputFile.value = ''
      var reader = new FileReader()
      reader.onload = (e) => {
        this.photoUrl = e.target.result
        this.photoChanged = true
      }
      reader.readAsDataURL(this.personal.photo)
    },
    clearImage () {
      this.personal.photo = ''
      this.photoUrl = ''
      this.photoChanged = true
    }
  },
  watch: {
    'personal.address.zipcode': function (val) {
      let onlyNumbers = val.replace(/-/g, '')
      if ((onlyNumbers || '').length === 8) {
        this.setLoader({ show: true })
        CEP(onlyNumbers)
          .then(response => {
            this.personal.address = {
              ...this.personal.address,
              state: response.state,
              city: response.city,
              street: response.street,
              neighborhood: response.neighborhood
            }
          })
          .catch(({ message }) => {
            this.setSnackbar({
              color: 'error',
              show: true,
              message: message
            })
          })
          .finally(() => {
            this.setLoader({ show: false })
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
