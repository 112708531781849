<template>
  <apexchart
    width="100%"
    height="100%"
    :options="computedChartOptions"
    :series="series"
    ref="apexChart">
  </apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'line-chart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    dateType: {
      type: String,
      default: 'monthly'
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: '100%',
          stacked: false,
          toolbar: {
            show: false,
            autoSelected: 'pan',
            zoom: {
              enabled: false
            }
          }
        },
        dataLabels: {
          formatter: function (val) {
            return ''
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'thin'
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return 'R$ ' + val.toFixed(2)
            }
          },
          min: 0,
          max: 200,
          tickAmount: 5
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        }

      },
      series: [{
        name: 'Valor',
        data: []
      }],
      maxVal: 0

    }
  },
  mounted () {
    this.initializeChartData()
  },
  computed: {
    computedChartOptions () {
      return {
        ...this.chartOptions,
        dataLabels: {
          ...this.chartOptions.dataLabels,
          enabled: !(this.chartData.length > 12)
        },
        xaxis: {
          min: this.startDate,
          tickAmount: this.chartData.length,
          labels: {
            rotate: -45,
            rotateAlways: true,
            formatter: (val) => {
              if (this.dateType === 'daily') {
                return new Date(val).toLocaleString('pt-BR', { day: 'numeric', month: 'short' })
              } else {
                return new Date(val).toLocaleString('pt-BR', { month: 'short' })
              }
            }
          }
        },
        yaxis: {
          ...this.chartOptions.yaxis,
          max: this.maxVal
        }

      }
    }
  },
  watch: {
    chartData: {
      handler () {
        this.initializeChartData()
      },
      deep: true
    }
  },
  methods: {
    initializeChartData () {
      const newSeries = this.chartData.map(item => {
        return {
          x: item.date,
          y: item.value
        }
      })
      this.series[0].data = newSeries
      let value = Math.max(...newSeries.map(item => item.y))
      this.maxVal = value * 1.05
    }
  }
}
</script>
