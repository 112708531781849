<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="550px"
  >
    <v-card>
      <v-card-title class="headline secondary white--text text-uppercase" primary-title>
        <span>Comparar Avaliações (PDF)</span>

        <v-spacer />

        <v-btn icon dark @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
      </v-card-title>

      <v-card-text class="my-4">
        <v-select
          v-model="firstMesocycle"
          label="Avaliação 1"
          placeholder="Selecionar Mesociclo"
          :items="filteredMesocycles1"
          return-object
          item-text="name"
          :menu-props="{maxHeight: '200px', nudgeBottom: '55px'}"
          class="py-4"
          hide-details
          filled
          dense
        >
          <template v-slot:item="{item}">
            {{ item.name }} ({{item.start_date | formatDateToBr}} - {{item.end_date | formatDateToBr}})
          </template>
        </v-select>

        <v-select
          v-model="secondMesocycle"
          label="Avaliação 1"
          placeholder="Selecionar Mesociclo"
          :items="filteredMesocycles2"
          return-object
          item-text="name"
          :menu-props="{maxHeight: '200px', nudgeBottom: '55px'}"
          class="py-4"
          hide-details
          filled
          dense
        >
          <template v-slot:item="{item}">
            {{ item.name }} ({{item.start_date | formatDateToBr}} - {{item.end_date | formatDateToBr}})
          </template>
        </v-select>
      </v-card-text>

      <v-divider />

      <v-card-actions class="px-5">
        <v-btn text color="secondary" :loading="loading" @click="close()">cancelar</v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="loading" :disabled="!canGenerate" @click="downloadMesocycleComparisonPdf">Gerar Pdf</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import DocumentService from '@/services/document-service.js'
import { mapActions } from 'vuex'

export default {
  name: 'CompareEvaluationsDialog',
  props: {
    mesocycles: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dialog: true,
      loading: false,
      firstMesocycle: {},
      secondMesocycle: {}
    }
  },
  methods: {
    ...mapActions('base', ['setSnackbar', 'fetchPdf']),
    close () {
      this.dialog = false
      this.$emit('closeCompareDialog')
    },
    async downloadMesocycleComparisonPdf () {
      this.loading = true

      const payload = {
        firstMesocycleId: this.firstMesocycle.id,
        secondMesocycleId: this.secondMesocycle.id
      }

      const fileName = 'comparacao-avaliacoes.pdf'
      await this.fetchPdf({ service: DocumentService.getComparisonPdf, payload, fileName })

      this.close()
      this.loading = false
    }
  },
  computed: {
    canGenerate () {
      return this.firstMesocycle.id && this.secondMesocycle.id
    },
    filteredMesocycles1 () {
      return this.mesocycles.filter(mesocycle => mesocycle.id !== this.secondMesocycle.id)
    },
    filteredMesocycles2 () {
      return this.mesocycles.filter(mesocycle => mesocycle.id !== this.firstMesocycle.id)
    }
  }
}
</script>

<style>

</style>
