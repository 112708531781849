<template>
  <v-col
    cols="3"
  >
    <v-row
      class="d-flex flex-column elevation-3 pa-3"
      no-gutters
    >
      <v-col>
        <span class="text-uppercase">
          Dados do Aluno
        </span>
        <v-divider class="mt-1 mb-2"></v-divider>

        <v-row
          no-gutters
        >
          <v-col
            class="py-0 pl-0 pr-1"
          >
            <v-text-field
              v-model="cycleToBeEvaluated.evaluated_items.student.stature"
              :readonly="readonly"
              :disabled="readonly"
              class="dense-padding-input-slot"
              filled
              suffix="m"
              v-mask="['#,##']"
              label="Estatura"

            />
          </v-col>
          <v-col
            class="py-0 pl-1"
          >
            <v-text-field
              v-model="cycleToBeEvaluated.evaluated_items.student.weight"
              :readonly="readonly"
              :disabled="readonly"
              @change="formatWeight"
              class="dense-padding-input-slot"
              filled
              suffix="Kg"
              label="Massa"
              v-mask="['##,##', '###,##']"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'student-info',
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    cycle: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      cycleToBeEvaluated: {
        evaluated_items: {
          student: {
            stature: '',
            weight: ''
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated'])
  },
  methods: {
    formatWeight (value) {
      if (value && value.length < 4) {
        this.cycleToBeEvaluated.evaluated_items.student.weight = value.substring(0, 2) + ',00'
      }
    },
    setCycle () {
      this.cycleToBeEvaluated = this.cycle === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    }
  },
  mounted () {
    this.setCycle()
  }

}
</script>

<style scoped>
</style>
