<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        :rules="rules"
        append-icon="mdi-calendar"
        filled
        readonly
        @click:append="menu = true"
        v-on="on"
      />
    </template>
    <v-date-picker
      color="secondary"
      v-model="date"
      @input="handleInput()"
      :min="min"
      :max="max"
      :locale="locale"
    ></v-date-picker>
  </v-menu>
</template>
<script>

export default {
  name: 'date-picker-field',
  props: ['label', 'value', 'rules', 'min', 'max', 'locale'],
  data: (vm) => ({
    date: vm.value,
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu: false
  }),
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    handleInput () {
      this.menu = false
      this.$emit('input', this.date)
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    }
  },
  watch: {
    value (newValue) {
      if (newValue === undefined) {
        this.date = ''
      } else {
        this.date = newValue
      }
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    }
  }
}
</script>
