<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="700"
    >

      <v-card>
        <v-card-title
          class="headline secondary white--text"
          primary-title
        >
          {{dialogTitle()}} Tutorial
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col>
                    <v-text-field
                      dense
                      v-model="name"
                      :rules="[validationRules.required]"
                      label="Nome"
                      required
                    >
                    </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                    <v-text-field
                      dense
                      v-model="description"
                      :rules="[validationRules.required]"
                      label="Descrição"
                      required
                    >
                    </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <v-text-field
                    dense
                    label="Link"
                    v-model="link">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    dense
                    :height="40"
                    v-model="tutorialType"
                    :items="tutorialTypes"
                    :rules="[validationRules.required]"
                    label="Tipo de Tutorial"
                    item-text="name"
                    item-value="value"
                    no-data-text="Nenhum item encontrado"
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                </v-col>
              </v-row>
               <v-row>

              </v-row>
            </v-container>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="loading"
            @click="close(false)">
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="!valid"
            :loading="loading"
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validationRules from '@/mixins/validation-rules'
import AdminTutorialService from '@/services/admin-tutorial-service'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'create-or-update-exercise-admin-dialog',
  props: ['editedItem', 'dialog'],
  data () {
    return {
      valid: false,
      loading: false,
      validationRules,
      id: undefined,
      name: '',
      description: '',
      link: '',
      tutorialType: '',
      workedMuscleGroups: '',
      tutorialTypes: [
        { name: 'Como usar o FISICSOFT', value: '1' },
        { name: 'Guia de fotos posturais', value: '2' }
      ]

    }
  },
  mounted () {

  },
  methods: {
    ...mapActions('base', ['setSnackbar']),
    dialogTitle () {
      return this.isNewItem ? 'Novo' : 'Editar'
    },
    save () {
      this.loading = true

      const payload = {
        id: this.id,
        name: this.name,
        description: this.description,
        link: this.link,
        tutorial_type: this.tutorialType
      }
      AdminTutorialService
        .createOrUpdate(payload)
        .then(({ data }) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Tutorial salvo com sucesso'
          })
          this.close(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    close (state) {
      this.$emit('close', state)
    },
    arrayIsEmpty (array = []) {
      return array.length > 0 || 'Este campo não pode ficar em branco'
    }
  },
  computed: {
    isNewItem () {
      return this.editedItem.id === undefined
    },
    ...mapGetters('auth', ['user'])
  },
  watch: {
    editedItem (newValue) {
      if (newValue) {
        if (newValue.id !== undefined) {
          this.id = newValue.id
        } else {
          this.id = null
        }
        this.searchText = ''
        this.name = newValue.name
        this.description = newValue.description
        this.link = newValue.link
        this.tutorial_type = newValue.tutorialType
      }
    }
  }
}
</script>
