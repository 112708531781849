<template>
  <div
    class="d-flex flex-column elevation-3 pa-5 mt-5"
  >
    <v-row>
      <v-col>
        <span class="text-uppercase font-weight-medium">Relação Cintura-Estatura</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <div v-if="stature && cintura">
      <div class="d-flex flex-row">
        <v-row>
          <v-col
            cols="4">
            <div class="d-flex flex-column">
              <v-row>
                <v-col
                  class="py-0"
                >
                <span
                  class="display-1"
                >
                  <strong>{{relacaoCinturaEstatura.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong>
                </span>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col
            cols="8"
          >
            {{ nivelCinturaEstatura }}
          </v-col>
        </v-row>

      </div>
    </div>
    <div v-else>
      <p v-for="(message, index) in messages" :key="index"> {{ message }}</p>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
  import { mapGetters } from 'vuex'
  import { get } from 'lodash'

  export default {
    name: 'cintura-estatura',
    data() {
      return {}
    },
    methods: {
      canCalculate(){
        if (this.stature && this.cintura){
          return true
        } else {
          return false
        }
      },
    },
    mounted() {
      this.mesocycleToBeEvaluated.evaluated_items.perimeter.relacaoCinturaEstatura = {
        value: this.relacaoCinturaEstatura.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
        label: this.nivelCinturaEstatura
      }
    },
    watch: {
      messages(val) {
        let data = {}

        if (val.length > 0){
          data = { messages: val };
        } else {
          data = {
            value: this.relacaoCinturaEstatura.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            label: this.nivelCinturaEstatura
          }
        }

        this.mesocycleToBeEvaluated.evaluated_items.perimeter.relacaoCinturaEstatura = { ...data }
      }
    },
    computed: {
      ...mapGetters('students', ['student', 'mesocycleToBeEvaluated']),
      messages() {
        const messages = []

        if (!this.stature) {
          messages.push('Estatura não informada')
        }
        if (!this.cintura) {
          messages.push('Cintura não informada')
        }

        return messages;
      },
      stature() {
        if (get(this.mesocycleToBeEvaluated, 'evaluated_items.student.stature'))
          return parseFloat(get(this.mesocycleToBeEvaluated, 'evaluated_items.student.stature').toString().replace(',', '.'))
        return ''
      },
      cintura() {
        if (get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.bodyPerimeter.cintura'))
          return parseFloat(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.bodyPerimeter.cintura').toString().replace(',', '.'))
        return ''
      },
      relacaoCinturaEstatura() {
        return (this.cintura / this.stature / 100)
      },
      nivelCinturaEstatura() {
        const rcs = this.relacaoCinturaEstatura

        if (rcs > 0.52)
          return 'RISCO PARA PROBLEMAS CARDIOVASCULARES AUMENTADO'

        if (rcs < 0.52)
          return 'RISCO PARA PROBLEMAS CARDIOVASCULARES  REDUZIDO'

        return 'NORMAL'
      }
    }

  }
</script>

<style scoped>
</style>
