<template>
  <v-dialog
    :key="`dialogMicro-${dialog}`"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card>
      <v-form
        v-model="valid"
      >
        <v-card-title
          class="secondary white--text"
          primary-title
        >
          {{  modalTitle }} Microciclo
          <v-spacer></v-spacer>
          <v-btn
            fab
            x-small
            text
            @click.stop="closeDialog()"
          >
            <v-icon
              class="white--text"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="editedItem.name"
                  label="Nome do Micro"
                  :rules="[validationRules.required]"
                  filled
                  required
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="editedItem.goal"
                  label="Objetivo do Microciclo"
                  :rules="[validationRules.required]"
                  filled
                  required
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="6"
              >
                <date-picker-field
                  v-model="editedItem.start_date"
                  :rules="[validationRules.required]"
                  @input="setEndDate"
                  :locale="'pt-BR'"
                  label="Início"
                />

              </v-col>
              <v-col
                cols="6"
              >
                <date-picker-field
                  :key="editedItem.end_date"
                  v-model="editedItem.end_date"
                  :rules="[validationRules.required]"
                  label="Fim"
                  :min="min(editedItem.start_date)"
                  :locale="'pt-BR'"
                />

              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="secondary"
            text
            @click.stop="closeDialog()"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="save()"
            :disabled="!valid"
          >
            Salvar
          </v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from '@/mixins/validation-rules'
import DatePickerField from '@/components/base/DatePickerField'
import { mapActions } from 'vuex'
import microcycleService from '@/services/microcycle-service'
export default {
  name: 'create-or-update-micro-dialog',
  components: {
    DatePickerField
  },
  props: ['editedItem', 'editedIndex', 'dialog', 'macrocycleId'],
  data () {
    return {
      valid: false,
      validationRules,
      trainingSystem: [
        { name: 'Adaptação à Hipertrofia', value: 1 },
        { name: 'Ficar Monstro', value: 2 }
      ]
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['setMicrocycles']),
    save () {
      this.setLoader({ show: true })
      microcycleService
        .createOrUpdate(this.studentId, this.macrocycleId, this.editedItem)
        .then(async ({ data }) => {
          const responseData = await microcycleService.all(this.studentId, this.macrocycleId)
          this.setMicrocycles({ macrocycleId: this.macrocycleId, microcycles: responseData.microcycles })
          this.showSnackbar('success', `Microciclo ${this.isNewItem() ? 'cadastrado' : 'atualizado'} com sucesso`)
          this.closeDialog()
        })
        .catch(({ response }) => {
          this.showSnackbar('error', response.data.errors.detail)
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    closeDialog () {
      this.$emit('update:dialog', false)
    },
    isNewItem () {
      return this.editedIndex === -1
    },
    showSnackbar (color, message) {
      this.setSnackbar({
        color,
        message,
        show: true
      })
    },
    min (date) {
      if (!date) {
        return new Date().toISOString().substring(0, 10)
      }
      var newDate = Date.parse(date) + (24 * 60 * 60 * 1000)
      newDate = new Date(newDate).toISOString().substring(0, 10)
      return newDate
    },
    setEndDate () {
      this.editedItem.end_date = this.min(this.editedItem.start_date)
    }
  },
  computed: {
    modalTitle () {
      return this.isNewItem() ? 'Cadastrar' : 'Atualizar'
    },
    studentId () {
      return this.$route.params.id
    }
  }
}
</script>

<style lang="scss"></style>
