<template>
  <v-container>
    <v-row>
      <v-col class="pb-0">
        <v-btn
          text
          class="transparent text-none text--secondary mx-0 px-0"
          @click="$router.push({ name: 'PersonalList' })"
        >
          <v-icon left>mdi-chevron-left</v-icon>Voltar</v-btn
        >
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto" class="title font-weight-black">
        Termos de uso e política de privacidade
      </v-col>

      <v-spacer />

      <v-col cols="auto">
        <v-btn v-if="!this.editTerm" color="primary" outlined @click="onEdit()">Editar Termo</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="containerTeste">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-tiptap v-if="this.editTerm" v-model="terms"
                :toolbar="['bold', 'italic', 'underline', '|', 'color', 'headings', '|', 'left', 'center','right', 'justify', '|', 'bulletList', 'orderedList', '|', 'clear']"
                 >
                <template #breakLine="{ editor }">
                  <v-btn icon small title="Quebra de linha" @click="editor.commands.hardBreak()">
                    <v-icon>mdi-format-line-spacing</v-icon>
                  </v-btn>
                </template>
                </v-tiptap>
                <v-tiptap v-else v-model="terms" view />
                <!-- <v-textarea v-if="this.editTerm" v-model="terms" label="Termos de uso e política de privacidade" filled hide-details rows="31"
                  :readonly="!editTerm">
                </v-textarea>
                <v-card-text v-else>
                  <p class="term" v-html="terms"></p>
                </v-card-text> -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="this.editTerm" @click="onCancel">Cancelar</v-btn>
            <v-btn v-if=" this.editTerm" color="primary" @click="saveTerms">Salvar</v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import TermsService from '@/services/admin-terms-service.js'

export default {
  data: () => ({
    terms: '',
    originalTerms: '',
    editTerm: false
  }),
  mounted () {
    this.fetchTerms()
  },

  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),

    fetchTerms () {
      this.setLoader({ show: true })
      TermsService.getTerms()
        .then(response => {
          this.terms = response.data.description
        })
        .catch(error => {
          console.error(error)
          this.setSnackbar({
            show: true,
            message: 'Erro ao buscar os termos de uso',
            color: 'error'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },

    saveTerms () {
      this.editTerm = false
      this.setLoader({ show: true })
      TermsService.createAndUpdateTerm({ description: this.terms })
        .then(() => {
          this.setSnackbar({
            show: true,
            text: 'Termos de uso atualizados com sucesso',
            color: 'success'
          })
        })
        .catch(error => {
          console.error(error)
          this.setSnackbar({
            show: true,
            message: 'Erro ao atualizar os termos de uso',
            color: 'error'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },

    onEdit () {
      this.editTerm = true
      this.originalTerms = this.terms
    },

    onCancel () {
      this.editTerm = false
      this.terms = this.originalTerms
    }
  }
}
</script>

<style scoped>

.term {
  white-space: pre-line;
  text-align: justify;
  padding: 0 1rem;
  overflow-y: auto;
}

.containerTeste {
  height: 100%;
  min-height: 75vh;
}

</style>
