<template>
  <v-list-item
  class="flex align-center py-1 "
  @click="onPersonalClick(personal.id)"
  >
      <v-col cols="3">{{ personal.fullname }}</v-col>
      <v-col cols="2">{{ lastLogin }}</v-col>
      <v-col cols="3">{{ personal.email }}</v-col>
      <v-col cols="2">{{ subscriptionPlanName }}</v-col>
      <v-col cols="1">{{ amountOfStudents }}</v-col>
      <v-col cols="1">
        <v-row class="ml-1" style="display: flex; justify-content: center;">
          <v-switch
            class="custom-switch"
            :input-value="personal.is_active"
            :color="activeToggle ? 'var(--v-secondary-base)' : 'grey'"
            @click.stop.prevent="toggleActive"
          ></v-switch>
        </v-row>

      </v-col>
  </v-list-item>
</template>

<script>
import { formatBrazilianDate } from '@/filters/format-date-to-br'

export default {
  name: 'personal-list-item',
  data: () => ({
    activeToggle: true
  }),
  props: {
    personal: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    toggleActive () {
      const updateStatus = !this.personal.is_active
      this.$emit('update-personal-status', updateStatus, this.personal.id)
    },
    onPersonalClick (id) {
      this.$router.push({ name: 'PersonalShow', params: { id: id } })
    }
  },
  computed: {
    lastLogin () {
      return formatBrazilianDate(this.personal.last_sign_in_at) || 'Nunca'
    },
    subscriptionPlanName () {
      if (this.personal && this.personal.subscription && this.personal.subscription.subscription_plan) {
        return this.personal.subscription.subscription_plan.name
      }
      return 'Sem plano'
    },
    amountOfStudents () {
      return this.personal.students_count || 0
    }
  }

}

</script>

<style scoped>

  ::v-deep .item-list:focus, .item-list:active {
    background-color: none;
    outline: none;

  }

  .custom-switch {
    margin: 0 !important;
    padding: 0 !important;
  }

  ::v-deep .custom-switch .v-messages {
    display: none !important;
  }

  ::v-deep .custom-switch .v-input--selection-controls__ripple {
    display: none !important;
  }

  ::v-deep .custom-switch .v-input__control {
    margin: 0;
  }

  ::v-deep .custom-switch .v-input__slot {
    margin: 0;
  }

</style>
