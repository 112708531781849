<template>
  <v-row>
    <v-col cols="12" class="elevation-3 pa-0">
      <v-container>
        <v-row justify="end">
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-avatar size="64">
                  <v-img :src="student.photo_url || require('@/assets/images/user-placeholder.png')" :alt="student.name"
                    :lazy-src="require('@/assets/images/user-placeholder.png')" @error="onImageError">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline font-weight-medium">
                    {{ student.id }} - {{ student.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption grey--text darken-5">
                    <span v-if="student.last_evaluation">Última Avaliação: {{ student.last_evaluation }}</span>
                    <span v-else>Não possui avaliações</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="align-self-center" cols="7">
            <v-row class="justify-end">
              <v-btn text :height="40" color="secondary" @click="$emit('toggle')" class="elevation-3 mr-4">
                <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi mdi-history</v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">Pagamentos</span>
              </v-btn>
              <!-- CHAT BUTTON
                <v-btn
                text :height="40"
                color="secondary"
                @click="$emit('update:chatDialog', true)"
                class="elevation-3 mr-4">
                <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi mdi-message</v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">Chat</span>
              </v-btn> -->
              <!-- STRENGTH TEST BUTTON
                <v-btn
                text :height="40"
                color="secondary"
                @click="$emit('update:strengthTestDialog', true)"
                class="elevation-3 mr-4">
                <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi mdi-arm-flex</v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">Teste de força</span>
              </v-btn> -->
              <v-btn text :height="40" disabled color="secondary" class="elevation-3 mr-4">
                <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-weight-lifter</v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">Ver Treinos</span>
              </v-btn>
              <v-btn text :height="40" color="secondary" @click="$emit('update:dialog', true)" class="elevation-3 mr-4">
                <v-icon :left="$vuetify.breakpoint.mdAndUp"> mdi-pencil</v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">Editar</span>
              </v-btn>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'student-card-details',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
    // strengthTestDialog: {
    //   type: Boolean,
    //   default: false
    // },
    // chatDialog: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    onImageError () {
      this.student.photo_url = null
    }
  },
  computed: {
    ...mapGetters('students', ['student'])
  }

}
</script>

<style scoped>
.student-card {
  width: 290px;
  height: 185px;
  padding: 15px 20px;
  margin: 15px 12px;
  cursor: pointer;
}

.background-list-student:hover {
  background: #dfd7ff;
}

.inactive-chip {
  background: #E5E5E9 !important;
}

.inactive-card {
  background: #f9f9ff !important;
  border: solid #f4f4f4 1px;
}

.switch-student-status {
  padding: 8px !important;
  border-radius: 4px !important;
  margin-top: 0;
}
</style>
