const posturaEvaluationFields = {
  Cabeça: [
    [
      { selected: 'NDN', items: ['PROTUSA', 'NDN'], key: 'cabeca_1' },
      {
        selected: 'NDN',
        items: ['INCLINADA PARA DIREITA', 'INCLINADA PARA ESQUERDA', 'NDN'],
        key: 'cabeca_2'
      },
      {
        selected: 'NDN',
        items: [' ROT. PARA DIREITA', 'ROT. PARA ESQUERDA', 'NDN'],
        key: 'cabeca_3'
      }
    ]
  ],
  Ombros: [
    [
      { selected: 'NDN', items: ['SLIDE', 'NDN'], key: 'ombro_1' },
      { selected: 'NDN', items: ['ROT. INTERNA', 'NDN'], key: 'ombro_2' },
      { selected: 'NDN', items: ['PROTUSO', 'NDN'], key: 'ombro_3' }
    ],
    [
      { selected: 'NDN', items: ['SLIDE', 'NDN'], key: 'ombro_4' },
      { selected: 'NDN', items: ['ROT. INTERNA', 'NDN'], key: 'ombro_5' },
      { selected: 'NDN', items: ['PROTUSO', 'NDN'], key: 'ombro_6' }
    ]
  ],
  'Cintura Escapular': [
    [
      { selected: 'NDN', items: ['TILT', 'NDN'], key: 'cintura_1' },
      { selected: 'NDN', items: ['ALADA', 'NDN'], key: 'cintura_2' },
      {
        selected: 'NDN',
        items: ['ROT. INFERIOR', 'ROT. SUPERIOR', 'NDN'],
        key: 'cintura_3'
      },
      {
        selected: 'NDN',
        items: ['ELEVADA', 'DEPRIMIDA', 'NDN'],
        key: 'cintura_4'
      },
      {
        selected: 'NDN',
        items: ['ABDUZIDA', 'ADUZIDA', 'NDN'],
        key: 'cintura_5'
      }
    ],
    [
      { selected: 'NDN', items: ['TILT', 'NDN'], key: 'cintura_6' },
      { selected: 'NDN', items: ['ALADA', 'NDN'], key: 'cintura_7' },
      {
        selected: 'NDN',
        items: ['ROT. INFERIOR', 'ROT. SUPERIOR', 'NDN'],
        key: 'cintura_8'
      },
      {
        selected: 'NDN',
        items: ['ELEVADA', 'DEPRIMIDA', 'NDN'],
        key: 'cintura_9'
      },
      {
        selected: 'NDN',
        items: ['ABDUZIDA', 'ADUZIDA', 'NDN'],
        key: 'cintura_10'
      }
    ]
  ],
  'Coluna Vertebral': [
    [
      {
        selected: 'NDN',
        items: [
          'HIPERLORDOSE CERVICAL',
          'RETIFICAÇÃO CERVICAL',
          'CIFOSE CERVICAL',
          'NDN'
        ],
        key: 'coluna_1'
      },
      {
        selected: 'NDN',
        items: [
          'HIPERCIFOSE TORÁCICA SUPERIOR',
          'HIPERCIFOSE TORÁCICA INFERIOR',
          'HIPERCIFOSE TORÁCICA LONGA',
          'HIPERCIFOSE TORÁCICA',
          'RETIFICAÇÃO TORÁCICA',
          // 'LORDOSE NO TORÁX',
          'NDN'
        ],
        key: 'coluna_2'
      },
      {
        selected: 'NDN',
        items: [
          'HIPERLORDOSE LOMBAR',
          'HIPERLORDOSE COMPENSATÓRIA',
          'RETIFICAÇÃO LOMBAR',
          'NDN'
        ],
        key: 'coluna_3'
      },
      {
        selected: 'NDN',
        items: [
          'ESCOLIOSE EM C LEVE',
          'ESCOLIOSE EM C SEVERA',
          'ESCOLIOSE EM S',
          'ESCOLIOSE EM S SEVERA',
          'NDN'
        ],
        key: 'coluna_4'
      }
    ]
  ],
  Pelve: [
    [
      {
        selected: 'NDN',
        items: ['INCL. ANTERIOR', 'INCL. POSTERIOR', 'NDN'],
        key: 'pelve_1'
      },
      {
        selected: 'NDN',
        items: ['INCL. PARA CIMA', 'INCL. PARA BAIXO', 'NDN'],
        key: 'pelve_2'
      },
      {
        selected: 'NDN',
        items: ['ROT. HORÁRIA', 'ROT. ANTEHORÁRIA', 'NDN'],
        key: 'pelve_3'
      }
    ],
    [
      {
        selected: 'NDN',
        items: ['INCL. ANTERIOR', 'INCL. POSTERIOR', 'NDN'],
        key: 'pelve_4'
      },
      {
        selected: 'NDN',
        items: ['INCL. PARA CIMA', 'INCL. PARA BAIXO', 'NDN'],
        key: 'pelve_5'
      },
      {
        selected: 'NDN',
        items: ['ROT. HORÁRIA', 'ROT. ANTEHORÁRIA', 'NDN'],
        key: 'pelve_6'
      }
    ]
  ],
  Quadril: [
    [
      {
        selected: 'NDN',
        items: ['ROT. INTERNA', 'ROT. EXTERNA', 'NDN'],
        key: 'quadril_1'
      },
      {
        selected: 'NDN',
        items: ['EM FLEXÃO', 'EM EXTENSÃO', 'NDN'],
        key: 'quadril_2'
      },
      {
        selected: 'NDN',
        items: ['EM ADUÇÃO', 'EM ABDUÇÃO', 'NDN'],
        key: 'quadril_3'
      }
    ],
    [
      {
        selected: 'NDN',
        items: ['ROT. INTERNA', 'ROT. EXTERNA', 'NDN'],
        key: 'quadril_4'
      },
      {
        selected: 'NDN',
        items: ['EM FLEXÃO', 'EM EXTENSÃO', 'NDN'],
        key: 'quadril_5'
      },
      {
        selected: 'NDN',
        items: ['EM ADUÇÃO', 'EM ABDUÇÃO', 'NDN'],
        key: 'quadril_6'
      }
    ]
  ],
  Joelhos: [
    [
      {
        selected: 'NDN',
        items: ['FLEXÃO', 'HIPEREXTENSÃO', 'NDN'],
        key: 'joelho_1'
      },
      { selected: 'NDN', items: ['VALGO', 'VARO', 'NDN'], key: 'joelho_2' }
    ],
    [
      {
        selected: 'NDN',
        items: ['FLEXÃO', 'HIPEREXTENSÃO', 'NDN'],
        key: 'joelho_3'
      },
      { selected: 'NDN', items: ['VALGO', 'VARO', 'NDN'], key: 'joelho_4' }
    ]
  ],
  Pés: [
    [{ selected: 'NDN', items: ['SUPINADO', 'PRONADO', 'NDN'], key: 'pe_1' }],
    [{ selected: 'NDN', items: ['SUPINADO', 'PRONADO', 'NDN'], key: 'pe_2' }]
  ]
}

export default posturaEvaluationFields
