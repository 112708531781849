<template>
  <v-container>
    <v-row align="center">
      <v-col xs="12" sm="6" md="4" lg="3">
        <v-select
          color="secondary"
          v-model="result"
          :items="items"
          item-text="value"
          item-value="key"
          solo
          dense
          bottom
        >
          <template v-slot:prepend>
            <v-subheader class="font-weight-bold mt-n2">Visualizar</v-subheader>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        sm="12" md="6"
        v-for="x in beforeAfter"
        :key="x"
      >
        <v-card class="elevation-4">
          <v-card-title>
            <span>{{x === 0 ? 'Antes' : 'Depois'}}</span>
            <v-spacer/>
            <span>{{getDate(x, evaluationResult) | formatDateToBr}}</span>
          </v-card-title>
          <v-img
            class="secondary lighten-3"
            height="346px"
            :src="getPhoto(x, result, evaluationResult)"
          >
            <v-container fill-height fluid v-if="!getPhoto(x, result, evaluationResult)">
              <v-row justify="center" class="ma-0">
                <v-col cols="12" align="center">
                  <v-icon size="111" color="secondary">mdi-image-off</v-icon>
                </v-col>
                <v-col cols="12" align="center">
                  <span>Nenhuma imagem adicionada</span>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    items: [
      { value: 'Frontais', key: 'frontal' },
      { value: 'Dorsal', key: 'dorsal' },
      { value: 'Perfil Esquerdo', key: 'perfil_esquerdo' },
      { value: 'Perfil Direito', key: 'perfil_direito' }
    ],
    result: 'frontal'
  }),
  computed: {
    ...mapGetters('students', ['currentMacroCycle']),
    getResult () {
      return (idx) => {
        try {
          let mesocycles = this.currentMacroCycle.mesocycles
          return mesocycles[idx]
        } catch (e) {
          return { end_date: '', photos: [] }
        }
      }
    },
    beforeAfter () {
      return [0, 1]
    },
    evaluationResult () {
      try {
        const size = this.currentMacroCycle.mesocycles.length
        let first = this.currentMacroCycle.mesocycles[0]
        let last = this.currentMacroCycle.mesocycles[size - 1]

        return [first, last]
      } catch (e) {
        return []
      }
    }
  },
  methods: {
    getDate (idx, mesocycles) {
      try {
        return mesocycles[idx].start_date
      } catch (e) {
        return ''
      }
    },
    getPhoto (idx, name, mesocycles) {
      try {
        let mesocycle = this.currentMacroCycle.mesocycles.find(mesocycle => mesocycle.id === mesocycles[idx].id)
        return mesocycle.photos.principais.find(p => p.name === name).src
      } catch (e) {
        return ''
      }
    }
  }
}
</script>

<style>

</style>
