import axios from './axios-instance'

export default {
  me () {
    return axios.get('v1/auth/me')
  },
  updateInfo (payload) {
    return axios.put(`v1/users/me`, payload)
  }
}
