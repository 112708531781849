<template>
  <v-dialog
    v-model="confirmationDialog"
    persistent
    max-width="450"
  >
    <v-card>
      <v-card-title class="headline">{{title}}</v-card-title>
      <v-card-text>
        {{message}}
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" text @click="cancel()">{{ cancelButton }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.stop="confirm()">{{ confirmButton }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'confirm-dialog',
  props: ['title', 'message', 'cancelButton', 'confirmButton', 'confirmationDialog'],
  methods: {
    confirm () {
      this.$emit('confirm')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
