import axios from './axios-instance'

export default {
  login (payload) {
    return axios.post('v1/admin/auth/login', payload)
  },
  logout () {
    localStorage.clear()
    return axios.delete('v1/admin/auth/logout')
  },
  resetPasswordInstructions (payload) {
    return axios.post('v1/admin/auth/reset_password_instructions', payload)
  },
  resetPassword (token, payload) {
    return axios.post(`v1/admin/auth/reset_password?token=${token}`, payload)
  }
}
