<template>
  <div
    class="d-flex flex-column elevation-3 pa-5 mt-5"
  >
    <v-row>
      <v-col>
        <span class="text-uppercase font-weight-medium">Relação Cintura-Quadril</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <div v-if="cintura && quadril && gender && age">
      <v-row>
        <v-col
          class="py-0"
        >
        <span
          class="display-1"
        >
          <strong>{{ relacaoCinturaQuadril }}</strong>
        </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pt-0"
        >
          {{nivelRelacaoCinturaQuadril}}
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <p v-for="(message, index) in messages" :key="index"> {{ message }}</p>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
  import { mapGetters } from 'vuex'
  import { get } from 'lodash'

  export default {
    name: 'cintura-quadril',
    data() {
      return {}
    },
    mounted() {
      this.mesocycleToBeEvaluated.evaluated_items.perimeter.relacaoCinturaQuadril = {
        value: this.relacaoCinturaQuadril,
        label: this.nivelRelacaoCinturaQuadril
      }
    },
    watch: {
      messages(val) {
        let data = {}

        if (val.length > 0){
          data = { messages: val };
        } else {
          data = {
            value: this.relacaoCinturaQuadril,
            label: this.nivelRelacaoCinturaQuadril
          }
        }

        this.mesocycleToBeEvaluated.evaluated_items.perimeter.relacaoCinturaQuadril = { ...data }
      }
    },
    computed: {
      ...mapGetters('students', ['student', 'mesocycleToBeEvaluated']),
      messages() {
        const messages = []

        if (!this.age) {
          messages.push('Idade não informada')
        }
        if (!this.gender) {
          messages.push('Sexo não informado')
        }
        if (!this.cintura) {
          messages.push('Cintura não informada')
        }
        if (!this.quadril) {
          messages.push('Quadril não informada')
        }

        return messages;
      },
      cintura() {
        if (get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.bodyPerimeter.cintura')){
          return parseFloat(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.bodyPerimeter.cintura').toString().replace(',', '.'))
        }
        return null
      },
      quadril() {
        if (get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.bodyPerimeter.quadril'))
          return parseFloat(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.bodyPerimeter.quadril').toString().replace(',', '.'))
        return null
      },
      relacaoCinturaQuadril() {
        return (this.cintura / this.quadril).toFixed(2)
      },
      gender() {
        return get(this.student, 'gender')
      },
      age() {
        return get(this.student, 'age')
      },
      nivelRelacaoCinturaQuadril() {
        const rcq = this.relacaoCinturaQuadril

        if (this.gender === 'male') {
          if (this.age <= 29) {
            if (rcq  <= 0.83) {
              return 'Ideal'
            }
            if (rcq  <= 0.88) {
              return 'Moderado'
            }
            if (rcq  <= 0.94) {
              return 'Alto'
            }
            if (rcq  > 0.94) {
              return 'Muito Alto'
            }
          }
          if (this.age <= 39){

            if (rcq  <= 0.84) {
              return 'Ideal'
            }
            if (rcq  <= 0.91) {
              return 'Moderado'
            }
            if (rcq  <= 0.96) {
              return 'Alto'
            }
            if (rcq  > 0.96) {
              return 'Muito Alto'
            }

          }
          if (this.age <= 49){
            if (rcq  <= 0.88) {
              return 'Ideal'
            }
            if (rcq  <= 0.95) {
              return 'Moderado'
            }
            if (rcq  <= 0.1) {
              return 'Alto'
            }
            if (rcq  > 0.1) {
              return 'Muito Alto'
            }
          }
          if (this.age <= 59){
            if (rcq  <= 0.9) {
              return 'Ideal'
            }
            if (rcq  <= 0.96) {
              return 'Moderado'
            }
            if (rcq  <= 1.02) {
              return 'Alto'
            }
            if (rcq  > 1.02) {
              return 'Muito Alto'
            }

          }
          if (this.age > 59){
            if (rcq  <= 0.91) {
              return 'Ideal'
            }
            if (rcq  <= 0.98) {
              return 'Moderado'
            }
            if (rcq  <= 1.03) {
              return 'Alto'
            }
            if (rcq  > 1.03) {
              return 'Muito Alto'
            }
          }
        } else {
          if (this.age <= 29) {
            if (rcq  <= 0.71) {
              return 'Ideal'
            }
            if (rcq  <= 0.77) {
              return 'Moderado'
            }
            if (rcq  <= 0.82) {
              return 'Alto'
            }
            if (rcq  > 0.82) {
              return 'Muito Alto'
            }
          }
          if (this.age <= 39){

            if (rcq  <= 0.72) {
              return 'Ideal'
            }
            if (rcq  <= 0.78) {
              return 'Moderado'
            }
            if (rcq  <= 0.84) {
              return 'Alto'
            }
            if (rcq  > 0.84) {
              return 'Muito Alto'
            }

          }
          if (this.age <= 49){
            if (rcq  <= 0.73) {
              return 'Ideal'
            }
            if (rcq  <= 0.79) {
              return 'Moderado'
            }
            if (rcq  <= 0.87) {
              return 'Alto'
            }
            if (rcq  > 0.87) {
              return 'Muito Alto'
            }
          }
          if (this.age <= 59){
            if (rcq  <= 0.74) {
              return 'Ideal'
            }
            if (rcq  <= 0.81) {
              return 'Moderado'
            }
            if (rcq  <= 0.88) {
              return 'Alto'
            }
            if (rcq  > 0.88) {
              return 'Muito Alto'
            }
          }
          if (this.age > 59){
            if (rcq  <= 0.76) {
              return 'Ideal'
            }
            if (rcq  <= 0.83) {
              return 'Moderado'
            }
            if (rcq  <= 0.9) {
              return 'Alto'
            }
            if (rcq  > 0.9) {
              return 'Muito Alto'
            }
          }
        }
      }
    }

  }
</script>

<style scoped>
</style>
