export default {
  namespaced: true,
  state: {
    users: [],
    currentUser: {
      fullname: 'Fernando Cintra',
      email: 'fernando.cintra@rogalabs.com',
      birthday: '1989-11-27',
      phone: '82981772815'
    }
  },
  mutations: {
    SET_USERS (state, payload) {
      Object.assign(state, { users: payload })
    },
    SET_USER (state, payload) {
      Object.assign(state, { currentUser: payload })
    },
    ADD_USERS (state, payload) {
      state.users.push(...payload)
    }
  },
  actions: {
    setUsers ({ commit }, payload) {
      commit('SET_USERS', payload)
    },
    setUser ({ commit }, payload) {
      commit('SET_USER', payload)
    },
    addUsers ({ commit }, payload) {
      commit('ADD_USERS', payload)
    }
  },
  getters: {
    users: state => state.users,
    user: state => state.currentUser
  }
}
