const timeMask = {
  methods: {
    timeMask (mask, time = '--:--') {
      const masks = {
        'mm:ss': {
          mask: 'M#:M#',
          tokens: {
            'M': { pattern: /[0-5]/ },
            '#': { pattern: /\d/ }
          }
        },
        'hh:mm:ss': {
          mask: 'H#:M#:M#',
          tokens: {
            'H': { pattern: /[0-2]/ },
            'M': { pattern: /[0-5]/ },
            '#': { pattern: /\d/ }
          }
        }
      }

      return masks[mask] || masks['mm:ss']
    }
  }
}

export default timeMask
