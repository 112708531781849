<template>
  <div>
    <v-timeline
      :class="{'no-link': macrocycle.microcycles.length <= 1}"
      dense
      v-if="macrocycle.microcycles.length > 0"
    >
      <v-timeline-item
        v-for="(microcycle, index) in macrocycle.microcycles"
        :key="microcycle.id"
        :color="statusColorMicrocycle(microcycle)"
      >
        <template v-slot:icon v-if="hasStatusIconMicrocycle(microcycle)">
          <v-icon size=18 dark>mdi-checkbox-marked-circle-outline</v-icon>
        </template>

        <student-micro-cycle-card
          :microcycle="microcycle"
          :index="index"
          :macrocycle="macrocycle"
          :cols="['12', '6', '6', '12']"
          :md="['6', '3', '3', '12']"
          :lg="['4', '2', '2', '4']"
        />
      </v-timeline-item>
    </v-timeline>
    <p
      class="elevation-3 pa-5 text-center mt-5"
      v-else>
      <span
      class="caption">
        Não possui nenhum Microciclo cadastrado
      </span>
    </p>
    <v-row
      v-if="canCreate"
      class="d-flex justify-end"
    >
      <v-col class="shrink">
        <student-micro-cycle-btn-new-micro
          :macrocycleId="macrocycle.id"
          :macrocycle="macrocycle"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import parseDateFromString from '@/mixins/parse-date-from-string'
import StudentMicroCycleCard from '@/components/student/show/StudentMicroCycleCard'
import StudentMicroCycleBtnNewMicro from '@/components/student/show/StudentMicroCycleBtnNewMicro'
export default {
  name: 'student-micro-cycle',
  props: ['macrocycle', 'canCreate'],
  mixins: [parseDateFromString],
  components: {
    StudentMicroCycleCard,
    StudentMicroCycleBtnNewMicro
  },
  methods: {
    hasTraining (microcycle) {
      try {
        return microcycle.has_training
      } catch (error) {
        return false
      }
    },
    wasEvaluated (microcycle) {
      try {
        return microcycle.was_evaluated
      } catch (error) {
        return false
      }
    },
    isCurrent (microcycle) {
      try {
        const today = new Date()
        return today > this.parseDateFromString(microcycle.start_date) &&
              today < this.parseDateFromString(microcycle.end_date)
      } catch (error) {
        return false
      }
    },
    statusColorMicrocycle (microcycle) {
      if (this.isCurrent(microcycle)) {
        return 'primary'
      } else if (this.wasEvaluated(microcycle) && this.hasTraining(microcycle)) {
        return 'secondary'
      } else if (this.wasEvaluated(microcycle) || this.hasTraining(microcycle)) {
        return 'grey'
      } else {
        return 'grey'
      }
    },
    hasStatusIconMicrocycle (microcycle) {
      return this.wasEvaluated(microcycle) || this.hasTraining(microcycle)
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-timeline {
    &:before {
      border: white dashed 1px;
    }
  }
  .v-timeline{
    &.no-link{
      &:before{
        display: none;
      }
    }
  }
</style>
