<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          text
          class="transparent text-none text--secondary mx-0 px-0"
          @click="$router.push({ name: 'MuscleGroupList' })"
        >
          <v-icon left>mdi-chevron-left</v-icon> Voltar para grupos</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col class="title font-weight-black">
        Cadastrar grupo muscular
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <v-stepper v-model="position" vertical>
          <v-stepper-step :complete="position > 1" step="1">
            Grupo muscular
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card elevation="0">
              <v-col cols="12" lg="8">
                <v-text-field
                  filled
                  placeholder="Nome do grupo muscular"
                  :rules="[v => (v||'').length > 0 || 'Campo obrigatório']"
                  v-model="muscleGroup.name"
                />
              </v-col>
            </v-card>
           <v-btn color="primary" @click="onFinish">Finalizar</v-btn>

          </v-stepper-content>

          <v-stepper-step :complete="position > 2" v-if="false" step="2">
           <v-btn color="primary" @click="position = muscleGroup.name.length > 0 ? 2 : 1">Continuar</v-btn>
          <v-btn text @click="position = 1">Voltar</v-btn>
            Desvios
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card elevation="0" v-if="false">
              <v-row
                align="center"
                v-for="(deviation, idx) in muscleGroup.postural_deviations"
                :key="idx"
              >
                <v-col cols="12" v-if="idx > 0"><v-divider /></v-col>

                <v-col cols="auto" class="pr-0">
                  <v-btn
                    color="error"
                    icon
                    @click="removePosturalDeviation(idx)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="d-flex">
                  <v-text-field
                    hide-details
                    placeholder="Nome do desvio"
                    filled
                    v-model="deviation.name"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    hide-details
                    label="Tipo do desvio"
                    filled
                    v-model="deviation.type"
                    :items="fields"
                  />
                </v-col>

                <v-col cols="auto">
                  <v-btn
                    icon
                    :disabled="!deviation.type"
                    @click="expanded = expanded === idx ? -1 : idx"
                  >
                    <v-icon v-if="expanded === idx">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-col>

                <v-expand-transition>
                  <v-col
                    cols="10"
                    offset="1"
                    class="mt-0 pt-0"
                    v-if="expanded === idx"
                  >
                    <v-row v-if="getColumns(deviation.type) === 2">
                      <v-col cols="6">
                        <v-row class="mx-0 justify-center">Esquerda</v-row>
                        <template
                          v-for="(option, idx) in getOptions(deviation.type, 0)"
                        >
                          <v-select
                            :key="idx"
                            filled
                            class="mt-4"
                            :items="option.items"
                            hide-details
                            clearable
                            v-model="deviation.properties[option.key]"
                          />
                        </template>
                      </v-col>
                      <v-col cols="6">
                        <v-row class="mx-0 justify-center">Direita</v-row>
                        <template
                          v-for="(option, idx) in getOptions(deviation.type, 1)"
                        >
                          <v-select
                            :key="idx"
                            filled
                            class="mt-4"
                            :items="option.items"
                            hide-details
                            clearable
                            v-model="deviation.properties[option.key]"
                          />
                        </template>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="getColumns(deviation.type) === 1">
                      <v-col cols="12">
                        <template
                          v-for="(option, idx) in getOptions(deviation.type, 0)"
                        >
                          <v-select
                            :key="idx"
                            filled
                            class="mt-4"
                            :items="option.items"
                            hide-details
                            clearable
                            v-model="deviation.properties[option.key]"
                          /> </template
                      ></v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>
              </v-row>

              <v-row class="mt-0 mb-4">
                <v-col>
                  <v-btn
                    outlined
                    color="primary"
                    @click="
                      newPosturalDeviation(muscleGroup.postural_deviations)
                    "
                  >
                    <v-icon left>mdi-plus</v-icon> adicionar outro</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>

          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import posturaEvaluationFields from '@/mixins/posturaEvaluationFields'
import { cloneDeep } from 'lodash'
import MuscleService from '@/services/muscle-service.js'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      fields: [],
      expanded: 0,
      position: 1,
      muscleGroup: {
        name: '',
        postural_deviations: [
          {
            name: '',
            type: '',
            properties: {}
          }
        ]
      }
    }
  },
  mounted () {
    try {
      this.fields = Object.keys(posturaEvaluationFields)
    } catch (error) {
      this.fields = []
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    onFinish () {
      this.setLoader({ show: true })
      const muscleGroupClone = cloneDeep(this.muscleGroup)

      muscleGroupClone.postural_deviations.forEach((deviation, index) => {
        const newProperties = Object.keys(deviation.properties).map((key) => {
          return {
            key: key,
            selected: deviation.properties[key]
          }
        })
        deviation.properties = newProperties
      })

      const payload = {
        muscle_group: {
          ...muscleGroupClone,
          postural_deviations: muscleGroupClone.postural_deviations.filter(
            (deviation) => {
              return (
                deviation.name &&
                deviation.type &&
                deviation.properties.length > 0
              )
            }
          )
        }
      }
      MuscleService.create(payload)
        .then(({ data }) => {
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Registro salvo com sucesso!'
          })
          this.$router.push({ name: 'MuscleGroupList' })
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    newPosturalDeviation (posturalDeviations) {
      this.expanded = this.muscleGroup.postural_deviations.length
      posturalDeviations.push({
        name: '',
        type: '',
        properties: {}
      })
    },
    removePosturalDeviation (index) {
      this.muscleGroup.postural_deviations.splice(index, 1)
      this.expanded = this.expanded === index ? -1 : this.expanded
    },
    getColumns (type) {
      try {
        return posturaEvaluationFields[type].length
      } catch (error) {
        return -1
      }
    },
    getOptions (type, idx) {
      try {
        return posturaEvaluationFields[type][idx]
      } catch (error) {
        return [{ items: [] }]
      }
    }
  }
}
</script>

<style></style>
