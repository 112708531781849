<template>
  <v-row class="align-center">
    <v-col cols="6">
      <v-avatar size="48">
        <v-img :src="student.photo_url || require('@/assets/images/user-placeholder.png')" />
      </v-avatar>
      <span class="headline font-weight-medium">
        {{ student.name }}
      </span>
    </v-col>
    <v-spacer></v-spacer>
    <slot name="actions">

    </slot>
    <v-col cols="auto" class="text-xs-right">
      <slot name="cycle-info">
        <span class="font-weight-medium">{{ cycleToBeEvaluated.name }}</span>
        <span> ({{ cycleToBeEvaluated.start_date | formatDateToBr }} - {{ cycleToBeEvaluated.end_date |
          formatDateToBr }})</span>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'student-header-info',
  data () {
    return {
      cycleToBeEvaluated: {
        name: '',
        start_date: '',
        end_date: ''
      }
    }
  },
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    cycle: {
      default: 'meso',
      type: String
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated'])
  },
  methods: {
    setCycle () {
      this.cycleToBeEvaluated = this.cycle === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    }
  },
  mounted () {
    this.setCycle()
  }
}
</script>

<style scoped></style>
