<template>
  <apexchart class="custom-chart" width="100%" height="100%" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'pie-chart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      series: [75, 20, 5],
      chartOptions: {
        chart: {
          type: 'donut',
          height: '100%'
        },
        // labels: ['Cancelado', 'Em Atraso', 'Ativo'],
        // colors: ['#f44336', '#ff9800', '#08b000'],
        legend: {
          position: 'bottom',
          fontSize: '16px',
          itemMargin: {
            horizontal: 10,
            vertical: 0
          }
        }
      }
    }
  },
  mounted () {
    this.initializeChartData()
  },
  methods: {
    initializeChartData () {
      const order = ['active', 'late', 'canceled']
      const sortedData = this.chartData.sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status))
      const newLabels = sortedData.map(item => item.status)
      const colors = ['#08b000', '#ff9800', '#f44336']
      const formattedLabels = newLabels.map(item => {
        if (item === 'canceled') {
          return 'Cancelado'
        } else if (item === 'late') {
          return 'Em Atraso'
        } else {
          return 'Ativo'
        }
      })

      this.series = sortedData.map(item => item.percentage)
      this.$set(this.chartOptions, 'labels', formattedLabels)
      this.$set(this.chartOptions, 'colors', colors)
    }
  }
}
</script>

<style scoped>
</style>
