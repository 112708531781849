<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card>
      <v-form>
      <v-card-title
        class="secondary white--text"
        primary-title
      >
        {{ isNewItem ? 'Novo' : 'Editar' }} Treino
        <v-spacer></v-spacer>
        <v-btn
          fab
          x-small
          text
          @click.stop="closeDialog()"
        >
          <v-icon
            class="white--text"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="trainingObject.name"
                class="dense-padding-input-slot"
                label="Nome do Treino"
                filled
                hide-details
                dense
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="trainingObject.exercise_type"
                label="Tipos de exercícios"
                :items="trainingExerciseTypes"
                return-object
                item-text="name"
                item-color="primary"
                :menu-props="{maxHeight: '200px', nudgeBottom: '64px'}"
                chips
                hide-details
                multiple
                filled
                dense
              >
                <template v-slot:selection="data">
                  <v-chip class="primary">
                    {{data.item.name}}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="trainingObject.muscle_group"
                label="Grupos musculares"
                :loading="loadingMuscleGroups"
                :items="muscleGroupList"
                return-object
                item-text="name"
                item-color="primary"
                :menu-props="{maxHeight: '200px', nudgeBottom: '64px'}"
                chips
                hide-details
                multiple
                filled
                dense
              >
              <template v-slot:selection="data">
                <v-chip class="primary">
                  {{data.item.name}}
                </v-chip>
              </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="title mb-0 pb-0">
              Dias da semana
            </v-col>
          </v-row>
          <v-row class="justify-space-between mx-0">
            <template v-for="(weekday, index) in weekdaysList">
              <v-checkbox :key="`weekday-${index}-${weekday.label}`"
                color="primary" v-model="trainingObject.weekdays"
                :disabled="!availableWeekday(weekday.value)"
                :label="weekday.label"
                :value="weekday.value"></v-checkbox>
            </template>
          </v-row>

          <v-row v-if="trainingObject.training_method && trainingObject.training_method.description">
            <v-col
              cols="12"
            >
              <span>Descrição: {{trainingObject.training_method.description}}</span>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click.stop="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="save()"
          :disabled="!valid"
        >
          {{ isNewItem ? 'Adicionar' : 'Atualizar' }} Treino
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>
import TrainingMethodsService from '@/services/user-training-methods-service.js'
import MuscleService from '@/services/user-muscle-service'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  name: 'create-or-update-training-dialog',
  props: ['dialog', 'editedItem', 'editedIndex'],
  data () {
    return {
      trainingObject: { weekdays: [] },
      weekdaysList: [
        { label: 'D', value: '0' },
        { label: 'S', value: '1' },
        { label: 'T', value: '2' },
        { label: 'Q', value: '3' },
        { label: 'Q', value: '4' },
        { label: 'S', value: '5' },
        { label: 'S', value: '6' }
      ],
      valid: false,
      timeout: null,
      loadingMuscleGroups: false,
      muscleGroupList: [],
      trainingExerciseTypes: [
        { name: 'Alongamento', value: 'stretching' },
        { name: 'Mobilidade', value: 'mobility' },
        { name: 'Liberação Miofascial', value: 'myofascial_release' },
        { name: 'Funcional', value: 'functional' },
        { name: 'Resistido', value: 'resistence' },
        { name: 'Manobras Respiratórias/core', value: 'manobra_resp' },
        { name: 'Exercícios Aeróbicos', value: 'exerc_aerobicos' }
      ],
      // autocomplete
      trainingMethodSelected: {},
      loadingSearch: false,
      searchText: '',
      trainingMethods: []
    }
  },
  computed: {
    ...mapGetters('students', ['mesocycleToBeEvaluated', 'student']),
    isNewItem () {
      return isEmpty(this.editedItem)
    }
  },
  mounted () {
    this.fetchMuscleGroups()
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    save () {
      const payload = {
        ...this.trainingObject,
        muscle_group: this.trainingObject.muscle_group.map(group => {
          return {
            id: group.id,
            name: group.name
          }
        })
      }
      this.setLoader({ show: true })
      if (this.isNewItem) {
        this.mesocycleToBeEvaluated.trainings.push({ ...payload })
      } else if (this.editedIndex >= 0) {
        this.mesocycleToBeEvaluated.trainings.splice(this.editedIndex, 1, {
          ...this.mesocycleToBeEvaluated.trainings[this.editedIndex],
          ...payload
        })
      }

      this.$emit('update', 'Treino salvo com sucesso', 'Erro ao salvar Treino')
      this.setLoader({ show: false })
      this.closeDialog()
    },
    closeDialog () {
      this.trainingObject = {}
      this.searchText = ''
      this.trainingMethodSelected = {}
      this.$emit('update:dialog', false)
    },
    fetchMuscleGroups () {
      this.loadingMuscleGroups = true
      MuscleService.get()
        .then(({ data }) => {
          this.muscleGroupList = data.muscle_groups
        })
        .catch(({ response }) => {
          console.log(response)
        })
        .finally(() => {
          this.loadingMuscleGroups = false
        })
    },
    availableWeekday (value) {
      var trainings
      var available = true
      var isAvailableDay = true
      const WEEKDAYS = {
        'sunday': 0,
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6
      }

      try {
        for (const key in this.student.personal_anamnesis.days_of_week) {
          const dayOfWeek = this.student.personal_anamnesis.days_of_week[key]

          isAvailableDay = parseInt(WEEKDAYS[dayOfWeek]) === parseInt(value) || isAvailableDay
        }

        trainings = [...this.mesocycleToBeEvaluated.trainings]

        if (this.editedIndex >= 0) {
          trainings.splice(this.editedIndex, 1)
        }

        trainings.forEach(training => {
          available = training.weekdays.findIndex(weekday => parseInt(weekday) === parseInt(value)) === -1 && available
        })
      } catch (error) {
        available = true
      }
      return available && isAvailableDay
    }
  },
  watch: {
    editedItem: {
      deep: true,
      handler (newVal, oldVal) {
        if (!isEmpty(newVal)) {
          this.trainingObject = Object.assign({}, { ...newVal })
          this.searchText = newVal.training_method ? newVal.training_method.name : ''
          clearTimeout(this.timeout)
        } else {
          const nextTrainingNumber = this.mesocycleToBeEvaluated.trainings.length || 0
          this.trainingObject = {
            name: `Treino ${String.fromCharCode(65 + nextTrainingNumber)}`,
            exercises: [],
            weekdays: []
          }
        }
      }
    },
    searchText (newVal, oldVal) {
      if (newVal) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          TrainingMethodsService.all({ q: newVal })
            .then(({ data }) => {
              this.trainingMethods = data.training_methods
              this.loadingSearch = false
            })
            .catch((error) => {
              this.setSnackbar({
                color: 'success',
                show: true,
                message: error
              })
            })
        }, 500)
      }
    },
    trainingObject: {
      deep: true,
      handler (newVal) {
        try {
          this.valid = newVal.weekdays.length > 0 && newVal.muscle_group.length > 0 && newVal.exercise_type.length > 0
        } catch (error) {
          this.valid = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .v-menu__content {
    overflow: auto !important;
  }

</style>
