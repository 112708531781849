<template>
  <v-dialog v-model="dialog" persistent width="600" scrollable>
    <v-card>
      <v-card-title class="headline secondary white--text" primary-title>
        <span>Adicionar Desvio</span>

        <v-spacer />

        <v-btn icon dark @click="close()"> <v-icon>mdi-close</v-icon> </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="isValid" lazy-validation>
          <v-col cols="12">
            <v-text-field
              hide-details="auto"
              placeholder="Nome do desvio"
              :rules="[v => (v||'').length > 0 || 'Campo requerido']"
              required
              filled
              dense
              v-model="posturalDeviation.name"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              hide-details="auto"
              label="Tipo do desvio"
              :rules="[v => (v||'').length > 0 || 'Campo requerido']"
              required
              filled
              dense
              v-model="posturalDeviation.type"
              @change="onSelectDeviationType()"
              :items="fields"
            />
          </v-col>
          <v-expand-transition>
            <v-col cols="12" class="mt-0 pt-0" v-if="expanded">
              <span class="error--text" v-if="!hasProperties(posturalDeviation) && !isValid">* erro, você deve selecionar algum desvio</span>
              <v-row v-if="getColumns(posturalDeviation.type) === 2">
                <v-col cols="6">
                  <v-row class="mx-0 justify-center">Esquerda</v-row>
                  <template
                    v-for="(option, idx) in getOptions(posturalDeviation.type, 0)"
                  >
                    <v-select
                      :key="idx"
                      filled
                      dense
                      class="mt-4"
                      :items="option.items"
                      hide-details="auto"
                      clearable
                      v-model="posturalDeviation.properties[option.key]"
                    />
                  </template>
                </v-col>
                <v-col cols="6">
                  <v-row class="mx-0 justify-center">Direita</v-row>
                  <template
                    v-for="(option, idx) in getOptions(posturalDeviation.type, 1)"
                  >
                    <v-select
                      :key="idx"
                      filled
                      dense
                      class="mt-4"
                      :items="option.items"
                      hide-details="auto"
                      clearable
                      v-model="posturalDeviation.properties[option.key]"
                    />
                  </template>
                </v-col>
              </v-row>
              <v-row v-else-if="getColumns(posturalDeviation.type) === 1">
                <v-col cols="12">
                  <template
                    v-for="(option, idx) in getOptions(posturalDeviation.type, 0)"
                  >
                    <v-select
                      :key="idx"
                      filled
                      dense
                      class="mt-4"
                      :items="option.items"
                      hide-details="auto"
                      clearable
                      v-model="posturalDeviation.properties[option.key]"
                    /> </template
                ></v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text color="secondary" @click="close()">cancelar</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="onSave(posturalDeviation)"
          >adicionar desvio
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import validationRules from '@/mixins/validation-rules'
// import ExerciseService from '@/services/admin-exercises-service'
// import { debounce } from 'lodash'
import MuscleService from '@/services/muscle-service.js'
import posturaEvaluationFields from '@/mixins/posturaEvaluationFields'
import { mapActions } from 'vuex'

export default {
  name: 'create-or-update-postural-deviation-dialog',
  props: {
    dialog: Boolean,
    editItem: Object,
    muscleGroupId: Number
  },
  data () {
    return {
      isValid: false,
      posturalDeviation: {},
      expanded: false,
      fields: []
    }
  },
  mounted () {
    try {
      this.fields = Object.keys(posturaEvaluationFields)
    } catch (error) {
      this.fields = []
    }
  },
  watch: {
    editItem (newItem) {
      try {
        var properties = {}
        newItem.properties.forEach(property => {
          properties[property.key] = property.selected
        })
        this.posturalDeviation = {
          ...newItem,
          properties: properties
        }
      } catch (error) {
        this.posturalDeviation = newItem
      } finally {
        this.expanded = !!newItem.type
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    ...mapActions('base', ['setSnackbar', 'setLoader']),
    async onSave (posturalDeviation) {
      this.isValid = await this.$refs.form.validate() && this.hasProperties(posturalDeviation)
      if (this.isValid) {
        const payload = this.getPayload(posturalDeviation)

        this.setLoader({ show: true })
        MuscleService.createOrUpdatePosturalDeviation(payload)
          .then(({ data }) => {
            this.setSnackbar({
              color: 'success',
              show: true,
              message: 'Desvio postural foi vinculado'
            })
            this.$emit('update')
            this.close()
          })
          .catch(({ response }) => {
            this.setSnackbar({
              show: true,
              color: 'error',
              message: response.data.errors.detail
            })
          })
          .finally(() => {
            this.setLoader({ show: false })
          })
      }
    },
    close () {
      this.$emit('update:dialog', false)
    },
    getColumns (type) {
      try {
        return posturaEvaluationFields[type].length
      } catch (error) {
        return -1
      }
    },
    getOptions (type, idx) {
      try {
        return posturaEvaluationFields[type][idx]
      } catch (error) {
        return [{ items: [] }]
      }
    },
    hasProperties (posturalDeviation) {
      try {
        return Object.values(posturalDeviation.properties).filter(value => !!value).length > 0
      } catch (error) {
        return false
      }
    },
    getPayload (posturalDeviation) {
      try {
        const properties = Object.keys(posturalDeviation.properties).map(propertyKey => {
          return {
            key: propertyKey,
            selected: posturalDeviation.properties[propertyKey]
          }
        })
        return {
          postural_deviation: {
            ...posturalDeviation,
            properties: properties.filter(property => property.selected !== undefined)
          },
          muscle_group_id: this.muscleGroupId
        }
      } catch (error) {
        return posturalDeviation
      }
    },
    onSelectDeviationType () {
      this.posturalDeviation.properties = {}
      this.expanded = true
    }
  }
}
</script>
