<template>
  <v-dialog v-model="dialog" persistent width="650">
    <v-form v-model="valid">
      <v-card>
        <v-card-title class="secondary white--text" primary-title>
          {{ modalTitle }} Macrociclo
          <v-spacer></v-spacer>
          <v-btn fab x-small text @click.stop="closeDialog()">
            <v-icon class="white--text">
              mdi-close
            </v-icon>

          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="flex-column px-3 pt-6">
            <!-- <span class="text-uppercase font-weight-medium">
              itens a serem avaliados
            </span> -->
            <!-- <v-divider></v-divider> -->
          </v-row>
          <!-- <v-row>
            <v-switch v-model="editedItem.items_to_be_evaluated"
              :class="[{ 'ml-3': index === 0 }, { 'ml-5': index > 0 }, 'items-to-be-evaluated']"
              v-for="(item, index) in itemsToBeEvaluated" :key="index" :label="item.text" :value="item.value" dense
              color="secondary">
            </v-switch>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="editedItem.name" label='Objetivo da Periodização'
                :rules="[validationRules.required]" filled required />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="hasEvaluatedItem('perimeter')" cols="6">
              <v-select v-model="editedItem.skinfold_protocol" label='Protocolo de Dobras Cutâneas'
                :rules="[validationRules.required]" :items="skinfoldList" no-data-text="Nenhum valor encontrado" filled
                required />
            </v-col>
            <v-col v-if="hasEvaluatedItem('caloric_expenditure')" cols="6">
              <v-select v-model="editedItem.vo2_protocol" label='Teste de VO2' :rules="[validationRules.required]"
                :items="vo2List" no-data-text="Nenhum valor encontrado" filled required />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" text @click.stop="closeDialog()">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()" :disabled="!valid">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validationRules from '@/mixins/validation-rules'
import macrocycleService from '@/services/macrocycle-service'

export default {
  name: 'create-or-update-macro-dialog',
  props: ['editedItem', 'editedIndex', 'dialog'],
  data () {
    return {
      valid: false,
      skinfoldList: [
        { text: 'TG Lohman - 2 Dobras', value: 'TG Lohman - 2 Dobras' },
        { text: 'Guedes - 3 Dobras', value: 'Guedes - 3 Dobras' },
        { text: 'Faulkner - 4 Dobras', value: 'Faulkner - 4 Dobras' },
        { text: 'Mcardle - 4 Dobras', value: 'Mcardle - 4 Dobras' },
        { text: 'Petroski - 4 Dobras', value: 'Petroski - 4 Dobras' },
        { text: 'Pollock - 5 Dobras', value: 'Pollock - 5 Dobras' },
        { text: 'Yuhasz - 6 Dobras', value: 'Yuhasz - 6 Dobras' },
        { text: 'Pollock - 7 Dobras', value: 'Pollock - 7 Dobras' },
        { text: 'Marinha Americana', value: 'Marinha Americana' }
      ],
      vo2List: [
        { text: 'Teste dos 12 minutos (Todos)', value: 'protocol_3' },
        { text: 'Corrida 15 minutos (Ativos, Atletas)', value: 'protocol_5' }
        // { text: 'Caminhada 3000m (Idosos, Obesos)', value: 'protocol_1' },
        // { text: 'Caminhada 1600m (Idosos, Obesos)', value: 'protocol_2' },
        // { text: 'Corrida 2400m (Ativos, Atletas)', value: 'protocol_4' },
      ],
      validationRules,
      itemsToBeEvaluated: [
        { text: 'Perimetria', value: 'perimeter' },
        { text: 'Somatótipo', value: 'somatotype' },
        { text: 'Gasto Calórico', value: 'caloric_expenditure' },
        { text: 'Postura', value: 'posture' }
      ]
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['createMacrocycle', 'updateMacrocycle']),
    save () {
      this.setLoader({ show: true })
      const vm = this
      this.editedItem.items_to_be_evaluated = [
        'posture',
        'caloric_expenditure',
        'perimeter'
      ]
      console.log(this.editedItem)
      macrocycleService
        .createOrUpdate(this.student.id, this.editedItem)
        .then(({ data }) => {
          if (vm.editedIndex >= 0) {
            vm.updateMacrocycle(data.macrocycle)
          } else {
            vm.createMacrocycle(data.macrocycle)
          }
          this.showSnackbar('success', `Periodização ${vm.isNewItem() ? 'cadastrada' : 'atualizada '} com sucesso`)
          this.closeDialog()
        })
        .catch(() => {
          this.showSnackbar('error', `Erro ao ${vm.isNewItem() ? 'cadastrar' : 'atualizar '} Periodização`)
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    closeDialog () {
      this.$emit('update:dialog', false)
    },
    showSnackbar (color, message) {
      this.setSnackbar({
        color,
        message,
        show: true
      })
    },
    isNewItem () {
      return this.editedIndex === -1
    },
    hasEvaluatedItem (value) {
      try {
        let idx = this.editedItem.items_to_be_evaluated.findIndex(item => item === value)
        return idx !== -1 || true
      } catch (error) {
        return false
      }
    }
  },
  computed: {
    ...mapGetters('students', ['student']),
    modalTitle () {
      return this.isNewItem() ? 'Criar' : 'Atualizar'
    }
  }
}
</script>

<style lang="scss">
.items-to-be-evaluated {
  &.v-input--switch {
    .v-input__slot {
      flex-direction: column-reverse;
    }
  }
}
</style>
