<template>
  <v-card
    elevation=0
    style="margin-left: 12%"
    max-width="600"
  >
    <v-card-title>Confirmar Atualização dos dados de Pagamento</v-card-title>
    <v-card-text>
      <p class="subtitle-1" style="white-space: pre-wrap">
        Assim que o seu pagamento for aprovado, te enviaremos um email para que você possa entrar no sistema.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn class="secondary--text"
        @click="$emit('continue')"
      >retornar
      </v-btn>
      <v-spacer/>
      <v-btn class="secondary"
        @click="$emit('finish')"
      >confirmar
      </v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
export default {
}
</script>

<style>

</style>
