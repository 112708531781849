<template>
  <v-container>
    <v-row class="d-flex align-center">
      <v-col cols="5">
        <search-field
          label="Busque por nomes ou emails"
          :loading="loadingSearch"
          @searchText="searchText = $event"
        />
      </v-col>
      <v-spacer></v-spacer>
      <div class="d-flex px-3 justify-between">
        <filter-select
          label="Filtros"
          :filters="filters"
          :selectedFilter.sync="selectedFilter"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" class="mx-2 elevation-2" height="38px" outlined
              @click="fetchSpreadSheet(true)"
              v-bind="attrs"
              v-on="on"
              >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Download planilha com lista de personais</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary" class="mx-1 elevation-2" height="38px" outlined
              @click="currentView = 'card'"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-view-grid</v-icon>
            </v-btn>
          </template>
          <span>Alternar Visualização para cartões</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary" class="mx-1 elevation-2" height="38px" outlined
              @click="currentView = 'list'"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>Alternar Visualização para lista</span>
        </v-tooltip>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary" class="mx-1 elevation-2" height="38px" outlined
              @click="openDashboard"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi mdi-finance</v-icon>
            </v-btn>
          </template>
          <span>Dashboard</span>
        </v-tooltip> -->

        <v-btn
          color="secondary"
          class="mx-2"
          height="38px"
          @click="newPersonal()"
        >
          <v-icon left> mdi-account-plus</v-icon>
          Novo personal
        </v-btn>
      </div>
    </v-row>

    <v-row class="justify-end py-2">
      <span class="secondary--text font-weight-bold px-3">Total de Usuários: {{ totalPages }}</span>
    </v-row>

    <v-row v-if="currentView === 'card'">
      <personal-card
        v-for="personal in users"
        :key="personal.id"
        :personal="personal"
      />
    </v-row>

    <v-row v-if="currentView === 'list'">
      <v-col cols="12">
        <v-row class="border-bottom py-0 px-7 secondary--text font-weight-bold mt-2">
          <v-col cols="3">Nome</v-col>
          <v-col cols="2">Último Login</v-col>
          <v-col cols="3">Email</v-col>
          <v-col cols="2">Plano do Professor</v-col>
          <v-col cols="1">Alunos</v-col>
          <v-col cols="1" style="display: flex; justify-content: center;">Status</v-col>
        </v-row>
        <v-list-item-group>
          <personal-list-item
            v-for="(personal, index) in users"
            :key="personal.id"
            :personal="personal"
            @update-personal-status="updateStatus"
            :class="index % 2 === 1 ? 'bg-list' : ''"
          />
        </v-list-item-group>
      </v-col>
    </v-row>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <dialog-full-screen
      :dialog.sync="dialog"
      :titleName="titleName"
      buttonName="Salvar"
      @button="$refs.personalForm.save()"
    >
      <personal-form
        :dialog.sync="dialog"
        ref="personalForm"
        @updateList="updatePersonalList()"
      />
    </dialog-full-screen>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PersonalCard from '@/components/personal/PersonalCard'
import PersonalService from '@/services/personal-node-service'
import SearchField from '@/components/base/SearchField'
import FilterSelect from '@/components/base/FilterSelect'
import InfiniteLoading from 'vue-infinite-loading'
import PersonalListItem from '@/components/personal/PersonalListItem'
import DialogFullScreen from '@/components/base/DialogFullScreen'
import PersonalForm from '@/components/personal/PersonalForm'

export default {
  components: {
    FilterSelect,
    SearchField,
    PersonalCard,
    InfiniteLoading,
    DialogFullScreen,
    PersonalForm,
    PersonalListItem
  },
  name: 'personal-list',
  created () {
    this.resetInfinityLoadAndPagination()
  },
  data: () => ({
    personal: {},
    loadingSearch: false,
    searchText: '',
    titleName: 'Novo Personal',
    nextPage: 1,
    totalPages: 0,
    resetList: false,
    state: null,
    currentView: 'card',
    filters: [
      {
        name: 'Todos',
        prop: undefined
      },
      {
        name: 'Ativos',
        prop: 'true'
      },
      {
        name: 'Inativos',
        prop: 'false'
      }
    ],
    selectedFilter: {
      name: 'Ativos',
      prop: 'true'
    },
    dialog: false
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar', 'fetchSpreadSheet']),
    ...mapActions('users', ['setUsers', 'addUsers']),
    ...mapActions('personal', ['setPersonal', 'resetPersonal', 'addPersonal']),
    infiniteHandler ($state) {
      this.state = $state
      this.resetList = false
      if (this.nextPage === null) {
        $state.complete()
      } else {
        this.getPersonais()
      }
    },
    getPersonais () {
      this.setLoader({ show: true })
      this.loadingSearch = true

      PersonalService.getPersonals(
        {
          q: this.searchText,
          page: this.nextPage,
          active: this.selectedFilter.prop,
          limit: 24
        })
        .then(({ data }) => {
          if (data.users.length > 0) {
            if (this.resetList) {
              this.setUsers([])
            }
            this.addUsers(data.users)
            this.nextPage = data.meta.next_page
            this.totalPages = data.meta.total_count
            this.state.loaded()
          } else {
            this.setUsers([])
            this.state.complete()
          }
        })
        .catch((error) => {
          console.log(error.response)
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao buscar os personais. Tente novamente mais tarde.'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.loadingSearch = false
        })
    },
    updateStatus (status, id) {
      console.log('status', status, 'id', id)
      this.setLoader({ show: true })
      PersonalService.updatePersonal({ is_active: status }, id)
        .then(() => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Status atualizado com sucesso!'
          })
          this.resetInfinityLoadAndPagination()
        })
        .catch(({ error }) => {
          console.log(error.response)
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao atualizar status. Tente novamente mais tarde.'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    resetInfinityLoadAndPagination () {
      if (this.state) {
        this.state.reset()
      }
      this.setUsers([])
      this.nextPage = 1
      this.resetList = true
    },
    updatePersonalList () {
      this.resetList = true
      this.getPersonais()
    },
    newPersonal () {
      this.resetPersonal()
      this.titleName = 'Novo Personal'
      this.dialog = true
    },
    editStudent () {
      this.titleName = 'Editar Personal'
      this.dialog = true
    }
  },
  computed: {
    ...mapGetters('users', ['users'])
  },
  watch: {
    selectedFilter (val) {
      this.resetInfinityLoadAndPagination()
    },
    searchText (val) {
      this.resetInfinityLoadAndPagination()
    }
  }
}
</script>

<style scoped>
  .bg-list {
    background-color: #e2e3ff;
  }
</style>
