<template>
  <div
    class="d-flex flex-column elevation-3 pa-5"
  >
    <v-row>
      <v-col>
        <span class="text-uppercase font-weight-medium">IMC</span>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <div v-if="canCalculate()">
      <v-row class="d-flex flex-row">
        <v-col
          class="text-center pa-0"
        >
          <img
            height="180"
            src="@/assets/images/student-profile/orange-student.png"
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
        <span class="display-1">
          <strong>{{IMC.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong>
        </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pt-0 text-uppercase"
        >
          {{nivelIMC.name}}
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <p v-for="(message, index) in messages" :key="index"> {{ message }}</p>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
  import { mapGetters } from 'vuex'
  import { get } from 'lodash'

  export default {
    name: 'imc',
    data() {
      return {}
    },
    methods: {
      canCalculate(){
        if (this.stature && this.weight){
          return true
        } else {
          return false
        }
      },
    },
    mounted() {
      this.mesocycleToBeEvaluated.evaluated_items.perimeter.imc = {
        value: this.IMC.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
        label: this.nivelIMC.name
      }
    },
    watch: {
      messages(val) {
        let data = {}

        if (val.length > 0){
          data = { messages: val };
        } else {
          data = {
            value: this.IMC.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            label: this.nivelIMC.name
          }
        }

        this.mesocycleToBeEvaluated.evaluated_items.perimeter.imc = { ...data }
      }
    },
    computed: {
      ...mapGetters('students', ['student', 'mesocycleToBeEvaluated']),
      messages() {
        const messages = []

        if (!this.weight) {
          messages.push('Peso não informado')
        }
        if (!this.stature) {
          messages.push('Altura não informada')
        }

        return messages;
      },
      stature() {
        if (get(this.mesocycleToBeEvaluated, 'evaluated_items.student.stature'))
          return parseFloat(get(this.mesocycleToBeEvaluated, 'evaluated_items.student.stature').replace(',', '.'))
        return null
      },
      weight() {
        if (get(this.mesocycleToBeEvaluated, 'evaluated_items.student.weight'))
          return parseFloat(get(this.mesocycleToBeEvaluated, 'evaluated_items.student.weight').replace(',', '.'))
        return null
      },
      IMC() {
        return (this.weight / (Math.pow(this.stature, 2)))
      },
      nivelIMC() {
        const imc = this.IMC

        if (imc <= 18.5)
          return {name: 'Abaixo do Peso', img: ''};

        if (imc <= 24.9)
          return {name: 'Ideal', img: ''};

        if (imc <= 29.9)
          return {name: 'Sobrepeso', img: ''};

        if (imc <= 35)
          return {name: 'Obesidade Grau 1', img: ''};

        if (imc <= 39.9)
          return {name: 'Obsidade Grau 2', img: ''};

        if (imc > 39.9)
          return {name: 'Obsidade Grau 3', img: ''};

        return {}
      }
    },
  }
</script>

<style scoped>
</style>
