<template>
  <v-container class="dashboardContainer" style="height: 90vh;">
    <v-col>
      <!-- <v-row>
        <v-col cols="12">
          <btn-back />
        </v-col>
      </v-row> -->
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="9" class="pt-0">
          <v-card class="container50 bg">
            <v-card-title class="d-flex justify-space-between pb-0">
              Faturamento
              <!-- <div class="faturamento">
                Faturamento
                <p class="faturamentoTotalPeriodo">
                  Faturamento total no período selecionado:
                </p>
                <p>
                  R$ {{ dashboardData.total_income_period ?
                    dashboardData.total_income_period.toFixed(2) : 0 }}
                </p>
              </div> -->
              <!-- código a baixo é para um calendario que permite selecionar data por dia -->
              <div class="calendarContainer">
                <v-menu offset-y v-model="menu" class="calendar">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="formattedDate" label="Selecione um período" prepend-icon="mdi-calendar"
                      color="secondary" readonly v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dates" :min="minDate" range @input="handleInput" @click.native.stop
                    locale="pt-br" color="secondary">
                  </v-date-picker>
                </v-menu>
                <v-btn @click="getRangeDate" color="secondary">
                  Filtrar
                </v-btn>
              </div>
              <!-- ------------------------------------------------ -->

            </v-card-title>
            <div class="custom-card">
              <line-chart :chartData="computedMonthlyIncomes" :dateType="determinegraphType()" />
            </div>
          </v-card>

          <v-card class="container25">
            <v-card-title class="pb-0">
              Formas de pagamento
            </v-card-title>
            <div class="custom-card">
              <bar-chart :chartData="paymentMethods" />
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="pt-0">
          <v-card class="container50 statusContainer">
            <v-card-title class="titleCard">
              Status De Pagamento
            </v-card-title>
            <div class="custom-card">
              <pie-chart v-if="invoiceStatus.length" :chartData="invoiceStatus" />
            </div>
          </v-card>
          <info-card-dashboard :goToPage="goToPage" :avatarUrl="avatarUrl" :name="personalName"
            :valueInfo="personalStudents" :typeInfo="'Alunos'" :title="'Professor com mais alunos'" />
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import LineChart from '@/components/dashboard/LineChart.vue'
import PieChart from '@/components/dashboard/PieChart.vue'
import { mapActions } from 'vuex'
import { getPersonalDashboard } from '@/services/dashboard-service'
import BarChart from '@/components/dashboard/BarChart.vue'
import InfoCardDashboard from '@/components/base/InfoCardDashboard.vue'
// import btnBack from '@/components/base/BtnBack.vue'

export default {
  name: 'personal-dashboard',
  components: {
    LineChart,
    PieChart,
    BarChart,
    InfoCardDashboard
    // btnBack
  },
  data: () => {
    const currentYear = new Date().getFullYear()
    return {
      selectedStartDate: `${currentYear}-01-01`,
      selectedEndDate: `${currentYear}-12-31`,
      dashboardData: {},
      formattedDate: '',
      menu: false,
      dates: [],
      minDate: null,
      graphType: 'monthly'
    }
  },
  mounted () {
    this.getDashboardData(this.selectedStartDate, this.selectedEndDate)
    this.formattedDate = `${this.formatDateForDisplay(this.selectedStartDate)} - ${this.formatDateForDisplay(this.selectedEndDate)}`
  },
  computed: {
    computedMonthlyIncomes () {
      if (this.dashboardData && this.dashboardData.monthly_incomes) {
        return this.dashboardData.monthly_incomes.map(item => ({
          ...item,
          value: parseFloat(item.value.toFixed(2)),
          date: this.formatDateForGraph(item.date)
        }))
      }
      return []
    },
    avatarUrl () {
      return this.dashboardData && this.dashboardData.personal_with_most_active_students && this.dashboardData.personal_with_most_active_students.photo_url
        ? this.dashboardData.personal_with_most_active_students.photo_url
        : require('@/assets/images/user-placeholder.png')
    },
    personalName () {
      return this.dashboardData && this.dashboardData.personal_with_most_active_students && this.dashboardData.personal_with_most_active_students.name
        ? this.dashboardData.personal_with_most_active_students.name
        : 'Nome Indisponível'
    },
    paymentMethods () {
      if (this.dashboardData && this.dashboardData.payment_methods_used) {
        return Object.entries(this.dashboardData.payment_methods_used).map(([method, value]) => {
          return {
            method: method === 'credit_card' ? 'Crédito' : this.capitalizeLetter(method),
            value: parseFloat(value.toFixed(2))
          }
        })
      }
      return []
    },
    personalStudents () {
      return this.dashboardData && this.dashboardData.personal_with_most_active_students && this.dashboardData.personal_with_most_active_students.students
        ? this.dashboardData.personal_with_most_active_students.students
        : 0
    },
    invoiceStatus () {
      if (this.dashboardData && this.dashboardData.invoice_status_percentage) {
        return this.dashboardData.invoice_status_percentage.map(item => {
          return {
            ...item,
            percentage: item.percentage != null ? parseFloat(item.percentage.toFixed(2)) : 0
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),

    getDashboardData (startDate, endDate) {
      this.setLoader({ show: true })
      this.determinegraphType()
      getPersonalDashboard(startDate, endDate, this.graphType)
        .then(response => {
          this.setLoader({ show: false })
          this.dashboardData = response.data
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao buscar dados do dashboard'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },

    capitalizeLetter (input) {
      if (typeof input === 'string') {
        return input.charAt(0).toUpperCase() + input.slice(1)
      }
      return input
    },

    getRangeDate () {
      if (this.dates.length === 2) {
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        this.getDashboardData(startDate, endDate)
      } else {
        this.setSnackbar({
          color: 'error',
          show: true,
          message: 'Selecione um intervalo de datas.'
        })
      }
    },

    keepOpen () {
      this.$nextTick(() => {
        this.menu = true
      })
    },

    handleInput (dates) {
      this.dates = dates
      if (dates.length === 1) {
        this.minDate = dates[0]
      } else if (dates.length === 2) {
        this.formattedDate = `${this.formatDateForDisplay(this.dates[0])} - ${this.formatDateForDisplay(this.dates[1])}`
        this.menu = false
        this.minDate = null
      }
    },

    formatDateForDisplay (date) {
      const parts = date.split('-').map(part => parseInt(part, 10))
      const localDate = new Date(parts[0], parts[1] - 1, parts[2])
      return `${localDate.getDate().toString().padStart(2, '0')}/${(localDate.getMonth() + 1).toString().padStart(2, '0')}/${localDate.getFullYear()}`
    },

    formatDateForGraph (date) {
      const parts = date.split('-').map(part => parseInt(part, 10))
      const utcDate = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]))
      return `${(utcDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${utcDate.getUTCDate().toString().padStart(2, '0')}/${utcDate.getUTCFullYear()}`
    },

    determinegraphType () {
      if (this.dates.length === 2) {
        const startDate = new Date(this.dates[0])
        const endDate = new Date(this.dates[1])
        const oneMonthLater = new Date(startDate)
        oneMonthLater.setMonth(startDate.getMonth() + 1)

        if (endDate > oneMonthLater) {
          this.graphType = 'monthly'
        } else {
          this.graphType = 'daily'
        }
      }
      return this.graphType
    },

    goToPage () {
      this.$router.push({ name: 'PersonalShow', params: { id: this.dashboardData.personal_with_most_active_students.id } })
    }
  }
}

</script>

<style scoped>
.faturamento>p {
  margin: 0;
  line-height: 25px;
}

.faturamentoTotalPeriodo {
  font-size: 14px;
}

.calendarContainer {
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: fit-content;
}

.custom-card {
  height: 80%;
  padding: 10px;
}

.container50 {
  height: 100%;
  max-height: 50vh;
  width: 100%;
  margin-bottom: 20px;
}

.container25 {
  height: 100%;
  max-height: 24vh;
  width: 100%;
}

.card-data {
  height: 75%;
}

.users-data {
  border-left: 4px solid #8b84e2;
  width: 100%;
}

.users-data:hover {
  background: #f4f4f4;
  cursor: pointer;
}

.filter {
  gap: 10px;
  width: 40%;
}

.dashboardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1300px) {
  .titleCard {
    font-size: 16px;
  }
}

@media screen and (max-width: 960px) {
  .dashboardContainer {
    align-items: start;
  }

  .statusContainer {
    max-height: 40vh;
  }

}
</style>
