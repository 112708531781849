<template>
  <v-card class="container25">
    <v-card-title class="custom-title">
      {{ title }}
    </v-card-title>
    <v-card-text class="card-data" style="height: 60%;">
      <div class="users-data fill-height px-3" @click="goToPage">
        <div class="infos">
          <v-avatar class="ml-2 imageAvatar">
            <v-img class="imageAvatar" :src="avatarUrl" alt="Avatar"></v-img>
          </v-avatar>
          <span class="textName">{{ name }}</span>
        </div>
        <span class="headline font-weight-bold text-secondary px-2">{{ valueInfo }} <span
            class="body-1 font-weight-medium ">{{ typeInfo }}</span></span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Info-card-dashboard',
  props: {
    title: {
      type: String,
      default: ''
    },
    avatarUrl: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    valueInfo: {
      type: Number,
      default: 0
    },
    typeInfo: {
      type: String,
      default: ''
    },
    goToPage: {
      type: Function,
      default: () => { }
    }
  }
}

</script>

<style>
.custom-title {
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  font-size: 18px;

}

.container25 {
  height: 100%;
  max-height: 24vh;
  width: 100%;
}

.users-data {
  border-left: 4px solid #8b84e2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}

.infos {
  display: flex;
  align-items: center;
}

.users-data:hover {
  background: #f4f4f4;
  cursor: pointer;
}

.textName {
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  overflow: hidden;
}

@media screen and (max-width: 1300px) {
  .custom-title {
    font-size: 14px;
    line-height: 18px;
  }

  .textName {
    font-size: 12px;
    line-height: 16px;
  }

  .imageAvatar {
    display: none;
  }
}
</style>
