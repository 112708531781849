import axios from './axios-instance'

export default {
  login (payload) {
    return axios.post('v1/auth/login', { ...payload, version: '1.0.3' })
  },
  logout () {
    localStorage.clear()
    return axios.delete('v1/auth/logout')
  },
  register (payload) {
    return axios.post('v1/auth/registration', payload)
  },
  me () {
    return axios.get(`v1/auth/me`, {
      params: {
        version: '1.0.3'
      }
    })
  },
  confirmRegister (token) {
    return axios.get(`v1/auth/confirm_register?token=${token}`)
  },
  confirmUserAccount (token, password) {
    return axios.put(`v1/auth/confirm_user_password?token=${token}`, { password })
  },
  resetPasswordInstructions (payload) {
    return axios.post('v1/auth/reset_password_instructions', payload)
  },
  resetPassword (token, payload) {
    return axios.post(`v1/auth/reset_password?token=${token}`, payload)
  },
  getStudentPersonal () {
    return axios.get(`v1/auth/get_student_personal`)
  },
  plans () {
    return axios.get('v1/plans')
  },
  newConfirmRegisterCode (payload) {
    return axios.post('v1/auth/new_confirm_register_code', payload)
  },
  confirmResetPasswordCode (payload) {
    return axios.post('v1/auth/confirm_reset_password_code', payload)
  }
}
