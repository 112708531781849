import { isMatch, differenceInCalendarYears, parse } from 'date-fns'

const validationRules = {
  email: v => /.+@.+/.test(v) || 'E-mail deve ser válido',
  cpf: v => (v || '').length === 14 || 'CPF deve conter 11 Dígitos',
  cep: v => (v || '').length === 9 || 'CEP deve conter 8 Dígitos',
  creditCard: v => (v || '').length === 19 || 'Número do Cartão de Crédito deve conter 16 Dígitos',
  required: v => !!v || 'Este campo não pode ficar em branco',
  time: v => (!v || v.length === 5 || v.length === 8) || 'Campo com formato inválido',
  timeRequired: v => parseInt((v || '').replace(/:/g, '')) > 0 || 'Tempo inválido',
  cellphoneSize: v => (!v || v.length === 16) || 'Campo com formato inválido',
  date: v => (v.length === 10 && isMatch(v, 'dd/MM/yyyy')) || 'Data inválida',
  age: v => {
    const result = differenceInCalendarYears(new Date(), parse(v, 'dd/MM/yyyy', new Date()))
    return (result < 101 && result > 0) || 'Data inválida'
  },
  passwordSize (v) {
    return (v || '').length >= 8 || 'Senha deve conter no mínimo 8 caracteres'
  }
}

export default validationRules
