<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn text class="transparent text-none text--secondary mx-0 px-0" @click="$router.go(-1)">
          <v-icon left>mdi-chevron-left</v-icon> Voltar para planos</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="title font-weight-black">
        {{ isEditMode ? 'Editar plano' : 'Cadastrar plano' }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <v-stepper v-model="position" vertical>

          <v-stepper-step :complete="position > 1" step="1">
            Nome do plano
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card elevation="0">
              <v-col cols="12" lg="8">
                <v-text-field filled placeholder="Nome do plano"
                  :rules="[v => (v || '').length > 0 || 'Campo obrigatório']" v-model="planForm.name" />

              </v-col>
            </v-card>
            <v-col cols="12" lg="8" class="buttons-container">
              <v-btn color="primary" @click="position = planForm.name.length > 0 ? 2 : 1">Continuar</v-btn>
            </v-col>
          </v-stepper-content>

          <v-stepper-step :complete="position > 2" v-if="true" step="2">
            Valor do plano
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card elevation="0" v-if="planForm.name.length > 0">
              <v-col cols="12" lg="8">
                <v-text-field filled placeholder="Valor do plano" prefix="R$ "
                  :rules="[v => (v || '').length > 0 || 'Campo obrigatório']" v-model="planForm.value"
                  @input="formatCurrency" />
              </v-col>
            </v-card>
            <v-col cols="12" lg="8" class="buttons-container">
              <v-btn text @click="position = 1">Voltar</v-btn>
              <v-btn color="primary" @click="position = planForm.value.length > 0 ? 3 : 2">Continuar</v-btn>
            </v-col>
          </v-stepper-content>

          <v-stepper-step :complete="position > 3" v-if="true" step="3">
            Frequência do plano
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card elevation="0" v-if="planForm.value.length > 0">
              <v-col cols="12" lg="8">
                <v-select filled placeholder="Frequência do plano" :items="frequencies" item-text="text"
                  item-value="value" :rules="[v => (v || '').length > 0 || 'Campo obrigatório']"
                  v-model="planForm.frequency" />
              </v-col>
            </v-card>
            <v-col cols="12" lg="8" class="buttons-container">
              <v-btn text @click="position = 2">Voltar</v-btn>
              <v-btn color="primary" @click="position = planForm.frequency.length > 0 ? 4 : 3">Continuar</v-btn>
            </v-col>
          </v-stepper-content>

          <v-stepper-step :complete="position > 4" v-if="true" step="4">
            Descrição do plano
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-card elevation="0" v-if="planForm.frequency.length > 0">
              <v-col cols="12" lg="8">
                <v-textarea filled placeholder="Descrição do plano" v-model="planForm.description" />
              </v-col>
            </v-card>
            <v-col cols="12" lg="8" class="buttons-container">
              <v-btn text @click="position = 3">Voltar</v-btn>
              <v-btn color="primary" @click="onFinish">Finalizar</v-btn>
            </v-col>
          </v-stepper-content>

        </v-stepper>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import PlanService from '@/services/plan-service.js'
export default {
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    planToEdit: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      position: 1,
      frequencies: [
        { text: 'Mensal', value: 'monthly' },
        { text: 'Bimestral', value: 'bimonthly' },
        { text: 'Semestral', value: 'semiannually' },
        { text: 'Anual', value: 'yearly' }
      ],
      planForm: {
        name: '',
        value: '',
        frequency: '',
        description: ''
      }
    }
  },
  created () {
    if (this.isEditMode && this.planToEdit) {
      this.planForm = cloneDeep(this.planToEdit)
      this.position = 1
    }
  },
  computed: {
    userId () {
      return this.$store.getters['auth/user'].id
    },
    isUserPersonal () {
      return this.$store.getters['auth/user'].isPersonal()
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),

    formatCurrency () {
      let value = this.planForm.value || ''
      value = value.replace(/\D/g, '')
      if (!value) {
        value = '0'
      }
      value = (parseFloat(value) / 100).toFixed(2)
      let parts = value.split('.')
      let integerPart = parts[0]
      let decimalPart = parts[1]

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      value = integerPart + ',' + decimalPart
      this.planForm.value = value
    },

    onFinish () {
      this.setLoader({ show: true })
      const planClone = cloneDeep(this.planForm)
      planClone.value = parseFloat(planClone.value.replace(/\./g, '').replace(',', '.'))

      if (this.isEditMode) {
        const formatPlanForUpdate = {
          name: planClone.name,
          value: planClone.value,
          frequency: planClone.frequency,
          description: planClone.description,
          personal_id: this.planToEdit.personal_id
        }

        PlanService.updatePlan(this.planForm.id, formatPlanForUpdate)
          .then(() => {
            this.setSnackbar({
              show: true,
              message: 'Plano atualizado com sucesso',
              color: 'success'
            })
            if (this.isUserPersonal) {
              this.$router.push({
                name: 'PlansPersonalList'
              })
            } else {
              this.$router.push({
                name: 'PlansList'
              })
            }
          })
          .catch((e) => {
            console.error(e.response)
            this.setSnackbar({
              show: true,
              message: 'Erro ao atualizar plano',
              color: 'error'
            })
          })
          .finally(() => {
            this.setLoader({ show: false })
          })
      } else {
        PlanService.addPlan(planClone)
          .then(() => {
            this.setSnackbar({
              show: true,
              message: 'Plano cadastrado com sucesso',
              color: 'success'
            })
            if (this.isUserPersonal) {
              this.$router.push({
                name: 'PlansPersonalList'
              })
            } else {
              this.$router.push({
                name: 'PlansList'
              })
            }
          })
          .catch((e) => {
            console.error(e.response)
            this.setSnackbar({
              show: true,
              message: 'Erro ao cadastrar plano',
              color: 'error'
            })
          })
          .finally(() => {
            this.setLoader({ show: false })
          })
      }
    }
  }

}
</script>

<style>
.buttons-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
  padding-bottom: 0;

}
</style>
