<template>
  <v-btn text @click="back" class="px-0">
    <v-icon>mdi mdi-arrow-left</v-icon>
    <span>Voltar</span>
  </v-btn>
</template>

<script>
export default {
  name: 'btn-back',
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>
