<template>
  <v-card
    elevation=0
    style="margin-left: 12%"
    max-width="600"
  >
    <v-card-title>
      Renovação do plano
    </v-card-title>
    <v-card-text>
      <p class="subtitle-1" style="white-space: pre-wrap">
        Tivemos um problema com a renovação do seu plano.<br/><br/> Para voltar a ter acesso o sistema você deve entrar em contato com o responsável do sistema.
      </p>
    </v-card-text>

    <v-card-actions>
      <v-btn outlined color="secondary" @click="$router.push({name: 'Login'})">
        Ir para Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'message-card'
}
</script>

<style>

</style>
