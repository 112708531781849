import axios from './axios-instance'

export default {
  async all (studentId, macrocycleId) {
    const { data } = await axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles`)
    return data
  },

  evaluationsResult (studentId, macrocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles`)
  },

  getMesocycle (studentId, macrocycleId, mesocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}`)
  },
  getMesocycleEvaluation (studentId, macrocycleId, mesocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/evaluation`)
  },
  getMesocycleTraining (studentId, macrocycleId, mesocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/trainings`)
  },
  createOrUpdate (studentId, macrocycleId, payload) {
    if (payload.id === null) {
      return axios.post(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles`, payload)
    } else {
      return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${payload.id}`, payload)
    }
  },
  mesocycleSaveEvaluation (studentId, macrocycleId, payload) {
    return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${payload.id}`, payload)
  },
  uploadPhoto (studentId, macrocycleId, mesocycleId, formData) {
    return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}`, formData)
  },
  destroy (studentId, macrocycleId, id) {
    return axios.delete(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${id}`)
  },
  removeTraining (mesocycleId, trainingIdx) {
    return axios.put(`v1/mesocycles/${mesocycleId}/training/${trainingIdx}`)
  },
  getFinalResult (studentId, macrocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/`)
  },
  selectItemToCopy (studentId, id, origin) {
    return axios.post(`v1/training_copy?idStudent=${studentId}&idMesocycles=${id}&idMesocyclesOrigin=${origin}`)
  },
  getmesocyclesList (studentId) {
    return axios.get(`v1/mesocyclesList/${studentId}`)
  }
}
