import { format, parseISO } from 'date-fns'

export const formatDateToBr = (date) => {
  if (!date) return null

  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

export const formatBrazilianDate = (date) => {
  if (!date) return null

  return format(parseISO(date), 'dd/MM/yyyy')
}

export default formatDateToBr
