<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="700"
    >

      <v-card>
        <v-card-title
          class="headline secondary white--text"
          primary-title
        >
          {{dialogTitle()}} Exercício
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col>
                    <v-text-field
                      dense
                      v-model="name"
                      :rules="[validationRules.required]"
                      label="Nome"
                      required
                    >
                    </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <v-text-field
                    dense
                    label="Link"
                    v-model="link">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <v-text-field
                    dense
                    label="Equipamento necessário"
                    v-model="equipamentNeeded">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-autocomplete
                    dense
                    :height="40"
                    v-model="exerciseType"
                    :items="exerciseTypes"
                    :rules="[validationRules.required]"
                    label="Tipo de Exercício"
                    item-text="name"
                    item-value="value"
                    no-data-text="Nenhum item encontrado"
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-if="needsMuscleGroup"
                    v-model="muscleGroupIds"
                    label="Grupos musculares"
                    :loading="loadingMuscleGroups"
                    :items="muscleGroupList"
                    return-object
                    item-text="name"
                    item-color="primary"
                    :menu-props="{maxHeight: '200px', nudgeBottom: '64px'}"
                    :rules="[arrayIsEmpty]"
                    chips
                    hide-details
                    multiple
                    filled
                    dense >
                  <template v-slot:selection="data">
                    <v-chip class="primary">
                      {{data.item.name}}
                    </v-chip>
                  </template>
                  </v-select>
                </v-col>
              </v-row>
               <v-row>
                <v-col>
                  <v-select
                    v-if="needsPostural"
                    v-model="posturalDeviationsIds"
                    label="Desvios Posturais"
                    :loading="loadingPosturalDeviations"
                    :items="posturalDeviationsList"
                    return-object
                    item-text="name"
                    item-color="primary"
                    :menu-props="{maxHeight: '200px', nudgeBottom: '64px'}"
                    :rules="[arrayIsEmpty]"
                    chips
                    hide-details
                    multiple
                    filled
                    dense >
                  <template v-slot:selection="data">
                    <v-chip class="primary">
                      {{data.item.name}}
                    </v-chip>
                  </template>
                  </v-select>

                </v-col>
              </v-row>
            </v-container>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="loading"
            @click="close(false)">
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="!valid"
            :loading="loading"
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validationRules from '@/mixins/validation-rules'
import AdminExerciseService from '@/services/admin-exercises-service'

import MuscleService from '@/services/user-muscle-service'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'create-or-update-exercise-admin-dialog',
  props: ['editedItem', 'dialog'],
  data () {
    return {
      valid: false,
      loading: false,
      loadingMuscleGroups: false,
      loadingPosturalDeviations: false,
      muscleGroupIds: [],
      posturalDeviationsIds: [],
      muscleGroupList: [],
      posturalDeviationsList: [],
      validationRules,
      id: undefined,
      name: '',
      link: '',
      equipamentNeeded: '',
      exerciseType: '',
      workedMuscleGroups: '',
      exerciseTypes: [
        // { name: 'Aeróbico Contínuo', value: 'aerobic' },
        // { name: 'Hiit', value: 'hiit' },
        { name: 'Mobilidade', value: 'mobility' },
        { name: 'Alongamento', value: 'stretching' },
        { name: 'Resistido', value: 'resistence' },
        { name: 'Liberação Miofascial', value: 'myofascial_release' },
        { name: 'Funcional', value: 'functional' },
        { name: 'Manobras Respiratórias/core', value: 'manobra_resp' },
        { name: 'Exercícios Aeróbicos', value: 'exerc_aerobicos' }],
      workedMuscleGroupsList: [
        { name: 'Bíceps', value: 'Bíceps' },
        { name: 'Tríceps', value: 'Tríceps' },
        { name: 'Dorsais', value: 'Dorsais' },
        { name: 'Peitoral', value: 'Peitoral' },
        { name: 'Deltóides', value: 'Deltóides' },
        { name: 'Abdômen', value: 'Abdômen' },
        { name: 'Glúteos', value: 'Glúteos' },
        { name: 'Isquiotibaiais', value: 'Isquiotibaiais' },
        { name: 'Adutores', value: 'Adutores' },
        { name: 'Quadríceps', value: 'Quadríceps' },
        { name: 'Pantirrilhas', value: 'Pantirrilhas' },
        { name: 'Core', value: 'Core' }
      ]
    }
  },
  mounted () {
    this.fetchMuscleGroups()
    this.fetchPosturalDeviations()
  },
  methods: {
    ...mapActions('base', ['setSnackbar']),
    dialogTitle () {
      return this.isNewItem ? 'Novo' : 'Editar'
    },
    save () {
      this.loading = true
      const muscleGroupIds = this.muscleGroupIds.length > 0 ? this.muscleGroupIds.map(muscleGroup => muscleGroup.id) : []
      const posturalDeviationsIds = this.posturalDeviationsIds.length > 0 ? this.posturalDeviationsIds.map(posturalDeviations => posturalDeviations.id) : []

      const payload = {
        id: this.id,
        name: this.name,
        link: this.link,
        exercise_type: this.exerciseType,
        equipament_needed: this.equipamentNeeded,
        muscle_group_ids: muscleGroupIds,
        postural_deviation_ids: posturalDeviationsIds
      }
      AdminExerciseService
        .createOrUpdate(payload)
        .then(({ data }) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Exercício salvo com sucesso'
          })
          this.close(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    close (state) {
      this.$emit('close', state)
    },
    fetchMuscleGroups () {
      this.loadingMuscleGroups = true
      MuscleService.get()
        .then(({ data }) => {
          this.muscleGroupList = data.muscle_groups
        })
        .catch(({ response }) => {
          console.log(response)
        })
        .finally(() => {
          this.loadingMuscleGroups = false
        })
    },
    fetchPosturalDeviations () {
      this.loadingPosturalDeviations = true
      AdminExerciseService.getPostural()
        .then(({ data }) => {
          this.posturalDeviationsList = data.postural_deviations
        })
        .catch(({ response }) => {
          console.log(response)
        })
        .finally(() => {
          this.loadingPosturalDeviations = false
        })
    },
    arrayIsEmpty (array = []) {
      return array.length > 0 || 'Este campo não pode ficar em branco'
    }
  },
  computed: {
    isNewItem () {
      return this.editedItem.id === undefined
    },
    needsMuscleGroup () {
      return this.exerciseType !== 'mobility' && this.exerciseType !== 'stretching' && this.exerciseType !== 'exerc_aerobicos' && this.exerciseType !== 'manobra_resp'
    },
    needsPostural () {
      return this.exerciseType === 'mobility' || this.exerciseType === 'stretching'
    },
    ...mapGetters('auth', ['user'])
  },
  watch: {
    editedItem (newValue) {
      if (newValue) {
        if (newValue.id !== undefined) {
          this.id = newValue.id
        } else {
          this.id = null
        }
        this.searchText = ''
        this.name = newValue.name
        this.link = newValue.link
        this.exerciseType = newValue.exercise_type
        this.equipamentNeeded = newValue.equipament_needed
        this.muscleGroupIds = newValue.muscle_groups || []
        this.posturalDeviationsIds = newValue.postural_deviations || []
      }
    }
  }
}
</script>
