<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="700"
    >

      <v-card>
        <v-card-title
          class="headline secondary white--text"
          primary-title
        >
          Selecionar Exercício
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row dense v-if="disableFields">
                <v-col>
                  <v-autocomplete
                    :height="40"
                    v-model="exercise"
                    :items="exerciseList"
                    :loading="searchLoading"
                    :search-input.sync="searchExercise"
                    label="Selecione o exercicio"
                    item-text="name"
                    return-object
                  >
                    <template v-slot:no-data v-if="searchExercise && !searchLoading">
                      <v-row justify="center" align="center">
                        <v-col cols=6 class="text-center">
                          O exercício procurado não existe em nossa base de dados,<br/> deseja cadastrar agora?
                          <v-btn color="primary" @click="createMode(searchExercise)">Cadastrar agora</v-btn>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:no-data v-else>
                      <v-row justify="center" align="center">
                        <v-col cols=6 class="text-center">
                          <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
                        </v-col>
                      </v-row>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col>
                    <v-text-field
                      v-model="exercise.name"
                      label="Nome"
                      hide-details
                      :disabled="disableFields"
                      filled
                      required
                    >
                    </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col >
                  <v-text-field
                    label="Link"
                    hide-details
                    :disabled="disableFields"
                    filled
                    v-model="exercise.link">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col >
                  <v-text-field
                    label="Equipamento necessário"
                    :disabled="disableFields"
                    hide-details
                    filled
                    v-model="exercise.equipament_needed">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="mb-n4">
                <v-col>
                  <v-autocomplete
                    :height="40"
                    v-model="exercise.exercise_type"
                    :items="exerciseTypes"
                    :disabled="disableFields"
                    filled
                    label="Tipo de Exercício"
                    hide-details
                    item-text="name"
                    item-value="value"
                    no-data-text="Nenhum item encontrado"
                  />
                </v-col>
              </v-row>
            </v-container>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="loading"
            @click="close(false)">
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!disableFields"
            color="primary"
            text
            :disabled="!valid"
            :loading="loading"
            @click="save(exercise)"
          >
            Salvar
          </v-btn>
          <v-btn v-else
            color="primary"
            text
            :disabled="!valid"
            :loading="loading"
            @click="select()"
          >
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validationRules from '@/mixins/validation-rules'
import ExerciseService from '@/services/admin-exercises-service'
import { debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'select-exercise-dialog',
  props: ['dialog'],
  data () {
    return {
      searchLoading: false,
      disableFields: true,
      valid: false,
      loading: false,
      validationRules,
      searchExercise: '',
      exercise: {},
      exerciseList: [],
      exerciseType: '',
      exerciseTypes: [
        { name: 'Mobilidade', value: 'mobility' },
        { name: 'Alongamento', value: 'stretching' },
        { name: 'Resistido', value: 'resistence' },
        { name: 'Liberação Miofascial', value: 'myofascial_release' },
        { name: 'Funcional', value: 'functional' },
        { name: 'Manobras Respiratórias/core', value: 'manobra_resp' },
        { name: 'Exercícios Aeróbicos', value: 'exerc_aerobicos' }]

    }
  },
  methods: {
    ...mapActions('base', ['setSnackbar']),
    save (exercise) {
      this.loading = true
      const payload = {
        ...exercise,
        user_id: null,
        id: null
      }
      ExerciseService
        .createOrUpdate(payload)
        .then(({ data }) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Exercício salvo com sucesso'
          })
          this.close(data.exercise)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    select () {
      this.close(this.exercise)
    },
    close (state = undefined) {
      this.disableFields = true
      this.exercise = {}
      this.$emit('close', state)
    },
    debouncedQuery: debounce(function (query) { this.searchQuery(query) }, 500),
    searchQuery (query) {
      this.searchLoading = true
      ExerciseService.all({ q: query, limit: 15 })
        .then(({ data }) => {
          this.exerciseList = data.exercises.filter(exercise => exercise.exercise_type !== 'aerobic' && exercise.exercise_type !== 'hiit')
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.searchLoading = false
        })
    },
    createMode (search) {
      this.exercise.name = search
      this.disableFields = false
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  watch: {
    searchExercise: {
      immediate: true,
      handler (newSearch) {
        this.debouncedQuery(newSearch || '')
      }
    }
  }
}
</script>
