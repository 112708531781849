<template>
  <v-row class="mainContainer">
    <v-col class="login-background pa-0" lg="5" md="5" sm="5" xs="12">
      <v-row class="align-center justify-end" style="height: 100%">
        <Logo imgColor="white" />
      </v-row>
    </v-col>
    <v-col class="mainContent">
      <v-row class="responsive-line" style="height: 100%">
        <v-card elevation="0" class="responsive-card">
          <v-card-title>
            Confirmação de registro
          </v-card-title>
          <v-card-text>
            <div>{{ msg }}</div>
            <v-text-field
              v-model="confirmationCode"
              label="Digite o código de confirmação"
              hint="Código enviado por WhatsApp"
              counter
              outlined
              maxlength=6
              class="mt-4"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
            <v-btn @click="newCode" text>
              <v-icon>mdi-refresh</v-icon>
              Solicite um novo código
            </v-btn>
            <div class="mt-6 d-flex flex-column" style="">
              <v-btn color="secondary" @click="confirmRegister">
                Confirmar Registro
              </v-btn>
              <v-btn class="mt-2" color="secondary" @click="$router.push({ name: 'Login' })">
                Voltar para Login
              </v-btn>
            </div>
          </v-card-text>

          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import authenticationService from '@/services/authentication-service'
import { mapActions } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'confirm-code',
  components: {
    Logo
  },
  data: () => ({
    confirmationCode: '',
    msg: 'Informe o código enviado para seu whatsapp.'
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),

    confirmRegister () {
      if (!this.confirmationCode.trim()) {
        this.setSnackbar({
          color: 'error',
          show: true,
          message: 'Por favor, informe o código de confirmação.'
        })
        return
      }

      this.msg = 'Aguarde, estamos processando sua confirmação de registro.'
      this.setLoader({ show: true })
      authenticationService.confirmRegister(this.confirmationCode.trim())
        .then((response) => {
          this.msg = 'Seu registro na plataforma foi confirmado. Você será redirecionado para a tela de login!'
          if (response.status === 204) {
            this.msg = 'Seu registro na plataforma já foi confirmado anteriormente. Você será redirecionado para a tela de login!'
          }
          this.$router.push({ name: 'Login' })
        })
        .catch((err) => {
          this.msg = 'Informe o código enviado para seu whatsapp.'
          this.setSnackbar({
            color: 'error',
            show: true,
            message: err.response?.data?.errors.detail || 'Houve um imprevisto. Por favor, tente novamente mais tarde.'
          })
        })
        .finally(() => {
          setInterval(() => {
            this.setLoader({ show: false })
          }, 200)
        })
    },

    newCode () {
      this.setLoader({ show: true })
      const email = this.$route.query.email
      const students = this.$route.query.students
      if (!email) {
        this.setSnackbar({
          color: 'error',
          show: true,
          message: 'Houve um imprevisto. Por favor, tente novamente mais tarde.'
        })
        return
      }

      authenticationService.newConfirmRegisterCode({ user: { email, students } })
        .then((response) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Um novo código de confirmação foi enviado para você!'
          })
        })
        .catch((err) => {
          console.log(err)
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Não foi possível enviar o novo código. Por favor, entre em contato com o suporte.'
          })
        })
        .finally(() => {
          setInterval(() => {
            this.setLoader({ show: false })
          }, 200)
        })
    }
  }
}
</script>

<style scoped>
.login-background {
  height: 100vh;
  background-image: url('../../assets/images/login-background.png');
  background-size: cover;
}

@media (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .login-background {
    width: 100%;
    flex-grow: 1;
  }

  .login-background .row {
    margin: 0;
  }

  .login-background img {
    width: 100%;
  }

  .mainContent {
    flex-grow: 3;

  }

  .responsive-card {
    width: 90%;
    margin: 0;
    margin-top: 24px;
    padding: 0;
  }
}
</style>
