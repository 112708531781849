const copyToClipboard = {
  methods: {
    copyToClipboard ($event) {
      try {
        const el = document.createElement('textarea')
        el.value = $event.target.parentElement.parentElement.innerText

        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()

        document.execCommand('copy')
        document.body.removeChild(el)
        this.$store.dispatch('base/setSnackbar', {
          color: 'success',
          show: true,
          message: 'E-mail copiado para área de transferência'
        })
      } catch (e) {
        this.$store.dispatch('base/setSnackbar', {
          color: 'error',
          show: true,
          message: 'Erro ao copiar para área de transferência'
        })
      }
    }
  }
}

export default copyToClipboard
