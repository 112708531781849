<template>
  <div class="toolbar-container">
    <v-app-bar class="application-toolbar px-12" fixed :color="topBarColor()" dark>
      <v-toolbar-title>
        <Logo @click="goToRoot()" :admin="user.isAdmin()" imgColor="white" :height="40" style="cursor: pointer" />
      </v-toolbar-title>

      <v-spacer />
      <div flat :color="topBarColor()" class="ActionsContainer" @mouseenter="showMenu()" @mouseleave="showMenu()">
        <v-btn v-for="n in filteredOptions" :key="n.id" color="transparent" class="no-shadow buttonAction"
          :class="{ 'active': n.active }" @click="n.run()">
          <v-icon color="white" class="icon">
            {{ n.icon }}
          </v-icon>
          <span class="menuText" :class="{ 'show': isShowMenu }">{{ n.name }}</span>
        </v-btn>
      </div>

      <v-list color="transparent" v-if="!user.isAdmin()" @click.native="onProfileClick()" v-ripple
        style="cursor: pointer;">
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="user.photo_url || require('@/assets/images/logo-placeholder.jpg')" :alt="user.fullname" contain
              :lazy-src="require('@/assets/images/logo-placeholder.jpg')">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ user.fullname }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-app-bar>
  </div>
</template>

<script>
import authenticationService from '@/services/authentication-service'
import adminAuthenticationService from '@/services/admin-authentication-service'
import { mapActions, mapGetters } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'top-bar',
  components: {
    Logo
  },
  props: {
    mode: {
      type: String,
      default: 'User'
    }
  },
  data: () => ({
    isShowMenu: false,
    options: []
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('auth', ['fetchCurrentUser']),
    logout () {
      this.setLoader({ show: true })
      if (this.user.isAdmin()) {
        adminAuthenticationService.logout()
          .then(() => {
            this.$router.push({ name: 'AdminLogin' })
          })
          .catch(() => {
            this.setSnackbar({
              color: 'error',
              show: true,
              message: 'Não foi possível fazer logout!'
            })
          })
          .finally(() => {
            this.setLoader({ show: false })
          })
      } else {
        authenticationService.logout()
          .then(() => {
            this.$router.push({ name: 'Login' })
          })
          .catch(() => {
            this.setSnackbar({
              color: 'error',
              show: true,
              message: 'Não foi possível fazer logout!'
            })
          })
          .finally(() => {
            this.setLoader({ show: false })
          })
      }
    },
    showMenu () {
      this.isShowMenu = !this.isShowMenu
    },
    goToExercises () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'ExercisesAdminList' })
      } else if (this.user.isPersonal()) {
        this.$router.push({ name: 'ExercisesPersonalList' })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    goToRoot () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'PersonalList' })
      } else {
        this.$router.push({ name: 'Students' })
      }
    },
    goToStudents () {
      if (this.user.isPersonal()) {
        this.$router.push({ name: 'StudentList' })
      }
    },
    goToMuscleGroups () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'MuscleGroupList' })
      }
    },
    goToTutorial () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'TutorialAdminList' })
      } else if (this.user.isPersonal()) {
        this.$router.push({ name: 'TutorialPersonalList' })
      } else {
        this.$router.push({ name: 'TutorialStudentList' })
      }
    },
    goToPlans () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'PlansList' })
      } else if (this.user.isPersonal()) {
        this.$router.push({ name: 'PlansPersonalList' })
      }
    },
    goToLandingPage () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'LandingPageEdit' })
      } else if (this.user.isPersonal()) {
        this.$router.push({ name: 'LandingPagePersonalEdit' })
      }
    },
    goToTerms () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'TermsView' })
      }
    },
    goToDashBoard () {
      if (this.user.isAdmin()) {
        this.$router.push({ name: 'PersonalDashboard' })
      } else if (this.user.isPersonal()) {
        this.$router.push({ name: 'StudentDashboard' })
      }
    },
    goToStudentShow () {
      // if (this.user.isPersonal()) {
      //   this.setLoader({ show: true })
      //   authenticationService.getStudentPersonal()
      //     .then(({ data }) => {
      //       this.$router.push({ name: 'StudentShow', params: { id: data.student } })
      //     })
      //     .catch(() => {
      //       this.setSnackbar({
      //         color: 'error',
      //         show: true,
      //         message: 'Personal não possui pacote contratado!!'
      //       })
      //     })
      //     .finally(() => {
      //       this.setLoader({ show: false })
      //     })
      // }
      if (this.user.isStudent()) {
        this.$router.push({ name: 'StudentShow' })
      }
    },
    topBarColor () {
      if (this.user.isAdmin()) {
        return 'secondary'
      }
      return 'primary'
    },
    topBarSmoothColor () {
      if (this.user.isAdmin()) {
        return '#dfd7ff'
      }
      return '#dfd7ff'
    },
    onProfileClick () {
      if (!this.user.isStudent()) {
        this.$router.push({ name: 'PersonalProfile' })
      }
    },

    updateActiveButton () {
      const currentRouteName = this.$route.name
      this.options = this.options.map(option => ({
        ...option,
        active: option.routeName.includes(currentRouteName)
      }))
    },
    goToWhatsapp () {
      window.open('https://wa.me/5582996723690', '_blank')
    },

    initOptions () {
      this.options = [
        {
          id: 10,
          name: 'Dashboard',
          run: this.goToDashBoard,
          icon: 'mdi mdi-finance',
          mode: ['Admin', 'PersonalTrainer'],
          active: true,
          routeName: ['PersonalDashboard', 'StudentDashboard']
        },
        {
          id: 9,
          name: 'LandingPage',
          run: this.goToLandingPage,
          icon: 'mdi mdi-file-edit',
          mode: ['Admin', 'PersonalTrainer'],
          active: false,
          routeName: ['LandingPageEdit', 'LandingPagePersonalEdit']
        },
        {
          id: 7,
          name: 'Planos',
          run: this.goToPlans,
          icon: 'mdi mdi-note',
          mode: ['Admin', 'PersonalTrainer'],
          active: false,
          routeName: ['PlansList', 'PlansPersonalList', 'PlansPersonalNew', 'PlansNew']
        },
        {
          id: 0,
          name: 'Personais',
          run: this.goToRoot,
          icon: 'mdi-account-plus',
          mode: ['Admin'],
          active: false,
          routeName: ['PersonalList', 'PersonalShow']
        },
        {
          id: 1,
          name: 'Alunos',
          run: this.goToStudents,
          icon: 'mdi-account-plus',
          mode: ['PersonalTrainer'],
          active: false,
          routeName: ['StudentList']
        },

        {
          id: 5,
          name: 'Grupos Musculares',
          run: this.goToMuscleGroups,
          icon: 'mdi-arm-flex',
          mode: ['Admin'],
          active: false,
          routeName: ['MuscleGroupList', 'MuscleGroupNew', 'MuscleGroupDetail']
        },
        {
          id: 3,
          name: 'Meu Treino',
          run: this.goToStudentShow,
          icon: 'mdi-weight-lifter',
          mode: ['Student'],
          active: false,
          routeName: ['StudentShow']
        },
        {
          id: 2,
          name: 'Exercícios',
          run: this.goToExercises,
          icon: 'mdi-dumbbell',
          mode: ['Admin', 'PersonalTrainer'],
          active: false,
          routeName: ['ExercisesAdminList', 'ExercisesPersonalList']
        },

        {
          id: 6,
          name: 'Tutoriais',
          run: this.goToTutorial,
          icon: 'mdi-book-variant',
          mode: 'all',
          active: false,
          routeName: ['TutorialAdminList', 'TutorialPersonalList', 'TutorialStudentList']
        },
        {
          id: 11,
          name: 'Suporte',
          run: this.goToWhatsapp,
          icon: 'mdi mdi-whatsapp',
          mode: 'all',
          active: false,
          routeName: ['Whatsapp']
        },
        {
          id: 8,
          name: 'Termos',
          run: this.goToTerms,
          icon: 'mdi mdi-shield-home',
          mode: ['Admin'],
          active: false,
          routeName: ['TermsView']
        },
        {
          id: 4,
          name: 'Sair',
          run: this.logout,
          icon: 'mdi-account-arrow-right',
          mode: 'all',
          active: false,
          routeName: ['Login']
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    filteredOptions () {
      return this.options.filter((val) => {
        return val.mode === 'all' || val.mode.includes(this.user.role_type)
      })
    }
  },
  mounted () {
    this.fetchCurrentUser()
      .then(() => {
        this.initOptions()
        this.updateActiveButton()
      })
  },
  watch: {
    $route (to, from) {
      this.updateActiveButton()
    }
  }
}
</script>

<style lang="scss">
.toolbar-container {
  height: 64px;

  .application-toolbar {
    z-index: 990 !important;
  }
}

.background-list {
  min-height: 32px !important;
}

.background-list:hover {
  background: #dfd7ff;
}

.no-shadow {
  box-shadow: none !important;
}

.ActionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
}

.buttonAction {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px !important;

  .icon {
    font-size: 20px;
    margin-right: 5px;
  }
}

.menuText {
  font-size: 12px;
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: all 1.0s ease;
}

.menuText.show {
  max-width: 200px;
}

.buttonAction.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}
</style>
