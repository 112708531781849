function LoginDialog () {
  this.show = false
  this.onSuccess = []
  this.onReject = []
}

export default {
  namespaced: true,
  state: {
    loader: {
      show: false,
      message: 'Por favor, aguarde!',
      color: 'white'
    },
    snackbar: {
      show: false,
      message: '',
      color: 'primary darken-2',
      timeout: 6000
    },
    loginDialog: new LoginDialog()
  },
  mutations: {
    SET_LOADER (state, payload) {
      Object.assign(state, { loader: { ...state.loader, ...payload } })
    },
    SET_SNACKBAR (state, payload) {
      Object.assign(state, { snackbar: { ...state.snackbar, ...payload } })
    },
    SET_LOGIN_DIALOG (state, payload) {
      state.loginDialog = {
        ...state.loginDialog,
        show: payload.show
      }

      state.loginDialog.onSuccess = [...state.loginDialog.onSuccess, payload.onSuccess]
      state.loginDialog.onReject = [...state.loginDialog.onReject, payload.onReject]
    },
    RESET_LOGIN_DIALOG (state) {
      state.loginDialog = new LoginDialog()
    }
  },
  actions: {
    setLoader ({ commit }, payload) {
      commit('SET_LOADER', payload)
    },
    setSnackbar ({ commit }, payload) {
      commit('SET_SNACKBAR', payload)
    },
    setLoginDialog ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGIN_DIALOG', { ...payload, onSuccess: resolve, onReject: reject })
        commit('auth/STOP_CHECK_SESSION', undefined, { root: true })
      })
    },
    resetLoginDialog ({ commit }) {
      commit('RESET_LOGIN_DIALOG')
    },
    async fetchSpreadSheet ({ commit }, isAdmin) {
      commit('SET_LOADER', { show: true })
      var service
      if (isAdmin) {
        service = await import('@/services/admin-user-service.js')
      } else {
        service = await import('@/services/student-service.js')
      }

      service = service.default

      service.getSpreadSheetWithAll()
        .then(({ data }) => {
          let url = getSpreadSheetUrl(data)
          let fileName = isAdmin ? 'relatorio-personal' : 'relatorio-alunos'
          downloadFromUrl(url, fileName)
        })
        .catch(({ response }) => {
          commit('SET_SNACKBAR', {
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          commit('SET_LOADER', { show: false })
        })
    },
    async fetchPdf ({ commit }, { service, payload, fileName }) {
      commit('SET_LOADER', { show: true })

      service(payload)
        .then(({ data }) => {
          let url = getPdfUrl(data)
          downloadFromUrl(url, fileName)
        })
        .catch(({ response }) => {
          commit('SET_SNACKBAR', {
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          commit('SET_LOADER', { show: false })
        })
    }
  },
  getters: {
    loader: state => state.loader,
    snackbar: state => state.snackbar,
    loginDialog: state => state.loginDialog
  }
}

const downloadFromUrl = (url, fileName) => {
  var a = document.createElement('a')
  document.body.appendChild(a)

  a.style = 'display: none'

  a.href = url
  a.download = fileName
  a.click()
  URL.revokeObjectURL(url)
}
const getSpreadSheetUrl = (bin) => {
  var blob = new Blob([bin], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })

  return URL.createObjectURL(blob)
}
const getPdfUrl = (bin) => {
  var blob = new Blob([bin], { type: 'application/octet-stream' })

  return URL.createObjectURL(blob)
}
