import { render, staticRenderFns } from "./ConfirmRegister.vue?vue&type=template&id=5fe1a4df&scoped=true"
import script from "./ConfirmRegister.vue?vue&type=script&lang=js"
export * from "./ConfirmRegister.vue?vue&type=script&lang=js"
import style0 from "./ConfirmRegister.vue?vue&type=style&index=0&id=5fe1a4df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe1a4df",
  null
  
)

export default component.exports