<template>
  <v-row class="mainContainer">
    <v-col class="login-background pa-0" lg="5" md="5" sm="5" xs="12">
      <v-row class="align-center justify-end" style="height: 100%">
        <Logo imgColor="white" />
      </v-row>
    </v-col>
    <v-col class="mainContent">
      <v-row class="responsive-line" style="height: 100%">
        <v-card elevation="0" class="responsive-card">
          <v-card-title>
            Confirmação de registro
          </v-card-title>
          <v-card-text>
            <div>
              <span class="subtitle-1" style="white-space: pre-wrap">{{ msg }}</span>
            </div>
            <div class="mt-6">
              <v-btn color="secondary" @click="$router.push({ name: 'Login' })">
                Ir para Login
              </v-btn>
            </div>

          </v-card-text>

          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import authenticationService from '@/services/authentication-service'
import { mapActions } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'confirm-register',
  components: {
    Logo
  },
  data: () => ({
    msg: 'Aguarde, estamos processando sua confirmação de registro.'
  }),
  mounted () {
    this.setLoader({ show: true })
    authenticationService.confirmRegister(this.$router.currentRoute.query.token)
      .then((response) => {
        this.msg = 'Seu registro na plataforma foi confirmado, agora é so fazer o login!'
        if (response.status === 204) {
          this.msg = 'Seu registro na plataforma já foi confirmado anteriormente, agora so fazer o login!'
        }
      })
      .catch(() => {
        this.msg = 'Não foi possível confirmar seu registro com esse link!\nPor favor entre em contato com o suporte.'
      })
      .finally(() => {
        setInterval(() => {
          this.setLoader({ show: false })
        }, 200)
      })
  },
  methods: {
    ...mapActions('base', ['setLoader'])
  }
}
</script>

<style scoped>
.login-background {
  height: 100vh;
  background-image: url('../../assets/images/login-background.png');
  background-size: cover;
}

@media (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .login-background {
    width: 100%;
    flex-grow: 1;
  }

  .login-background .row {
    margin: 0;
  }

  .login-background img {
    width: 100%;
  }

  .mainContent {
    flex-grow: 3;

  }

  .responsive-card {
    width: 90%;
    margin: 0;
    margin-top: 24px;
    padding: 0;
  }
}
</style>
