import axios from './axios-instance'

export default {
  all ({ q = '', page = 1, limit = 20, exerciseType = 'all' }) {
    return axios.get(`v1/exercises?exercise_type=${exerciseType}&limit=${limit}&page=${page}&q=${q}`)
  },
  suggested ({ q = '', mesocycleId, trainingIndex, microcycleId }) {
    const query = q ? `?q=${q}` : ''

    if (microcycleId) { return axios.get(`v1/microcycles/${microcycleId}/training/${trainingIndex}/suggested-exercises${query}`) }

    return axios.get(`v1/mesocycles/${mesocycleId}/training/${trainingIndex}/suggested-exercises${query}`)
  },
  createOrUpdate (payload) {
    if (payload.id === null) {
      return axios.post('v1/exercises', payload)
    }
    return axios.put(`v1/exercises/${payload.id}`, payload)
  },
  destroy (id) {
    return axios.delete(`v1/exercises/${id}`)
  }

}
