import axios from './axios-instance'

export default {
  getById (id) {
    return axios.get('v1/admin/muscle-groups/' + id)
  },
  get () {
    return axios.get('v1/admin/muscle-groups/')
  },
  destroy (id) {
    return axios.delete('v1/admin/muscle-groups/' + id)
  },
  create (payload) {
    return axios.post('v1/admin/muscle-groups/', payload)
  },
  update (id, payload) {
    return axios.put(`v1/admin/muscle-groups/${id}`, payload)
  },
  destroyPosturalDeviation (id) {
    return axios.delete('v1/admin/postural-deviations/' + id)
  },
  getPostural () {
    return axios.get('v1/admin/postural-deviations/')
  },
  createOrUpdatePosturalDeviation (payload) {
    if (payload.postural_deviation.id) {
      return axios.put(`v1/admin/postural-deviations/${payload.postural_deviation.id}`, payload.postural_deviation)
    } else {
      return axios.post('v1/admin/postural-deviations/', payload)
    }
  },
  addExercise (muscleGroupId, posturalDeviationId, exerciseId) {
    return axios.put(`v1/admin/muscle-groups/${muscleGroupId}/postural-deviations/${posturalDeviationId}/exercise/${exerciseId}`)
  },
  unlinkExercise (muscleGroupId, posturalDeviationId, exerciseId) {
    return axios.delete(`v1/admin/muscle-groups/${muscleGroupId}/postural-deviations/${posturalDeviationId}/exercise/${exerciseId}`)
  }
}
