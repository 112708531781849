<template>
  <div
    class="d-flex flex-column elevation-3 pa-5"
  >
    <div v-if="canCalculateSomatorioDobras">
      <v-row>
        <v-col>
          <span class="text-uppercase font-weight-medium">Somatório</span>
          <v-divider></v-divider>
          <span class="headline" v-if="!isNaN(somatorioDobras)"> {{ somatorioDobras }} mm </span>
          <span class="headline" v-else> ----- </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="text-uppercase font-weight-medium">Periférico</span>
          <v-divider></v-divider>
          <span class="headline" v-if="!isNaN(somatorioDobrasPeriferico)"> {{ somatorioDobrasPeriferico }} mm </span>
          <span class="headline" v-else> ----- </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="text-uppercase font-weight-medium">Central</span>
          <v-divider></v-divider>
          <span class="headline" v-if="!isNaN(somatorioDobrasCentral)"> {{ somatorioDobrasCentral }} mm </span>
          <span class="headline" v-else> ----- </span>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      Preencha todos as Dobras Cutâneas
    </div>

    <v-row>
      <v-col>
        <span class="text-uppercase font-weight-medium">Massa Livre de Gordura</span>
        <v-divider></v-divider>
        <span class="headline" v-if="!isNaN(massaPorcentagem.adiposa) && canCalculateSomatorioDobras"> {{massaPorcentagem.muscular}}% </span>
        <span class="headline" v-else>-----</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-uppercase font-weight-medium">% gordura estimada</span>
        <v-divider></v-divider>
        <span class="headline" v-if="!isNaN(massaPorcentagem.adiposa) && canCalculateSomatorioDobras"> {{massaPorcentagem.adiposa}}%</span>
        <span class="headline" v-else>-----</span>
        <v-spacer/>
        <span class="" v-if="!isNaN(massaPorcentagem.adiposa) && !isNaN(massaClassificacao.fat) && canCalculateSomatorioDobras"> {{massaClassificacao.adiposa}} </span>
        <span class="" v-else>-----</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-uppercase font-weight-medium">Protocolo</span>
        <v-divider></v-divider>
        <span class="text-uppercase font-weight-medium">{{getSkinfoldProtocol}}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>
/*eslint-disable*/
  import { mapGetters } from 'vuex'
  import { get, keys, reduce } from 'lodash'

  export default {
    name: 'somatorio',
    data() {
      return {}
    },
    computed: {
      ...mapGetters('students', ['student', 'mesocycleToBeEvaluated']),
      getSkinfoldProtocol () {
        try {
          return this.mesocycleToBeEvaluated.macrocycle.skinfold_protocol
        } catch (error) {
          return 'Sem protocolo'
        }
      },
      getSkinFold() {
        return this.mesocycleToBeEvaluated.evaluated_items.perimeter.skinFold
      },
      massaPorcentagem () {
        let muscle, fat = 0
        let densidadeCorporal = 0
        let somatorio = 0

        switch (this.getSkinfoldProtocol) {
          case 'TG Lohman - 2 Dobras':
            somatorio = this.somatorioDobrasCutaneas(['triceps', 'panturrilha'])
            fat = this.student.gender === 'male'
            /*se for homem*/  ? 0.735 * somatorio + 1
            /*se for mulher*/ : 0.735 * somatorio + 5,1
            break
          case 'Guedes - 3 Dobras':
            densidadeCorporal = this.student.gender === 'male'
            /*se for homem*/  ? 1.17136 - 0.06706 * Math.log10(this.somatorioDobrasCutaneas(['triceps', 'supra_iliaca', 'abdominal']))
            /*se for mulher*/ : 1.1665 - 0.07063 * Math.log10(this.somatorioDobrasCutaneas(['coxa_media', 'supra_iliaca', 'subescapular']))

            fat =  ((4.95 / densidadeCorporal) - 4.5) * 100
            break
          case 'Faulkner - 4 Dobras':
            fat =  this.somatorioDobrasCutaneas(['triceps', 'supra_iliaca', 'abdominal', 'subescapular']) * 0.153 + 5.783
            break
          case 'Mcardle - 4 Dobras':
            densidadeCorporal = this.student.gender === 'male'
            /*se for homem*/  ? 1.1610 - 0.0632 * Math.log10(this.somatorioDobrasCutaneas(['triceps', 'supra_iliaca', 'biceps', 'subescapular']))
            /*se for mulher*/ : 1.06234 - 0.00068 * (this.getSkinFold.subescapular) - 0.00039 * (this.getSkinFold.triceps) - 0.00025 * (this.getSkinFold.coxa_media)

            fat =  ((4.95 / densidadeCorporal) - 4.5) * 100
            break
          case 'Petroski - 4 Dobras':
            densidadeCorporal = this.student.gender === 'male'
            /*se for homem*/  ? 1.10726863 - 0.00081201 * (this.somatorioDobrasCutaneas(['subescapular', 'axilar_media', 'triceps', 'supra_iliaca'])) + 0.00000212 * (this.somatorioDobrasCutaneas(['subescapular', 'axilar_media', 'triceps', 'supra_iliaca'])) - 0.00041761 * (this.student.age)
            /*se for mulher*/ : 1.1954713 - 0.07513507 * Math.log10(this.somatorioDobrasCutaneas(['coxa_media', 'axilar_media', 'panturrilha', 'supra_iliaca'])) - 0.00041072 * (this.student.age)

            fat =  ((4.95 / densidadeCorporal) - 4.5) * 100
            break
          case 'Pollock - 5 Dobras':
            let x1 = this.somatorioDobrasCutaneas([ 'peitoral', 'abdominal', 'coxa_media'])
            let x2 = this.somatorioDobrasCutaneas([ 'coxa_media', 'triceps', 'supra_iliaca']) 
            densidadeCorporal = this.student.gender === 'male'
            /*se for homem*/  ? 1.10938 - 0.0008267 * (x1) + 0.0000016 * Math.pow(x1, 2.0) - 0.0002574 * (this.student.age)
            /*se for mulher*/ : 1.0994921 - 0.0009929 * (x2) + 0.0000023 * Math.pow(x2, 2.0) - 0.0001392 * (this.student.age)

            fat =  ((4.95 / densidadeCorporal) - 4.5) * 100
            break   
          case 'Yuhasz - 6 Dobras':
            somatorio = this.somatorioDobrasCutaneas([ 'peitoral', 'abdominal', 'triceps', 'coxa_media', 'supra_iliaca', 'subescapular'])
        
            fat =  somatorio * 0.095 + 3.64
            break   
          case 'Pollock - 7 Dobras':
            somatorio = this.somatorioDobrasCutaneas([ 'subescapular', 'axilar_media', 'triceps', 'coxa_media', 'supra_iliaca', 'abdominal', 'peitoral'])
            densidadeCorporal = this.student.gender === 'male'
            /*se for homem*/  ? 1.112 - 0.00043499 * (somatorio) + 0.00000055 * Math.pow(somatorio, 2.0) - 0.00028826*(this.student.age)
            /*se for mulher*/ : 1.097 - 0.00046971 * (somatorio) + 0.00000056 * Math.pow(somatorio, 2.0) - 0.00012828*(this.student.age)

            fat =  ((4.95 / densidadeCorporal) - 4.5) * 100
            break 
          case 'Marinha Americana':
            somatorio = this.somatorioDobrasCutaneas([ 'subescapular', 'axilar_media', 'triceps', 'coxa_media', 'supra_iliaca', 'abdominal', 'peitoral'])
            densidadeCorporal = this.student.gender === 'male'
            /*se for homem*/  ? 1.112 - 0.00043499 * (somatorio) + 0.00000055 * Math.pow(somatorio, 2.0) - 0.00028826*(this.student.age)
            /*se for mulher*/ : 1.097 - 0.00046971 * (somatorio) + 0.00000056 * Math.pow(somatorio, 2.0) - 0.00012828*(this.student.age)

            fat =  ((4.95 / densidadeCorporal) - 4.5) * 100
            break     
          default:
            // caso n tenha protocolo ou seja invalido
            fat = 0
            break
        }
        fat = fat.toFixed(1)
        muscle = 100 - fat
        return {
          muscular: `${muscle}`,
          adiposa: `${fat}`
        }
      },
      massaClassificacao () {
        let classification = 'ERRO'
        let age = this.student.age
        let fat = 0
        let somatorio = this.somatorioDobrasCutaneas([ 'subescapular', 'axilar_media', 'triceps', 'coxa_media', 'supra_iliaca', 'abdominal', 'peitoral'])
        let densidadeCorporal = this.student.gender === 'male'
        /*se for homem*/  ? 1.112 - 0.00043499 * (somatorio) + 0.00000055 * Math.pow(somatorio, 2.0) - 0.00028826*(this.student.age)
        /*se for mulher*/ : 1.097 - 0.00046971 * (somatorio) + 0.00000056 * Math.pow(somatorio, 2.0) - 0.00012828*(this.student.age)

        fat =  ((4.95 / densidadeCorporal) - 4.5) * 100

        if (this.student.gender === 'male') { // homem
          if (age < 20) {
            classification = ''
          } else if (age <= 29) {
            if (fat <= 5.2) { classification = 'MUITO MAGRO' } 
            else if (fat <= 9.3) {classification = 'MAGRO'}
            else if (fat <= 14.1) {classification = 'MUITO BOM'}
            else if (fat <= 17.5) {classification = 'SAUDAVEL'}
            else if (fat <= 22.4) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 29.2) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else if (age <= 39) {
            if (fat <= 9.2) { classification = 'MUITO MAGRO' } 
            else if (fat <= 14) {classification = 'MAGRO'}
            else if (fat <= 17.5) {classification = 'MUITO BOM'}
            else if (fat <= 20.6) {classification = 'SAUDAVEL'}
            else if (fat <= 24.2) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 30) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else if (age <= 49) {
            if (fat <= 11.5) { classification = 'MUITO MAGRO' } 
            else if (fat <= 16.3) {classification = 'MAGRO'}
            else if (fat <= 19.6) {classification = 'MUITO BOM'}
            else if (fat <= 22.5) {classification = 'SAUDAVEL'}
            else if (fat <= 26.2) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 31.4) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else if (age <= 59) {
            if (fat <= 12.9) { classification = 'MUITO MAGRO' } 
            else if (fat <= 18.1) {classification = 'MAGRO'}
            else if (fat <= 21.2) {classification = 'MUITO BOM'}
            else if (fat <= 24.2) {classification = 'SAUDAVEL'}
            else if (fat <= 27.6) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 32.4) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else{
            if (fat <= 13) { classification = 'MUITO MAGRO' } 
            else if (fat <= 18.5) {classification = 'MAGRO'}
            else if (fat <= 22) {classification = 'MUITO BOM'}
            else if (fat <= 25) {classification = 'SAUDAVEL'}
            else if (fat <= 28.4) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 33.6) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }
        }else { // mulher
          if (age < 20) {
            classification = '-'
          } else if (age <= 29) {
            if (fat <= 10.7) { classification = 'MUITO MAGRO' } 
            else if (fat <= 17) {classification = 'MAGRO'}
            else if (fat <= 20.5) {classification = 'MUITO BOM'}
            else if (fat <= 23.8) {classification = 'SAUDAVEL'}
            else if (fat <= 27.6) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 35.5) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else if (age <= 39) {
            if (fat <= 13.3) { classification = 'MUITO MAGRO' } 
            else if (fat <= 18) {classification = 'MAGRO'}
            else if (fat <= 21.8) {classification = 'MUITO BOM'}
            else if (fat <= 24.8) {classification = 'SAUDAVEL'}
            else if (fat <= 30) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 35.8) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else if (age <= 49) {
            if (fat <= 16.1) { classification = 'MUITO MAGRO' } 
            else if (fat <= 21.4) {classification = 'MAGRO'}
            else if (fat <= 25.1) {classification = 'MUITO BOM'}
            else if (fat <= 28.3) {classification = 'SAUDAVEL'}
            else if (fat <= 32.1) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 37.7) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else if (age <= 59) {
            if (fat <= 18.8) { classification = 'MUITO MAGRO' } 
            else if (fat <= 25.1) {classification = 'MAGRO'}
            else if (fat <= 28.6) {classification = 'MUITO BOM'}
            else if (fat <= 32.6) {classification = 'SAUDAVEL'}
            else if (fat <= 35.6) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 39.6) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }else{
            if (fat <= 19.1) { classification = 'MUITO MAGRO' } 
            else if (fat <= 25) {classification = 'MAGRO'}
            else if (fat <= 29.6) {classification = 'MUITO BOM'}
            else if (fat <= 32.8) {classification = 'SAUDAVEL'}
            else if (fat <= 36.7) {classification = 'OBESIDADE GRAU 1'}
            else if (fat <= 40.4) {classification = 'OBESIDADE GRAU 2'}
            else {classification = 'OBESIDADE GRAU 3'}
          }
        }
        return {
          adiposa: classification,
          fat: fat
        }
      },
      canCalculateSomatorioDobras(){
        const result = keys(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.skinFold'))
        let show = (result.length === 10)

        this.mesocycleToBeEvaluated.evaluated_items.perimeter = {
          ...this.mesocycleToBeEvaluated.evaluated_items.perimeter,
          somatorio: this.showValue(this.somatorioDobras, show),
          periferico: this.showValue(this.somatorioDobrasPeriferico, show),
          central: this.showValue(this.somatorioDobrasCentral, show),
          massaMuscular: this.showValue(this.massaPorcentagem.muscular, show),
          massaAdiposa: {
            value: this.showValue(this.massaPorcentagem.adiposa, show),
            label: this.massaClassificacao.adiposa,
          },
          protocolo: this.getSkinfoldProtocol
        }

        return show
      },
      somatorioDobras() {
        const sum = reduce(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.skinFold'), (acc, value) => {
          return acc + parseFloat(value)
        }, 0)
        return sum
      },
      somatorioDobrasPeriferico(){
        const items = ['biceps', 'supraespinhal', 'coxa_media', 'triceps', 'panturrilha']
        const sum = reduce(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.skinFold'), (acc, value, key) => {
          if (items.includes(key))
            return acc + parseFloat(value)
          return acc
        }, 0)
        return sum
      },
      somatorioDobrasCentral(){
        const items = ['peitoral', 'axilar_media', 'supra_iliaca', 'abdominal', 'subescapular']
        const sum = reduce(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.skinFold'), (acc, value, key) => {
          if (items.includes(key))
            return acc + parseFloat(value)
          return acc
        }, 0)
        return sum
      },
      somatorioDobrasCutaneas(){
        return items => {
        const sum = reduce(get(this.mesocycleToBeEvaluated, 'evaluated_items.perimeter.skinFold'), (acc, value, key) => {
          if (items.includes(key))
            return acc + parseFloat(value)
          return acc
        }, 0)
        return sum
        }
      },
      relacaoCinturaEstatura() {
        return (this.cintura / this.stature / 100).toFixed(2)
      },
      nivelCinturaEstatura() {
        const rcs = this.relacaoCinturaEstatura

        if (rcs > 0.52)
          return 'RISCO PARA PROBLEMAS CARDIOVASCULARES AUMENTADO'

        if (rcs < 0.52)
          return 'RISCO PARA PROBLEMAS CARDIOVASCULARES  REDUZIDO'

        return 'NORMAL'
      }
    },
    methods: {
      showValue(value, show) {
        return show && !isNaN(value) ? value : '----'
      }
    }
  }
</script>

<style scoped>
</style>
