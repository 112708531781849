<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="700"
    >

      <v-card>
        <v-card-title
          class="headline secondary white--text"
          primary-title
        >
          Remover Exercício
        </v-card-title>

        <v-card-text>
          <blockquote class="blockquote">
            Deseja remover o exercício <strong>'{{ itemToBeDeleted.name }}'</strong>? Caso esse exercício esteja
            sendo utilizado em algum treino, ele será apenas ocultado dessa lista.
          </blockquote>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="loading"
            @click="close(false)">
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="loading"
            @click="deleteItem()"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExerciseService from '@/services/user-exercises-service'
import { mapActions } from 'vuex'

export default {
  name: 'DeleteExerciseDialog',
  props: ['itemToBeDeleted', 'dialog'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions('base', ['setSnackbar']),

    deleteItem () {
      this.loading = true
      ExerciseService
        .destroy(this.itemToBeDeleted.id)
        .then(({ data }) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Exercício removido com sucesso'
          })
          this.close(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    close (state) {
      this.$emit('close', state)
    }
  }
}
</script>

<style scoped>

</style>
