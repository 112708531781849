<template>
  <v-container>
    <v-row v-if="!isStudent">
      <v-col cols="12" class="px-0">
        <btn-back />
      </v-col>
    </v-row>
    <template v-if="!showPaymentHistory">
      <student-card-details :dialog.sync="dialogStudentForm" class="mt-6" @toggle="togglePaymentHistory" />
      <template>
        <v-row class="pt-5">
          <v-row>

          </v-row>
          <v-spacer />
          <v-col cols="auto">
            <v-row align="center">
              <v-btn-toggle v-model="macroMode" tile borderless dense active-class="macro-mode--active"
                class="macro-mode py-1" mandatory>
                <v-btn class="elevation-2">
                  <v-icon>
                    mdi-timeline
                  </v-icon>
                </v-btn>
                <v-btn class="ml-4 elevation-2">
                  <v-icon>
                    mdi-calendar-month
                  </v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-btn class="ml-4 py-1" color="primary" @click="newMacroCycle()" v-if="!isStudent">
                <v-icon class="mr-2">
                  mdi-file-table-box-multiple
                </v-icon>
                NOVO MACROCICLO
              </v-btn>
            </v-row>
          </v-col>

        </v-row>

        <create-or-update-macro-dialog :editedItem="editedItem" :editedIndex="editedIndex" :dialog.sync="dialog" />

        <v-row class="pt-2">
          <v-col v-if="student.macrocycles.length === 0" cols="12" class="pa-0">
            <div class="dashed-container pa-30 d-flex justify-center align-center">
              <img width="120" src="@/assets/images/students/show/calendar-with-clock.png" />
            </div>

          </v-col>
          <v-col class="px-0" v-else>

            <student-macro-cycle :mode="macroMode" @openCreateOrUpdateMacrocycle="editItem($event)" />

          </v-col>

        </v-row>

        <dialog-full-screen :dialog.sync="dialogStudentForm" titleName="Editar Aluno" buttonName="Salvar"
          @button="$refs.studentForm.save()">
          <student-form :dialog.sync="dialogStudentForm" ref="studentForm" @updateList="getStudent()" />
        </dialog-full-screen>

        <student-meso-cycle-detail v-if="studentEvaluationDialog" @updateList="getStudent()" />
        <student-micro-cycle-detail v-if="studentEvaluationDialogMicro" @updateList="getStudent()" />
        <student-meso-cycle-final-result />
        <student-training-detail v-if="studentTrainingDialog" @update="updateTraining('meso', $event)" @updateStudantData="getStudent()" />
        <student-training-detail-micro v-if="studentTrainingDialogMicro" @update="updateTraining('micro', $event)" @updateStudantData="getStudent()" />
        <create-or-update-exercise-dialog @update="updateExercise($event)" />
      </template>
    </template>
    <template v-else>
      <v-row class="mt-7">
        <v-col cols="12" class="pa-0">
          <student-payment-history :id="student.id" @toggle="togglePaymentHistory" :showBtn="true" />
        </v-col>
      </v-row>
    </template>
    <!--
    teste de força
    <template v-else>
    <v-row class="mt-7 elevation-3 pa-0">
      <v-col class="px-8">
        <v-row class="px-0">
          <v-btn
            text
            class="px-0"
            @click="strengthTestDialog = false"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
            <span>Voltar</span>
          </v-btn>
        </v-row>
        <v-row class="px-0 d-flex flex-column justify-center align-center">
          <v-col cols="11" >
            <h2 class="mb-4">Teste de Força</h2>
            <v-data-table
              v-if = "strengthTests.length > 0"
              :headers="headersStrengthTest"
              :items="strengthTests"
              :items-per-page="5"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15],
                itemsPerPageText: 'Itens por página',
                pageText: '{0}-{1} de {2}'
              }"
              >
            </v-data-table>
            <span v-if="strengthTests.length === 0">Nenhum teste de força cadastrado</span>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </template> -->

    <!-- CHAT
    <template>
    <div>
      <v-bottom-sheet
        v-model="chatDialog"
        max-width="30vw"
        hide-overlay
        inset
      >
      <v-card style="height: 60vh; width: 30vw;">
        <v-card-title class="headline primary" style="color: white;">
          Chat
          <v-spacer></v-spacer>
          <v-btn icon @click="chatDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="chatContainer" ref="messageContainer">

          <div v-for="(message, index) in messages" :key="index">
            <div :class="{'messageUser': message.user === 'Você', 'messageOther': message.user !== 'Você' }">
              <div :class="{'user message': message.user === 'Você', 'otherUser message': message.user !== 'Você'}">
                <span><strong>{{ message.user }}</strong></span>
                <span>{{ message.text }}</span>
              </div>
            </div>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-textarea
            label="Digite sua mensagem"
            v-model="newMessage"
            auto-grow
            rows="2"
            @keyup.enter.exact="sendMessage"
            @keyup.shift.enter="addNewLine"
          ></v-textarea>
          <v-btn color="primary" @click="sendMessage">Enviar</v-btn>
        </v-card-actions>
        </v-card>
      </v-bottom-sheet>
    </div>
  </template> -->

  </v-container>

</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import StudentCardDetails from '@/components/student/show/StudentCardDetails'
import StudentMacroCycle from '@/components/student/show/StudentMacroCycle'
import CreateOrUpdateMacroDialog from '@/components/student/show/CreateOrUpdateMacroDialog'
import DialogFullScreen from '@/components/base/DialogFullScreen'
import StudentForm from '@/components/student/StudentForm'
import StudentService from '@/services/student-service'
import StudentMesoCycleDetail from '@/components/student/show/StudentMesoCycleDetail'
import StudentMicroCycleDetail from '@/components/student/show/StudentMicroCycleDetail'
import StudentTrainingDetail from '@/components/student/show/StudentTrainingDetail'
import StudentTrainingDetailMicro from '@/components/student/show/StudentTrainingDetailMicro'
import CreateOrUpdateExerciseDialog from '@/components/student/show/CreateOrUpdateExerciseDialog'
import mesocycleService from '@/services/mesocycle-service'
import microcycleService from '@/services/microcycle-service'
import StudentMesoCycleFinalResult from '@/components/student/show/StudentMesoCycleFinalResult'
import StudentPaymentHistory from '@/components/student/StudentPaymentHistory'
import BtnBack from '../../components/base/BtnBack.vue'
import StrengthTestService from '@/services/user-strength-test-service'

export default {
  name: 'student-show',
  components: {
    StudentCardDetails,
    StudentMacroCycle,
    CreateOrUpdateMacroDialog,
    DialogFullScreen,
    StudentForm,
    StudentMesoCycleDetail,
    StudentMicroCycleDetail,
    StudentTrainingDetail,
    StudentTrainingDetailMicro,
    CreateOrUpdateExerciseDialog,
    StudentMesoCycleFinalResult,
    StudentPaymentHistory,
    BtnBack
  },
  beforeMount () {
    this.getStudent()
    this.getStrengthTest()
  },
  data () {
    return {
      // messages: [],
      // newMessage: '',
      // strengthTests: [],
      // headersStrengthTest: [
      //   { text: 'Exercício', value: 'exercise' },
      //   { text: 'Repetição max.', value: 'maxReps' },
      //   { text: 'Peso utilizado', value: 'weightUsed' },
      //   { text: 'Grupo muscular', value: 'muscleGroup' }
      // ],
      dialogStudentForm: false,
      dialog: false,
      strengthTestDialog: false,
      // chatDialog: false,
      macroMode: 0,
      editedItem: {},
      editedIndex: -1,
      showPaymentHistory: false
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['setStudent', 'setCycleType']),
    getStudent () {
      this.setLoader({ show: true })
      StudentService
        .get(this.$route.params.id)
        .then(({ data }) => {
          this.setStudent(data.student)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao carregar aluno'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    getStrengthTest () {
      const studentId = this.$route.params.id
      this.setLoader({ show: true })
      StrengthTestService
        .getStrengthTest(studentId)
        .then(({ data }) => {
          this.strengthTests = data.strength_tests || []
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao carregar teste de força'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    // sendMessage() {
    //   if (!this.newMessage.trim()) {
    //     return;
    //   }
    //   if (this.newMessage.trim() !== '') {
    //     this.messages.push({
    //       user: 'Você',
    //       text: this.newMessage
    //     });
    //     this.newMessage = '';

    //     setTimeout(() => {
    //       this.messages.push({
    //         user: 'Bot',
    //         text: 'Resposta automática...'
    //       });
    //     }, 1000);
    //   }
    //   this.$nextTick(() => {
    //     const container = this.$refs.messageContainer;
    //     if(container) {
    //       container.scrollTop = container.scrollHeight;
    //     }
    //     });
    //   this.newMessage = '';
    // },
    // addNewLine() {
    //   this.newMessage += '\n';
    // },
    newMacroCycle () {
      this.editedItem = { id: null, items_to_be_evaluated: [], skinfold_protocol: 'Guedes - 3 Dobras', vo2_protocol: 'protocol_3' }
      this.editedIndex = -1
      this.dialog = true
    },
    editItem ({ editedItem, editedIndex }) {
      this.editedItem = editedItem
      this.editedIndex = editedIndex
      this.dialog = true
    },
    editStudent () {
      this.setStudent(this.student)
      this.dialogStudentForm = true
    },
    updateTraining (mode, { successMessage, failMessage }) {
      this.update(mode)
        .then(() => {
          this.showSnackbar('success', successMessage)
        })
        .catch(() => {
          this.showSnackbar('error', failMessage)
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    updateExercise ({ successMessage, failMessage, cycle }) {
      this.update(cycle)
        .then(() => {
          this.showSnackbar('success', successMessage)
        })
        .catch(() => {
          this.showSnackbar('error', failMessage)
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    update (cycleParam = 'meso') {
      this.setCycleType(cycleParam)
      this.setLoader({ show: true })
      if (cycleParam === 'micro') {
        const payload = { ...this.microcycleToBeEvaluated }
        return microcycleService
          .createOrUpdate(this.student.id, payload.macrocycle.id, payload)
      } else {
        const payload = { ...this.mesocycleToBeEvaluated }
        return mesocycleService
          .createOrUpdate(this.student.id, payload.macrocycle.id, payload)
      }
    },
    showSnackbar (color, message) {
      this.setSnackbar({
        color,
        show: true,
        message
      })
    },
    togglePaymentHistory () {
      this.showPaymentHistory = !this.showPaymentHistory
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated', 'studentEvaluationDialog', 'studentEvaluationDialogMicro', 'studentTrainingDialog', 'studentTrainingDialogMicro']),
    ...mapGetters('auth', ['user']),
    isStudent () {
      return this.user.isStudent()
    }
  }
  // updated() {
  //   const container = this.$refs.messageContainer;
  //   if(container) {
  //     container.scrollTop = container.scrollHeight;
  //   }
  // }

}
</script>

<style scoped>
.pa-30 {
  padding: 60px !important;
}

.dashed-container {
  border: dashed 2px darkgrey;
}

.macro-mode--active {
  background-color: var(--v-secondary-base) !important;
}

.macro-mode--active>.v-btn__content>i {
  color: #fff !important;
}

.macro-mode>:not(.macro-mode--active)>.v-btn__content>i {
  color: var(--v-secondary-base) !important;
}

.macro-mode>.v-btn {
  border-radius: 8px !important;
}

.v-dialog__content {
  padding: 0 60px !important;
  justify-content: flex-end !important;
}

.user {
  background-color: #eeadbb44;
}

.otherUser {
  background-color: #a0b6eb44;
}

.chatContainer {
  height: 70%;
  overflow-y: auto;
  margin-top: 20px;
}

.message {
  border-radius: 0.5rem;
  width: fit-content;
  max-width: 100%;
  padding: 0.5rem;
  min-width: 20%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.messageUser {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.messageOtherUser {
  width: 100%;
}
</style>
