const posturaUploadFilesList = {
  principais: {
    frontal: 'Foto frontal',
    perfil_esquerdo: 'Perfil esquerdo',
    perfil_direito: 'Perfil direito',
    perfil_esquerdo_flexao: 'Perfil esq. em flexão',
    perfil_direito_flexao: 'Perfil dir. em flexão',
    dorsal: 'Foto dorsal'
  },
  opcionais: {
    teste_apoio_unipodal_esquerdo: 'Teste apoio unipodal esq.',
    teste_apoio_unipodal_direito: 'Teste apoio unipodal dir.',
    dorsal_maos_cintura: 'Vista post. mãos na cintura',
    dorsal_90_graus: 'Vista posterior ombros a 90°',
    dorsal_170_graus: 'Vista posterior ombros a 170°',
    costas_agachado: 'Vista posterior agachado',
    tronco_flexionado: 'Vista ant tronco parci. Flex',
    tronco_flexionado_perfil: 'Vista ant tronco total. flex',
    ajoelhado_perfil_esquerdo: 'Vista lateral ajoelhado esq.',
    ajoelhado_perfil_direito: 'Vista lateral ajoelhado dir.',
    ajoelhado_costas: 'Vista posterior ajoelhado',
    sentado_quadril_esq: 'Sentado qua. abduz. esquerdo',
    sentado_quadril_dir: 'Sentado qua. abduz. direito',
    sentar_alcancar: 'Foto sentar alcançar',
    perna_cruzada_esquerda: 'Vista Caudocranial joelho esq',
    perna_cruzada_direita: 'Vista Caudocranial joelho dir',
    agachado_perfil_esquerdo: 'Vista lateral agachado esq.',
    agachado_perfil_direito: 'Vista lateral agachado dir.',
    deitado_quadril_flexao_esq: 'Quadril em flex. lado esq.',
    deitado_quadril_flexao_dir: 'Quadril em flex. lado dir.',
    testa_thomas_esquerdo: 'Teste de Thomas lado esq.',
    testa_thomas_direito: 'Teste de Thomas lado dir.',
    dorsal_maos_quadril: 'Vista dorsal mãos nas costas',
    perna_cruzada_deitado: 'Vista craniocaudal deitado'
    // perna_cruzada_joelho_flex_esq: 'Teste de Patrick lado esq.',
    // perna_cruzada_joelho_flex_dir: 'Teste de Patrick lado dir.'
  }
}

export default posturaUploadFilesList
