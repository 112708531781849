import axios from './axios-node-instance'

export default {
  getAllUsers (payload) {
    return axios.get('user/', { params: payload })
  },

  getUser (id) {
    return axios.get(`user/${id}`)
  },

  getPayments (payload) {
    return axios.get('payment/', { params: payload })
  }
}
