<template>
  <v-row class="mainContainer">
    <v-col class="login-background pa-0" lg="5" md="5" sm="5" xs="12">
      <v-row class="align-center justify-end" style="height: 100%">
        <Logo imgColor="white" />
      </v-row>
    </v-col>
    <v-col class="mainContent">
      <v-row class="responsive-line" style="height: 100%">
        <component @confirm="openConfirmForm" @finish="updatePaymentInformation" :is="currentComponent" v-model="data">
        </component>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import Logo from '@/components/base/Logo'
import MessageCard from './MessageCard'
import PaymentForm from './PaymentFormCard'
import ConfirmForm from './ConfirmFormCard'
import SubscribeService from '@/services/subscribe-service.js'

export default {
  name: 'confirm-register',
  components: {
    Logo
  },
  data: () => ({
    currentComponent: MessageCard,
    data: null
  }),
  computed: {
    loginBackgroundStyle () {
      return {
        height: '100vh',
        backgroundImage: `url('${require('@/assets/images/login-background.png')}')`,
        backgroundSize: 'cover'
      }
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('base', ['setLoader']),
    openPaymentForm () {
      this.currentComponent = PaymentForm
    },
    openConfirmForm (payload) {
      this.data = payload
      this.currentComponent = ConfirmForm
    },
    updatePaymentInformation () {
      const payload = {
        paymentInformation: {
          creditCard: {
            card_hash: this.data.cardHash,
            holder_cpf: this.data.holderCpf,
            holder_phone: this.data.holderPhone
          },
          plan: {
            id: this.data.plan.id,
            installments: this.data.plan.max_installments
          }
        }
      }
      SubscribeService.subscribe(payload)
        .then(({ data }) => {
        }).catch((request) => {
          console.log(request)
        })
    }
  }
}
</script>

<style scoped>
.login-background {
  height: 100vh;
  background-image: url('../../../assets/images/login-background.png');
  background-size: cover;
}

.mainContainer {
  height: 100vh;
  display: flex;
}

.responsive-line {
  align-items: center;
  justify-content: start;
}

@media (max-width: 598px) {

  .mainContainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .login-background {
    width: 100%;
    flex-grow: 1;
  }

  .login-background img {
    width: 100%;
  }

  .login-background .row {
    margin: 0;
  }

  .mainContent {
    flex-grow: 3;
  }

  .responsive-card {
    width: 90%;
    margin: 0;
    margin-top: 24px;
    padding: 0;
  }
}
</style>
