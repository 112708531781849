
const defaultPersonal = {
  name: null,
  birthdate: '',
  email: '',
  cellphone: '',
  gender: 1, // 1 = masculino 0 = feminino
  password: '',
  cpf: '',
  address: {
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    zipcode: '',
    complement: ''
  },
  subscription_plan_id: 1
}

export default {
  namespaced: true,
  state: {
    personals: [],
    currentPersonal: { ...defaultPersonal }
  },
  mutations: {
    SET_PERSONALS (state, payload) {
      Object.assign(state, { personals: payload })
    },
    SET_PERSONAL (state, payload) {
      Object.assign(state, { currentPersonal: payload })
    },
    ADD_PERSONAL (state, payload) {
      state.personal.push(...payload)
    },
    RESET_PERSONAL (state) {
      Object.assign(state, { currentPersonal: { ...defaultPersonal } })
    }
  },
  actions: {
    setPersonals ({ commit }, payload) {
      commit('SET_PERSONALS', payload)
    },
    setPersonal ({ commit }, payload) {
      commit('SET_PERSONAL', payload)
    },
    addPersonal ({ commit }, payload) {
      commit('ADD_PERSONAL', payload)
    },
    resetPersonal ({ commit }) {
      commit('RESET_PERSONAL')
    }
  },
  getters: {
    getPersonals: state => state.personals,
    getCurrentPersonal: state => state.currentPersonal
  }
}
