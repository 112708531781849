<template>
  <v-app>
    <router-view />
    <loader/>
    <alert-snack-bar/>
  </v-app>
</template>

<script>
import Loader from '@/components/base/Loader'
import AlertSnackBar from '@/components/base/AlertSnackBar'

export default {
  name: 'App',

  components: {
    AlertSnackBar,
    Loader
  },

  data: () => ({
    //
  })
}
</script>

<style>
  .v-application {
    background: white !important;
  }
</style>
