import axios from './axios-instance'

export default {
  async all (studentId, macrocycleId) {
    const { data } = await axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles`)
    return data
  },
  evaluationsResult (studentId, macrocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles`)
  },
  getMicrocycle (studentId, macrocycleId, microcycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${microcycleId}`)
  },
  getMicrocycleEvaluation (studentId, macrocycleId, microcycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${microcycleId}/evaluation`)
  },
  getMicrocycleTraining (studentId, macrocycleId, microcycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${microcycleId}/trainings`)
  },
  createOrUpdate (studentId, macrocycleId, payload) {
    if (!payload.id) {
      return axios.post(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles`, payload)
    } else {
      return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${payload.id}`, payload)
    }
  },
  microcycleSaveEvaluation (studentId, macrocycleId, payload) {
    return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${payload.id}`, payload)
  },
  uploadPhoto (studentId, macrocycleId, microcycleId, formData) {
    return axios.put(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${microcycleId}`, formData)
  },
  destroy (studentId, macrocycleId, id) {
    return axios.delete(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${id}`)
  },
  removeTraining (microcycleId, trainingIdx) {
    return axios.put(`v1/microcycles/${microcycleId}/training/${trainingIdx}`)
  },
  getFinalResult (studentId, macrocycleId) {
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/`)
  },
  selectItemToCopy (studentId, id, origin) {
    return axios.post(`v1/training_copy?idStudent=${studentId}&idMicrocycles=${id}&idMicrocyclesOrigin=${origin}`)
  },
  getmicrocyclesList (studentId) {
    return axios.get(`v1/microcyclesList/${studentId}`)
  }
}
