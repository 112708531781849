import axios from './axios-instance'

export default {
  all ({ q = '', page = 1, limit = 20, tutorialType = '' }) {
    return axios.get(`v1/admin/tutorials?tutorial_type=${tutorialType}&limit=${limit}&page=${page}&q=${q}`)
  },
  createOrUpdate (payload) {
    if (payload.id === null) {
      return axios.post('v1/admin/tutorials', payload)
    }
    return axios.put(`v1/admin/tutorials/${payload.id}`, payload)
  },
  destroy (id) {
    return axios.delete(`v1/admin/tutorials/${id}`)
  }
}
