<template>
  <v-row class="mx-12 mt-6 px-12" style=" height: 75vh;">
    <v-tabs v-model="tab" slider-size="3" color="secondary" style="border-bottom: 1px solid #a1a1a1;">
      <v-tab key="dados">Dados</v-tab>
      <v-tab key="address">Endereço</v-tab>
      <v-tab key="plan">Plano</v-tab>
    </v-tabs>

    <v-form ref="form" lazy-validation style="height: 90%; width: 100%;">
      <v-tabs-items v-model="tab" class="fill-height">

        <v-tab-item key="dados" class="fill-height">
          <v-col cols="12" lg="8" sm="12">
            <v-row class="pt-4">
              <v-col cols="12" lg="6" sm="6">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.name" label="Nome"
                  :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="6" sm="6">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.cpf" label="CPF"
                  v-mask="'###.###.###-##'" :rules="[validationRules.required, validationRules.cpf]" />
              </v-col>
              <v-col cols="12" lg="4" sm="4">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.birthdate"
                  v-mask="'##/##/####'" label="Data de nascimento" :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="4" sm="4">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.cellphone" label="Telefone"
                  v-mask="'(##)#####-####'" :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="4" sm="4">
                <v-select color='secondary' item-color="secondary" filled dense v-model="getCurrentPersonal.gender"
                  :items="gender" label="Gênero" :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="6" sm="6">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.email" label="E-mail"
                  :rules="validateOnNext ? [validationRules.email] : []" />
              </v-col>
              <v-col cols="12" lg="6" sm="6">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.password" label="Senha"
                  :rules="validateOnNext ? [validationRules.required, validationRules.passwordSize] : []"
                  :type="showPassword ? 'text' : 'password'" append-icon="mdi-eye"
                  @click:append="showPassword = !showPassword">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-row class="d-flex justify-end px-6" dense
            style="position: absolute; bottom: 0; width: 100%; gap: 10px; margin-bottom: 5px;">
            <v-btn color="primary" @click="nextTab" class="around">
              Próximo
            </v-btn>
          </v-row>
        </v-tab-item>

        <v-tab-item key="address" class="fill-height">
          <v-col cols="12" lg="8" sm="12">
            <v-row class="pt-4">
              <v-col cols="12" lg="10" sm="10">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.address.street" label="Rua"
                  :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="2" sm="2">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.address.number" label="Número"
                  :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="8" sm="4">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.address.neighborhood"
                  label="Bairro" :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="4" sm="4">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.address.city" label="Cidade"
                  :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="4" sm="4">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.address.state" label="Estado"
                  :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="4" sm="4">
                <v-text-field color='secondary' filled dense v-mask="'#####-###'"
                  v-model="getCurrentPersonal.address.zipcode" label="CEP" :rules="[validationRules.required]"
                  @change="handleCEPChange" />
              </v-col>
              <v-col cols="12" lg="4" sm="8">
                <v-text-field color='secondary' filled dense v-model="getCurrentPersonal.address.complement"
                  label="Complemento" :rules="[validationRules.required]" />
              </v-col>
            </v-row>
          </v-col>
          <v-row class="d-flex justify-end px-6" dense
            style="position: absolute; bottom: 0; width: 100%; gap: 10px; margin-bottom: 5px">
            <v-btn @click="previousEditTab">Voltar</v-btn>
            <v-btn color="primary" @click="nextTab">Próximo</v-btn>
          </v-row>
        </v-tab-item>

        <v-tab-item key="plan" class="fill-height">
          <v-col cols="12" lg="8" sm="12">
            <v-row class="pt-4">
              <v-col cols="12" lg="6" sm="6">
                <v-select color='secondary' item-color="secondary" filled dense
                  v-model="getCurrentPersonal.subscription_plan_id"
                  :items="plans.map(plan => ({ text: plan.name, value: plan.id }))" label="Plano"
                  :rules="[validationRules.required]" />
              </v-col>
              <v-col cols="12" lg="4" sm="6">
                <v-card v-if="getPlan(getCurrentPersonal.subscription_plan_id).name" class="mx-auto" max-width="400">
                  <v-card-title>
                    <v-icon left>mdi-dumbbell</v-icon>
                    {{ getPlan(getCurrentPersonal.subscription_plan_id).name }}
                  </v-card-title>

                  <v-card-text>
                    <p style="max-height: 170px; overflow-y: auto;">{{
                      getPlan(getCurrentPersonal.subscription_plan_id).description }}</p>
                    <p class="font-weight-bold">Frequência: {{
                      frequencyText(getPlan(getCurrentPersonal.subscription_plan_id).frequency) }}</p>
                    <p class="font-weight-bold">Valor: R$ {{
                      parseFloat(getPlan(getCurrentPersonal.subscription_plan_id).value).toFixed(2) }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-row class="d-flex justify-end px-6" dense
            style="position: absolute; bottom: 0; width: 100%; gap: 10px; margin-bottom: 5px;">
            <v-btn @click="previousEditTab">
              Voltar
            </v-btn>
            <v-btn color="primary" @click="nextTab">
              Cadastrar
            </v-btn>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-form>
  </v-row>
</template>

<script>
import validationRules from '@/mixins/validation-rules'
import jsonToFormData from '@/mixins/jsonToFormData'
import { mapActions, mapGetters } from 'vuex'
import clonedeep from 'lodash/cloneDeep'
import personalNodeService from '@/services/personal-node-service'
import PlanService from '@/services/plan-service.js'
import { fetchAddressByCEP } from '@/services/cep-address.js'

export default {
  name: 'personal-form',
  props: {
    dialog: Boolean
  },
  mixins: [jsonToFormData],
  data () {
    return {
      tab: 0,
      validationRules,
      personalCache: null,
      showPassword: false,
      validateOnNext: false,
      frequencies: [
        { text: 'Mensal', value: 'monthly' },
        { text: 'Bimestral', value: 'bimonthly' },
        { text: 'Semestral', value: 'semiannually' },
        { text: 'Anual', value: 'yearly' }
      ],
      gender: [
        { text: 'Masculino', value: 1 },
        { text: 'Feminino', value: 2 }
      ],
      plans: []
    }
  },
  methods: {
    ...mapActions('personal', ['setPersonal']),
    ...mapActions('base', ['setLoader', 'setSnackbar']),

    previousEditTab () {
      this.tab--
    },

    nextTab () {
      if (this.tab === 2) {
        this.save()
      } else {
        this.tab++
      }
    },

    resetForm () {
      this.$refs.form.resetValidation()
      this.setPersonal(this.personalCache)
    },

    save () {
      this.validateOnNext = true

      if (!this.$refs.form.validate()) {
        this.setSnackbar({
          show: true,
          message: 'Erro ao salvar personal: Verifique os campos obrigatórios',
          color: 'error'
        })
        return
      }
      this.setLoader({ show: true })
      const clonePersonal = clonedeep(this.getCurrentPersonal)

      if (clonePersonal.birthdate) {
        const birthdate = clonePersonal.birthdate.split('/').reverse().join('-')
        const date = new Date(birthdate)
        if (!isNaN(date)) {
          clonePersonal.birthdate = date.toISOString()
        } else {
          console.error('Invalid birthdate:', clonePersonal.birthdate)
        }
      } else {
        console.error('Birthdate is not defined')
      }

      clonePersonal.address.zipcode = clonePersonal.address.zipcode.replace('-', '')

      personalNodeService.createPersonal(clonePersonal)
        .then(() => {
          this.setSnackbar({
            show: true,
            message: 'Personal salvo com sucesso',
            color: 'success'
          })
          this.$emit('updateList')
          this.resetForm()
          this.close()
        })
        .catch((error) => {
          console.error('Erro ao salvar personal:', error.response)
          this.setSnackbar({
            show: true,
            message: 'Erro ao salvar personal: ' + error.response.data.message,
            color: 'error'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    close () {
      this.setLoader({ show: false })
      this.$refs.form.resetValidation()
      this.setPersonal(this.personalCache)
      this.$emit('update:dialog', false)
    },

    async handleCEPChange () {
      const cep = this.getCurrentPersonal.address.zipcode.replace('-', '')
      if (cep.length === 8) {
        const addressData = await fetchAddressByCEP(cep)
        if (addressData && !addressData.erro) {
          this.getCurrentPersonal.address.street = addressData.logradouro
          this.getCurrentPersonal.address.neighborhood = addressData.bairro
          this.getCurrentPersonal.address.city = addressData.localidade
          this.getCurrentPersonal.address.state = addressData.uf
        } else {
          this.setSnackbar({
            show: true,
            message: 'CEP não encontrado',
            color: 'error'
          })
        }
      }
    },

    getPlan (id) {
      const plan = this.plans.find(plan => plan.id === id)
      return plan || { name: '', description: '', frequency: '', value: '' }
    },
    frequencyText (value) {
      return this.frequencies.find(frequency => frequency.value === value).text
    },

    async getPlans () {
      this.setLoader({ show: true })
      try {
        const response = await PlanService.getPlansPagination(1, 999, 'master')
        this.plans = response.data.results.map(plan => ({
          description: plan.description,
          frequency: plan.frequency,
          id: plan.id,
          name: plan.name,
          value: plan.value
        }))
      } catch (error) {
        console.error('Erro ao buscar planos:', error.response)
        this.setSnackbar({
          show: true,
          message: 'Erro ao buscar planos',
          color: 'error'
        })
      } finally {
        this.setLoader({ show: false })
      }
    }
  },
  computed: {
    ...mapGetters('personal', ['getCurrentPersonal'])
  },
  watch: {
    dialog (val) {
      if (val) {
        this.personalCache = clonedeep(this.$store.getters['personal/personal'])
        this.getPlans()
      }
    }
  }
}
</script>

<style scoped>
p {
  max-height: 100px;
  overflow-y: auto;
}

p::-webkit-scrollbar {
  width: 5px;
}

p::-webkit-scrollbar-track {
  background: #f1f1f1;
}

p::-webkit-scrollbar-thumb {
  background: #888;
}

p::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
