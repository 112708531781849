<template>
  <v-row class="mainContainer">
    <v-col class="login-admin-background pa-0" lg="5" md="5" sm="5" xs="12">
      <v-row class="align-center justify-end" style="height: 100%">
        <Logo imgColor="white" admin />
      </v-row>
    </v-col>
    <v-col class="mainContent">
      <v-row class="responsive-line" style="height: 100%">
        <v-card elevation="0" class="responsive-card">
          <v-card-title class="justify-center titleCardLogin">
            Administrador Entrar
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="email" color="secondary" label="Email" filled dense />
            <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword" :type="showPassword ? 'text' : 'password'"
              @keyup.enter="login" color="secondary" label="Senha" filled dense />
            <v-btn block color="primary" @click="login">
              Entrar
            </v-btn>
            <v-row class="justify-center mb-6 mt-2">
              <v-dialog width="480" v-model="dialog">
                <template v-slot:activator="{ on }">
                  <v-btn color="blue darken-2" text small v-on="on">
                    Esqueci a senha
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline primary white--text" primary-title>
                    Resetar a senha
                  </v-card-title>

                  <v-card-text>
                    <div>
                      <span>Digite seu e-mail</span>
                    </div>
                    <div>
                      <v-text-field v-model="resetPasswordEmail" color="secondary" label="Email" hide-details filled
                        dense />
                    </div>

                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="resetPasswordInstructions">
                      Enviar instruções
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card-text>

          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import adminAuthenticationService from '@/services/admin-authentication-service'
import axiosInstance from '@/services/axios-instance'
import axiosNodeInstance from '@/services/axios-node-instance'
import { mapActions } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'admin-login',
  components: {
    Logo
  },
  data: () => ({
    showPassword: false,
    email: '',
    password: '',
    dialog: false,
    resetPasswordEmail: ''
  }),
  methods: {
    ...mapActions('base', ['setLoader']),
    ...mapActions('base', ['setSnackbar']),
    ...mapActions('auth', ['loginUser']),
    login () {
      this.setLoader({ show: true })
      const payload = {
        email: this.email.toLowerCase(),
        password: this.password
      }
      adminAuthenticationService.login(payload)
        .then(({ data }) => {
          localStorage.setItem('access_token', data.token)
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`
          axiosNodeInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`
          this.$router.push({ name: 'AdminHomeLayout' })
        })
        .catch((request) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: request.response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    resetPasswordInstructions () {
      this.setLoader({ show: true })
      adminAuthenticationService.resetPasswordInstructions({ email: this.resetPasswordEmail.toLowerCase() })
        .then(({ data }) => {
          this.setSnackbar({
            show: true,
            message: 'Por favor, siga as instruções enviadas no email!'
          })
        })
        .catch((request) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: request.response.data.errors.detail
          })
        })
        .finally(() => {
          this.resetPasswordEmail = ''
          this.dialog = false
          this.setLoader({ show: false })
        })
    }
  }
}
</script>

<style lang="scss">
.login-admin-background {
  height: 100vh;
  background-image: url('../../../assets/images/login-admin-background.png');
  background-size: cover;
}

.mainContainer {
  height: 100vh;
  display: flex;
}

.responsive-card {
  width: 40%;
  margin-left: 12%;
  margin-top: 48px;
}

.responsive-line {
  align-items: center;
  justify-content: start;
}

.titleCardLogin {
  word-break: break-word;
}

@media (min-width: 1100px) and (max-width: 1400px) {

  .responsive-line {
    justify-content: center;
  }

  .responsive-card {
    width: 70%;
    margin: 0 5%;
    margin-left: 6%;
    margin-top: 48px;

  }
}

@media (max-width: 1099px) {

  .responsive-line {
    align-items: center;
    justify-content: center;
  }

  .responsive-card {
    width: 100%;
    margin: 0 5%;
    margin-top: 48px;

  }
}

@media (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .login-background {
    width: 100%;
    flex-grow: 1;
  }

  .login-background .row {
    margin: 0;
  }

  .login-background img {
    width: 100%;
  }

  .mainContent {
    flex-grow: 3;

  }

  .responsive-card {
    width: 90%;
    margin: 0;
    margin-top: 24px;
    padding: 0;
  }
}
</style>
