<template>
  <v-row class="mainContainer">
    <v-col class="login-background pa-0" lg="5" md="5" sm="5" xs="12">
      <v-row class="align-center justify-end" style="height: 100%">
        <Logo imgColor="white" />
      </v-row>
    </v-col>
    <v-col class="mainContent">
      <v-row class="responsive-line" style="height: 100%">
        <v-card elevation="0" class="responsive-card">
          <v-card-title class="text-title font-weight-black mb-12">
            Confirmar Cadastro
          </v-card-title>
          <v-card-text class="password-form">
            <span class="title subtitle">
              Defina sua senha de acesso abaixo:
            </span>
            <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword" :type="showPassword ? 'text' : 'password'"
              @keyup.enter="login" color="secondary" label="Senha" filled dense />

            <v-row class="justify-end pa-3">
              <v-btn color="secondary" text @click="$router.push({ name: 'Login' })">
                Cancelar
              </v-btn>
              <v-btn color="secondary" class="ml-3" @click="confirmPassword">
                Finalizar
              </v-btn>
            </v-row>

          </v-card-text>

          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import authenticationService from '@/services/authentication-service'
import { mapActions } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'confirm-student-account',
  components: {
    Logo
  },
  data: () => ({
    password: '',
    showPassword: false
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    confirmPassword () {
      this.setLoader({ show: true })
      const token = this.$router.currentRoute.query.token

      authenticationService.confirmUserAccount(token, this.password)
        .then(() => {
          this.setSnackbar({
            show: true,
            message: 'Cadastro confirmado com sucesso!'
          })
        })
        .catch((response) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.$router.push({ name: 'Login' })
        })
    }
  }
}
</script>

<style scoped>
.login-background {
  height: 100vh;
  background-image: url('../../assets/images/register-background.png');
  background-size: cover;
}

.text-title {
  font-size: 40px;
}

.password-form {
  max-width: 450px;
}

@media (min-width: 600px) and (max-width: 800px) {
  .text-title {
    font-size: 30px;

  }

  .subtitle {
    font-size: 16px !important;
  }
}

@media (max-width: 598px) {
  .subtitle {
    font-size: 16px !important;
  }

  .text-title {
    font-size: 28px;
    display: flex;
    justify-content: center;

  }

  .mainContainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .login-background {
    width: 100%;
    flex-grow: 1;
  }

  .login-background .row {
    margin: 0;
  }

  .login-background img {
    width: 100%;
  }

  .mainContent {
    flex-grow: 3;

  }

  .responsive-card {
    width: 90%;
    margin: 0;
    margin-top: 24px;
    padding: 0;
  }
}
</style>
