<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          text
          class="transparent text-none text--secondary mx-0 px-0"
          @click="$router.push({ name: 'MuscleGroupList' })"
        >
          <v-icon left>mdi-chevron-left</v-icon> Voltar para grupos</v-btn
        >
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="auto" class="title font-weight-black">
        <span v-if="!isEditMode">Grupo {{ muscleGroup.name }}</span>

        <v-text-field
          v-else
          v-model="newMuscleGroupName"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-btn v-if="!isEditMode" small icon @click="onEditMuscleGroup()">
          <v-icon size="20">mdi-pencil</v-icon>
        </v-btn>

        <template v-else>
          <v-btn
            :disabled="!canSave"
            small
            rounded
            color="success"
            class="mr-4 px-0"
            @click.stop="onSaveMuscleGroup()"
          >
            <v-icon size="20">mdi-check</v-icon>
          </v-btn>

          <v-btn
            small
            icon
            color="error"
            @click.stop="isEditMode = false"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-col>

      <v-spacer />

      <v-col cols="auto" v-if="false">
        <v-btn color="primary" outlined @click="onAddPosturalDeviation()">adicionar desvio</v-btn>
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="deviation in  muscleGroup.postural_deviations"
          :key="deviation.id"

        >
          <v-expansion-panel-header class="justify-center row">
            <v-col cols="auto">
              {{ deviation.name }}
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-row justify="end" class="mr-4" >
                <v-btn icon @click.stop="onEditPosturalDeviation(deviation)" v-if="false">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon class="mx-2" @click.stop="onDestroyPosturalDeviation(deviation) "  v-if="false">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  outlined
                  color="secondary"
                  @click.stop="addExercise(muscleGroup.id, deviation.id)"
                  >adicionar exercicio</v-btn
                >
              </v-row>
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :items="deviation.exercises"
              :headers="headers"
              hide-default-footer
              :items-per-page="getExerciseCounter(deviation.exercises)"
            >
              <template v-slot:item.actions="{ item }" >
                <v-btn icon @click="onEditExercise(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="confirmRemoveFromExercises(item, deviation.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.exercise_type="{ item }">
                {{ getExerciseType(item) }}
              </template>

              <template v-slot:no-data>
                Não existem exercícios para este desvio, adicione um exercício
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <select-exercise-dialog
      v-if="showSelectExerciseDialog"
      :dialog="showSelectExerciseDialog"
      @close="onClose"
    />

    <confirm-dialog
      title="Atenção"
      :message="confirmationDialog.message"
      cancelButton="Cancelar"
      :confirmButton="confirmationDialog.confirmButton"
      :confirmationDialog="confirmationDialog.show"
      @confirm="confirmationDialog.run(confirmationDialog.payload)"
      @cancel="closeConfirmationDialog()"
    />

    <create-or-update-exercise-dialog
      v-if="createOrUpdateExerciseDialog"
      :dialog="createOrUpdateExerciseDialog"
      :editedItem="editedItem"
      @close="afterEditExercise($event)"
    />
    <create-or-update-postural-deviation-dialog
      :dialog.sync="createOrUpdatePosturalDeviationDialog"
      :editItem="editPosturalDeviation"
      :muscleGroupId="muscleGroup.id"
      @update="fetchMuscleGroup(id)"
    />
  </v-container>
</template>

<script>
import MuscleService from '@/services/muscle-service.js'
import { mapActions } from 'vuex'
import CreateOrUpdatePosturalDeviationDialog from '@/components/personal/configurations/muscleGroups/CreateOrUpdatePosturalDeviationDialog'
import CreateOrUpdateExerciseDialog from '@/components/admin/configurations/exercises/CreateOrUpdateExerciseAdminDialog'
import ConfirmDialog from '@/components/base/ConfirmDialog'
import SelectExerciseDialog from '@/components/personal/configurations/muscleGroups/SelectExerciseDialog'

export default {
  props: ['id'],
  components: {
    SelectExerciseDialog,
    ConfirmDialog,
    CreateOrUpdateExerciseDialog,
    CreateOrUpdatePosturalDeviationDialog
  },
  data () {
    return {
      isEditMode: false,
      newMuscleGroupName: '',
      muscleGroup: {},
      state: {},
      showSelectExerciseDialog: false,
      createOrUpdateExerciseDialog: false,
      createOrUpdatePosturalDeviationDialog: false,
      editedItem: {},
      editPosturalDeviation: {},
      confirmationDialog: {
        show: false,
        payload: {}
      },
      exerciseTypes: [
        { name: 'Mobilidade', value: 'mobility' },
        { name: 'Funcional', value: 'functional' }
      ],
      headers: [
        { text: 'Exercício', sortable: false, value: 'name' },
        { text: 'Tipo de exercício', sortable: false, value: 'exercise_type' },
        { text: 'Vídeo Explicativo', sortable: false, value: 'link' },
        {
          text: 'Ações',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '120px'
        }
      ]
    }
  },
  mounted () {
    this.fetchMuscleGroup(this.id)
  },
  computed: {
    canSave () {
      return !!this.newMuscleGroupName.replace(/ /g, '') && this.newMuscleGroupName.length > 3
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    getExerciseCounter (exercises) {
      try {
        return exercises.length + 1
      } catch (error) {
        return 0
      }
    },
    getPostural () {
      var t = this.muscleGroup.postural_deviations
      t = t.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.size > b.size) ? 1 : -1) : -1)
      return t
    },
    getExerciseType (exercise) {
      try {
        const type = this.exerciseTypes.find(
          type => type.value === exercise.exercise_type
        )
        return type.name
      } catch (error) {
        return 'Sem classificação'
      }
    },
    fetchMuscleGroup (id) {
      this.setLoader({ show: true })
      MuscleService.getById(id)
        .then(({ data }) => {
          this.muscleGroup = data.muscle_group
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
          if (response.data.errors.status === '404') {
            this.$router.push({ name: 'MuscleGroupList' })
          }
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    addExercise (muscleGroupId, posturalDeviationId) {
      this.state = { muscleGroupId, posturalDeviationId }
      this.showSelectExerciseDialog = true
    },
    onClose (exercise) {
      this.showSelectExerciseDialog = false
      if (!exercise) {
        return
      }

      let { muscleGroupId, posturalDeviationId } = this.state

      const idx = this.muscleGroup.postural_deviations.findIndex(
        deviation => deviation.id === posturalDeviationId
      )

      this.setLoader({ show: true })
      MuscleService.addExercise(muscleGroupId, posturalDeviationId, exercise.id)
        .then(({ data }) => {
          this.muscleGroup.postural_deviations[idx].exercises.push(exercise)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.state = {}
        })
    },
    confirmRemoveFromExercises (item, deviationId) {
      this.confirmationDialog = {
        payload: { item, deviationId },
        show: true,
        run: this.removeFromExercises,
        confirmButton: 'Desvincular',
        message: 'Deseja desvincular o exercício selecionado?'
      }
    },
    closeConfirmationDialog () {
      this.confirmationDialog = { show: false }
    },
    removeFromExercises ({ item, deviationId }) {
      const muscleGroupId = this.muscleGroup.id
      const posturalDeviations = this.muscleGroup.postural_deviations
      const deviationIdx = posturalDeviations.findIndex(
        deviation => deviation.id === deviationId
      )
      const itemIdx = posturalDeviations[deviationIdx].exercises.findIndex(
        exercise => exercise.id === item.id
      )

      this.setLoader({ show: true })
      MuscleService.unlinkExercise(muscleGroupId, deviationId, item.id)
        .then(() => {
          try {
            posturalDeviations[deviationIdx].exercises.splice(itemIdx, 1)
          } catch (error) {
            console.log(error)
          }
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Exercício desvinculado'
          })
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.closeConfirmationDialog()
        })
    },
    onEditExercise (exercise) {
      this.editedItem = exercise
      this.createOrUpdateExerciseDialog = true
    },
    afterEditExercise (state) {
      this.editedItem = {}
      this.createOrUpdateExerciseDialog = false
      if (state) this.fetchMuscleGroup(this.id)
    },
    onEditMuscleGroup () {
      this.newMuscleGroupName = this.muscleGroup.name
      this.isEditMode = true
    },
    onSaveMuscleGroup () {
      const payload = {
        muscle_group: {
          name: this.newMuscleGroupName
        }
      }

      this.setLoader({ show: true })

      MuscleService.update(this.muscleGroup.id, payload)
        .then(({ data }) => {
          this.muscleGroup = data.muscle_group
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Grupo muscular atualizado'
          })
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.isEditMode = false
        })
    },
    onEditPosturalDeviation (posturalDeviation) {
      this.editPosturalDeviation = posturalDeviation
      this.createOrUpdatePosturalDeviationDialog = true
    },
    onAddPosturalDeviation () {
      this.editPosturalDeviation = {
        name: '',
        type: '',
        properties: []
      }
      this.createOrUpdatePosturalDeviationDialog = true
    },
    onDestroyPosturalDeviation (posturalDeviation) {
      this.confirmationDialog = {
        payload: posturalDeviation,
        show: true,
        run: this.removePosturalDeviation,
        confirmButton: 'remover',
        message: 'Deseja remover o desvio postural selecionado?'
      }
    },
    removePosturalDeviation (posturalDeviation) {
      this.setLoader({ show: true })

      MuscleService.destroyPosturalDeviation(posturalDeviation.id)
        .then(({ data }) => {
          this.fetchMuscleGroup(this.id)
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Desvio postural removido'
          })
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.closeConfirmationDialog()
        })
    }
  }
}
</script>

<style></style>
