<template>
  <v-text-field
    v-model="searchText"
    append-icon="mdi-magnify"
    @input="emitText($event)"
    :placeholder="label"
    solo
    dense
    hide-details
    :loading="loading"
  />
</template>

<script>
export default {
  name: 'search-field',
  props: {
    label: {
      type: String,
      default: 'Buscar...'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchText: '',
    updateText: null
  }),
  methods: {
    emitText (val) {
      clearTimeout(this.updateText)
      this.updateText = setTimeout(() => {
        this.$emit('searchText', val)
      }, 500)
    }
  }
}
</script>

<style scoped>

</style>
