<template>
  <v-col
    sm="6"
    md="4"
    lg="3"
    xl="2"
    @click="$router.push({name: 'PersonalShow', params: {id: personal.id} })">
    <div style="min-height: 200px;"
      :class="[{'elevation-3': personal.is_active, 'inactive-card':!personal.is_active}, 'pa-3']"
    >
      <v-row>
        <v-col cols="3">
          <v-avatar>
            <v-img
              :src="personal.photo_url || require('@/assets/images/user-placeholder.png')"
              :alt="personal.fullname "
              :lazy-src="require('@/assets/images/user-placeholder.png')"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </v-col>
        <v-col
          cols="9">
          <div>
            <span
              class="subtitle-1 font-weight-bold">{{ personal.fullname || defaultPersonal.fullname | reduceName}}</span>
          </div>
          <div>
            <span class="caption"> Último Login: {{lastLogin}} </span>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row>
        <v-col cols="10">
          <v-chip v-if="personal.email"
          small class="my-2" :class="personal.is_active ? 'primary lighten-3' : 'inactive-chip'">
            {{ personal.email }}
          </v-chip>
          <v-chip  v-if="personal.age"
          small class="mr-2" :class="personal.is_active ? 'primary lighten-3' : 'inactive-chip'">
            {{ personal.age}} anos
          </v-chip>
          <v-chip v-if="personal.phone"
          small :class="personal.is_active ? 'primary lighten-3' : 'inactive-chip'">
            {{ personal.phone }}
          </v-chip>
        </v-col>
      </v-row>
    </div>

  </v-col>
</template>

<script>
import { formatBrazilianDate } from '@/filters/format-date-to-br'

export default {
  name: 'personal-card',
  props: {
    personal: {
      type: Object,
      default: () => ({})
    }
  },
  data: (vm) => ({
    defaultPersonal: {
      photo: 'https://cdn.vuetifyjs.com/images/john.jpg',
      fullname: '',
      last_login: 'Nunca',
      email: '',
      age: '',
      phone: ''
    }
  }),
  computed: {
    lastLogin () {
      return formatBrazilianDate(this.personal.last_sign_in_at) || this.defaultPersonal.last_sign_in_at || 'Nunca'
    }
  }
}
</script>

<style scoped>
  .background-list-personal:hover {
    background: #dfd7ff;
  }

  .inactive-chip{
   background: #E5E5E9 !important;
  }

  .inactive-card{
    background: #f9f9ff !important;
    border: solid #f4f4f4 1px;
  }
</style>
