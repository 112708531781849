<template>
  <div>
    <v-btn color="primary" block @click="createMesocycle({ id: null })">
      <v-icon class="mr-2">
        mdi-file-table-box-multiple
      </v-icon>
      ADICIONAR MESO
    </v-btn>

    <create-or-update-meso-dialog :dialog.sync="createOrUpdateMesoDialog" :editedItem="mesocycle" :editedIndex="-1"
      :macrocycleId="macrocycleId" @update="updateItem(payload, index)" />
  </div>
</template>

<script>

import CreateOrUpdateMesoDialog from '@/components/student/show/CreateOrUpdateMesoDialog'
export default {
  name: 'student-meso-cycle-btn-new-meso',
  props: ['macrocycleId', 'macrocycle', 'newMesocycle'],
  components: {
    CreateOrUpdateMesoDialog
  },
  data: () => ({ createOrUpdateMesoDialog: false, mesocycle: {} }),
  methods: {
    createMesocycle (item) {
      const startDate = new Date().toISOString().substr(0, 10)
      this.mesocycle = Object.assign({}, { ...item, name: `Mesociclo ${this.macrocycle.mesocycles.length + 1}`, start_date: `${startDate}` })
      const vm = this
      this.$nextTick(() => {
        if (vm.newMesocycle) {
          vm.mesocycle.start_date = vm.newMesocycle.start_date
        }
      })
      this.createOrUpdateMesoDialog = true
    }
  },
  watch: {
    createOrUpdateMesoDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
