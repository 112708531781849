import axios from './axios-node-instance'

export default {
  createPersonal (payload) {
    return axios.post('personal-trainer/', payload)
  },

  getPersonals (payload) {
    return axios.get('user/personal/', { params: payload })
  },

  getPersonal (id) {
    return axios.get(`user/${id}`)
  },

  updatePersonal (payload, id) {
    return axios.put(`user/${id}`, payload)
  }

}
