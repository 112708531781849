<template>
  <v-col
    cols="4"
    class="pr-2"

  >
  <v-row
    class="d-flex flex-column elevation-3 pa-3"
      no-gutters
    >
    <v-row
    >

      <v-col>
        <span
          class="text-uppercase"
        >
          Freq cardíaca e pressão arterial
        </span>
        <v-divider class="mt-1 mb-2"></v-divider>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="d-flex flex-row"
    >
      <v-col
        class="py-0 pl-0 pr-1"
        cols="5"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.heart.fcRepouso"
          :readonly="readonly"
          :disabled="readonly"
          class="dense-padding-input-slot"
          filled
          label="FC Repouso"
          suffix="bpm"
          v-mask="['###']"
        />
      </v-col>
      <v-col
        class="py-0 px-1"
        cols="3"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.heart.sistole"
          :readonly="readonly || isStudent"
          :disabled="readonly || isStudent"
          class="dense-padding-input-slot"
          filled
          label="Sístole"
          v-mask="['###']"
        />
      </v-col>
      <v-col
        class="py-4 px-0 text-center"
        cols="1"
      >
        /
      </v-col>
      <v-col
        class="py-0"  cols="3"
      >
        <v-text-field
          v-model="cycleToBeEvaluated.evaluated_items.heart.diastole"
          :readonly="readonly || isStudent"
          :disabled="readonly || isStudent"
          class="dense-padding-input-slot"
          filled
          label="Diástole"
          v-mask="['###']"
        />
      </v-col>
    </v-row>
  </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'heart-info',
  data: () => ({
    cycleToBeEvaluated: {
      evaluated_items: {
        heart: {
          fcRepouso: null,
          sistole: null,
          diastole: null
        }
      }
    }
  }),
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    isStudent: {
      default: false,
      type: Boolean
    },
    cycle: {
      default: '',
      type: String
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated'])
  },
  methods: {
    setCycle () {
      this.cycleToBeEvaluated = this.cycle === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    }
  },
  mounted () {
    this.setCycle()
  }
}
</script>

<style scoped>
</style>
