<template>
  <div v-if="visible" class="drag-content" @mousedown="onMouseDown" @mousemove="onDrag" @mouseenter="hover = true"
    @mouseleave="hover = false">
    <div v-show="visible" ref="grade" :style="style">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'draggable-grade',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    hasContent: {
      type: Boolean,
      default: false
    },
    zoomBase: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    hover: false,
    Visible: false,
    size: 64,
    color: 'red', // %23 = #
    borderWidth: 2,
    draggable: false,
    zoom: 1,
    currentPoint: {
      x: 0,
      y: 0
    },
    position: {
      x: 0,
      y: 0
    }
  }),
  computed: {
    style () {
      if (this.hasContent) {
        return {
          position: 'absolute',
          transform: `scale(${this.zoom + this.zoomBase}, ${this.zoom + this.zoomBase})`,
          top: `${this.position.y}px`,
          left: `${this.position.x}px`,
          width: '100%',
          height: '100%'
        }
      }

      return {
        'background-image': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='${this.size}' width='${this.size}'%3E%3Cpolygon points='0,0 0,${this.size} ${this.size},${this.size} ${this.size},0' style='fill:transparent;stroke:${this.color};stroke-width:${this.borderWidth}' /%3E Sorry, your browser does not support inline SVG.%0A%3C/svg%3E")`,
        backgroundRepeat: 'repeat',
        opacity: '0.5',
        position: 'absolute',
        top: `calc(-100% + ${this.position.y}px)`,
        left: `calc(-100% + ${this.position.x}px)`,
        width: '300%',
        height: '300%'
      }
    }
  },
  methods: {
    onMouseDown ({ offsetX, offsetY }) {
      if (!this.Visible) { return }
      if (!this.draggable) {
        this.currentPoint = {
          x: offsetX,
          y: offsetY
        }
      }
      this.draggable = true
    },
    onDrag ({ movementX, movementY }) {
      if (!this.Visible) { return }
      if (!this.draggable) { return }

      let maxRange

      maxRange = this.$refs.grade.offsetWidth

      this.position.x =
        this.position.x + movementX >= maxRange
          ? maxRange
          : this.position.x + movementX <= -maxRange
            ? -maxRange
            : this.position.x + movementX

      maxRange = this.$refs.grade.offsetHeight

      this.position.y =
        this.position.y + movementY > maxRange
          ? maxRange
          : this.position.y + movementY < -maxRange
            ? -maxRange
            : this.position.y + movementY
    },
    onZoom ({ deltaY, deltaZoom }) {
      if (!this.Visible) { return }

      if (this.hasContent) {
        this.zoom += deltaZoom
        if (this.zoom <= 0.5) {
          this.zoom = 0.5
        } else if (this.zoom >= 3.0) {
          this.zoom = 3.0
        }
        return
      }

      if (deltaY > 0) { this.size += 8 } else { this.size -= 8 }
      if (this.size < 32) {
        this.size = 32
      } else if (this.size > 256) {
        this.size = 256
      }
    },
    toggleVisibility () {
      this.Visible = !this.Visible
      this.$emit('update:visible', this.Visible)
    }
  },
  mounted () {
    // global mouseup listner to interrupt drag
    window.addEventListener('mouseup', e => {
      this.draggable = false
      this.currentPoint = { x: 0, y: 0 }
    })
    this.Visible = this.visible || this.hasContent
  }
}
</script>
<style lang="scss">
.drag-content {
  position: absolute;
  top: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.visibility-btn {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
