<template>
  <dialog-full-screen :dialog="studentEvaluationDialogMicro" titleName="Avaliação do Aluno" @close="closeDialog()">
    <v-container>

      <student-header-info :cycle="cycle" />
      <v-row>

        <student-info :cycle="cycle" :readonly="microcycleIsFinished" />
        <heart-info :cycle="cycle" :readonly="microcycleIsFinished" />
        <biochemical-info :cycle="cycle" :isStudent="userIsStudent" :readonly="microcycleIsFinished || userIsStudent" />

      </v-row>
      <!-- <v-row>
        <vcol>

        </vcol>
      </v-row> -->
      <v-row>
        <v-col cols="12">
          <v-tabs class="student-evaluation-tabs">
            <template v-for="(tab, index) in evaluatedTabs">
              <v-tab :key="`tab-${index}`">
                {{ allTabs[tab].title }}
              </v-tab>
              <v-tab-item :key="`tab-item-${index}`" eager>
                <component :is="allTabs[tab].component" :cycle="cycle" :readonly="microcycleIsFinished" :isStudent="userIsStudent"
                  v-if="studentEvaluationDialogMicro" @closeDialog="closeDialog()"
                  @updateMicrocycleEvaluation="update();" />
              </v-tab-item>
            </template>
            <v-spacer />
            <v-btn color="primary" class="mt-2" @click="checkEvaluationPdfDownload">
              Gerar PDF da avaliação
            </v-btn>
            <v-btn color="primary" class="mt-2 ml-2" @click="goToTraining">
              Ir para treino
            </v-btn>
          </v-tabs>
        </v-col>
      </v-row>

    </v-container>

    <confirm-dialog title="Alteração não salva"
      message="Para baixar a avaliação é necessário que esteja salva. Deseja continuar?" cancelButton="Cancelar"
      confirmButton="Continuar" :confirmationDialog="updateEvaluationDialog" @confirm="downloadEvaluationPdf()"
      @cancel="() => updateEvaluationDialog = false" />
  </dialog-full-screen>
</template>

<script>
import DialogFullScreen from '@/components/base/DialogFullScreen'
import StudentHeaderInfo from '@/components/student/show/studentEvaluation/StudentHeaderInfo'
import StudentInfo from '@/components/student/show/studentEvaluation/StudentInfo'
import HeartInfo from '@/components/student/show/studentEvaluation/HeartInfo'
import BiochemicalInfo from '@/components/student/show/studentEvaluation/BiochemicalInfo'
import Perimetria from '@/components/student/show/studentEvaluation/Perimetria'
import Somatotipo from '@/components/student/show/studentEvaluation/Somatotipo'
import Postura from '@/components/student/show/studentEvaluation/Postura'
import GastoCalorico from '@/components/student/show/studentEvaluation/GastoCalorico'
import TesteForca from '@/components/student/show/studentEvaluation/TesteForca'
import ConfirmDialog from '@/components/base/ConfirmDialog'
import microcycleService from '@/services/microcycle-service'
import DocumentService from '@/services/document-service.js'
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep, isEqual } from 'lodash'

export default {
  name: 'student-micro-cycle-detail',
  components: {
    DialogFullScreen,
    Perimetria,
    Somatotipo,
    StudentInfo,
    StudentHeaderInfo,
    HeartInfo,
    BiochemicalInfo,
    GastoCalorico,
    Postura,
    ConfirmDialog,
    TesteForca
  },
  data () {
    return {
      allTabs: {
        caloric_expenditure: {
          title: 'Gasto Calórico',
          component: GastoCalorico
        },
        perimeter: {
          title: 'Perimetria',
          component: Perimetria
        },
        posture: {
          title: 'Postura',
          component: Postura
        },
        strength: {
          title: 'Teste de Força',
          component: TesteForca
        }
      },
      updateEvaluationDialog: false,
      evaluationCopy: {},
      microcycle: null,
      cycle: 'micro'
    }
  },
  mounted () {
    this.copyEvaluation()
  },
  methods: {
    ...mapActions('base', ['setSnackbar', 'fetchPdf']),
    ...mapActions('students', ['setStudentEvaluationDialogMicro', 'setMicrocycles', 'setMicrocycleToBeEvaluated', 'setMicrocycleEvaluation', 'setMicrocycleTraining', 'setStudentEvaluationDialogMicro', 'setTrainingDialogMicro']),
    closeDialog () {
      this.$emit('updateList')
      this.setStudentEvaluationDialogMicro(false)
    },
    copyEvaluation () {
      this.evaluationCopy = cloneDeep(this.microcycleToBeEvaluated.evaluated_items)
    },
    update () {
      this.updateEvaluation()
        .then((data) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Avaliação salva com sucesso'
          })
          this.copyEvaluation()
          // this.$emit('updateList')
        })
        .catch(() => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao salvar avaliação'
          })
        })
    },
    updateEvaluation () {
      const payload = { ...this.microcycleToBeEvaluated, evaluation: true }
      return microcycleService.microcycleSaveEvaluation(this.student.id, payload.macrocycle.id, payload)
    },
    valuesToCompare (object) {
      const {
        // eslint-disable-next-line camelcase
        caloric_expenditure: { aerobic_items, travel_distance, water },
        perimeter: { bodyPerimeter, protocolo, skinFold },
        somatotype: { femur, umero },
        biochemical,
        posture,
        heart,
        student
      } = object

      return {
        biochemical,
        aerobic_items,
        travel_distance,
        water,
        heart,
        bodyPerimeter,
        protocolo,
        skinFold,
        posture,
        femur,
        umero,
        student
      }
    },
    checkEvaluationPdfDownload () {
      if (!isEqual(this.valuesToCompare(this.evaluationCopy), this.valuesToCompare(this.microcycleToBeEvaluated.evaluated_items))) {
        this.updateEvaluationDialog = true
      } else {
        this.downloadEvaluationPdf()
      }
    },
    goToTraining () {
      const microcycle = this.microcycleToBeEvaluated
      microcycleService
        .getMicrocycleTraining(this.student.id, microcycle.macrocycle.id, microcycle.id)
        .then(({ data }) => {
          this.setStudentEvaluationDialogMicro(false)
          this.setMicrocycleToBeEvaluated(microcycle)
          this.setMicrocycleTraining(data)
          this.setTrainingDialogMicro(true)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
    },
    async downloadEvaluationPdf () {
      this.updateEvaluation()
        .then(() => {
          this.updateEvaluationDialog = false
          this.copyEvaluation()

          const payload = {
            studentId: this.microcycleToBeEvaluated.macrocycle.student_id,
            macrocycleId: this.microcycleToBeEvaluated.macrocycle.id,
            microcycleId: this.microcycleToBeEvaluated.id,
            cycle: 'micro'
          }

          const microcycleName = this.microcycleToBeEvaluated && this.microcycleToBeEvaluated.name ? this.microcycleToBeEvaluated.name : 'sem nome'
          const tag = Object.values(payload).join('.')
          const fileName = `${tag} - ${microcycleName}.pdf`
          this.fetchPdf({ service: DocumentService.getEvaluationPdf, payload, fileName })
        })
        .catch(() => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Não foi possível gerar o PDF'
          })
        })
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'students', 'studentEvaluationDialogMicro', 'microcycleToBeEvaluated']),
    ...mapGetters('auth', ['user']),
    userIsStudent () {
      return this.user.isStudent()
    },
    microcycleIsFinished () {
      const now = new Date()
      const endDate = new Date(`${this.microcycleToBeEvaluated.end_date} 23:59:59`)

      return endDate < now
    },
    evaluatedTabs () {
      try {
        let f = this.microcycleToBeEvaluated.macrocycle.items_to_be_evaluated

        f.sort()

        return [
          'posture',
          'caloric_expenditure',
          'perimeter',
          'strength'
        ]
        // return [...this.microcycleToBeEvaluated.macrocycle.items_to_be_evaluated, 'strength']
      } catch (error) {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1264px;
  }
}

.student-evaluation-tabs {
  &>.v-tabs-bar {
    border-bottom: solid 1px lightgrey !important;
  }
}
</style>
