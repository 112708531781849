<template>
  <v-container class="planContainer">
    <confirm-dialog title="Atenção" message="Deseja remover o plano selecionado? Essa ação não poderá ser desfeita"
      cancelButton="Cancelar" confirmButton="Remover" :confirmationDialog="confirmationDialog" @confirm="onDelete()"
      @cancel="closeConfirmationDialog()" />
    <v-row align="center" style="margin-bottom: 7px;">
      <v-col cols="4">
        <search-field label="Busque por nomes ou valor(ex: plano x ou 100)" :loading="loadingSearch"
          @searchText="searchText = $event" :color="defineColor()" />
      </v-col>

      <v-spacer />

      <!-- <v-col cols="auto" class="px-1 pl-2">
        <v-btn :color="defineColor()" fill @click="goToLanding()">LandingPage</v-btn>
      </v-col> -->

      <v-col cols="auto" class="px-1 pr-3">
        <v-btn :color="defineColor()" outlined @click="onAdd()">adicionar plano</v-btn>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="plansContainer no-overflowX">
      <v-row v-if="plans.length === 0" style="height: 100%">
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-icon>mdi-emoticon-sad-outline</v-icon>
          <span>Nenhum plano encontrado</span>
        </v-col>
      </v-row>
      <v-col cols="12" lg="4" md="6" sm="12" v-for="(plan, index) in plans" :key="index" class="planCard">
        <v-card height="220" class="d-flex flex-column">
          <v-card-title style="flex-wrap: nowrap;">
            <v-icon left>mdi-dumbbell</v-icon>
            <span class="truncate">{{ capitalizeFirstLetter(plan.name) }}</span>

            <v-card-actions style="padding: 0; margin-left: auto;">
              <v-btn icon @click.stop="onEdit(plan)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click.stop="selectItemRemove(index)" style="margin: 0; margin-right: 5px;">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-row class="statusActive">
                <v-switch v-model="plan.is_active" @change="togglePlanStatus(plan)" :color="defineColor()"
                  :label="plan.is_active === true ? 'Ativo' : 'Inativo'" />
              </v-row>
            </v-card-actions>
          </v-card-title>
          <p style="overflow-y: auto; flex-grow: 1; margin: 0 1rem;">
            {{ capitalizeFirstLetter(plan.description) }}
          </p>
          <v-row d-flex align="end" justify="space-between" class="mx-4 my-3">
            <span>Frequência: {{ frequencyText(plan.frequency) }}</span>
            <span>Valor: {{ formatCurrency(plan.value) }}</span>
          </v-row>
        </v-card>
      </v-col>

    </v-row>
    <v-row class="pagination" v-if="plans.length > 0">
      <v-pagination v-model="pageNumber" :length="totalPages" @input="handlePageChange" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PlanService from '@/services/plan-service.js'
import ConfirmDialog from '@/components/base/ConfirmDialog'
import SearchField from '@/components/base/SearchField'

export default {
  components: {
    ConfirmDialog,
    SearchField
  },
  data () {
    return {
      plans: [],
      ownerPlansID: '',
      ownerPlansName: '',
      pageNumber: 1,
      itensPerPage: 12,
      loadingSearch: false,
      searchText: '',
      frequencies: [
        { text: 'Mensal', value: 'monthly' },
        { text: 'Bimestral', value: 'bimonthly' },
        { text: 'Semestral', value: 'semiannually' },
        { text: 'Anual', value: 'yearly' }
      ],
      totalItens: 0,
      totalPages: 0,
      confirmationDialog: false,
      selectedItemToRemove: {},
      originalStatus: null
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    isUserAdmin () {
      return this.$store.getters['auth/user'].isAdmin()
    },
    isUserPersonal () {
      return this.$store.getters['auth/user'].isPersonal()
    },
    userId () {
      return this.$store.getters['auth/user'].id
    }
  },
  mounted () {
    const user = this.$store.getters['auth/user']
    if (user) {
      this.ownerPlansID = user.role_id
      this.ownerPlansName = user.fullname.replace(/\s/g, '').toLowerCase()
      this.fetchPlans(this.pageNumber, this.itensPerPage)
    } else {
      this.ownerPlansName = 'user'
    }
  },
  watch: {
    searchText: {
      handler (newSearchText) {
        this.pageNumber = 1
        this.fetchPlans(this.pageNumber, this.itensPerPage, newSearchText)
      },
      immediate: true
    },
    user: {
      handler: function (newVal) {
        if (newVal && (this.isUserAdmin || this.isUserPersonal)) {
          this.fetchPlans(this.pageNumber, this.itensPerPage)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),

    formatCurrency (value) {
      if (isNaN(value) || value === null || value === undefined) {
        return '0,00'
      }

      let number = parseFloat(value)
      return number.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },

    fetchPlans (page, itemsPerPage, search = '') {
      this.setLoader({ show: true })

      let ownerBy = this.isUserAdmin ? 'master' : 'personal_trainer'
      let ownerById = ''

      if (this.isUserPersonal) {
        ownerById = this.user.role_id
      }

      if (search !== '') {
        search = search.replace(/,/g, '.')
      }

      PlanService.getPlansPagination(page, itemsPerPage, ownerBy, ownerById, search)
        .then(({ data }) => {
          this.plans = data.results
          this.totalItens = data.total
          this.totalPages = Math.ceil(data.total / this.itensPerPage)
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },

    handlePageChange () {
      this.fetchPlans(this.pageNumber, this.itensPerPage)
    },

    defineColor () {
      return this.isUserAdmin ? 'secondary' : 'primary'
    },

    onAdd () {
      if (this.isUserPersonal) {
        this.$router.push({ name: 'PlansPersonalNew' })
      } else if (this.isUserAdmin) {
        this.$router.push({ name: 'PlansNew' })
      }
    },
    selectItemRemove (index) {
      this.selectedItemToRemove = this.plans[index]
      this.confirmationDialog = true
    },
    onDelete () {
      this.setLoader({ show: true })
      PlanService.destroyPlan(this.selectedItemToRemove.id)
        .then(() => {
          this.fetchPlans(this.pageNumber, this.itensPerPage)
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Plano removido com sucesso'
          })
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: 'Erro ao remover plano'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.closeConfirmationDialog()
        })
    },
    onEdit (plan) {
      if (this.isUserPersonal) {
        this.$router.push({
          name: 'PlansPersonalNew',
          params: {
            planToEdit: plan,
            isEditMode: true
          }
        })
      } else if (this.isUserAdmin) {
        this.$router.push({
          name: 'PlansNew',
          params: {
            planToEdit: plan,
            isEditMode: true
          }
        })
      }
    },
    // goToLanding () {
    //   const user = this.$store.getters['auth/user']
    //   if (user) {
    //     this.ownerPlansID = user.role_id || ''
    //     this.ownerPlansName = user.fullname
    //       ? user.fullname
    //         .normalize('NFD')
    //         .replace(/[\u0300-\u036f]/g, '')
    //         .replace(/[^a-zA-Z0-9]/g, '')
    //         .toLowerCase()
    //       : 'user'
    //   }

    //   if (this.ownerPlansID && this.ownerPlansName) {
    //     if (this.isUserAdmin) {
    //       window.open(`https://venda.fisicsoft.com/?fisicUser=1`, '_blank')
    //     } else if (this.isUserPersonal) {
    //       window.open(`https://venda.fisicsoft.com/?${this.ownerPlansName}=${this.ownerPlansID}`, '_blank')
    //     }
    //   } else {
    //     this.setSnackbar({
    //       show: true,
    //       color: 'error',
    //       message: 'Informações de redirecionamento indisponíveis'
    //     })
    //   }
    // },
    frequencyText (value) {
      return this.frequencies.find(frequency => frequency.value === value).text
    },
    capitalizeFirstLetter (string) {
      if (!string) {
        return ''
      } else {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    closeConfirmationDialog () {
      this.confirmationDialog = false
      this.selectedItemToRemove = {}
    },

    togglePlanStatus (plan) {
      const originalStatus = plan.is_active

      this.$set(plan, 'is_active', !originalStatus)

      this.setLoader({ show: true })

      console.log(originalStatus)

      PlanService.updatePlan(plan.id, { is_active: originalStatus })
        .then(() => {
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Status do plano alterado com sucesso'
          })
        })
        .catch(({ response }) => {
          this.$set(plan, 'is_active', originalStatus)
          this.setSnackbar({
            show: true,
            color: 'error',
            message: 'Erro ao alterar status do plano'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    }
  }
}
</script>

<style scoped>
p {
  max-height: 100px;
  overflow-y: auto;
}

p::-webkit-scrollbar {
  width: 5px;
}

p::-webkit-scrollbar-track {
  background: #f1f1f1;
}

p::-webkit-scrollbar-thumb {
  background: #888;
}

p::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.planContainer {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}

.no-overflowX {
  overflow-x: hidden;
}

.plansContainer {
  align-content: flex-start;
  margin-top: 7px;
  overflow-y: auto;
  height: 85%;
  flex: 100%;
}

.planCard {
  height: fit-content;
}

.pagination {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 15px 0;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

.statusActive {
  padding: 0;
  margin: 0;
  min-width: 95px;
}

.v-messages {
  display: none;
}

.v-input {
  margin: 0;
}

::v-deep .v-messages {
  display: none !important;
}

::v-deep .v-input--selection-controls__ripple {
  display: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
