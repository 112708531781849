<template>
  <v-container>
    <!-- <v-row>
      <v-col class="pb-0">
        <v-btn
          text
          class="transparent text-none text--secondary mx-0 px-0"
          @click="isUserPersonal ? $router.push({ name: 'StudentList' }) : isUserAdmin ? $router.push({ name: 'PersonalList' }) : null"
        >
          <v-icon left>mdi-chevron-left</v-icon>Voltar</v-btn
        >
      </v-col>
    </v-row> -->
    <v-row style="height: 80vh;">
      <v-col>
        <v-tabs v-model="tab" slider-size="2" color="secondary" style="border-bottom: 1px solid #a1a1a1;"
          @change="handleTabChange">

          <v-tab key="edit">Editar</v-tab>
          <v-tab key="preview">Visualizar</v-tab>

        </v-tabs>

      </v-col>

      <v-col cols="auto" style="padding: 0; margin: 0; margin-top: 1.5rem; margin-right: 10px;">
        <v-btn :color="defineColor()" fill @click="goToLanding()">LandingPage</v-btn>
      </v-col>

      <v-col cols="12" class="fill-height">
        <v-card class="px-6 pb-6 fill-height">
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item key="edit" style="height: 85%;">

              <v-col>
                <v-tabs v-model="editTab" slider-size="2" color="secondary" style="border-bottom: 1px solid #a1a1a1;">
                  <v-tab key="home">Home</v-tab>
                  <v-tab key="imgVideo">Carrossel</v-tab>

                  <v-tab key="section1">Seção 1</v-tab>
                  <v-tab key="section2">Seção 2</v-tab>
                  <v-tab key="personal">Personal</v-tab>
                  <v-tab key="contact">Contato</v-tab>
                </v-tabs>
              </v-col>
              <v-tabs-items v-model="editTab" class="fill-height">

                <!-- Hero -->
                <v-tab-item key="home" class="fill-height">
                  <v-card-text>
                    <v-col cols="12" lg="6" sm="12">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="landingPageForm.name_landing" label="Nome da landing page" filled dense
                            required :color="defineColor()" />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="landingPageForm.title_home" label="Título da Home" filled dense
                            required :color="defineColor()" />
                        </v-col>
                        <v-col cols="12" lg="12" sm="12">
                          <v-textarea v-model="landingPageForm.description_home" label="Descrição Home" filled dense
                            no-resize rows="4" required :color="defineColor()" />
                        </v-col>
                        <v-col cols="12" lg="6" sm="6" style="position: relative">
                          <v-file-input ref="fileInput1" v-model="landingPageForm.url_image_1" label="Banner"
                            filled dense accept="image/jpeg,image/png,image/jpg" :color="defineColor()" prepend-icon=""
                            hide-details="" style="margin-bottom: 0;">
                            <template v-slot:append>
                              <v-btn icon small @click="$refs.fileInput1.$el.querySelector('input').click()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                          </v-file-input>
                          <div style="padding-top: 5px;">
                            <v-helper text>
                              Resolução recomendada: Maior ou igual a 1920 x 1080
                            </v-helper>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end px-6" style="position: absolute; bottom: 0; width: 100%;">
                    <v-btn color="primary" @click="nextEditTab">
                      Próximo
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <!-- ---------------------------------------------- -->

                <!-- Imagens e video -->
                <v-tab-item key="imgVideo" class="fill-height" style="overflow-y: auto;">
                  <v-card-text>
                    <v-col cols="12" lg="6" sm="12">
                      <v-row>
                        <v-col cols="12" lg="6" sm="6" style="position: relative">
                          <v-file-input ref="fileInput2" v-model="landingPageForm.url_image_2" label="Imagem 1" filled
                            dense accept="image/jpeg,image/png,image/jpg" :color="defineColor()" prepend-icon="">
                            <template v-slot:append>
                              <v-btn icon small @click="$refs.fileInput2.$el.querySelector('input').click()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                          </v-file-input>
                        </v-col>

                        <v-col cols="12" sm="6" style="position: relative">
                          <v-file-input ref="fileInput4" v-model="landingPageForm.url_image_3" label="Imagem 2" filled
                            dense accept="image/jpeg,image/png,image/jpg" :color="defineColor()" prepend-icon="">
                            <template v-slot:append>
                              <v-btn icon small @click="$refs.fileInput4.$el.querySelector('input').click()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="6" style="position: relative">
                          <v-file-input ref="fileInput5" v-model="landingPageForm.url_image_5" label="Imagem 3" filled
                            dense accept="image/jpeg,image/png,image/jpg" :color="defineColor()" prepend-icon="">
                            <template v-slot:append>
                              <v-btn icon small @click="$refs.fileInput5.$el.querySelector('input').click()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="6" style="position: relative; margin-bottom: 10px; ">
                          <v-file-input ref="fileInput6" v-model="landingPageForm.url_image_6" label="Imagem 4" filled
                            dense accept="image/jpeg,image/png,image/jpg" :color="defineColor()" prepend-icon="">
                            <template v-slot:append>
                              <v-btn icon small @click="$refs.fileInput6.$el.querySelector('input').click()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                      <v-helper text>
                        Resolução recomendada: Maior ou igual a 854 x 480
                      </v-helper>
                    </v-col>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end px-6" style="position: absolute; bottom: 0; width: 100%;">
                    <v-btn @click="previousEditTab">
                      Voltar
                    </v-btn>

                    <v-btn color="primary" @click="nextEditTab">
                      Próximo
                    </v-btn>
                  </v-card-actions>

                </v-tab-item>
                <!-- ---------------------------------------------- -->

                <!-- Seção 1 -->
                <v-tab-item key="section1" class="fill-height">
                  <v-card-text>
                    <v-col cols="12" lg="6" sm="12">
                      <v-row>
                        <v-col cols="12" lg="6" sm="6">
                          <v-text-field v-model="landingPageForm.title_page_2" label="Título da seção 1" filled dense
                            required :color="defineColor()" />
                        </v-col>
                        <v-col cols="12" sm="6" style="position: relative">
                          <v-text-field v-model="landingPageForm.url_video" label="URL do vídeo do YouTube" filled dense
                            required :color="defineColor()" />
                        </v-col>

                        <v-col cols="12">
                          <v-textarea v-model="landingPageForm.description_page_2" label="Descrição da seção 1" filled
                            dense no-resize rows="4" required :color="defineColor()" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end px-6" style="position: absolute; bottom: 0; width: 100%;">
                    <v-btn @click="previousEditTab">
                      Voltar
                    </v-btn>
                    <v-btn color="primary" @click="nextEditTab">
                      Próximo
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <!-- ---------------------------------------------- -->

                <!-- Seção 2 -->
                <v-tab-item key="section2" class="fill-height">
                  <v-card-text>
                    <v-col cols="12" lg="6" sm="12">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="landingPageForm.title_page_4" label="Título da seção 2" filled dense
                            required :color="defineColor()" />
                        </v-col>
                        <v-col cols="12" sm="6" style="position: relative; gap: 2rem">
                          <v-file-input ref="fileInput2" v-model="landingPageForm.url_image_4" label="Imagem da seção 2"
                            filled dense accept="image/jpeg,image/png,image/jpg" :color="defineColor()" prepend-icon=""
                            hide-details="">
                            <template v-slot:append>
                              <v-btn icon small @click="$refs.fileInput2.$el.querySelector('input').click()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                          </v-file-input>
                          <div style="padding-top: 5px;">
                            <v-helper text>
                              Resolução recomendada: Maior ou igual a 1280 x 960
                            </v-helper>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea v-model="landingPageForm.description_page4" label="Descrição da seção 2" filled
                            dense no-resize rows="4" required :color="defineColor()" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end px-6" style="position: absolute; bottom: 0; width: 100%;">
                    <v-btn @click="previousEditTab">
                      Voltar
                    </v-btn>
                    <v-btn color="primary" @click="nextEditTab">
                      Próximo
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <!-- ---------------------------------------------- -->

                <!-- Personal -->
                <v-tab-item key="personal" class="fill-height">
                  <v-card-text>
                    <v-col cols="12" lg="6" sm="12">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="landingPageForm.name_personal" label="Nome do Usuário" filled dense
                            required :color="defineColor()" />
                        </v-col>
                        <v-col cols="12">
                          <v-textarea v-model="landingPageForm.cv_personal" label="Currículo" filled dense no-resize
                            rows="6" required :color="defineColor()" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end px-6" style="position: absolute; bottom: 0; width: 100%;">
                    <v-btn @click="previousEditTab">
                      Voltar
                    </v-btn>
                    <v-btn color="primary" @click="nextEditTab">
                      Próximo
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <!-- ---------------------------------------------- -->

                <!-- Contatos -->
                <v-tab-item key="contacts" style="overflow: auto;">
                  <v-card-text style="height: 100%;">
                    <v-col cols=" 12" lg="12" sm="12">
                      <v-row>
                        <v-col cols="12" lg="6" sm="12">
                          <v-text-field v-model="landingPageForm.address" label="Endereço completo" filled dense
                            required :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.email" label="E-mail de contato" filled dense required
                            :color="defineColor()" />
                        </v-col>
                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.whatsapp" maxlength="15"
                            @input="applyPhoneMask('whatsapp')" label="Número do WhatsApp" filled dense required
                            :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.phone1" @input="applyPhoneMask('phone1')"
                            maxlength="15" label="Número do telefone 1" filled dense required :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.phone2" @input="applyPhoneMask('phone2')"
                            maxlength="15" label="Número do telefone 2" filled dense required :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.telegram" label="Link do telegram" filled dense
                            required :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.instagram" label="Link do instagram" filled dense
                            required :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.youtube" label="Link do youtube" filled dense required
                            :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.site1" label="Link do site 1" filled dense required
                            :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12">
                          <v-text-field v-model="landingPageForm.site2" label="Link do site 2" filled dense required
                            :color="defineColor()" />
                        </v-col>

                        <v-col cols="12" lg="3" sm="12" style="margin-bottom: 25px;">
                          <v-text-field v-model="landingPageForm.site3" label="Link do site 3" filled dense required
                            :color="defineColor()" />
                        </v-col>

                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end px-6" style="position: absolute; bottom: 0; width: 100%;">
                    <v-btn @click="previousEditTab">
                      Voltar
                    </v-btn>
                    <v-btn color="primary" @click="onFinish">
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <!-- ---------------------------------------------- -->

              </v-tabs-items>
            </v-tab-item>
            <v-tab-item key="preview" style="height: 100%;">
              <iframe :key="iframeKey" :src="previewUrl"
                style="margin-top: 12px; width: 100%; height: 100%; border: none; border-radius: 6px;"></iframe>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LandingPageService from '@/services/landingpage-service.js'
export default {
  name: 'LandingPageEdit',
  data () {
    return {
      tab: 0,
      ownerPlansID: '',
      ownerPlansName: '',
      previewUrl: ``,
      editTab: 0,
      showImage: false,
      iframeKey: 0,
      landingPageForm: {
        // usado para buscar a landing page
        name_landing: undefined,

        // home
        title_home: '',
        description_home: '',
        url_image_1: undefined,

        // Section 1
        title_page_2: undefined,
        description_page_2: undefined,
        url_video: undefined,

        // Section 2
        title_page_4: undefined,
        description_page4: undefined,
        url_image_4: undefined,

        // Personal
        name_personal: undefined,
        cv_personal: undefined,

        // carrossel
        url_image_2: undefined,
        url_image_3: undefined,
        url_image_5: undefined,
        url_image_6: undefined,

        // Contato
        address: undefined,
        email: undefined,
        phone1: undefined,
        phone2: undefined,
        whatsapp: undefined,
        site1: undefined,
        site2: undefined,
        site3: undefined,
        telegram: undefined,
        youtube: undefined,
        instagram: undefined
      }
    }
  },

  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),

    handleTabChange (newTab) {
      if (newTab === 1) {
        this.updatePreviewUrl()
      }
    },

    updatePreviewUrl () {
      const user = this.$store.getters['auth/user']
      if (user) {
        this.ownerPlansID = this.isUserAdmin ? 1 : user.role_id
        this.ownerPlansName = user.fullname ? user.fullname.replace(/\s/g, '').toLowerCase() : 'fisicUser'
        this.previewUrl = `https://venda.fisicsoft.com/?${this.ownerPlansName}=${this.ownerPlansID}`
        this.iframeKey++
      }
    },

    previousEditTab () {
      this.editTab--
    },

    nextEditTab () {
      this.editTab++
    },

    applyPhoneMask (field) {
      let value = this.landingPageForm[field]

      value = value.replace(/\D/g, '')

      if (value.length > 0) {
        if (value.length <= 2) {
          value = value.replace(/^(\d{0,2})/, '($1')
        } else if (value.length <= 6) {
          value = value.replace(/^(\d{2})(\d{0,4})/, '($1) $2')
        } else if (value.length <= 10) {
          value = value.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3')
        } else {
          value = value.replace(/^(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3')
        }
      }

      this.$set(this.landingPageForm, field, value)
    },

    cleanPhoneNumber (number) {
      return number ? number.replace(/\D/g, '') : undefined
    },

    goToLanding () {
      const user = this.$store.getters['auth/user']

      if (user) {
        this.ownerPlansID = user.role_id || ''
        this.ownerPlansName = user.fullname ? user.fullname.replace(/\s/g, '').toLowerCase() : 'user'
      }

      if (this.isUserAdmin) {
        window.open(`https://venda.fisicsoft.com/?fisicUser=1`, '_blank')
      } else if (this.isUserPersonal && this.ownerPlansID && this.ownerPlansName) {
        window.open(`https://venda.fisicsoft.com/?${this.ownerPlansName}=${this.ownerPlansID}`, '_blank')
      } else {
        this.setSnackbar({
          show: true,
          color: 'error',
          message: 'Informações de redirecionamento indisponíveis'
        })
      }
    },

    validateImage (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg']
      return validImageTypes.includes(file.type)
    },

    onFinish () {
      this.setLoader({ show: true })
      const user = this.$store.getters['auth/user']
      let formData = new FormData()
      let cleanData = { ...this.landingPageForm }
      console.log(cleanData)

      // Remover máscaras dos números de telefone
      cleanData.phone1 = this.cleanPhoneNumber(this.landingPageForm.phone1)
      cleanData.phone2 = this.cleanPhoneNumber(this.landingPageForm.phone2)
      cleanData.whatsapp = this.cleanPhoneNumber(this.landingPageForm.whatsapp)

      for (let key in cleanData) {
        if (cleanData[key] instanceof File) {
          if (!this.validateImage(cleanData[key])) {
            this.setSnackbar({
              color: 'error',
              show: true,
              message: 'Apenas arquivos JPEG, PNG e JPG são permitidos!'
            })
            this.setLoader({ show: false })
            return
          }
          formData.append(key, cleanData[key])
        } else if (cleanData[key] !== null && cleanData[key] !== undefined) {
          formData.append(key, cleanData[key])
        }
      }

      const roleId = this.isUserAdmin ? 1 : user.role_id

      LandingPageService.updateLandingPageData(formData, roleId)
        .then(() => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Landing Page atualizada com sucesso!'
          })
        })
        .catch((e) => {
          console.error(e.response)
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Não foi possível atualizar a Landing Page!'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },

    defineColor () {
      if (this.isAdmin) {
        return 'secondary'
      } else {
        return 'primary'
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    isUserAdmin () {
      return this.$store.getters['auth/user'].isAdmin()
    },
    isUserPersonal () {
      return this.$store.getters['auth/user'].isPersonal()
    },
    userId () {
      return this.$store.getters['auth/user'].id
    }
  },
  mounted () {
    this.updatePreviewUrl()
  }

}

</script>

<style scoped>
.form-container-part {
  padding: 0 10px;
}

.v-card__text {
  padding: 0 10px;
}
</style>
