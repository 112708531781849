<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card>
      <v-form>
      <v-card-title
        class="secondary white--text"
        primary-title
      >
        Copiar Exercicio
        <v-spacer></v-spacer>
        <v-btn
          fab
          x-small
          text
          @click.stop="closeDialog()"
        >
          <v-icon
            class="white--text"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="training_to_copy"
                label="Copiar para qual treino?"
                :items="fetchTrainings()"
                return-object
                item-text="name"
                item-color="primary"
                :menu-props="{maxHeight: '200px', nudgeBottom: '64px'}"
                chips
                hide-details
                multiple
                filled
                dense
              >
                <template v-slot:selection="data">
                  <v-chip class="primary">
                    {{data.item.name}}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click.stop="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="save()"
          :disabled="!valid"
        >
         Copiar
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  name: 'copy-training-dialog',
  props: ['dialog', 'editedItem', 'editedIndex', 'cycle'],
  data () {
    return {
      training_to_copy: [],
      valid: false,
      timeout: null,
      // autocomplete
      loadingSearch: false,
      searchText: '',
      trainingMethods: [],
      cycleToBeEvaluated: {
        trainings: []
      }
    }
  },
  computed: {
    ...mapGetters('students', ['mesocycleToBeEvaluated', 'student', 'microcycleToBeEvaluated']),
    isNewItem () {
      return isEmpty(this.editedItem)
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    save () {
      const selectedTrainings = this.training_to_copy.map(t => t.name)

      for (const trainingName of selectedTrainings) {
        const trainingIndex = this.cycleToBeEvaluated.trainings.findIndex(training => training.name === trainingName)

        const hasTraining = this.cycleToBeEvaluated.trainings[trainingIndex].exercises.find(exercise => exercise.exercise.id === this.editedItem.id)

        if (hasTraining) {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: `Esse exercício já existe no treino ${trainingName}`
          })
          continue
        }

        this.cycleToBeEvaluated.trainings[trainingIndex].exercises.push(this.editedItem)
      }

      this.setLoader({ show: true })
      this.$emit('update', 'Treino salvo com sucesso', 'Erro ao salvar Treino')
      this.setLoader({ show: false })
      this.closeDialog()
    },
    closeDialog () {
      this.trainingObject = {}
      this.searchText = ''
      this.trainingMethodSelected = {}
      this.training_to_copy = []
      this.valid = false
      this.$emit('update:dialog', false)
    },
    fetchTrainings () {
      const mapped = this.cycleToBeEvaluated?.trainings?.map(training => {
        return { name: training.name, value: training.name }
      })
      return mapped ?? []
    },
    setCycle () {
      this.cycleToBeEvaluated = this.cycle === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    }
  },
  watch: {
    training_to_copy (newVal, oldVal) {
      if (newVal.length > 0) {
        this.valid = true
      }
    }
  },
  mounted () {
    this.setCycle()
  }
}
</script>

<style lang="scss">
  .v-menu__content {
    overflow: auto !important;
  }

</style>
