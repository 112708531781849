<template>
  <v-card
    elevation=0
    style="margin-left: 12%"
    max-width="600"
  >
    <v-card-title>
      Assinatura do Plano
    </v-card-title>
    <v-card-text>
      <payment-information-step>
        <template v-slot:actions="{ next }">
          <v-btn absolute right class="ml-2" color="secondary"
            @click="next(onNext)"
          >
            Próximo
          </v-btn>
        </template>
      </payment-information-step>
    </v-card-text>

    <v-card-actions>

    </v-card-actions>
  </v-card>
</template>

<script>
import PaymentInformationStep from '@/components/authentication/register/PaymentInformationStep'
export default {
  components: {
    PaymentInformationStep
  },
  methods: {
    onNext (payload) {
      this.$emit('confirm', payload)
    }
  }
}
</script>

<style>

</style>
