import axios from './axios-node-instance'

function getPersonalDashboard (startDate, endDate, interval) {
  return axios.get(`/dashboard/master?start_date=${startDate}&end_date=${endDate}&interval=${interval}`)
}

function getStudentDashboard (startDate, endDate, interval) {
  return axios.get(`/dashboard/personal?start_date=${startDate}&end_date=${endDate}&interval=${interval}`)
}

export { getPersonalDashboard, getStudentDashboard }
