<template>
  <v-dialog v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
            :retain-focus="false"
            eager
            persistent>
    <v-card style="min-height: calc(100% - 30px) !important">
      <v-toolbar dark color="secondary" style="margin-top: 64px;">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{titleName}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="button()"
            v-if="buttonName !== ''"
          >
            {{buttonName}}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'dialog-full-screen',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    buttonName: {
      type: String,
      default: ''
    },
    titleName: {
      type: String,
      default: ''
    }
  },
  methods: {
    close () {
      document.body.classList.remove('removedScroll') // devolve scrollbar ao body
      this.$emit('update:dialog', false)
      this.$emit('close')
    },
    button () {
      this.$emit('button')
    }
  },
  watch: {
    dialog (newVal) {
      if (newVal) {
        document.body.classList.add('removedScroll') // omite scrollbar do body
      }
    }
  }
}
</script>

<style lang="scss">
.removedScroll::-webkit-scrollbar {
  display: none;
}
</style>
