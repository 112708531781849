import axios from './axios-instance'

export default {
  all ({ q = '', page = 1, limit = 20, exerciseType = 'all' }) {
    return axios.get(`v1/admin/exercises?exercise_type=${exerciseType}&limit=${limit}&page=${page}&q=${q}`)
  },
  createOrUpdate (payload) {
    if (payload.id === null) {
      return axios.post('v1/admin/exercises', payload)
    }
    return axios.put(`v1/admin/exercises/${payload.id}`, payload)
  },
  destroy (id) {
    return axios.delete(`v1/admin/exercises/${id}`)
  },
  getPostural () {
    return axios.get('v1/admin/postural-deviations/')
  }
}
