<template>
    <apexchart
      width="100%"
      height="100%"
      :options="computedChartOptions"
      :series="series"
      ref="apexChart">
    </apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'bar-chart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: '100%',
          stacked: false,
          toolbar: {
            show: false,
            autoSelected: 'pan',
            zoom: {
              enabled: false
            }
          }
        },
        dataLabels: {
          style: {
            fontSize: '0px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'thin'
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      series: [{
        name: 'Pagamentos',
        data: []
      }],
      maxVal: 0
    }
  },
  mounted () {
    this.initializeChartData()
  },
  computed: {
    computedChartOptions () {
      return {
        ...this.chartOptions,
        yaxis: {
          ...this.chartOptions.yaxis,
          max: this.maxVal
        }
      }
    }
  },
  watch: {
    chartData: {
      handler () {
        this.initializeChartData()
      },
      deep: true
    }
  },
  methods: {
    initializeChartData () {
      const newSeries = this.chartData.map(item => {
        return {
          x: item.method,
          y: item.value
        }
      })
      this.series[0].data = newSeries
      let value = Math.max(...newSeries.map(item => item.y))
      this.maxVal = value * 1.05
    }
  }
}
</script>
