<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-card min-width="296px" max-width="360px" align="center" class="pa-6">
          <v-img
            :src="user.photo_url || require('@/assets/images/logo-placeholder.jpg')"
            :alt="user.fullname"
            contain
            :lazy-src="require('@/assets/images/logo-placeholder.jpg')"
          ></v-img>

        <v-card-text class="pb-0">
          <v-card-title class="justify-center">
            {{user.fullname}}
          </v-card-title>
          <v-card-subtitle>
            {{user.email}}
          </v-card-subtitle>
          <v-card-subtitle>
            {{user.cpf}}
          </v-card-subtitle>
          <v-card-subtitle>
            {{user.phone}}
          </v-card-subtitle>
          <v-card-subtitle>
            {{user.address | fullAddress}}
          </v-card-subtitle>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-col>
            <v-row justify="center">
              <v-btn color="secondary"
                @click="onEdit"
              >
                <v-icon left>mdi-pencil</v-icon> editar
              </v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', ['user'])
  },
  mounted () {
    this.fetchCurrentUser()
      .then(() => {
        this.profile = this.user
      })
  },
  methods: {
    ...mapActions('auth', ['fetchCurrentUser']),
    onEdit () {
      this.$router.replace({ name: 'PersonalProfileForm' })
    }
  }
}
</script>
