import axios from './axios-instance'

export default {
  get () {
    return axios.get('v1/muscle-groups/')
  },
  getPostural () {
    return axios.get('v1/admin/postural-deviations/')
  }

}
