<template>
  <v-expansion-panels multiple flat v-model="expandedPanels">
    <v-expansion-panel class="mt-12" v-if="canShowFatPercentage">
      <v-expansion-panel-header>
        <div class="pl-6 title secondary--text">
          Percentual de gordura (avaliação da marinha americana)
          <v-tooltip bottom max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="gray"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <p class="caption mb-0 pa-1 px-0" style="line-height: 16px;">O método usado pela marinha americana para medir a gordura corporal é baseado apenas em medidas corporais. <br/> Este método consiste nas medições de sua altura, cintura e pescoço, para homens, e, adicionalmente, quadril para mulheres.</p>
          </v-tooltip>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="v-card pt-4">
        <v-data-table
          :headers="headers"
          :items-per-page="-1"
          :items="percentualGorduraItems"
          hide-default-footer
          no-data-text="Não há informações para serem listadas"
        >
          <template v-slot:item.result="{item}">
            {{item.result}} <v-icon  v-if="item.result[0] === '-'"> mdi-arrow-down </v-icon> <v-icon v-else-if="item.result[0] !== '0'">mdi-arrow-up</v-icon>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="mt-12">
      <v-expansion-panel-header>
        <div class="pl-6 title secondary--text">Perímetros Corporais </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="v-card pt-4">
        <v-data-table
          :headers="headers"
          :items-per-page="-1"
          :items="perimetrosCorporaisItems"
          hide-default-footer
          no-data-text="Não há informações para serem listadas"
        >
          <template v-slot:item.result="{item}">
            {{item.result}} <v-icon  v-if="item.result[0] === '-'"> mdi-arrow-down </v-icon> <v-icon v-else-if="item.result[0] !== '0'">mdi-arrow-up</v-icon>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="mt-12">
      <v-expansion-panel-header>
        <div class="pl-6 title secondary--text">Dobras Cutâneas </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="v-card pt-4">
        <v-data-table
          :headers="headers"
          :items-per-page="-1"
          :items="dobrasCutaneasItems"
          hide-default-footer
          no-data-text="Não há informações para serem listadas"
        >
          <template v-slot:item.result="{item}">
            {{item.result}} <v-icon  v-if="item.result[0] === '-'"> mdi-arrow-down </v-icon> <v-icon v-else-if="item.result[0] !== '0'">mdi-arrow-up</v-icon>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import MesocycleService from '@/services/mesocycle-service.js'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      expandedPanels: [0, 1, 2],
      percentualGorduraItems: [],
      dobrasCutaneasItems: [],
      perimetrosCorporaisItems: [],
      headers: [],

      translatePerimeters: {
        pescoco: 'Pescoço',
        deltoides: 'Deltóides',
        braco_direito_relaxado: 'Braço Dir. Relaxado',
        braco_direito_contraido: 'Braço Dir. Contraído',
        antebraco_direito: 'Antebraço Direito',
        abdomen: 'Abdomên',
        coxa_medial_direita: 'Coxa Medial Direita',
        coxa_distral_direita: 'Coxa Distal Direita',
        panturrilha_direita: 'Panturrilha Direita',
        torax: 'Toráx',
        braco_esquerdo_relaxado: 'Braço Esq. Relaxado',
        braco_esquerdo_contraido: 'Braço Esq. Contraído',
        cintura: 'Cintura',
        antebraco_esquerdo: 'Antebraço Esquerdo',
        quadril: 'Quadril',
        coxa_medial_esquerda: 'Coxa Medial Esquerda',
        coxa_distal_esquerda: 'Coxa Distal Esquerda',
        panturrilha_esquerda: 'Panturrilha Esquerda',
        peitoral: 'Peitoral',
        biceps: 'Bíceps',
        axilar_media: 'Axiliar Média',
        supra_iliaca: 'Supra Ilíaca',
        abdominal: 'Abdominal',
        supraespinhal: 'Supraespinhal',
        coxa_media: 'Coxa Média',
        panturrilha: 'Panturrilha',
        subescapular: 'Subescapular',
        triceps: 'Tríceps'
      }
    }
  },

  computed: {
    ...mapGetters('students', ['student', 'currentMacroCycle', 'studentFinalResultDialog']),

    canShowFatPercentage () {
      if (this.currentMacroCycle.items_to_be_evaluated) {
        return this.currentMacroCycle.items_to_be_evaluated.includes('perimeter') &&
               this.student.student_type === 'consulting'
      }

      return false
    }
  },

  methods: {
    fetchResult () {
      const studentId = this.student.id || ''
      const macrocycleId = this.currentMacroCycle.id
      var perimetrosCorporaisObject = {}
      var percentualGorduraObject = {}
      var dobrasCutaneasObject = {}

      MesocycleService.getFinalResult(studentId, macrocycleId)
        .then(({ data }) => {
          this.headers = [{ text: 'Nome', value: 'name' }]
          const mesocycles = data.mesocycles.filter(m => m.was_evaluated)

          mesocycles.forEach(mesocycle => {
            var date = mesocycle.start_date
            var bodyPerimeters = mesocycle.evaluated_items.perimeter.bodyPerimeter
            var skinFolds = mesocycle.evaluated_items.perimeter.skinFold

            this.headers.push({
              text: this.$options.filters.formatDateToBr(date),
              align: 'center',
              value: date
            })

            percentualGorduraObject = {
              name: this.currentMacroCycle.name,
              ...percentualGorduraObject,
              [date]: mesocycle.american_marine_evaluation
            }

            this.getPerimeterKeys(date, perimetrosCorporaisObject, bodyPerimeters)
            this.getPerimeterKeys(date, dobrasCutaneasObject, skinFolds, 'mm')
          })
          const startDate = mesocycles[0].start_date
          const endDate = mesocycles[mesocycles.length - 1].start_date

          this.getFinalResultKey(startDate, endDate, perimetrosCorporaisObject)
          this.getFinalResultKey(startDate, endDate, dobrasCutaneasObject, 'mm')
          this.fatPercentageFormat(startDate, endDate, percentualGorduraObject)

          this.perimetrosCorporaisItems = this.getArrayFromObject(perimetrosCorporaisObject)
          this.dobrasCutaneasItems = this.getArrayFromObject(dobrasCutaneasObject)
          this.percentualGorduraItems = [ percentualGorduraObject ]

          this.headers.push({
            text: 'Resultado Final',
            align: 'end',
            value: 'result',
            width: '160px'
          })
        })
    },

    fatPercentageFormat (first, last, fatPercentage) {
      fatPercentage.result = fatPercentage[last] - fatPercentage[first]

      Object.keys(fatPercentage).forEach(key => {
        if (key !== 'name') {
          if (fatPercentage[key]) {
            fatPercentage[key] = parseFloat(fatPercentage[key]).toFixed(3)
          } else {
            fatPercentage[key] = '- -'
          }
        }
      })
    },

    getPerimeterKeys (date, obj, keys, suffix = 'cm') {
      Object.keys(keys).forEach(key => {
        obj[key] = {
          name: this.translatePerimeters[key],
          ...obj[key],
          [date]: `${keys[key]} ${suffix}`
        }
      })
      return obj
    },

    getFinalResultKey (first, last, obj, suffix = 'cm') {
      Object.keys(obj).forEach(key => {
        var columns = Object.keys(obj[key])
        const v0 = obj[key][columns[1]].split(' ')[0]
        const vf = obj[key][columns[columns.length - 1]].split(' ')[0]
        obj[key].result = `${(vf - v0)} ${suffix}`
      })
    },

    getArrayFromObject (obj) {
      const array = []

      Object.keys(obj).forEach(key => {
        array.push(obj[key])
      })

      return array
    },

    getValues (item) {
      const array = []
      Object.keys(item).forEach(key => {
        if (key !== 'name' && key !== 'result') {
          array.push(item[key] ? parseFloat(item[key].split(' ')[0]) : 0)
        }
      })

      return array
    }

  },
  watch: {
    studentFinalResultDialog: {
      immediate: true,
      handler  (value) {
        if (value) {
          this.fetchResult()
        }
      }
    }
  }
}
</script>

<style>

</style>
