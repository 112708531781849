import axios from './axios-instance'

export default {
  getTrainingPdf ({ studentId, macrocycleId, mesocycleId, cycle, microcycleId }) {
    if (cycle && cycle === 'micro') {
      return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${microcycleId}/trainings_pdf`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf'
        }
      })
    }
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/trainings_pdf`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
  },

  getEvaluationPdf ({ studentId, macrocycleId, mesocycleId, cycle, microcycleId }) {
    if (cycle && cycle === 'micro') {
      return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/microcycles/${microcycleId}/evaluation_pdf`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf'
        }
      })
    }
    return axios.get(`v1/students/${studentId}/macrocycles/${macrocycleId}/mesocycles/${mesocycleId}/evaluation_pdf`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
  },

  getComparisonPdf ({ firstMesocycleId, secondMesocycleId, cycle, firstMicrocycleId, secondMicrocycleId }) {
    if (cycle && cycle === 'micro') {
      return axios.get(`v1/microcycles/compare/first_microcycle/${firstMicrocycleId}/second_microcycle/${secondMicrocycleId}?format=pdf`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf'
        }
      })
    }
    return axios.get(`v1/mesocycles/compare/first_mesocycle/${firstMesocycleId}/second_mesocycle/${secondMesocycleId}?format=pdf`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
  }
}
