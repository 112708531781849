<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto" class="title font-weight-black">
        Grupos Musculares
      </v-col>

      <v-spacer />

      <v-col cols="auto">
        <v-btn color="primary" outlined @click="onAdd()">adicionar grupo</v-btn>
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-col cols="4" v-for="(muscleGroup, idx) in musclesGroupList" :key="idx">
        <v-card
          class="pl-4 py-2"
          :ripple="false"
          @click="onDetail(muscleGroup)"
        >
          <v-card-title class="pa-0">
            <v-card-title class="pa-0 body-1 font-weight-bold">
              {{ muscleGroup.name }}
            </v-card-title>

            <v-spacer />

            <v-card-actions>
              <v-btn icon @click.stop="onRemove(muscleGroup)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog
      title="Atenção"
      message="Deseja remover o grupo muscular selecionado? Essa ação não poderá ser desfeita"
      cancelButton="Cancelar"
      confirmButton="Remover"
      :confirmationDialog="confirmationDialog"
      @confirm="destroyMuscleGroup()"
      @cancel="closeConfirmationDialog()"
    />
  </v-container>
</template>

<script>
import MuscleService from '@/services/muscle-service.js'
import ConfirmDialog from '@/components/base/ConfirmDialog'
import { mapActions } from 'vuex'
export default {
  components: { ConfirmDialog },
  data () {
    return {
      musclesGroupList: [],
      confirmationDialog: false,
      selectedMuscleToDelete: null
    }
  },
  mounted () {
    this.fetchMuscleGroup()
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    fetchMuscleGroup () {
      this.setLoader({ show: true })
      MuscleService.get()
        .then(({ data }) => {
          this.musclesGroupList = data.muscle_groups
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    destroyMuscleGroup () {
      this.setLoader({ show: true })
      MuscleService.destroy(this.selectedMuscleToDelete.id)
        .then(() => {
          let idx = this.musclesGroupList.findIndex(
            muscleGroup => muscleGroup.id === this.selectedMuscleToDelete.id
          )
          this.musclesGroupList.splice(idx, 1)
          this.closeConfirmationDialog()
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.selectedMuscleToDelete = null
        })
    },
    onAdd () {
      this.$router.push({ name: 'MuscleGroupNew' })
    },
    onDetail (muscleGroup) {
      this.$router.push({
        name: 'MuscleGroupDetail',
        params: { id: muscleGroup.id }
      })
    },
    onRemove (muscleGroup) {
      this.confirmationDialog = true
      this.selectedMuscleToDelete = muscleGroup
    },
    closeConfirmationDialog () {
      this.confirmationDialog = false
      this.selectedMuscleToDelete = null
    }
  }
}
</script>

<style></style>
