<template>
  <v-snackbar
    v-model="snackbar.show"
    dark
    :color="snackbar.color"
    multi-line
  >
    <v-layout align-center justify-center>
      <v-icon>{{ snackbar.icon }}</v-icon>
      <span v-html="message"></span>
      <v-spacer></v-spacer>
      <v-btn @click="setSnackbar({ show: false })" text>
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'alert-snack-bar',
  computed: {
    ...mapGetters('base', ['snackbar']),
    message () {
      return this.snackbar.message instanceof Array ? this.snackbar.message.join('<br/>') : this.snackbar.message
    }
  },
  methods: {
    ...mapActions('base', ['setSnackbar'])
  }
}
</script>

<style scoped>

</style>
