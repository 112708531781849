<template>
  <div>
    <top-bar mode="User"/>
    <router-view></router-view>
    <LoginDialog mode="User"/>
  </div>
</template>

<script>
import TopBar from '@/components/base/TopBar'
import LoginDialog from '@/components/authentication/LoginDialog'
import { mapActions } from 'vuex'

export default {
  components: { TopBar, LoginDialog },
  name: 'home-layout',

  methods: {
    ...mapActions('auth', ['activeCheckSession', 'stopCheckSession'])
  },

  mounted () {
    this.activeCheckSession()
  },

  beforeDestroy () {
    this.stopCheckSession()
  }
}
</script>
