<template>
  <v-container
    class="py-5 px-0"
  >
    <v-row class="px-3" justify="space-between">
      <v-col cols="12" class="elevation-2 pb-0">
        <v-row class="px-2">
          <v-col cols="7" class="pb-0">
            <span class="font-weight-bold">VO2 Máximo</span>
            <v-divider></v-divider>
            <v-col class="pb-0 px-0">
              <v-spacer/>
              <v-row>
                <v-col cols="7" class="pb-0">
                  <v-text-field class="body-2"
                    label="Protocolo"
                    :value="vo2Translate[this.cycleToBeEvaluated.macrocycle.vo2_protocol]"
                    readonly
                    filled
                    dense
                  />
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4" class="pb-0">
                  <v-row>
                    <span class="text-uppercase caption">Relativo</span>
                  </v-row>
                  <v-row>
                    <span class="headline font-weight-black">{{calcs.vo2Relative}} ml/min</span>
                  </v-row>
                </v-col>

              </v-row>

              <v-row>
                <v-col cols="7" class="pb-0">
                  <v-text-field class="body-2"
                    v-model="caloricExpenditure.travel_distance"
                    label="Distância percorrida em metros"
                    :rules="distanceRules"
                    :disabled="disableInputReadOnly"
                    :readonly="disableInputReadOnly"
                    filled
                    dense
                    v-mask="['#####']"
                  />
                </v-col>
                <v-spacer></v-spacer>

              </v-row>

            </v-col>

          </v-col>
          <v-col cols="3">
            <v-img
              id="vo2chart"
              class="mt-6 vo2--chart"
              src="@/assets/images/vo2-chart.png"
            >
              <div class="vo2--cursor" :style="styleVo2Cursor">
                <v-img class="vo2--cursor--image"
                  src="@/assets/images/vo2-cursor.png"
                />
              </div>
            </v-img>
            <v-row class="justify-center mt-n12">
              <span class="text-uppercase title">{{calcs.vo2Classification.name}}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
      <!-- gasto calorico -->
     <v-row class="px-3 mt-12 align-end">
      <span class="subtitle-2 font-weight-bold">GASTO CALÓRICO</span>
      <v-spacer/>
      <v-dialog v-model="dialogAerobic" max-width="600px" @click:outside="$refs.form.resetValidation()">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" :disabled="!canAddExercise" v-if="!disableInput"> Adicionar Exercício </v-btn>
        </template>
        <v-card>
          <v-card-title class="secondary">
            <span class="headline white--text"> Novo Exercício </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isValidNewItemAerobic">
                <v-row>
                  <v-col cols="12">
                    <v-select :rules="[vRules.required]" v-model="newItemAerobic.exercise_type" label="Tipo do exercício" :items="['Musculação', 'Aeróbico']" hide-details="auto"></v-select>
                  </v-col>

                  <template v-if="newItemAerobic.exercise_type === 'Aeróbico'">
                    <v-col cols="12">
                      <v-text-field :rules="[vRules.required]" v-model="newItemAerobic.name" label="Exercício" hide-details="auto"></v-text-field>
                    </v-col>
                  </template>

                  <template v-else-if="newItemAerobic.exercise_type === 'Musculação'">
                    <v-col cols="6" md="4">
                      <v-text-field :rules="[vRules.required]" v-model="newItemAerobic.min_reps" label="Mín. Repetições" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field :rules="[vRules.required]" v-model="newItemAerobic.max_reps" label="Máx. Repetições" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field :rules="[vRules.required]" v-model="newItemAerobic.series" label="Total de séries por Semana" hide-details="auto"></v-text-field>
                    </v-col>
                  </template>

                  <v-col cols="6" sm="6" md="4">
                    <v-text-field :rules="[vRules.required]" v-model="newItemAerobic.week_times" v-mask="'###'" label="Vezes na Semana" hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="4">
                    <v-text-field :rules="[vRules.required]" v-model="newItemAerobic.day_time"  label="Tempo por dia" hide-details="auto" suffix="Min"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :rules="[vRules.required]" v-model="newItemAerobic.intensity" v-mask="'###'" label="Intensidade" hide-details="auto" suffix="%"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="closeAerobic()">Cancelar</v-btn>
            <v-btn :disabled="!isValidNewItemAerobic" color="secondary" @click="saveAerobic(newItemAerobic)">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="mt-2">
      <v-col>
          <v-data-table
            :headers="headers"
            :items="caloricExpenditure.aerobic_items"
            :items-per-page="50"
            disable-pagination
            hide-default-footer
            :no-data-text="noDataTextExercises"
            class="elevation-2"
          >
            <template v-slot:item.action="{ item }">
              <v-btn
                small icon
                color="secondary"
                class="elevation-2"
                @click="editItem(item, caloricExpenditure.aerobic_items)"
                v-if="!disableInput"
              >
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn
                small icon
                color="secondary"
                class="elevation-2"
                @click="deleteItem(item, caloricExpenditure.aerobic_items)"
                v-if="!disableInput"
              >
                <v-icon
                  small
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:item.intensity="{item}">
              {{item.intensity}} %
            </template>

            <template v-slot:item.mets="{item}">
              {{metsMinDia(item).toLocaleString('pt-br', {minimumFractionDigits: 1, maximumFractionDigits: 1})}}
            </template>

            <template v-slot:item.calories="{item}">
              {{consumoSemana(item).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 0})}} Kcal
            </template>

            <template v-slot:item.fc_training="{item}">
              {{fcTreino(item).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 0})}} bpm
            </template>

            <template v-slot:item.fc_max="{item}">
              {{fcMaximaPercent(item).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}} %
            </template>
          </v-data-table>
      </v-col>
    </v-row>

      <v-row class="mt-2 px-3">
      <v-col class="elevation-2 px-0">
        <v-row class="px-3 px-md-5 justify-center">
          <v-col md=4 lg=2>
            <v-row class="px-3 py-2">
              <span class="text-uppercase subtitle-2">TX Metabólica Basal</span>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-3 mt-5">
              <span class="headline font-weight-bold" v-if="!taxaMetabolicaBasalError">{{taxaMetabolicaBasal.toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 0})}} Kcal</span>
              <span class="subtitle text--secondary" v-else>{{taxaMetabolicaBasalError}}</span>
            </v-row>
          </v-col>
          <v-col md=4 lg=2>
            <v-row class="px-3 py-2">
              <span class="text-uppercase subtitle-2">TX Média Atividades</span>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-3 mt-5">
              <span class="headline font-weight-bold">{{taxaMediaAtividade.toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 0})}} Kcal</span>
            </v-row>
          </v-col>
          <v-col md=4 lg=2>
            <v-row class="px-3 " v-if="!disableInput">
              <v-select
                color="primary"
                v-model="calories.type"
                :items="['Déficit Calorico', 'Superávit Calorico']"
                hide-details
                dense
              ></v-select>
            </v-row>
            <v-row class="px-3 mt-2" v-if="!disableInput">
              <v-text-field
                class="font-weight-bold"
                v-model="calories.value"
                suffix="Kcal"
                hide-details
                v-mask="'######'"
              ></v-text-field>
            </v-row>
            <v-row class="px-3 mt-5" v-else>
              <span class="headline font-weight-bold">{{ calories.value }} Kcal</span>
            </v-row>
          </v-col>
          <v-col md=4 lg="auto">
            <v-row class="px-3 py-2">
              <span class="text-uppercase subtitle-2">Consumo Calórico Dia</span>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-3 mt-5">
              <span class="headline font-weight-bold">{{consumoCaloricoDiario.toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 0})}} Kcal</span>
            </v-row>
          </v-col>
          <v-col md=4 lg="auto">
            <v-row class="px-3 py-2">
              <span class="text-uppercase subtitle-2">FC Máxima</span>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-3 mt-5">
              <span class="headline font-weight-bold">{{fcMaxima.toFixed(0)}} BPM</span>
            </v-row>
          </v-col>
          <v-col md=4 lg=2>
            <v-row class="px-3 py-2">
              <span class="text-uppercase subtitle-2">FC de reserva</span>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-3 mt-5">
              <span class="headline font-weight-bold" v-if="!fcReservaError">{{fcReserva.toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 0})}} BPM</span>
              <span class="subtitle text--secondary" v-else>{{fcReservaError}}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="my-12"/>
    <v-row class="mt-12" v-if="!readonly">
      <v-col class="d-flex justify-end">
        <span class="text-uppercase subtitle-2">Frequência Cardiáca Máxima (med. SCI Sports Exerc 207)</span>
      </v-col>
    </v-row>
    <v-row class="mt-12" v-if="!readonly">
      <v-col class="d-flex justify-end">
        <v-btn
          text
          color="secondary"
          @click="$emit('closeDialog')"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="ml-10"
          color="primary"
          @click="saveEvaluation()"
        >
          Salvar Avaliação
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'
import timeMask from '@/mixins/timeMask.js'
import ConfirmDialog from '@/components/base/ConfirmDialog'
import validationRules from '@/mixins/validation-rules.js'
/*eslint-disable*/
export default {
  name: 'gasto-calorico',
  components: {
    ConfirmDialog
  },
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    isStudent: {
      default: false,
      type: Boolean
    },
    cycle: {
      type: String,
      default: 'meso'
    }
  },
  mixins: [timeMask],
  data () {
    return {
      vo2Translate: {
        'protocol_1': 'Caminhada 3000m (Idosos, Obesos)' ,
        'protocol_2': 'Caminhada 1600m (Idosos, Obesos)' ,
        'protocol_3': 'Teste dos 12 minutos (Todos)' ,
        'protocol_4': 'Corrida 2400m (Ativos, Atletas)' ,
        'protocol_5': 'Corrida 15 minutos (Ativos, Atletas)'
      },
      vRules: validationRules,
      minDistance: 505,
      calories: {
        type: 'Superávit Calorico',
        value: 0
      },
      headers: [
        {
          text: 'Exercícios Físicos',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Vezes na Semana', value: 'week_times', sortable: false },
        { text: 'Tempo por dia (min)', value: 'day_time', sortable: false },
        { text: 'Intensidade', value: 'intensity', sortable: false },
        { text: 'Consumo/Semana', value: 'calories', sortable: false },
        { text: 'FC Treino', value: 'fc_training', sortable: false },
        { text: '% FC Max', value: 'fc_max', sortable: false },
        { text: '', value: 'action', sortable: false }
      ],
      headers2: [
        {
          text: 'Exercícios Físicos',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Vezes na Semana', value: 'week_times', sortable: false },
        { text: 'Carga do Teste', value: 'test_load', sortable: false },
        { text: 'Repetições', value: 'reps', sortable: false },
        { text: 'Consumo/Semana', value: 'calories', sortable: false },
        { text: '', value: 'action', sortable: false }
      ],
      newItemAerobic: {
        name: '',
        week_times: '',
        day_time: '',
        intensity: '',
        mets: '',
        calories: '',    
        fc_training: '',
        fc_max: ''
      },
      defaultItemAerobic: {
        name: '',
        week_times: '',
        day_time: '',
        intensity: '',
        mets: '',
        calories: '',
        fc_training: '',
        fc_max: ''
      },
      dialogAerobic: false,
      newItemMuscle: {
        name: '',
        week_times: '',
        test_load: '',
        reps: '',
        calories: ''
      },
      defaultItemMuscle: {
        name: '',
        week_times: '',
        test_load: '',
        reps: '',
        calories: ''
      },
      dialogMuscle: false,
      travelDistance: '',
      caloricExpenditure: {},
      calcs: {
        vo2Relative: '',
        vo2Maximum: '',
        vo2Classification: {
          name: '',
          cursor: '-1'
        }
      },
      isValidNewItemAerobic: false,
      taxaMetabolicaBasalError: '',
      fcReservaError: '',
      isEditing: false,
      editingIndex: -1,
      cycleToBeEvaluated: {
        evaluated_items: {
          student: {
            stature: '',
            weight: ''
          },
          heart: {
            fcRepouso: ''
          },
          caloric_expenditure: {
            basal_metabolic_rate: ''
          }
        },
        macrocycle: {
          vo2_protocol: ''
        }
      }
    }
  },
  methods: {
    ...mapActions('base', ['setSnackbar']),
    ...mapActions('students', ['setMesocycleToBeEvaluated', 'setMicrocycleToBeEvaluated']),
    deleteItem (item, items) {
      const index = items.indexOf(item)
      items.splice(index, 1)
    },
    editItem (item, items) {
      this.newItemAerobic = item
      this.dialogAerobic = true
      this.isEditing = true
      this.editingIndex = items.indexOf(item)
    },
    closeAerobic () {
      this.dialogAerobic = false
      this.$refs.form.resetValidation()
      setTimeout(() => {
        this.newItemAerobic = Object.assign({}, this.defaultItemAerobic)
      }, 300)
    },
    saveAerobic (newItem) {
      if (!this.$refs.form.validate()) { return }

      const item = {
        ...newItem,
        mets: this.metsMinDia(newItem),
        //.toLocaleString('pt-br', {minimumFractionDigits: 1, maximumFractionDigits: 1}),
        calories: this.consumoSemana(newItem).toLocaleString('pt-br', {maximumFractionDigits: 0}),
        fc_training: this.fcTreino(newItem).toLocaleString('pt-br', {maximumFractionDigits: 0}),
        fc_max: this.fcMaximaPercent(newItem).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      }

      if (this.isEditing) {
        this.caloricExpenditure.aerobic_items.splice(this.editingIndex, 1, item)
        this.isEditing = false
      } else {
        if (item.exercise_type === 'Musculação') {
          this.caloricExpenditure.aerobic_items.push({...item, name: 'Musculação'})
        } else {
          this.caloricExpenditure.aerobic_items.push(item)
        }
      }
      this.closeAerobic()
    },
    saveMuscle (newItem) {
      this.caloricExpenditure.muscle_items.push(newItem)
      this.closeMuscle()
    },
    closeMuscle () {
      this.dialogMuscle = false
      setTimeout(() => {
        this.newItemMuscle = Object.assign({}, this.defaultItemMuscle)
      }, 300)
    },
    saveEvaluation () {
      if(this.cycleToBeEvaluated.evaluated_items.student.stature==''){
        this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Estatura Obrigatória!'
          })
          return 
      }
      if(this.cycleToBeEvaluated.evaluated_items.student.weight==''){
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Estatura Massa Obrigatória!'
          })
          return 
      }
      if(this.cycleToBeEvaluated.evaluated_items.heart.fcRepouso==''){
        this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Frequencia Cardiaca de Repouso Obrigatória'
          })
          return 
      }
      if (this.cycle === 'micro') {
        this.$emit('updateMicrocycleEvaluation')
      } else {
        this.$emit('updateMesocycleEvaluation')
      }
    },
    setDietCalories (type) {
      this.calories.type = type
    },
    getDietCalories (calories) {
      try {
        if (calories.type === 'Déficit Calorico') {
          return calories.value * -1
        } else {
          return calories.value
        }
      } catch(error) {
        return 0
      }
    },
    metsMinDia(item) {
      const gender = this.student.gender
      const vo2Relative = this.vo2Relative.replace(',', '.')
      var intensity, minReps, maxReps, series,min

      intensity = parseInt(item.intensity) / 100
      minReps = parseInt(item.min_reps)
      maxReps = parseInt(item.max_reps)
      series = parseInt(item.series)
      //min = parseInt(item.day_time)  
      min=parseInt(item.day_time)

      
      if (item.exercise_type === 'Aeróbico') {
        
        var Mets= ((item.intensity  * vo2Relative) / 100) / 3.5
      
        var peso=this.cycleToBeEvaluated.evaluated_items.student.weight.replace(',', '.')
        
        return (Mets * 1.25 * (peso/60))  * min
          
        //return (item.intensity  * vo2Relative) / 100 / 3.5
      } else if (item.exercise_type === 'Musculação') {

        /*Calculo do gasto calórico da musculação = −473,595 + −1,2110(X1) + 17,5723(X2) x 7 (R2 = 0,61, p < 0,05).
Onde X1 = volume load (n.º de séries x n.º de repetições); X2 = tempo de sessão (minutos).*/
         return   -1 * (-(473.595) + (-1.2110 * (series * maxReps )) + 17.5723 * (min) )
          //, p < 0,05)
        /*return gender === 'male'
              ? ((intensity * (minReps + maxReps)) /200) * series /0.0175 / 1000
              : ((intensity * 0.7 * (minReps + maxReps)) /200) * series /0.0175 / 1000*/
        return 0;
      }
    },
    consumoSemana(item) {
      if (!this.cycleToBeEvaluated.evaluated_items.student.weight) return 0;
      const metsMinDia = this.metsMinDia(item)
     
      return metsMinDia * item.week_times
    },
    fcTreino(item) {
     
      const fcRepouso = parseInt(this.cycleToBeEvaluated.evaluated_items.heart.fcRepouso)
      return Math.floor((item.intensity / 100) * fcRepouso)   + 60
    },
    fcMaximaPercent(item) {
      const fcTreino = this.fcTreino(item)
      const fcMaxima = this.fcMaxima

      return ((fcTreino * 100)/ fcMaxima).toFixed(0)

    },
    updateCurrentCycle() {      
      this.cycleToBeEvaluated = this.cycle === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
      console.log(this.cycleToBeEvaluated)
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated']),
    vo2Relative() {
      let distance = this.caloricExpenditure.travel_distance
      let value = 0
      if ( !isEmpty(distance)) {
        distance = parseFloat(distance.replace(/[.]/g,','))
        if ( !isNaN(distance) && distance >= this.minDistance ) {
          if (this.cycleToBeEvaluated.macrocycle.vo2_protocol === 'protocol_3') {
            value = (distance - 504.9) / 44.73
          } else if (this.cycleToBeEvaluated.macrocycle.vo2_protocol === 'protocol_5') {
            value = ( (distance / 15) * 0.2) + 3.5
          }
          return value.toFixed(2).replace(/[.]/g,',')
        }
      }
      return '-,--'
    },
    vo2Maximum () {
      /*
      let distance = this.caloricExpenditure.travel_distance
      let weight = this.cycleToBeEvaluated.evaluated_items.student.weight
     
      if ( !isEmpty(distance) && !isEmpty(weight)) {
        distance = parseFloat(distance.replace(/[.]/g,','))
        weight = parseFloat(weight.replace(',','.'))
        if ( !isNaN(distance) && !isNaN(weight) && distance >= this.minDistance) {
          let value = ((22.351 * (distance/1000)) - 11.288) * weight / 1000

          return value.toFixed(2).replace(/[.]/g,',')
        }
      }*/
      if( this.vo2Relative!='-,--'){
        var ret= (parseFloat(this.vo2Relative.replace(/[,]/g,'.')))
             return (ret/100).toFixed(3)
      }else {
        return '-,--'
      }
    },
    vo2Classification () {
      
      let classes = []

      let {age, gender} = this.student
      let vo2Relative = parseFloat(this.vo2Relative.replace(/[,]/g,'.'))

      if ( !age || isEmpty(gender) || isNaN(vo2Relative) ) {
        return {
          name: '',
          cursor: '-1'
        }
      }

      if (age >= 13 && age < 20) {
        classes = gender === 'male' ?
        /* homem */   [35, 38.3, 45.1, 50.9, 55.9] :
        /* mulher */  [25, 30.9, 34.9, 38.9, 41.9]
      } else if (age >= 20 && age < 30) {
        classes = gender === 'male' ?
        /* homem */   [33, 36.4, 42.4, 46.4, 52.4] :
        /* mulher */  [23.6, 28.9, 32.9, 36.9, 40.9]
      } else if (age >= 30 && age < 40) {
        classes = gender === 'male' ?
        /* homem */   [31.5, 35.4, 40.9, 44.9, 49.9] :
        /* mulher */  [22.8, 26.9, 31.4, 35.6, 40]
      } else if (age >= 40 && age < 50) {
        classes = gender === 'male' ?
        /* homem */   [30.2, 33.5, 38.9, 43.7, 48] :
        /* mulher */  [21, 24.4, 28.9, 32.8, 36.9]
      } else if (age >= 50 && age < 60) {
        classes = gender === 'male' ?
        /* homem */   [25.1, 30.9, 35.7, 40.9, 45.3] :
        /* mulher */  [20.2, 22.7, 26.9, 31.4, 35.7]
      } else if (age >= 60) {
        classes = gender === 'male' ?
        /* homem */   [20.5, 26, 32.3, 36.4, 44.2] :
        /* mulher */  [17.5, 20.1, 24, 30.2, 31.4]
      } else {
        classes = gender === 'male' ?
        /* homem */   [0, 0, 0, 0, 0] :
        /* mulher */  [0, 0, 0, 0, 0]
      }

      let media = ( classes[4] - classes[0] ) / 4

      let base = vo2Relative < (classes[0] - media) ? vo2Relative : classes[0] - media
      let topo = vo2Relative > (classes[4] + media) ? vo2Relative : classes[4] + media

      let name, value

      if ( vo2Relative >= base && vo2Relative < classes[0] ) {
        name = 'muito fraco'
        value = 'very-weak'
      } else if (  vo2Relative < classes[1] ) {
        name = 'fraco'
        value = 'weak'
      } else  if (  vo2Relative < classes[2] ) {
        name = 'regular'
        value = 'regular'
      } else if ( vo2Relative < classes[3] ) {
        name = 'bom'
        value = 'good'
      } else if ( vo2Relative < classes[4] ) {
        name = 'muito bom'
        value = 'very-good'
      } else if (vo2Relative <= topo ) {
        name = 'excelente'
        value = 'excellent'
      } else {
        name = ''
      }
      return {
        name,
        value,
        cursor: Math.floor((vo2Relative - base) * 180 / (topo - base))
      }
    },
    vo2Data() {
      return {
        vo2Relative: this.vo2Relative,
        vo2Maximum: this.vo2Maximum,
        vo2Classification: this.vo2Classification
      }
    },
    taxaMetabolicaBasal () {
      let gender = this.student.gender
      let age = this.student.age
      let stature = this.cycleToBeEvaluated.evaluated_items.student.stature
      let weight = this.cycleToBeEvaluated.evaluated_items.student.weight

      const fields = {gender, age, stature, weight}
      const errors = []
      const translate = {gender: 'Gênero', age: 'Idade', stature: 'Estatura', weight: 'Peso'}

      Object.keys(fields).forEach(field => {
        if (!fields[field]) {
          errors.push(translate[field])
        }
      })

      if ( !age || isEmpty(gender) || isEmpty(stature) || isEmpty(weight)) {
        this.taxaMetabolicaBasalError = errors.length > 1 ? `Verifique os campos ${errors.join(', ')}` : `Verifique o campo ${errors[0]}`
        this.cycleToBeEvaluated.evaluated_items.caloric_expenditure.basal_metabolic_rate = {
          error: this.taxaMetabolicaBasalError
        }
        return 0
      } else {
        this.taxaMetabolicaBasalError = ''
      }

      stature = parseFloat(stature.replace(/[,]/g,'.'))
      weight = parseFloat(weight.replace(/[,]/g,'.'))

      if ( isNaN(stature) || isNaN(weight) || stature <= 0 || weight <= 0)
        return 0

      let result = gender === 'male' ?
                   66 + (13.7 * weight) + (5 * stature * 100) - (6.8 * age) :
                   655 + (9.6 * weight) + (1.85 * stature * 100) - (4.7 * age)


      this.cycleToBeEvaluated.evaluated_items.caloric_expenditure.basal_metabolic_rate = {
        value: result.toLocaleString('pt-br', {maximumFractionDigits: 0})
      }
      return result
    },
    consumoCaloricoDiario () {
      let aerobic_items = this.caloricExpenditure.aerobic_items
      let muscle_items = this.caloricExpenditure.muscle_items
      let caloria=parseInt(this.getDietCalories(this.calories))
     


      let result = parseInt(this.taxaMetabolicaBasal) + caloria
      result += this.taxaMediaAtividade
     // if (!isEmpty(aerobic_items) && !isEmpty(muscle_items)) {
       
      //}

      result = isNaN(result) ? 0 : result

      this.cycleToBeEvaluated.evaluated_items.caloric_expenditure.daily_caloric_intake = result.toLocaleString('pt-br', {maximumFractionDigits: 0})

      return result
    },
    fcMaxima() {
      let age = this.student.age
      const result = !age ? 0 : (206.9 -  (age * 0.67))
      this.cycleToBeEvaluated.evaluated_items.caloric_expenditure.fc_max = result.toFixed(0)

      return result
    },
    fcReserva () {
      let fcRepouso = this.cycleToBeEvaluated.evaluated_items.heart.fcRepouso

      if (!fcRepouso || isNaN(parseFloat(fcRepouso))){
        this.fcReservaError = 'Verifique FC de Repouso'
        this.cycleToBeEvaluated.evaluated_items.caloric_expenditure.fc_reserve = {
          error: this.fcReservaError
        }
        return 0
      } else {
        this.fcReservaError = ''
      }

      const result = this.fcMaxima - 60
      this.cycleToBeEvaluated.evaluated_items.caloric_expenditure.fc_reserve = {
        value: result.toFixed(0)
      }

      return result
    },
    sumSeries () {
      let treinos = this.cycleToBeEvaluated.trainings
      let sum = 0
      try {
        treinos.forEach(treino => {
          treino.exercises.forEach( exercise => {
            if (exercise.series !== undefined) {
              sum += parseInt(exercise.series) || 0
            }
          })
        })
        return sum
      }catch (error) {
        return 0
      }
    },
    maximumForce () {
      let muscleItems = this.caloricExpenditure.muscle_items
      let max = 0
      try {
        muscleItems.forEach((element, index) => {
          let testLoad = parseInt(element.test_load)
          if (isNaN(testLoad)) {
            return
          }
          if (index === 0) {
            max = testLoad
          }
          max = testLoad > max ? testLoad : max
        })
        return max
      } catch (error) {
        return 0
      }
    },
    styleVo2Cursor () {
      let img = document.querySelector('#vo2chart')
      let {cursor} = this.vo2Classification
      try {
        let height = img.clientWidth * (300/596) * 0.95
        let width = img.clientWidth * 0.65
        return {
          width: `${width}px`,
          top: `${height}px`,
          left: `calc(50% - ${width/2}px)`,
          transform: `rotate(${cursor}deg)`
        }
      }catch(error) {
        return {
          width: `0px`,
          top: `0px`,
          left: `0px`,
          transform: `rotate(0deg)`
        }
      }
    },
    taxaMediaAtividade() {
      let aerobic_items = this.caloricExpenditure.aerobic_items
      let sum = 0

      try {
        aerobic_items.forEach(element => {
          sum += this.consumoSemana(element)
        })
       
       

        const result = aerobic_items.length <= 0 ? 0 : (sum) / 7
        this.cycleToBeEvaluated.evaluated_items.caloric_expenditure.average_rate = result.toLocaleString('pt-br', {maximumFractionDigits: 0})

        return result
      } catch (error) {
        return 0
      }
    },
    distanceRules(){
      return [
        v => (v && v >= this.minDistance) || `A distância precisa ser maior que ${this.minDistance} metros`
      ]
    },
    canAddExercise() {
      return this.cycleToBeEvaluated.evaluated_items.student.weight
          && this.cycleToBeEvaluated.evaluated_items.student.stature
          && this.caloricExpenditure.travel_distance
    },
    noDataTextExercises() {
      return this.canAddExercise ? 'Nenhum item a ser exibido': 'Verifique os campos estatura, massa e distância para poder adicionar exercício'
    },
    disableInput () {
      return this.readonly || this.isStudent
    },
    disableInputReadOnly () {
      return this.readonly
    }
  },
  watch: {
    calories: {
      deep: true,
      handler (newVal) {
        if (newVal !== undefined) {
          this.caloricExpenditure.calories = {...newVal}
        }
      }
    },
    calcs: {
      deep: true,
      handler (newVal) {
        if (newVal !== undefined) {
          this.caloricExpenditure.calcs = {...newVal}
        }
      }
    },
    'caloricExpenditure.travel_distance': {
      handler() {
        this.$nextTick(() => {
          this.calcs = {...this.vo2Data}
        })
      }
    },
    'mesocycleToBeEvaluated.evaluated_items.student.weight': {
      handler() {
        this.$nextTick(() => {
          this.calcs = {...this.vo2Data}
        })
      }
    },
    'microcycleToBeEvaluated.evaluated_items.student.weight': {
      handler() {
        this.$nextTick(() => {
          this.calcs = {...this.vo2Data}
        })
      }
    },
    'newItemAerobic.intensity': {
      handler(newVal) {
        if (newVal > 100) {
          this.newItemAerobic.intensity = 100
        }
      }
    },
    caloricExpenditure: {
      deep: true,
      handler(newVal) {
        if (newVal !== undefined) {
          this.cycleToBeEvaluated.evaluated_items.caloric_expenditure = {...newVal}
        }
      }
    }
  },
  mounted () {
    this.updateCurrentCycle();
    setTimeout(() => {
      console.log('relative', this.cycleToBeEvaluated)
      
      this.caloricExpenditure = {
        ...this.cycleToBeEvaluated.evaluated_items.caloric_expenditure,
        protocol: this.vo2Translate[this.cycleToBeEvaluated.macrocycle.vo2_protocol],
        calcs: {...this.vo2Data}
      }
    
      let calories = this.caloricExpenditure.calories
      if (calories !== undefined) {
        this.calories = {...calories}
      }
      //this.vo2Classification()
    }, 500)
  }

}
</script>

<style scoped>
.vo2--cursor--image{
  position: absolute;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
  width: 37px;
  left: -12px;
  top: -21.5px;
  transform: rotate(-123deg);
}
.vo2--cursor {
  height: 1px;
  position: absolute;
  transition: transform 0.5s ease-out;
}
.vo2--chart {
  object-fit: contain;
  overflow: visible;
}
</style>
