import axios from 'axios'

const baseURL = 'https://fisic-node-prod.mestresdaweb.org'

const axiosNodeInstance = axios.create({
  baseURL: `${baseURL}/api/`
})

axiosNodeInstance.defaults.headers.common.Accept = 'application/json'

export default axiosNodeInstance
