<template>
  <table>
    <tr>
      <td :class="[canDrag && 'drag', 'drag-column']" v-if="!readonly">
        <v-img
          src="@/assets/images/drag-icon.svg"
          class="drag-icon"
          contain
        />
      </td>
      <td v-for="(header, index) in headers.slice(1, headers.length)" :style="columnSize(header)" :key="index">
        <div :style="getAlignment(header)">
          <slot :item="item" :name="columnName(header)">
            {{ getNonSlotValue(item, header) }}
          </slot>
        </div>
      </td>
    </tr>
    <tr v-if="isExpanded">
      <slot name="expanded-item"></slot>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'DataTableRowHandler',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Array,
      default: () => ([])
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    canDrag: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    columnName (header) {
      return `item.${header.value}`
    },
    getAlignment (header) {
      const align = header.align ? header.align : 'center'
      return `text-align: ${align}`
    },
    getNonSlotValue (item, header) {
      const [val1, val2] = header.value.split('.')
      let val = item[val1]

      if (val2 && val) {
        val = val[val2]
      }
      if (val) {
        return val
      }

      return ''
    },
    columnSize (header) {
      return `width: ${header.width + 5}px; min-width: ${header.width}px;`
    }
  }
}
</script>

<style scoped>
  .drag-column {
    width: 50px;
    max-width: 50px;
    cursor: move;
  }

  .drag-icon {
    width: 40px;
    height: 20px;
  }
</style>
